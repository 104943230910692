<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getComplainList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4" />
        </div>
        <Row>
            <Col span="24">
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getComplainList"
                :highlight="true"
                @on-row-click="onComplainSelect" />
            </Col>
        </Row>

        <Modal v-model="complainModal.show" title="用户留言信息" :mask-closable="false" width="50">
            <Spin v-if="complainModal.loading"
                size="large"
                fix></Spin>
            <Form ref="complainModalForm"
                :label-width="100">
                <Row>
                    <Col span="12">
                    <FormItem label="会员号:">
                        <Input v-model="complainModal.memCode" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="会员名称:">
                        <Input v-model="complainModal.memName" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="12">
                    <FormItem label="会员联系方式:">
                        <Input v-model="complainModal.memLinkMan" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="留言时间:">
                        <Input v-model="complainModal.complainTime" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="24">
                    <FormItem label="留言类型:">
                        <RadioGroup v-model="complainModal.complainType" >
                            <Radio label="1" disabled>
                                <span>留言</span>
                            </Radio>
                            <Radio label="2" disabled>
                                <span>建议</span>
                            </Radio>
                            <Radio label="3" disabled>
                                <span>投诉客户经理</span>
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="标题:">
                        <Input v-model="complainModal.title" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="留言内容:">
                        <Input v-model="complainModal.complainText" type="textarea" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row  v-if="images.length > 0">
                    <Col span="24">
                    <FormItem label="图片:">
                        <template>
                            <Row :gutter="16" style="margin-top: 10px;">
                                <Col span="6" v-for="(item, index) in images" :key="index">
                                    <div style="width: 150px; height: 150px;">
                                        <img :src="item" fit="fill" width="150px" height="150px"/>
                                    </div>
                                </Col>
                            </Row>
                        </template>
                    </FormItem>
                    </Col>
                </Row>
                <Row v-if="complainModal.isAnswer == 1">
                    <Col span="12">
                    <FormItem label="回复人:">
                        <Input v-model="complainModal.answerMan" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="回复时间:">
                        <Input v-model="complainModal.answerTime" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="回复内容:"
                        required>
                        <Input v-model="complainModal.answerText"  type="textarea"></Input>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info" @click="onSaveComplain">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
export default {
    name: 'ComplainList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            complainModal: {
                show: false,
                loading: false,
                memCode: '',
                memName: '',
                memLinkMan: '',
                complainTime: '',
                title: '',
                complainText: '',
                answerMan: '',
                answerTime: '',
                answerText: '',
                complainType: '',
                picUrl: '',
                isAnswer: '',
                mode: 'add'
            },
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '会员号', key: "MemCode", align: 'center' },
                    { title: '会员名称', key: "MemName", align: 'center' },
                    { title: '联系方式', key: "MemLinkMan", align: 'center' },
                    { title: '留言时间', key: "ComplainTime", align: 'center' },
                    { title: '留言类型', key: "ComplainType", align: 'center',
                        render: (h, params) => {
                            let text = params.row.ComplainType === 1 ? '留言' : params.row.ComplainType === 2 ? '建议' : '投诉客户经理';
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    { title: '标题', key: "Title", align: 'center' },
                    { title: '图片', key: "PicUrl", align: 'center',
                        render: (h, params) => {
                            let srcArr = params.row.PicUrl.split(",");
                            let imgArr = [];
                            srcArr.forEach(a => {
                                imgArr.push(
                                    h('img', {
                                        attrs: {
                                            src: this.imageDomain + a,
                                            style: 'width: 20px;margin-right:6px'
                                        }
                                    })
                                );
                            });
                            return h('div', imgArr);
                        }
                    },
                    { title: '回复人', key: "AnswerMan", align: 'center' },
                    { title: '回复时间', key: "AnswerTime", align: 'center' },
                    { title: '回复内容', key: "AnswerText", align: 'center' },
                    { title: '是否回复', key: "IsAnswer", align: 'center',
                        render: (h, params) => {
                            let text = params.row.IsAnswer === 1 ? '已回复' : '未回复';
                            let color = params.row.IsAnswer === 1 ? 'black' : 'red';
                            return h('div', [
                                h('span', {
                                    style: {
                                        color: color
                                    }
                                }, text)
                            ]);
                        }
                    },
                    {
                        title: "操作",
                        align: "center",
                        width: 80,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showCustom: this.checkAccessArray('ComplainEdit'),
                                        customText: '回复留言',
                                        showDelete: this.checkAccessArray('ComplainEdit')
                                    },
                                    on: {
                                        handleCustom: dataId => {
                                            this.onReplyComplain(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteComplain(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '会员号', type: 'Input', value: '', name: 'MemCode', sql: 'like' },
                    { label: '会员名称', type: 'Input', value: '', name: 'MemName', sql: 'like' },
                    { label: '标题', type: 'Input', value: '', name: 'Title', sql: 'like' },
                    { label: '留言类型', type: 'Select', value: [], name: 'ComplainType', sql: 'in', options: [{ key: '建议', value: '2' }, { key: '投诉客户经理', value: '3' }], clearable: true },
                    { label: '是否回复', type: 'Select', value: [], name: 'isAnswer', sql: 'in', options: [{ key: '未回复', value: '0' }, { key: '已回复', value: '1' }], clearable: true }
                ]
            },
            selectedComplain: null,
            images: []
        };
    },
    computed: {

    },
    mounted() {
        this.getComplainList();
    },
    methods: {
        onComplainSelect(row, index) {
            this.selectedComplain = row;
        },
        onAddComplain() {
            this.complainModal.mode = 'add';
            this.complainModal.id = '';
            this.complainModal.memCode = '',
            this.complainModal.memName = '',
            this.complainModal.memLinkMan = '',
            this.complainModal.complainTime = '',
            this.complainModal.title = '',
            this.complainModal.complainText = '',
            this.complainModal.answerMan = '',
            this.complainModal.answerTime = '',
            this.complainModal.answerText = '',
            this.complainModal.complainType = '',
            this.complainModal.picUrl = '',
            this.complainModal.isAnswer = '',
            this.complainModal.show = true;
        },
        onDeleteComplain(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '确定删除吗？',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.complainDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getComplainList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onReplyComplain(id) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.complainModal.id = b.Id;
            this.complainModal.memCode = b.MemCode;
            this.complainModal.memName = b.MemName;
            this.complainModal.memLinkMan = b.MemLinkMan;
            this.complainModal.complainTime = b.ComplainTime;
            this.complainModal.title = b.Title;
            this.complainModal.complainText = b.ComplainText;
            this.complainModal.answerMan = b.AnswerMan;
            this.complainModal.answerTime = b.AnswerTime;
            this.complainModal.answerText = b.AnswerText;
            this.complainModal.complainType = b.ComplainType + '';
            this.complainModal.picUrl = b.PicUrl;
            this.complainModal.isAnswer = b.IsAnswer;
            this.complainModal.mode = 'modify';
            this.complainModal.show = true;

            this.images = this.complainModal.picUrl.split(",").map(p=> this.imageDomain + p);
        },
        onSaveComplain() {

            if(!this.complainModal.answerText) {
                return this.showWarningModal('请先输入回复内容！');
            }

            let replyObj = {
                id: this.complainModal.id,
                answerText: this.complainModal.answerText
            };
            this.complainModal.loading = true;
            api.complainReply(replyObj).then(res => {
                this.complainModal.loading = false;
                if (res.code === 0) {
                    this.complainModal.show = false;
                    this.getComplainList();
                    this.showSuccessModal();
                }
            });
        },
        getComplainList() {
            this.mainTable.tableLoading = true;
            this.mainTable.tableSearch.filters.enable = true;
            this.mainTable.tableSearch.filters.conditions.push({ name: 'ComplainType', type:'neq', value: '1' });
            api.complainList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedComplain = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        }
    }
};
</script>
<style>
</style>

