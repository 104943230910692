// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'mutationobserver-shim';
import Vue from 'vue';
import VueTour from 'vue-tour';
import VueCookies from 'vue-cookies';
import App from './App';
import router from './router';
import 'vue-tour/dist/vue-tour.css';
import store from './store';

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

//全局组件
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';

import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

import { init as initApm } from '@elastic/apm-rum';
initApm({
  serviceName: 'flower-admin',
  serverUrl: 'https://apm.kainonly.com:8443',
  serviceVersion: '',
  environment: process.env.NODE_ENV === 'development' ? 'dev' : 'prod'
});

//vue注册调用方法二：
Vue.use(Viewer, { name: 'Viewer' });
Viewer.setDefaults({
  Options: {
    inline: true, //启用 inline 模式 默认false
    button: true, //显示右上角关闭按钮 默认true
    navbar: true, //显示缩略图导航 默认true
    title: true, //显示当前图片的标题 默认true
    toolbar: true, // 显示工具栏 默认true
    tooltip: true, // 显示缩放百分比 默认true
    movable: true, //图片是否可移动 默认true
    zoomable: true, //图片是否可缩放 默认true
    rotatable: true, //图片是否可旋转 默认true
    scalable: true, //图片是否可翻转 默认true
    transition: true, //使用css3过度 默认true
    fullscreen: true, //是否全屏 默认true
    keyboard: true, //是否支持键盘 默认true
    url: 'data-source' //设置大图片的URL
  }
});

import VueBarcode from 'vue-barcode';
Vue.use('barcode', VueBarcode);

import Print from 'vue-print-nb';
Vue.use(Print);

Vue.use(VueCookies);
Vue.use(VueTour);
Vue.use(ViewUI);

Vue.component('pagination-table', PaginationTable);
Vue.component('search-card', SearchCard);
Vue.config.productionTip = false;

Vue.prototype.imageDomain = 'http://img.brightenflower.cn/';

//上次登录信息
Vue.prototype.setLoginInfo = function (loginInfo) {
  localStorage.setItem('bf_ic_user_login', JSON.stringify(loginInfo));
};
Vue.prototype.getLoginInfo = function () {
  return JSON.parse(localStorage.getItem('bf_ic_user_login'));
};

Vue.prototype.setLastLoginInfo = function (loginInfo) {
  localStorage.setItem('bf_ic_user_last_login', JSON.stringify(loginInfo));
};
Vue.prototype.getLastLoginInfo = function () {
  return JSON.parse(localStorage.getItem('bf_ic_user_last_login'));
};

Vue.prototype.setUserPermission = function (permissionList) {
  localStorage.setItem('bf_ic_user_permission', JSON.stringify(permissionList));
};
Vue.prototype.getUserPermission = function () {
  return JSON.parse(localStorage.getItem('bf_ic_user_permission'));
};

Vue.prototype.getUserNickName = function () {
  let userInfo = this.getLoginInfo();
  if (userInfo) {
    return userInfo.userNickName;
  } else {
    return '';
  }
};
Vue.prototype.getUserToken = function () {
  let userInfo = this.getLoginInfo();
  if (userInfo) {
    return userInfo.userToken;
  } else {
    return '';
  }
};

Vue.prototype.getUserWorkRegionId = function () {
  let userInfo = this.getLoginInfo();
  if (userInfo) {
    return userInfo.userWorkRegionId;
  } else {
    return '';
  }
};

Vue.prototype.getUserDepartmentId = function () {
  let userInfo = this.getLoginInfo();
  if (userInfo) {
    return userInfo.userDepartmentId;
  } else {
    return '';
  }
};

Vue.prototype.getUserDepartmentName = function () {
  let userInfo = this.getLoginInfo();
  if (userInfo) {
    return userInfo.userDepartmentName;
  } else {
    return '';
  }
};

Vue.prototype.getUserId = function () {
  let userInfo = this.getLoginInfo();
  if (userInfo) {
    return userInfo.userId;
  } else {
    return '';
  }
};
Vue.prototype.getLeaderId = function () {
  let userInfo = this.getLoginInfo();
  if (userInfo) {
    return userInfo.leaderId;
  } else {
    return '';
  }
};

Vue.prototype.getUserRole = function () {
  let userInfo = this.getLoginInfo();
  if (userInfo) {
    return userInfo.userRole;
  } else {
    return '';
  }
};

Vue.prototype.getUserPosition = function () {
  let userInfo = this.getLoginInfo();
  if (userInfo) {
    return userInfo.userPosition;
  } else {
    return '';
  }
};

Vue.prototype.getUserType = function () {
  let userInfo = this.getLoginInfo();
  if (userInfo) {
    return userInfo.userType;
  } else {
    return '';
  }
};

Vue.prototype.getUserOrgCode = function () {
  let userInfo = this.getLoginInfo();
  if (userInfo) {
    return userInfo.orgCode;
  } else {
    return '';
  }
};

Vue.prototype.getMenus = function (resourceList) {
  if (resourceList) {
    let menuArray = [];
    let meuns = resourceList.filter(it => it.resType > 0 && it.resType < 3);
    meuns.forEach(item => {
      item.children = meuns.filter(info => info.parentId === item.id).sort(this.compareTo('sort', 'asc'));
      if (item.resType === 1) {
        menuArray.push(item);
      }
    });
    return menuArray.sort(this.compareTo('sort', 'asc'));
  } else {
    return [];
  }
};

Vue.prototype.compareTo = function (fieldName, type) {
  if (type === 'asc') {
    return function (obj1, obj2) {
      let val1 = obj1[fieldName];
      let val2 = obj2[fieldName];
      if (val1 > val2) {
        return 1;
      } else if (val1 < val2) {
        return -1;
      } else {
        return 0;
      }
    };
  } else {
    return function (obj1, obj2) {
      let val1 = obj1[fieldName];
      let val2 = obj2[fieldName];
      if (val1 < val2) {
        return 1;
      } else if (val1 > val2) {
        return -1;
      } else {
        return 0;
      }
    };
  }
};

Vue.prototype.showSuccessModal = function (content = '操作成功') {
  setTimeout(() => {
    // this.$Modal.success({ title: '操作成功', content: content });
    this.$Message.success({ content: content });
  }, 301);
};

Vue.prototype.showWarningModal = function (content = '') {
  setTimeout(() => {
    this.$Modal.warning({ title: '警告', content: content });
  }, 301);
};

Vue.prototype.showLoading = function () {
  this.$Spin.show({
    render: h => {
      return h('div', [
        h('Icon', {
          style: {
            animation: 'ani-demo-spin 1s linear infinite',
            color: 'green'
          },
          props: {
            type: 'ios-loading',
            size: 24
          }
        }),
        h(
          'div',
          {
            style: {
              color: 'green',
              fontSize: '20px'
            }
          },
          '数据加载中...'
        )
      ]);
    }
  });
};
Vue.prototype.hideLoading = function () {
  this.$Spin.hide();
};

Vue.prototype.checkAccessArray = function (access) {
  let accessArray = JSON.parse(localStorage.getItem('bf_ic_user_permission'));
  return accessArray.includes(access);
};

Vue.prototype.checkCreator = function (access) {
  return this.getUserId() === access;
};

Vue.prototype.openUrl = function (url) {
  window.open(url);
};

Vue.prototype.$downLoadFile = function (url) {
  let link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  let fileName = url.split('/').pop();
  link.setAttribute('download', fileName); //指定下载后的文件名，防跳转
  document.body.appendChild(link);
  link.click();
};

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('bf_ic_user_login') !== null) {
    next();
  } else {
    if (to.name === 'UserLogin') {
      next();
      return;
    }
    next({
      path: 'system-management/user/user-login'
    });
  }
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App }
});
