import MyHome from '@/view/MyHome';

export default {
    path: '/container',
    name: 'container',
    component: MyHome,
    meta: { title: '集货站管理', keepAlive: true },
    children: [{
        path: 'commission-settlement/commission-settlement',
        name: 'CommissionSettlementList',
        component: () => import('@/view/container-management/commission-settlement/commission-settlement'),
        meta: {
            title: '代销结算',
            keepAlive: false
        }
    },
    {
        path: 'commission-settlement/commission-settlement-audit',
        name: 'CommissionSettlementAudit',
        component: () => import('@/view/container-management/commission-settlement/commission-settlement-audit'),
        meta: {
            title: '代销结算审核',
            keepAlive: false
        }
    },
    {
        path: 'commission-settlement/commission-settlement-summary',
        name: 'CommissionSettlementSummaryList',
        component: () => import('@/view/container-management/commission-settlement/commission-settlement-summary'),
        meta: {
            title: '代销结算汇总',
            keepAlive: true
        }
    },
    {
        path: 'writing-on-commission/writing-on-commission',
        name: 'WritingOnCommissionList',
        component: () => import('@/view/container-management/writing-on-commission/writing-on-commission'),
        meta: {
            title: '代销开单',
            keepAlive: true
        }
    },
    {
        path: 'check-supply/check-supply',
        name: 'CheckSupplyList',
        component: () => import('@/view/container-management/check-supply'),
        meta: {
            title: '查看供货',
            keepAlive: true
        }
    },
    {
        path: 'inspection-and-acceptance/inspection-and-acceptance',
        name: 'InspectionAndAcceptanceList',
        component: () => import('@/view/container-management/inspection-and-acceptance/inspection-and-acceptance'),
        meta: {
            title: '质检验收',
            keepAlive: true
        }
    },
    {
        path: 'allocation-of-cargo/allocation-of-cargo',
        name: 'AllocationOfCargoList',
        component: () => import('@/view/container-management/allocation-of-cargo/allocation-of-cargo'),
        meta: {
            title: '配货',
            keepAlive: true
        }
    },
    {
        path: 'product-printing/product-printing',
        name: 'ProductPrintingList',
        component: () => import('@/view/container-management/product-printing/product-printing'),
        meta: {
            title: '产品打印',
            keepAlive: true
        }
    },
    {
        path: 'scan-inbound/scan-inbound',
        name: 'ScanInboundList',
        component: () => import('@/view/container-management/scan-inbound/scan-inbound'),
        meta: {
            title: '入库扫描',
            keepAlive: true
        }
    },
    {
        path: 'scan-outbound/scan-outbound',
        name: 'ScanOutboundList',
        component: () => import('@/view/container-management/scan-outbound/scan-outbound'),
        meta: {
            title: '配货扫描',
            keepAlive: true
        }
    },
    {
        path: 'scan-outbound/Inbox',
        name: 'ScanOutInbox',
        component: () => import('@/view/container-management/inbox-management/index'),
        meta: {
            title: '入框管理',
            keepAlive: true
        }
    },
    {
        path: 'scan-outbound/supplyData',
        name: 'ScanOutSupplyData',
        component: () => import('@/view/container-management/supply-data/index'),
        meta: {
            title: '上传供货数据',
            keepAlive: true
        }
    },
    {
        path: 'category-fee',
        name: 'ProductCategoryFee',
        component: () => import('@/view/container-management/product-category-fee/index'),
        meta: {
            title: '收费预设',
            keepAlive: false
        }
    }
    ]
};
