import getPostRequest, { getXpackPostRequest } from './base-api';

export default {
	// 获取当前服务器已有分片信息
	logisticsExcelChunkUpload(data){
		return getXpackPostRequest(data, "logisticsFreight/chunkUpload");
	},
	// 检查是否可以秒传
	logisticsCheckFastUpload(data){
		return getXpackPostRequest(data, "logisticsFreight/checkFastUpload");
	},
    // 获取物流部门
    logisticsDeptList(data) {
        return getPostRequest(data, 'api/parameters/logisticsDeptList');
    },
    // 物流运费
    logisticsFreightList(data) {
        return getPostRequest(data, 'api/saleInvoice/logisticsFreight');
    },
    // 保存物流运费
    logisticsFreightSave(data) {
        return getPostRequest(data, 'api/saleInvoice/logisticsFreightSave');
    },
    // 提交物流运费
    logisticsFreightCommit(data) {
        return getPostRequest(data, 'api/saleInvoice/logisticsFreightCommit');
    },
    // 查看物流运费
    logisticsFreightViewList(data) {
        return getPostRequest(data, 'api/saleInvoice/logisticsFreightView');
    },
	// 保存打包装箱费
	savepackingBoxFee(data) {
	    return getPostRequest(data, 'api/saleInvoice/packingBoxFeeSave');
	},
	// 提交打包装箱费
	packingBoxFeeCommit(data) {
	    return getPostRequest(data, 'api/saleInvoice/packingBoxFeeCommit');
	},
    // 打包装箱
    packingBoxFeeList(data) {
        return getPostRequest(data, 'api/saleInvoice/packingBoxFeeList');
    },
    // 查看打包装箱费
    packingBoxFeeView(data) {
        return getPostRequest(data, 'api/saleInvoice/packingBoxFeeView');
    },
	//物流方案列表
	freightConfigList (data) {
	    return getPostRequest(data, 'api/freightConfig/freightConfigList');
	},
	//获取非汽车早班预设明细数据
	otherFreightConfigList(data) {
	    return getPostRequest(data, 'api/freightConfig/freightConfigList');
	},
	//获取汽车早班预设明细数据
	carFreightConfigList(data) {
	    return getPostRequest(data, 'api/freightConfig/freightConfigList');
	},
	//运费预估设置保存
	freightConfigSave(data) {
	    return getPostRequest(data, 'api/freightConfig/freightConfigSave');
	},
	//删除运费预估设置
	freightConfigDelete(data) {
	    return getPostRequest(data, 'api/freightConfig/freightConfigDelete');
	},
	//获取箱号列表
	boxListList(data) {
	    return getPostRequest(data, 'api/boxList/boxListList');
	},
	//获取审批
	freightReconciliationSave(data) {
	    return getPostRequest(data, 'api/freightReconciliation/freightReconciliationSave');
	},
	//获取对账列表
	freightReconciliationList(data) {
	    return getPostRequest(data, 'api/freightReconciliation/freightReconciliationList');
	},
	//删除对账
	freightReconciliationDelete(data) {
	    return getPostRequest(data, 'api/freightReconciliation/freightReconciliationDelete');
	},
	//对账详情
	freightReconciliationInfo(data) {
	    return getPostRequest(data, 'api/freightReconciliation/freightReconciliationInfo');
	},
	//对账审批
	freightReconciliationApprove(data) {
	    return getPostRequest(data, 'api/freightReconciliation/freightReconciliationApprove');
	},
	//对账审批详情
	freightReconciliationApproveList(data) {
	    return getPostRequest(data, 'api/freightReconciliationApprove/freightReconciliationApproveList');
	},
	iceBottleCtSave(data) {
	    return getPostRequest(data, 'api/boxList/iceBottleCtSave');
	},
	boxListSave(data) {
	    return getPostRequest(data, 'api/boxList/boxListSave');
	},
	boxListDelete(data) {
	    return getPostRequest(data, 'api/boxList/boxListDelete');
	},
	//按目的地城市运费预设
	freightConfigCityList(data) {
	    return getPostRequest(data, 'api/freightConfig/freightConfigCityList');
	},
	//运费预设批量保存接口
	freightConfigBatchSave(data) {
	    return getPostRequest(data, 'api/freightConfig/freightConfigBatchSave');
	},
	//所有城市列表
	getAllCityList(data) {
	    return getPostRequest(data, 'api/district/allCityList');
	},
	//根据城市删除预设配置
	freightConfigDeleteByCity(data) {
	    return getPostRequest(data, 'api/freightConfig/freightConfigDeleteByCity');
	},
	//根据会员号获取会员名称、默认地址和物流方案
	getMemberDefaultAddress(data) {
	    return getPostRequest(data, 'api/memberAddress/memberDefaultAddress');
	},
	//加箱面提交
	boxFaceCreate(data) {
	    return getPostRequest(data, 'api/order/boxFaceCreate');
	},
};

