import MyHome from '@/view/MyHome';

const prefix = "scan-management";

export default {
    path: `/scan`,
    name: 'ScanManagement',
    component: MyHome,
    meta: { title: '扫码管理', keepAlive: true },
    children: [
        {
            path: 'order-barcode/track-list',
            name: 'BarcodeTrack',
            component: () => import(`@/view/${prefix}/order-barcode/track-list`),
            meta: { title: '条码跟踪表', keepAlive: false }
        },
        {
            path: 'order-barcode/scan-record-list',
            name: 'BarcodeScanRecordList',
            component: () => import(`@/view/${prefix}/order-barcode/scan-record-list`),
            meta: { title: '扫码明细', keepAlive: false }
        }
    ]
};
