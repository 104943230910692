<template>
	<div>
		<Form ref="formRef" :label-width="140" :model="formValidate" :rules="ruleValidate" style="width: 600px">
			<FormItem class="mb-20">
			    <h3>密码修改</h3>
			</FormItem>
			<FormItem label="会员号:" prop="memberCode" class="mb-20">
			    <Input v-model="formValidate.memberCode" @on-blur="getMemberName"></Input>
			</FormItem>
			<FormItem label="会员名称:" prop="memberName" class="mb-20">
			    <Input v-model="formValidate.memberName" readonly></Input>
			</FormItem>
			<FormItem label="请输入新密码:" prop="password" class="mb-20"> 
			    <Input v-model="formValidate.password" type="password" password ></Input>
			</FormItem>
			<FormItem label="请再次输入新密码:" prop="passwordConfirm" class="mb-20">
			    <Input v-model="formValidate.passwordConfirm" type="password" password ></Input>
			</FormItem>
			<FormItem class="mb-10">
			    <Button type="info" @click="onSave('formRef')" v-if="checkAccessArray('memberResetPassword')">确认修改密码</Button>
			</FormItem>
		</Form>
	</div>
</template>

<script>
	import api from '@/api/api';
	export default {
		data() {
			const validatePassword =(rule, value, callback) =>{
				if (value !== formValidate.password && formValidate.password !== '') {
					callback(new Error("两次输入的密码不一致"));
				} else {
					callback();
				}
			};
			return {
				memberId:'',
				formValidate:{
					memberCode:'',
					memberName:'',
					password:'',
					passwordConfirm:''
				},
				ruleValidate:{
					memberCode: [
						{ required: true, message: '会员号不能为空', trigger: 'blur' }
					],
					memberName: [
						{ required: true, message: '会员名称不能为空', trigger: 'blur' }
					],
					password: [{
						required: true,
						message: '请输入新密码',
						trigger: 'blur'
					},{
						pattern: /^[0-9a-zA-Z_]{6,16}$/,
						message: '密码应由0-9,a-z,-_任意组合6-16个的字符串',
						trigger: 'blur'
					}
					],
					passwordConfirm: [{
						required: true,
						message: '请再次输入新密码',
						trigger: 'blur'
					},{
						required: true,
						validator: this.validatePassword,
						message: '两次输入的密码不一致',
						trigger: "blur"
					}
					]
				}
			}
		},
		methods:{
			
			getMemberName(){
				if(this.formValidate.memberCode != ''){
					this.getMemberInfo()
				}
			},
			async getMemberInfo() {
				const {data : data} = await api.memberFundInfoByCodeOrPhone({codeOrPhone:this.formValidate.memberCode})
				let res = data.memberFundVO
				this.formValidate.memberName = res.memberName
				this.memberId = res.memberId
			},
			onSave(name){
				this.$refs[name].validate((valid) => {
					if (valid) {
						try{
							let dataForm = {
								memberId : this.memberId,
								password : this.formValidate.password,
								passwordConfirm : this.formValidate.passwordConfirm
							}
							api.memberResetPassword(dataForm).then(res =>{
								if(res.code == 0){
									this.showSuccessModal('会员密码修改成功!');
									this.$refs[name].resetFields();
								}
							})
							
						}catch(e){
							console.log(e);
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
.mb-20{
	margin-bottom: 20px !important;
}
</style>