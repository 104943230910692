<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getManagerComplainList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4" />

        </div>
        <Row>
            <Col span="24">
            <pagination-table tableCaption="客户投诉列表"
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getManagerComplainList"
                :highlight="true"
                @on-row-click="onManagerComplainSelect" />
            </Col>
        </Row>

        <Modal v-model="managerComplainModal.show" title="客户投诉信息" :mask-closable="false" width="50">
            <Spin v-if="managerComplainModal.loading"
                size="large"
                fix></Spin>
            <Form ref="managerComplainModalForm"
                :label-width="100">
                <Row>
                    <Col span="12">
                    <FormItem label="客户经理ID:">
                        <Input v-model="managerComplainModal.userId" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="客户经理名称:">
                        <Input v-model="managerComplainModal.userName" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="12">
                    <FormItem label="投诉时间:">
                        <Input v-model="managerComplainModal.complainTime" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="标题:">
                        <Input v-model="managerComplainModal.title" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row
                    <Col span="12">
                    <FormItem label="投诉类型:">
                        <Input v-model="managerComplainModal.complainType" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row
                    <Col span="24">
                    <FormItem label="投诉内容:">
                        <Input v-model="managerComplainModal.complainText" type="textarea" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row v-if="images.length > 0">
                    <Col span="24">
                    <FormItem label="图片:">
                        <template>
                            <Row :gutter="16" style="margin-top: 10px;">
                                <Col span="6" v-for="(item, index) in images" :key="index">
                                    <div style="width: 150px; height: 150px;">
                                        <img :src="item" fit="fill" width="150px" height="150px"/>
                                    </div>
                                </Col>
                            </Row>
                        </template>
                    </FormItem>
                    </Col>
                </Row>
                <Row  v-if="managerComplainModal.isAnswer == 1">
                    <Col span="12">
                    <FormItem label="回复人:">
                        <Input v-model="managerComplainModal.answerMan" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="回复时间:">
                        <Input v-model="managerComplainModal.answerTime" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="回复内容:">
                        <Input v-model="managerComplainModal.answerText" type="textarea"></Input>
                    </FormItem>
                    </Col>
                </Row>

            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveManagerComplain">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
export default {
    name: 'ManagerComplainList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            imageDomain: this.imageDomain,
            managerComplainModal: {
                show: false,
                loading: false,
                userId: '',
                userName: '',
                complainTime: '',
                title: '',
                complainText: '',
                answerMan: '',
                answerTime: '',
                answerText: '',
                complainType: '',
                picUrl: '',
                isAnswer: '',
                mode: 'add',
            },
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '客户经理名称', key: "UserName", align: 'center' },
                    { title: '投诉时间', key: "ComplainTime", align: 'center' },
                    { title: '标题', key: "Title", align: 'center' },
                    { title: '投诉内容', key: "ComplainText", align: 'center' },
                    { title: '投诉类型', key: "ComplainType", align: 'center' },
                    { title: '图片', key: "PicUrl", align: 'center',
                        render: (h, params) => {
                            let srcArr = params.row.PicUrl.split(",");
                            let imgArr = []
                            srcArr.forEach((a) => {
                                imgArr.push(
                                    h('img', {
                                        attrs: {
                                            src: this.imageDomain + a,
                                            style: 'width: 20px;margin-right:6px'
                                        }
                                    })
                                )
                            })
                            return h('div', imgArr)
                        }
                    },
                    { title: '回复人', key: "AnswerMan", align: 'center' },
                    { title: '回复时间', key: "AnswerTime", align: 'center' },
                    { title: '回复内容', key: "AnswerText", align: 'center' },
                    { title: '是否回复', key: "IsAnswer", align: 'center',
                        render: (h, params) => {
                            let text = params.row.IsAnswer === 0 ? '未回复' : '已回复';
                            let color = params.row.IsAnswer === 1 ? 'black' : 'red';
                            return h('div', [
                                h('span', {
                                    style: {
                                        color: color
                                    }
                                }, text)
                            ]);
                        }
                    },
                    {
                        title: "操作",
                        align: "center",
                        width: 80,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showCustom: this.checkAccessArray('ComplainEdit'),
                                        customText: '回复投诉',
                                        showDelete: this.checkAccessArray('ManagerComplainEdit')
                                    },
                                    on: {
                                        handleCustom: dataId => {
                                            this.onReplyManagerComplain(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteManagerComplain(dataId);
                                        }
                                    }
                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '客户经理', type: 'Input', value: '', name: 'UserName', sql: 'like' },
                    { label: '标题', type: 'Input', value: '', name: 'Title', sql: 'like' },
                    /* { label: '投诉内容', type: 'Input', value: '', name: 'ComplainText', sql: 'like' },
                    { label: '回复人', type: 'Input', value: '', name: 'AnswerMan', sql: 'like' },
                    { label: '回复内容', type: 'Input', value: '', name: 'AnswerText', sql: 'like' }, */
                    { label: '投诉类型', type: 'Input', value: '', name: 'ComplainType', sql: 'like' },
                ]
            },
            selectedManagerComplain: null,
            images: [],
        };
    },
    computed: {

    },
    mounted() {
        this.getManagerComplainList();
    },
    methods: {
        onManagerComplainSelect(row, index) {
            this.selectedManagerComplain = row;
        },
        onDeleteManagerComplain(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '确定删除吗？',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.managerComplainDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getManagerComplainList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onReplyManagerComplain(id) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.managerComplainModal.id = b.Id;
            this.managerComplainModal.userId = b.UserId;
            this.managerComplainModal.userName = b.UserName;
            this.managerComplainModal.complainTime = b.ComplainTime;
            this.managerComplainModal.title = b.Title;
            this.managerComplainModal.complainText = b.ComplainText;
            this.managerComplainModal.answerMan = b.AnswerMan;
            this.managerComplainModal.answerTime = b.AnswerTime;
            this.managerComplainModal.answerText = b.AnswerText;
            this.managerComplainModal.complainType = b.ComplainType;
            this.managerComplainModal.picUrl = b.PicUrl;
            this.managerComplainModal.isAnswer = b.IsAnswer;
            this.managerComplainModal.mode = 'modify';
            this.managerComplainModal.show = true;

            this.images = this.managerComplainModal.picUrl.split(",").map(p=> this.imageDomain + p);
        },
        onSaveManagerComplain() {
            this.managerComplainModal.loading = true;

            if(!this.managerComplainModal.answerText) {
               return this.showWarningModal('请先输入回复内容！');
            }

            let replyObj = {
                id: this.managerComplainModal.id,
                answerText: this.managerComplainModal.answerText
            };
            api.managerComplainReply(replyObj).then(res => {
                this.managerComplainModal.loading = false;
                if (res.code === 0) {
                    this.managerComplainModal.show = false;
                    this.getManagerComplainList();
                    this.showSuccessModal();
                }
            });
        },
        getManagerComplainList() {
            this.mainTable.tableLoading = true;
            api.managerComplainList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedManagerComplain = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        }
    }
};
</script>
<style>
</style>

