<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getShareProductList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4" />
        </div>
        <Row>
            <Col span="24">
            <pagination-table :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getShareProductList"
                :highlight="true"
                @on-row-click="onShareProductSelect" />
            </Col>
        </Row>

        <Modal v-model="shareProductModal.show" title="我看花信息" :mask-closable="false" width="60" @on-cancel="onCancel">
            <Spin v-if="shareProductModal.loading" size="large" fix></Spin>
            <Form ref="shareProductModalForm"  :model="shareProductModal" :label-width="100">
                <Row>
                    <Col span="12">
                    <FormItem label="分享人:" prop="uploader">
                        <Input v-model="shareProductModal.uploader" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="分享类型:" prop="shareType">
                        <Input v-if="shareProductModal.shareType==0" value="基地内容" readonly></Input>
						<Input v-if="shareProductModal.shareType==1" value="出货实拍" readonly></Input>
						<Input v-if="shareProductModal.shareType==2" value="花艺分享" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="分享内容:" prop="shareContent">
                        <Input type="textarea" v-model="shareProductModal.shareContent" autosize readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
				<Row>
				   <Col span="24">
				   <FormItem label="分享图片:" prop="sharePicPath">
					   <img style="width:100px;height:100px;margin-right:18px;" v-for="(item, index) in shareProductModal.picList" :key="index" :src="item"/>
				   </FormItem>
				   </Col>
				</Row>
                <Row>
                    <Col span="24">
                    <FormItem label="分享视频:" prop="shareVideo">
						<video style="margin-right:20px;" width="220" height="180" controls v-for="(item, index) in shareProductModal.videoList" :key="index">
						    <source :src="item" type="video/mp4">
						</video>
                    </FormItem>
                    </Col>
                </Row>
				<Row>
				    <Col span="24">
						<FormItem label="关联产品:" prop="shareProTable">
							<div v-for="(item, index) in shareProductModal.shareProTable" :key="index">{{item.brand+' '+item.name+' '+item.grade+' '+item.color}}</div>
						</FormItem>
				    </Col>
				</Row>
                <Row>
                    <Col span="24">
                    <FormItem label="审核状态:" prop="status">
						<RadioGroup v-model="shareProductModal.status" size="small">
						    <Radio :label="0" v-if="shareProductModal.showApproveBtn">待审核</Radio>
						    <Radio :label="1">审核通过</Radio>
							<Radio :label="2">审核不通过</Radio>
						</RadioGroup>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
				<Button @click="onCancel()">关闭</Button>
                <Button type="info" @click="onApproveShareProduct" v-if="shareProductModal.showApproveBtn">审核</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
export default {
    name: 'ShareProductList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
			imageDomain: this.imageDomain,
            shareProductModal: {
                show: false,
                loading: false,
				showApproveBtn:false,
                uploader: '',
                headPicPath: '',
                shareContent: '',
                sharePicPath: '',
                shareVideo: '',
                relatedProductId: '',
                likeCount: 0,
                shareType: '',
                status: '',
                mode: 'add',
				picList:[],
				videoList:[],
				shareProTable:[]
            },
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
					{ title: '分享时间', key: "CreateTime", align: 'center' },
                    { title: '分享人', key: "Uploader", align: 'center' },
                    { title: '分享内容', key: "ShareContent", align: 'center' },
                    { title: '分享类型', key: "ShareType", align: 'center',
					render: (h, params) => {
					    let text = '';
					    switch (parseInt(params.row.ShareType)) {
					        case 0: text = '基地内容'; break;
					        case 1: text = '出货实拍'; break;
							case 2: text = '花艺分享'; break;
					        default: text = '未知类型';
					    }
					    return h('div', [
					        h('span', {}, text)
					    ]);
					}
					},
                    { title: '审核状态', key: "Status", align: 'center' ,
					render: (h, params) => {
					    let text = '';
					    switch (parseInt(params.row.Status)) {
					        case 0: text = '待审核'; break;
					        case 1: text = '审核通过'; break;
							case 2: text = '审核不通过'; break;
					        default: text = '未知类型';
					    }
					    return h('div', [
					        h('span', {}, text)
					    ]);
					}
					},
					{ title: '点赞数', key: "LikeCount", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 80,
						render: (h, params) => {
							let txt=params.row.Status==0?'审核':'查看';
							return h('div', [
								h('Button', {
									props: {
										size: 'small',
										shape: 'circle',
										statusField: 'Status',
										showEdit: this.checkAccessArray('ShareProductApprove')
									},
									style: {
										margin: '2px'
									},
									on: {
										click: () => {
											this.onShareProductApprove(params.row.Id);
										}
									}
								}, txt)
							]);
						}
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '分享人', type: 'Input', value: '', name: 'Uploader', sql: 'like' },
					{label: '分享类型',type: 'Select',value: '',name: 'ShareType',sql: 'eq',options: [{
								key:'基地内容',
								value: '0'
							},
							{
								key:  '出货实拍',
								value: '1'
							},
							{
								key: '花艺分享',
								value: '2'
							}
						],
						clearable: true
					},
					{label: '审核状态',type: 'Select',value: '',name: 'Status',sql: 'eq',options: [{
                   			key:'待审核',
                   			value: '0'
                   		},
                   		{
                   			key:  '审核通过',
                   			value: '1'
                   		},
						{
							key: '审核不通过',
							value: '2'
						}
                   	],
                   	clearable: true
                   },
                ]
            },
            selectedShareProduct: null,
        };
    },
    computed: {

    },
    mounted() {
        this.getShareProductList();
    },
    methods: {
        onShareProductSelect(row, index) {
            this.selectedShareProduct = row;
        },
        onShareProductApprove(id) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
			this.shareProductModal.id = b.Id;
			api.shareProductInfo({id:this.shareProductModal.id}).then(res => {
			    if (res.code === 0) {
			        this.shareProductModal.show = true;
					let shareProductInfo=res.data.shareProductInfo;
					this.shareProductModal.uploader = shareProductInfo.uploader;
					this.shareProductModal.shareContent = shareProductInfo.shareContent;
					this.shareProductModal.sharePicPath = shareProductInfo.sharePicPath;
					this.shareProductModal.shareVideo = shareProductInfo.shareVideo;
					this.shareProductModal.likeCount = shareProductInfo.likeCount;
					this.shareProductModal.shareType = shareProductInfo.shareType;
					this.shareProductModal.status = shareProductInfo.status;
					if(this.shareProductModal.status==0){
						this.shareProductModal.showApproveBtn=true;
					}else{
						this.shareProductModal.showApproveBtn=false;
					}
					if(!!this.shareProductModal.sharePicPath){
						this.shareProductModal.picList=this.shareProductModal.sharePicPath.split(',').map(p=> this.imageDomain + p);
					}else{
						this.shareProductModal.picList=[];
					}
					if(!!this.shareProductModal.shareVideo){
						this.shareProductModal.videoList=this.shareProductModal.shareVideo.split(',').map(p=> this.imageDomain + p);
					}else{
						this.shareProductModal.videoList=[];
					}
					this.shareProductModal.shareProTable=res.data.productList.map(p=>p);;
			    }
			});
        },
        onApproveShareProduct() {
            this.shareProductModal.loading = true;
			let params={
				id:this.shareProductModal.id,
				status:this.shareProductModal.status
			}
			console.log('params',params)
            api.shareProductApprove(params).then(res => {
                this.shareProductModal.loading = false;
                if (res.code === 0) {
                    this.shareProductModal.show = false;
					this.$Message.success('审核成功！');
					this.getShareProductList();
                }
            });
        },
        getShareProductList() {
            this.mainTable.tableLoading = true;
            api.shareProductList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedShareProduct = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
		onCancel(){
			this.$refs.shareProductModalForm.resetFields();
			this.shareProductModal.show = false;
		},
    }
};
</script>
<style>
	.proTitle{margin-top:20px;font-size:16px;font-weight: bold;}
</style>


