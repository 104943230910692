export const USER_POSITION_MEMBER = "组员";
export const USER_POSITION_LEADER = "组长";
export const USER_POSITION_MANAGER = "场长";
export const USER_POSITION_DIRECTOR = "总监";
export const USER_POSITION_GM = "总经理";
export const USER_POSITION_LIST = [USER_POSITION_MEMBER, USER_POSITION_LEADER, USER_POSITION_MANAGER, USER_POSITION_DIRECTOR, USER_POSITION_GM];
export const USER_LEADER_POSITION = { "组员": USER_POSITION_LEADER, "组长": USER_POSITION_MANAGER, "场长": USER_POSITION_DIRECTOR, "总监": USER_POSITION_GM, "总经理": USER_POSITION_GM };

export const USER_ROLE_ADMIN = "系统管理员";
export const USER_ROLE_SUPPLY = "供应商";
export const USER_ROLE_BUYER = "采购";
export const USER_ROLE_PROJECT = "项目场";
export const USER_ROLE_FINANCE = "财务";
export const USER_ROLE_HR = "人力资源";
export const USER_ROLE_WAREHOUSE = "仓管";
export const USER_ROLE_IT = "IT";
export const INNER_USER_ROLE_LIST = [USER_ROLE_ADMIN, USER_ROLE_SUPPLY, USER_ROLE_BUYER, USER_ROLE_PROJECT, USER_ROLE_FINANCE, USER_ROLE_HR, USER_ROLE_WAREHOUSE, USER_ROLE_IT];

export const USER_TYPE_SUPPLY = "1701";             // 供应商
export const USER_TYPE_JOIN = "1702";               // 产品经理
export const USER_TYPE_COLLECT = "1703";            // 集货站
export const USER_TYPE_CUSTOMER_MANAGER = "1704";   // 客户经理
export const USER_TYPE_OHTER = "1705";              // 其他
export const USER_TYPE_DELIVER = "1706";            // 配送站
export const USER_TYPE_INSIDER = "1707";            // 内部人员
export const USER_TYPE_EXPERT = "1708";             // 专家
