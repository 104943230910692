import api from "@/api/api";
import search from '@/libs/search';

export const getCompanyOptions = function (searchForm, selectItemName) {
    let tempSearch = search.getSearchParams({ closeAllConditions: true });
    api.companyList(tempSearch).then(res => {
        if (res.code === 0) {
            let companyOptions = res.data.records.map(e => {
                return {
                    key: e.companyName,
                    value: e.companyId
                };
            });
            let searchFormItem = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchFormItem) {
                searchFormItem.options = companyOptions;
            }
        }
    });
};

export const getDepartmentOptions = function (searchForm, selectItemName) {
    let tempSearch = search.getSearchParams({ closeAllConditions: true });
    api.departmentList(tempSearch).then(res => {
        if (res.code === 0) {
            let departmentOptions = res.data.records.map(e => {
                return {
                    key: e.departmentName,
                    value: e.departmentId
                };
            });
            let searchFormItem = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchFormItem) {
                searchFormItem.options = departmentOptions;
            }
        }
    });
};

export const getRegionOptions = function (searchForm, selectItemName) {
    let tempSearch = search.getSearchParams({ closeAllConditions: true });
    api.regionList(tempSearch).then(res => {
        if (res.code === 0) {
            let regionOptions = res.data.records.map(e => {
                return {
                    key: e.regionName,
                    value: e.regionId
                };
            });
            let searchFormItem = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchFormItem) {
                searchFormItem.options = regionOptions;
            }
        }
    });
};

export const getMainCateOptions = function (searchForm, selectItemName) {
    let tempSearch = search.getSearchParams();
    tempSearch.limit.enable = false;
    tempSearch.filters.enable = true;
    tempSearch.filters.conditions.push({ name: 'ParentId', type: 'eq', value: '_DB_NULL_' });
    api.productCategoryList(tempSearch).then(res => {
        if (res.code === 0) {
            let mainCateOptions = res.data.records.map(e => {
                return {
                    key: e.Name,
                    value: e.Name,
                    id: e.Id
                };
            });
            let searchFormItem = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchFormItem) {
                searchFormItem.options = mainCateOptions;
            }
        }
    });
};


export const getSubCategoryOptions = function (searchForm, selectItemName, mainCateName) {
    let tempSearch = search.getSearchParams();
    tempSearch.limit.enable = false;
    tempSearch.filters.enable = true;
    let searchFormItem = searchForm.find(e => {
        return e.name === "MainCategory";
    });
    if (searchFormItem && searchFormItem.options) {
        const mainCategory = searchFormItem.options.filter(v => {
            return v.value === mainCateName;
        })[0];
        if (mainCategory && mainCategory.id){
            tempSearch.filters.conditions.push({ name: 'T_ProductCategory.ParentId', type: 'eq', value: mainCategory.id });
        }else {
            let searchMain = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchMain) {
                searchMain.value = '';
                searchMain.options = [];
            }
            return;
        }
    }
    api.productCategoryList(tempSearch).then(res => {
        if (res.code === 0) {
            let mainCateOptions = res.data.records.map(e => {
                return {
                    key: e.Name,
                    value: e.Name
                };
            });
            let searchFormItem = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchFormItem) {
                searchFormItem.options = mainCateOptions;
            }
        }
    });
};

export const getBrandOptions = function (searchForm, selectItemName) {
    let tempSearch = search.getSearchParams({ closeAllConditions: true });
    api.brandList(tempSearch).then(res => {
        if (res.code === 0) {
            let brandOptions = res.data.records.map(e => {
                return {
                    key: e.Name,
                    value: e.Code
                };
            });
            let searchFormItem = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchFormItem) {
                searchFormItem.options = brandOptions;
            }
        }
    });
};

export const getSubCateOptions = function (searchForm, selectItemName) {
    let tempSearch = search.getSearchParams({ closeAllConditions: true });
    api.productCategoryList(tempSearch).then(res => {
        if (res.code === 0) {
            let subCateOptions = res.data.records.map(e => {
                return {
                    key: e.Name,
                    value: e.Name
                };
            });
            let searchFormItem = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchFormItem) {
                searchFormItem.options = subCateOptions;
            }
        }
    });
};

export const getThirdCateOptions = function (searchForm, selectItemName, includeAllCateName = false) {
    let tempSearch = search.getSearchParams({ closeAllConditions: true });
    api.thirdCateList(tempSearch).then(res => {
        if (res.code === 0) {
            let thirdCateOptions = res.data.records.map(e => {
                return {
                    key: includeAllCateName ? `${e.mainCateName}-${e.subCateName}-${e.thirdCateName}` : e.thirdCateName,
                    value: e.thirdCateId
                };
            });
            let searchFormItem = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchFormItem) {
                searchFormItem.options = thirdCateOptions;
            }
        }
    });
};

export const getWarehouseOptions = function (searchForm, selectItemName) {
    let tempSearch = search.getSearchParams({ closeAllConditions: true });
    api.warehouseList(tempSearch).then(res => {
        if (res.code === 0) {
            let warehouseOptions = res.data.records.map(e => {
                return {
                    key: e.warehouseName,
                    value: e.warehouseId
                };
            });
            let searchFormItem = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchFormItem) {
                searchFormItem.options = warehouseOptions;
            }
        }
    });
};

export const setSelectOptions = function (searchForm, selectItemName, dataSource, keyField, valueField) {
    let options = dataSource.map(e => {
        return {
            key: e[keyField],
            value: e[valueField]
        };
    });
    let searchFormItem = searchForm.find(e => {
        return e.name === selectItemName;
    });
    if (searchFormItem) {
        searchFormItem.options = options;
    }
};

export const getSupListByUserOptions = function (searchForm, selectItemName) {
    return new Promise((reslove, reject) => {
        let tempSearch = search.getSearchParams({ closeAllConditions: true });
        api.supplierListByUser(tempSearch).then(res => {
            if (res.code === 0) {
                let supOptions = res.data.records.map(e => {
                    return {
                        key: e.Code + ' ' + e.Name,
                        value: e.Code
                    };
                });
                let searchFormItem = searchForm.find(e => {
                    return e.name === selectItemName;
                });
                if (searchFormItem) {
                    searchFormItem.options = supOptions;
                    if (supOptions.length === 1) {
                        searchFormItem.value = supOptions[0].value;
                    }
                }
                if (supOptions.length === 0) {
                    reject();
                }
                else {
                    reslove();
                }
            }
            else {
                reject();
            }
        }).catch(err => {
            reject(err);
        });
    });
};

export const getBoxNumOptions = function (searchForm, selectItemName) {
    let tempSearch = search.getSearchParams({ closeAllConditions: true });
    api.boxListList(tempSearch).then(res => {
        if (res.code === 0) {
            let boxNumOptions = res.data.records.map(e => {
                return {
                    key: e.Code,
                    value: e.Code
                };
            });
            let searchFormItem = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchFormItem) {
                searchFormItem.options = boxNumOptions;
            }
        }
    });
};

export const getDeliveryWayOptions = function (searchForm, selectItemName) {
    let tempSearch = search.getSearchParams();
    tempSearch.limit.enable = false;
    tempSearch.filters.enable = true;
    tempSearch.filters.conditions.push({ name: 'ParaDec', type: 'eq', value: '物流方式' });
    api.parametersList(tempSearch).then(res => {
        if (res.code === 0) {
            let deliveryWayOptions = res.data.records.map(e => {
                return {
                    key: e.ParaName,
                    value: e.ParaName
                };
            });
            let searchFormItem = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchFormItem) {
                searchFormItem.options = deliveryWayOptions;
            }
        }
    });
};


export const getManagerDepartmentOptions = function (searchForm, selectItemName) {
    let tempSearch = search.getSearchParams();
    tempSearch.limit.enable = false;
    tempSearch.filters.enable = true;
    tempSearch.filters.conditions.push({ name: 'ParaDec', type: 'eq', value: '部门' });
    api.parametersList(tempSearch).then(res => {
        if (res.code === 0) {
            let managerDepartmentOptions = res.data.records.map(e => {
                return {
                    key: e.ParaName,
                    value: e.ParaName
                };
            });
            let searchFormItem = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchFormItem) {
                searchFormItem.options = managerDepartmentOptions;
            }
        }
    });
};

/**
 * @description: 根据账号获取绑定的集货站
 * @param {Array<Object>} searchForm
 * @param {String} selectItemName
 * @return {Promise}
 */
export const getCollectByUserOptions = function (searchForm, selectItemName) {
    return new Promise((reslove, reject) => {
        let tempSearch = search.getSearchParams({ closeAllConditions: true });
        api.parametersCollectByUserList(tempSearch).then(res => {
            if (res.code === 0) {
                let supOptions = res.data.records.map(e => {
                    return {
                        key: e.ParaCode + ' ' + e.ParaName,
                        value: e.ParaCode,
                        name: e.ParaName
                    };
                });
                let searchFormItem = searchForm.find(e => {
                    return e.name === selectItemName;
                });
                if (searchFormItem) {
                    searchFormItem.options = supOptions;
                    if (supOptions.length === 1) {
                        searchFormItem.value = supOptions[0].value;
                        searchFormItem.disabled = true;
                    }
                }
                reslove(supOptions);
            }
            else {
                reject();
            }
        }).catch(err => {
            reject(err);
        });
    });
};

export const getJoinByUserOptions = function (searchForm, selectItemName) {
    let tempSearch = search.getSearchParams({ closeAllConditions: true });
    api.parametersJoinByUserList(tempSearch).then(res => {
        if (res.code === 0) {
            let supOptions = res.data.records.map(e => {
                return {
                    key: e.ParaCode + ' ' + e.ParaName,
                    value: e.ParaCode
                };
            });
            let searchFormItem = searchForm.find(e => {
                return e.name === selectItemName;
            });
            if (searchFormItem) {
                searchFormItem.options = supOptions;
                if (supOptions.length === 1) {
                    searchFormItem.value = supOptions[0].value;
                }
            }
        }
    });
};

export const getServiceDeptOptions = function (searchForm, selectItemName) {
    return new Promise((reslove, reject) => {
        let tempSearch = search.getSearchParams({ closeAllConditions: true });
        api.parametersServiceDeptList(tempSearch).then(res => {
            if (res.code === 0) {
                let supOptions = res.data.records.map(e => {
                    return {
                        key: e.ParaName,
                        value: e.ParaName,
                        name: e.ParaName
                    };
                });
                let searchFormItem = searchForm.find(e => {
                    return e.name === selectItemName;
                });
                if (searchFormItem) {
                    searchFormItem.options = supOptions;
                    if (supOptions.length === 1) {
                        searchFormItem.value = supOptions[0].value;
                        searchFormItem.disabled = true;
                    }
                }
                reslove(supOptions);
            }
            else {
                reject();
            }
        }).catch(err => {
            reject(err);
        });
    });
};

export const getJoinDepartmentOptions = function (searchForm, selectItemName) {
    return new Promise((reslove, reject) => {
        let tempSearch = search.getSearchParams({ closeAllConditions: true });
        search.globalAddFilterConditionKey(tempSearch, 't2.ParaName', 'eq', '货源部门');
        api.departmentList(tempSearch).then(res => {
            if (res.code === 0) {
                let departmentOptions = res.data.records.map(e => {
                    return {
                        key: e.DeptName,
                        value: e.DeptName
                    };
                });
                let searchFormItem = searchForm.find(e => {
                    return e.name === selectItemName;
                });
                if (searchFormItem) {
                    searchFormItem.options = departmentOptions;
                }
                reslove(departmentOptions);
            }
        }).catch(err => {
            reject(err);
        });
    });
};

//获取赔付部门
export const getCompensateDepartmentOptions = function (searchForm, selectItemName) {
    return new Promise((reslove, reject) => {
        let tempSearch = search.getSearchParams({ closeAllConditions: true });
        api.getCompensateDepartment(tempSearch).then(res => {
            if (res.code === 0) {
                let departmentOptions = res.data.records.map(e => {
                    return {
                        key: e.ServiceDept,
                        value: e.ServiceDept
                    };
                });
                let searchFormItem = searchForm.find(e => {
                    return e.name === selectItemName;
                });
                if (searchFormItem) {
                    searchFormItem.options = departmentOptions;
                }
                reslove(departmentOptions);
            }
        }).catch(err => {
            reject(err);
        });
    });
};
