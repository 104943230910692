import getPostRequest from './base-api';

export default {
    getPurchaseOrderList(data) {
        return getPostRequest(data, 'api/purchase/purchaseList');
    },
    getPurchasePrintList(data) {
        return getPostRequest(data, 'api/purchase/purchaseList');
    },
    barCodeList(data) {
        return getPostRequest(data, 'api/inventory/barCodeList');
    },
    //供应商
    supSendMemOrderDiffList(data) {
        return getPostRequest(data, 'api/inventory/SupSendMemOrderDiffList');
    },
    supSendMemOrderDiffDetail(data) {
        return getPostRequest(data, 'api/inventory/SupSendMemOrderDiffDetail');
    },
    supSendConfirm(data) {
        return getPostRequest(data, 'api/inventory/supSendConfirm');
    },
    /**
     * @description: 供货商进销存
     * @param {object} data
     * @return {object}
     */
    supPSIList(data) {
        return getPostRequest(data, 'api/inventory/supPSIList');
    },
    //集运站
    collSendRevdDiffList(data) {
        return getPostRequest(data, 'api/inventory/collStaSendRevdDiffList');
    },
    collSendRevdDiffDetail(data) {
        return getPostRequest(data, 'api/inventory/collSendRevdDiffDetail');
    },
    collSendConfirm(data) {
        return getPostRequest(data, 'api/inventory/collSendConfirm');
    },
    //配送站
    /**
     * @description: 配送站收与集货站发差异列表
     * @param {object} data
     * @return {object}
     */
    delivRevdCollSendDiffList(data) {
        return getPostRequest(data, 'api/inventory/delivRevdCollSendDiffList');
    },
    /**
     * @description: 配送站收与集货站发差异明细
     * @param {object} data
     * @return {object}
     */
    delivRevdCollSendDiffDetail(data) {
        return getPostRequest(data, 'api/inventory/delivRevdCollSendDiffDetail');
    },
    /**
     * @description: 配送站收货确认
     * @param {object} data
     * @return {object}
     */
    delivRevdConfirm(data) {
        return getPostRequest(data, 'api/inventory/delivRevdConfirm');
    },
    /**
     * @description: 配送站发货与配送站收货差异列表
     * @param {object} data
     * @return {object}
     */
    delivSendRevdDiffList(data) {
        return getPostRequest(data, 'api/inventory/delivSendRevdDiffList');
    },
    /**
     * @description: 配送站发货与配送站收货差异明细
     * @param {object} data
     * @return {object}
     */
    delivSendRevdDiffDetail(data) {
        return getPostRequest(data, 'api/inventory/delivSendRevdDiffDetail');
    },
    /**
     * @description: 配送站发货确认
     * @param {object} data
     * @return {object}
     */
    delivSendConfirm(data) {
        return getPostRequest(data, 'api/inventory/delivSendConfirm');
    }
};
