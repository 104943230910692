import { render, staticRenderFns } from "./MyHome.vue.js?vue&type=template&id=afd40bcc&scoped=true!=!../../node_modules/vue-loader/lib/index.js??vue-loader-options!./MyHome.vue?vue&type=template&id=afd40bcc&scoped=true"
import script from "./MyHome.vue.js?vue&type=script&lang=js!=!../../node_modules/vue-loader/lib/index.js??vue-loader-options!./MyHome.vue?vue&type=script&lang=js"
export * from "./MyHome.vue.js?vue&type=script&lang=js!=!../../node_modules/vue-loader/lib/index.js??vue-loader-options!./MyHome.vue?vue&type=script&lang=js"
import style0 from "./MyHome.vue.less?vue&type=style&index=0&id=afd40bcc&prod&lang=less!=!../../node_modules/vue-loader/lib/index.js??vue-loader-options!./MyHome.vue?vue&type=style&index=0&id=afd40bcc&prod&lang=less"
import style1 from "./MyHome.vue.less?vue&type=style&index=1&id=afd40bcc&prod&scoped=true&lang=less!=!../../node_modules/vue-loader/lib/index.js??vue-loader-options!./MyHome.vue?vue&type=style&index=1&id=afd40bcc&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afd40bcc",
  null
  
)

export default component.exports