import MyHome from '@/view/MyHome';

export default {
    path: '/logistics',
    name: 'logistics',
    component: MyHome,
    meta: { title: '物流管理', keepAlive: true },
    children: [
        {
            path: 'packing-box/packing-box-fee',
            name: 'PackingBoxFee',
            component: () => import('@/view/logistics-management/packing-box/packing-box-fee'),
            meta: {
                title: '打包装箱费',
                keepAlive: true
            }
        },
        {
            path: 'packing-box/packing-box-fee-list',
            name: 'PackingBoxFeeList',
            component: () => import('@/view/logistics-management/packing-box/packing-box-fee-list'),
            meta: {
                title: '查看打包装箱费',
                keepAlive: true
            }
        },
        {
            path: 'logistics-freight/freight',
            name: 'LogisticsFreight',
            component: () => import('@/view/logistics-management/logistics-freight/freight'),
            meta: {
                title: '物流运费',
                keepAlive: true
            }
        },
        {
            path: 'logistics-freight/freight-list',
            name: 'LogisticsFreightList',
            component: () => import('@/view/logistics-management/logistics-freight/freight-list'),
            meta: {
                title: '查看物流运费',
                keepAlive: true
            }
        },
		{
		    path: 'freight-config/freight-config-list',
		    name: 'FreightConfigList',
		    component: () => import('@/view/logistics-management/freight-config/freight-config-list'),
		    meta: {
		        title: '运费预估设置',
		        keepAlive: true
		    }
		},
		{
		    path: 'logistics-reconciliation-approval',
		    name: 'LogisticsReconciliationApproval',
		    component: () => import('@/view/logistics-management/logistics-reconciliation-approval'),
		    meta: {
		        title: '物流对账审批',
		        keepAlive: true
		    }
		},
		{
		    path: 'logistics-box-set',
		    name: 'LogisticsBoxSet',
		    component: () => import('@/view/logistics-management/box'),
		    meta: {
		        title: '装箱保暖费预设',
		        keepAlive: true
		    }
		},
		{
		    path: 'logistics-cool-box-set',
		    name: 'LogisticsCoolBoxSet',
		    component: () => import('@/view/logistics-management/box/cool-box'),
		    meta: {
		        title: '冰瓶纸箱预设',
		        keepAlive: true
		    }
		},
    ]
};
