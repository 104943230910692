import router from '@/router';
import axios from 'axios';
import { Modal, Spin } from 'view-design';
import Vue from 'vue';

let vm = new Vue();

const addErrorLog = errorInfo => {
    const { statusText, status, request: { responseURL } } = errorInfo;
    let info = {
        type: 'ajax',
        code: status,
        mes: statusText,
        url: responseURL
    };
};

class HttpRequest {
    constructor(baseUrl = baseURL, fileServerUrl = fileServerURL, excelServerUrl = excelServerURL) {
        this.baseUrl = baseUrl;
        this.fileServerUrl = fileServerUrl;
        this.excelServerUrl = excelServerUrl;
        this.queue = {};
    }
    getBaseUrl() {
        return this.baseUrl;
    }
    getFileServerUrl() {
        return this.fileServerUrl;
    }
    getExcelServerUrl(){
        return this.excelServerUrl;
    }
    getInsideConfig() {
        const config = {
            baseURL: this.baseUrl,
            headers: {
                Token: vm.getUserToken(),
                UserId: vm.getUserId()
            }
        };
        return config;
    }
    destroy(url) {
        delete this.queue[url];
        if (!Object.keys(this.queue).length) {
            // Spin.hide()
        }
    }
    interceptors(instance, url) {
        // 请求拦截
        instance.interceptors.request.use(config => {
            // 添加全局的loading...
            if (!Object.keys(this.queue).length) {
                // Spin.show() // 不建议开启，因为界面不友好
            }
            this.queue[url] = true;
            return config;
        }, error => {
            return Promise.reject(error);
        });
        // 响应拦截
        instance.interceptors.response.use(res => {
            let response = res.data;
            if (response.code !== 0) {
                if (response.code === 2) {
                    setTimeout(function () {
                        Modal.error({
                            title: '错误',
                            content: response.message,
                            closable: false
                        });
                    }, 301);
                }
                else if(response.code == 200){
                    // TODO
                }
                else if (response.code === 20000 || response.code === 20001) {
                    Modal.error({
                        title: '错误',
                        content: response.message,
                        closable: false
                    });
                    vm.setLoginInfo(null);
                    router.replace({ name: 'UserLogin' });
                }
                else {
                    setTimeout(function () {
                        Modal.error({
                            title: '错误',
                            content: response.message,
                            closable: false
                        });
                    }, 301);
                }
            }
            this.destroy(url);
            // const { data, status } = res;
            return res.data;
            // return { data : res.data, status : res.status };
        }, error => {
            console.log(error)
            Modal.error({
                title: '错误',
                content: '全局接口访问失败（请检查接口情况）',
                closable: false
            });
            Spin.hide();
            this.destroy(url);
            let errorInfo = error.response;
            if (!errorInfo) {
                const { request: { statusText, status }, config } = JSON.parse(JSON.stringify(error));
                errorInfo = {
                    statusText,
                    status,
                    request: { responseURL: config.url }
                };
            }
            addErrorLog(errorInfo);
            return Promise.reject(error);
        });
    }
    request(options) {
        const instance = axios.create();
        options = Object.assign(this.getInsideConfig(), options);
        this.interceptors(instance, options.url);
        return instance(options);
    }
}
export default HttpRequest;
