<template>
    <div class="">
        <Modal v-model="show" :title="title" :mask-closable="false" width="60" cancel-text="关闭">
            <Spin v-if="loading" size="large" fix></Spin>

            <Form ref="productAndSupplierModalForm" :label-width="100">
                <Row>
                    <Col span="6">
                    <FormItem label="所属品牌:">
                        <Select v-model="brandCode"
                            filterable
                            clearable
                            style="width:100%"
                            @on-change="onBrandChange">
                            <Option v-for="item in brandList"
                                :value="item.code"
                                :key="item.code">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="6">
                    <FormItem label="产品名称:" required>
                        <Select v-model="productName"
                            filterable
                            clearable
                            style="width:100%">
                            <Option v-for="item in productNames"
                                :value="item.Name"
                                :key="item.Name">{{ item.Name }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="6">
                    <FormItem label="等级:" required>
                        <Select v-model="grade"
                            filterable
                            style="width:100%">
                            <Option v-for="item in productGrades"
                                :value="item.ParaName"
                                :key="item.ParaName">{{ item.ParaName }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="6">
                    <FormItem label="">
                        <Button type="primary" @click="onBindProduct">绑定</Button>
                    </FormItem>
                    </Col>
                </Row>
            </Form>

            <Row>
                <Col span="24">
                <pagination-table
                    :columns="mainTable.tableColumns"
                    :data="mainTable.tableData"
                    :loading="mainTable.tableLoading"
                    :table-search="mainTable.tableSearch"
                    :table-load-event="getProductList"
                    :highlight="true"/>
                </Col>
            </Row>

        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
export default {
    name: 'ProductAndSupplierList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            show: false,
            loading: false,
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '产品名称', key: "Name", align: 'center' },
                    { title: '所属品牌', key: "Brand", align: 'center' },
                    { title: '所属品牌编码', key: "BrandCode", align: 'center' },
                    { title: '产品经理', key: "JoinName", align: 'center' },
                    { title: '产品经理编码', key: "JoinCode", align: 'center' },
                    { title: '所属大类', key: "MainCategory", align: 'center' },
                    { title: '产品等级', key: "Grade", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 40,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showDelete: this.checkAccessArray('ProductTagEdit')
                                    },
                                    on: {
                                        handleDelete: dataId => {
                                            this.onUnbindProduct(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: []
            },
            title: '',
            tagId: '',
            productName: '',
            brandCode: '',
            grade: '',
            brandList: [],
            productGrades: [],
            productNames: []
        };
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        showModal(tagId, title) {
            this.show = true;
            this.tagId = tagId;
            this.title = '【' + title + '】关联的产品';
            this.productName = '';
            this.brandCode = '';
            this.grade = '';
            this.getProductList();
            this.getBrandList();
            this.getGradeList();
        },

        onBindProduct() {

            if (!this.brandCode) {
                return this.showSuccessModal('请先选择产品品牌');
            }

            if (!this.productName) {
                return this.showSuccessModal('请先输入产品名称');
            }

            if (!this.grade) {
                return this.showSuccessModal('请先选择产品等级');
            }

            this.loading = true;
            let params = { productName: this.productName, tagId: this.tagId, brandCode: this.brandCode, productGrade: this.grade };
            api.productTagBindProduct(params).then(res => {
                this.loading = false;
                if (res.code === 0) {
                    this.getProductList();
                    this.showSuccessModal(res.message);
                }
            });
        },

        onUnbindProduct(productId) {
            let params = { productId: productId, tagId: this.tagId };
            api.ProductTagUnbindProduct(params).then(res => {
                if (res.code === 0) {
                    this.getProductList();
                    this.showSuccessModal(res.message);
                }
            });
        },

        getProductList() {
            this.mainTable.tableLoading = true;
            search.globalAddFilterConditionKey(this.mainTable.tableSearch, 'Tags', 'like', this.tagId);
            api.productList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },

        getBrandList() {
            this.brandList = [];
            api.brandListByUser({}).then(res => {
                if (res.code === 0) {
                    this.brandList = res.data.records;
                }
            });
        },

        getGradeList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersGradeList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.productGrades = res.data.records;
                }
            });
        },

        getProductSimpleInfoList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            tempSearch.filters.enable = true;
            tempSearch.filters.conditions.push({ name: 'BrandCode', type:'eq', value: this.brandCode });
            this.productNames = [];
            this.productName = '';
            api.productSimpleInfoList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.productNames = res.data.records;
                }
            });
        },

        onBrandChange(value) {
            if (value) {
                this.getProductSimpleInfoList();
            }
            else {
                this.productNames = [];
                this.productName = '';
            }
        }


    }
};
</script>
<style media="print" scoped lang="less">

/deep/ .ivu-modal-footer > .ivu-btn-primary {
    display: none;
}
</style>

