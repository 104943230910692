<template>
    <div>
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getRoleList"
                :table-search="mainTable.tableSearch" />

            <Button v-if="checkAccessArray('LeafRoleEdit')"
                size="small"
                @click="onAddRole">新增角色</Button>
        </div>

        <Row>
            <Col span="12">
            <pagination-table ref="roleTable"
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getRoleList"
                :highlight="true"
                :showPage="false"
                @on-row-click="onRoleClick" />
            </Col>
            <Col span="8" style="margin-left:20px;" v-if="showResources">
                <Button v-if="checkAccessArray('LeafRoleEdit')"
                    size="small"
                    @click="onGrantSave" style="margin: 10px;">保存</Button>
                <div style="margin-bottom: 10px;">{{ resourceTile }}</div>

                <Tree :data="treeData" show-checkbox multiple ref="tree"></Tree>

            </Col>
        </Row>

        <Modal v-model="roleModal.show"
            title="角色信息"
            :mask-closable="false">
            <Spin v-if="roleModal.loading"
                size="large"
                fix></Spin>
            <Form ref="roleModalForm"
                :label-width="80">
                <Row>
                    <Col span="24">
                    <FormItem label="角色名称:">
                        <Input v-model="roleModal.name"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="角色描述:">
                        <Input v-model="roleModal.description"></Input>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveRole">保存</Button>
            </div>

        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import { compare } from 'semver';
export default {
    name: 'RoleList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            roleModal: {
                show: false,
                loading: false,
                mode: 'add',
                id: '',
                name: '',
                description: ''
            },
            showResources: false,
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '角色名称', key: "Name", align: 'center' },
                    { title: '角色描述', key: "Description", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 100,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'roleStatus',
                                        showEdit: this.checkAccessArray('LeafRoleEdit'),
                                        showDelete: this.selectedRole && this.selectedRole.Id === params.row.Id && this.checkAccessArray('LeafRoleEdit')
                                    },
                                    on: {
                                        handleEdit: dataId => {
                                            this.onModifyRole(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteRole(dataId);
                                        }
                                    }
                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '角色名称', type: 'Input', value: '', name: 'Name', sql: 'like' }
                ]
            },
            resourceTile: '',
            selectedRole: null,
            treeData: []
        };
    },
    computed: {

    },
    mounted() {
        this.getRoleList();
    },
    methods: {
        onDeleteRole(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '角色删除后，属于该角色的功能将无法使用。',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.roleDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getRoleList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onAddRole() {
            this.roleModal.mode = 'add';
            this.roleModal.id = '';
            this.roleModal.name = '';
            this.roleModal.description = '';
            this.roleModal.show = true;
        },
        onModifyRole(id) {
            let r = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.roleModal.id = r.Id;
            this.roleModal.name = r.Name;
            this.roleModal.description = r.Description;
            this.roleModal.mode = 'modify';
            this.roleModal.show = true;
        },
        onSaveRole() {
            this.roleModal.isLeaf = 1;
            this.roleModal.loading = true;
            api.roleSave(this.roleModal).then(res => {
                this.roleModal.loading = false;
                if (res.code === 0) {
                    this.roleModal.show = false;
                    this.getRoleList();
                    this.showSuccessModal();
                }
            });

        },
        onGrantSave(id) {
            if (this.selectedRole === null) {
                this.showWarningModal('请先选择角色');
            }

            let selectedResource = this.$refs.tree.getCheckedNodes();
            let resourceIds = selectedResource.map(obj => {
                return obj.id;
            });
            let data = {
                role: this.selectedRole.Id,
                resourceIds: resourceIds
            };

            api.grantSave(data).then(res => {
                if (res.code === 0) {
                    this.showSuccessModal("请刷新页面使权限生效。");
                }
            });
        },
        onRoleClick(row, index) {
            this.selectedRole = row;
            this.resourceTile = row.Name + '角色权限';
            this.showResources = true;
            let tempSearch = search.getSearchParams();
            tempSearch.limit.enable = false;
            tempSearch.filters.enable = true;
            tempSearch.filters.conditions = [{ name: 'Role', type:'eq', value: row.Id }];
            api.grantEchoList(tempSearch).then(res => {
                let keys = [];
                if (res.code === 0) {
                    const checkedKeys = res.data.records;
                    this.getResourceTree(checkedKeys);

                }
            });
        },
        getRoleList() {
            this.mainTable.tableLoading = true;
            this.mainTable.tableSearch.limit.enable = false;
            this.mainTable.tableSearch.filters.enable = true;
            this.mainTable.tableSearch.filters.conditions.push({ name: 'Creater', type:'eq', value: this.getUserId()});
            api.roleList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        getResourceTree(checkedKeys) {
            let array = [];
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.leafResourceList(tempSearch).then(res => {
                if (res.code === 0) {
                    let records = res.data.resourceList.map(it => {
                        return { id: it.id, title: it.name, parentId: it.parentId, sort: it.sort, resType: it.resType, checked: checkedKeys.includes(it.id) };
                    }).sort(this.compareTo('sort', 'asc'));
                    records.forEach(item=>{
                        // 找到每个对象的子节点
                        item.children = records.filter(info => info.parentId === item.id);
                        if (item.resType === 1) {
                            // 将一层节点放入新数组中
                            item.expand = false;
                            array.push(item);
                        }
                    });
                }
                this.treeData = array.sort(this.compareTo('sort', 'asc'));
            });
        }


    }
};
</script>
<style>
</style>
