import getPostRequest from './base-api';

export default {
    operateLogList(data) {
        return getPostRequest(data, 'api/operateLog/operateLogList');
    },
    departmentPurchaseList(data) {
        return getPostRequest(data, 'api/catePurchaseRelation/departmentPurchaseList');
    },
    departmentPurchaseSave(data) {
        return getPostRequest(data, 'api/catePurchaseRelation/departmentPurchaseSave');
    },
    catePurchaseRelationSave(data) {
        return getPostRequest(data, 'api/catePurchaseRelation/catePurchaseRelationSave');
    },
    catePurchaseRelationList(data) {
        return getPostRequest(data, 'api/catePurchaseRelation/catePurchaseRelationList');
    },
    departmentCateRelationSave(data) {
        return getPostRequest(data, 'api/departmentCateRelation/departmentCateRelationSave');
    },
    departmentCateRelationDelete(data) {
        return getPostRequest(data, 'api/departmentCateRelation/departmentCateRelationDelete');
    },
    departmentCateRelationList(data) {
        return getPostRequest(data, 'api/departmentCateRelation/departmentCateRelationList');
    },
    sendAuditCode(data) {
        return getPostRequest(data, 'api/systemConfig/sendAuditCode');
    },
    deliveryTimeList(data) {
        return getPostRequest(data, 'api/deliveryTime/deliveryTimeList');
    },
    deliveryTimeSave(data) {
        return getPostRequest(data, 'api/deliveryTime/deliveryTimeSave');
    },
    manualList(data) {
        return getPostRequest(data, 'api/manual/manualList');
    },
    manualDelete(data) {
        return getPostRequest(data, 'api/manual/manualDelete');
    },
    manualSave(data) {
        return getPostRequest(data, 'api/manual/manualSave');
    },
    notificationList(data) {
        return getPostRequest(data, 'api/notification/notificationList');
    },
    notificationDelete(data) {
        return getPostRequest(data, 'api/notification/notificationDelete');
    },
    notificationSave(data) {
        return getPostRequest(data, 'api/notification/notificationSave');
    }
};
