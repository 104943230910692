export const changeDate = (time) => {
    let date = new Date(time)
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    month = month < 10 ? '0' + month : month
    let day = date.getDate()
    day = day < 10 ? '0' + day : day
    let result = `${year}-${month}-${day}`
    return result;
};

export const changeDateTimeFun = (time) => {
    let date = new Date(time)
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    month = month < 10 ? '0' + month : month
    let day = date.getDate()
    day = day < 10 ? '0' + day : day
	let HH = date.getHours()
	HH = HH < 10 ? '0' + HH : HH
	let mm = date.getMinutes()
	mm = mm < 10 ? '0' + mm : mm
	let ss = date.getSeconds()
	ss = ss < 10 ? '0' + ss : ss
    let result = `${year}-${month}-${day} ${HH}:${mm}:${ss}`
	result =  Date.parse(result)
    return result;
};