<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card ref="searchCard"
                :form="mainTable.searchForm"
                :table-load-event="getProductStandardList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4"
                @changeSelectContent="changeSelectContent"/>

            <Button v-if="checkAccessArray('ProductStandardAdd')" size="small" @click="onAddProductStandard">新增产品标准</Button>
            <Button v-if="checkAccessArray('SupplierViewActive')" size="small" @click="onToHistory">查看历史版本</Button>
        </div>
        <Row>
            <Col span="24">
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getProductStandardList"
                :highlight="true"
                @on-row-click="onProductStandardSelect" />
            </Col>
        </Row>

        <Modal v-model="productStandardModal.show" title="产品标准信息" :mask-closable="false" width="70">
            <Spin v-if="productStandardModal.loading"
                size="large"
                fix></Spin>
            <Form ref="productStandardModalForm" :label-width="120">
                <Row>
                    <Col span="8">
                    <FormItem label="产品标准编码:" required>
                        <Input v-model="productStandardModal.code" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="名称:" required>
                        <Input v-model="productStandardModal.name"></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="等级:" required>
                        <Select v-model="productStandardModal.grade"
                            filterable
                            style="width:100%">
                            <Option v-for="item in productGrades"
                                :value="item.ParaName"
                                :key="item.ParaName">{{ item.ParaName }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="大类:" required>
                        <Select v-model="productStandardModal.mainCategory"
                            filterable
                            style="width:100%"
                            @on-change="onMainCateChange">
                            <Option v-for="item in mainCateList"
                                :value="item.Name"
                                :key="item.Name">{{ item.Name }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="小类:" required>
                        <Select v-model="productStandardModal.subCategory"
                            filterable
                            style="width:100%"
                            @on-change="onSubCateChange">
                            <Option v-for="item in subCateFilterList"
                                :value="item.Name"
                                :key="item.Name">{{ item.Name }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="单位:" required>
                        <Select v-model="productStandardModal.unit"
                            filterable
                            style="width:100%">
                            <Option v-for="item in unitList"
                                :value="item.ParaName"
                                :key="item.ParaName">{{ item.ParaName }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="颜色:" required>
                        <Select v-model="productStandardModal.color"
                            filterable
                            multiple
                            style="width:100%">
                            <Option v-for="item in colorList"
                                :value="item.Color"
                                :key="item.Id">{{ item.Color }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="包装数:" required>
                        <Input v-model="productStandardModal.packCount"></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="拼音编码:" >
                        <Input v-model="productStandardModal.pinYinCode"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="关键字:" >
                        <Input v-model="productStandardModal.keyword"></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="长度:" >
                        <Input v-model="productStandardModal.length"></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="叶片:" >
                        <Input v-model="productStandardModal.blade"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="花苞:" >
                        <Input v-model="productStandardModal.bud"></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="茎杆:" >
                        <Input v-model="productStandardModal.stalk"></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="枝条弯曲度:" >
                        <Input v-model="productStandardModal.bending"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="开放度:" >
                        <Input v-model="productStandardModal.openness"></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="病虫害:" >
                        <Input v-model="productStandardModal.diseased"></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="机械损伤:" >
                        <Input v-model="productStandardModal.damage"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="重量:" required>
                        <Input v-model.number="productStandardModal.weight" type="number">
                            <template #suffix>
                                <span style="display: inline-block; line-height: 32px;">kg</span>
                            </template>
                        </Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="体积:" required >
                        <Input v-model.number="productStandardModal.volumn"  type="number">
                            <template #suffix>
                                <span style="display: inline-block; line-height: 32px;">m³</span>
                            </template>
                        </Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="专利费率:" >
                        <Input v-model="productStandardModal.patentFeeRate" type="number" :disabled="!isPatentFeeRateEdit">
                            <template #suffix>
                                <span style="display: inline-block; line-height: 32px;">%</span>
                            </template>
                        </Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="是否为新品:">
                        <RadioGroup v-model="productStandardModal.isNew">
                            <Radio label="0">
                                <span>非新品</span>
                            </Radio>
                            <Radio label="1">
                                <span>新品</span>
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="整扎标准:" >
                        <Input v-model="productStandardModal.tieStandard" type="textarea"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="备注:" >
                        <Input v-model="productStandardModal.remark" type="textarea"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="图片:">
                        <template>
                            <Upload action="https://up-z2.qiniup.com/"
                                    :data="uploadParams"
                                    :format="['jpg','jpeg','png']"
                                    :on-format-error ="handleFormatError"
                                    :on-success="handleUploadSuccess"
                                    :before-upload="beforeUpload"
                                    :show-upload-list="showUploadList"
                            >
                                <Button icon="ios-cloud-upload-outline">上传图片</Button>
                            </Upload>

                            <Row :gutter="16" style="margin-top: 10px;" v-if="productStandardModal.picPath">
                                <Col span="6">
                                    <img :src="this.imageDomain + productStandardModal.picPath" fit="fill" width="150px" height="150px" />
                                </Col>
                            </Row>
                        </template>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <span style="margin-right: 30px;font-size: 20px;color: red;padding-top: 0px;" v-if="approvalStatus != ''">{{approvalStatus}} ({{approveRemark}})</span>
                <Button type="info" v-if="productStandardModal.readOnly == false" @click="onSaveProductStandard">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import { setSelectOptions } from '@/libs/selectOption';
import { USER_TYPE_EXPERT } from '@/view/common/userType';
import { Modal } from 'view-design';
export default {
    name: 'ProductStandardList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            productStandardModal: {
                show: false,
                loading: false,
                readOnly: false,
                code: '',
                name: '',
                grade: '',
                color: '',
                mainCategory: '',
                subCategory: '',
                unit: '',
                packCount: '',
                pinYinCode: '',
                keyword: '',
                picType: 0,
                paraShow: '',
                length: '',
                blade: '',
                bud: '',
                stalk: '',
                bending: '',
                diseased: '',
                weight: 0,
                openness: '',
                damage: '',
                tieStandard: '',
                remark: '',
                volumn: 0,
                isNew: '1',
                patentFeeRate: 0,
                picPath: '',
                mode: 'add'
            },
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '产品标准编码', key: "Code", align: 'center' },
                    { title: '图片', key: "PicPath", width: 40, align: 'center',
                        render: (h, params) => {
                            const imageSrc = this.imageDomain + params.row.PicPath;
                            return h('img', {
                                style: {
                                    width: '20px',
                                    height: '20px'
                                },
                                attrs: {
                                    src: imageSrc
                                },
                                on: {
                                    click: () => {
                                        if (!params.row.PicPath) {
                                            return;
                                        }

                                        Modal.info({
                                            title: '',
                                            closable: true,
                                            okText: '关闭',
                                            render: h => {
                                                return h("img", {
                                                    attrs: {
                                                        src: imageSrc,
                                                        style: "margin-top:20px;width: 350px;height: 350px;"
                                                    }
                                                });
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    },
                    { title: '名称', key: "Name", align: 'center' },
                    { title: '颜色', key: "Color", align: 'center' },
                    { title: '大类', key: "MainCategory", align: 'center' },
                    { title: '小类', key: "SubCategory", align: 'center' },
                    { title: '等级', key: "Grade", align: 'center' },
                    { title: '单位', key: "Unit", align: 'center' },
                    { title: '包装数', key: "PackCount", align: 'center' },
                    { title: '长度', key: "Length", align: 'center' },
                    { title: '叶片', key: "Blade", align: 'center' },
                    { title: '花苞', key: "Bud", align: 'center' },
                    { title: '茎杆', key: "Stalk", align: 'center' },
                    { title: '开放度', key: "Openness", align: 'center' },
                    { title: '重量', key: "Weight", align: 'center',
                        render: (h, params) => {
                            let text = '';
                            if (params.row.Weight) {
                                text = params.row.Weight + 'kg';
                            }
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    { title: '体积', key: "Volumn", align: 'center',
                        render: (h, params) => {
                            let text = '';
                            if (params.row.Volumn) {
                                text = params.row.Volumn + 'm³';
                            }
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    { title: '专利费', key: "PatentFeeRate", align: 'center',
                        render: (h, params) => {
                            let text = params.row.PatentFeeRate + '%';
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
					{
					    title: '状态', key: "ApproveState", align: 'center', width: 80,
					    render: (h, params) => {
					        let text = '';
					        let color = '';
					        switch(parseInt(params.row.ApproveState)) {
					            case 1: text = '待审核'; color = 'red'; break;
					            case 2: text = '审核通过'; color = 'black'; break;
								case 3: text = '审核不通过'; color = 'black'; break;
								case 4: text = '撤销申请'; color = 'black'; break;
					            default: text = '无申请记录'; color = 'red';
					        }
					        return h('div', [
					            h('span', {
					                style: {
					                    color: color
					                }
					            }, text)
					        ]);
					    }
					},
                    {
                        title: "操作",
                        key: "Creater",
                        align: "center",
                        width: 100,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showInfo: !this.checkAccessEdit(params.row),
                                        showEdit: this.checkAccessEdit(params.row),
										showCustom: this.checkAccessEdit(params.row) && params.row.ApproveState==1,
										customText: '撤销申请',
                                        showDelete: this.checkAccessEdit(params.row)
                                    },
                                    on: {
                                        handleInfo: dataId => {
                                            this.onModifyProductStandard(dataId, true);
                                        },
                                        handleEdit: dataId => {
                                            this.onModifyProductStandard(dataId);
                                        },
										handleCustom: dataId => {
										    this.onCancel(dataId);
										},
                                        handleDelete: dataId => {
                                            this.onDeleteProductStandard(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '编码/名称', type: 'Input', value: '', name: 'concat(Code,Name)', sql: 'like' },
                    { label: '等级', type: 'Select', value: '', name: 'Grade', sql: 'like', options: [], clearable: true },
                    { label: '大类', type: 'Select', value: '', name: 'MainCategory', sql: 'like', options: [], clearable: true },
                    { label: '小类', type: 'Select', value: '', name: 'SubCategory', sql: 'like', options: [], clearable: true }
                ]
            },
            selectedProductStandard: null,
            unitList: [],
            mainCateList: [],
            subCateList: [],
            subCateFilterList: [],
            searchSubCateFilterList: [],
            productGrades:[],
            colorList: [],
            isPatentFeeRateEdit: false,
            showUploadList: false,
            imageDomain: this.imageDomain,
            uploadParams: {},
            approvalStatus: '',
            approveRemark: '',
            allProductCategoryList: []
        };
    },
    computed: {

    },
    async mounted() {
        this.allProductCategoryList = await this.getAllProductCategoryList();
        this.productMainCategoryList();
        this.getProductStandardList();
        this.getUnitList();
        this.getGradeList();
    },
    methods: {
        setSelectOptions,
        onProductStandardSelect(row, index) {
            this.selectedProductStandard = row;
        },
        onAddProductStandard() {
            this.productStandardModal.mode = 'add';
            this.productStandardModal.id = '';
            this.productStandardModal.code = '系统自动分配',
            this.productStandardModal.name = '',
            this.productStandardModal.grade = '',
            this.productStandardModal.color = '',
            this.productStandardModal.mainCategory = '',
            this.productStandardModal.subCategory = '',
            this.productStandardModal.unit = '',
            this.productStandardModal.packCount = '',
            this.productStandardModal.pinYinCode = '',
            this.productStandardModal.keyword = '',
            this.productStandardModal.picType = 0,
            this.productStandardModal.paraShow = '',
            this.productStandardModal.length = '',
            this.productStandardModal.blade = '',
            this.productStandardModal.bud = '',
            this.productStandardModal.stalk = '',
            this.productStandardModal.bending = '',
            this.productStandardModal.diseased = '',
            this.productStandardModal.weight = 0,
            this.productStandardModal.openness = '',
            this.productStandardModal.damage = '',
            this.productStandardModal.tieStandard = '',
            this.productStandardModal.remark = '',
            this.productStandardModal.volumn = 0;
            this.productStandardModal.isNew = '1';
            this.productStandardModal.patentFeeRate = 0;
            this.productStandardModal.picPath = '';
            this.getProductColorList();
            this.productStandardModal.show = true;
        },
		onCancel(id) {
		    this.$Modal.confirm({
		        title: '确认',
		        content: '确定撤销申请吗？',
		        onOk: () => {
		            this.mainTable.tableLoading = true;
		            api.productStandardCancel({ id: id }).then(res => {
		                this.mainTable.tableLoading = false;
		                if (res.code === 0) {
		                    this.getProductStandardList();
		                    this.showSuccessModal();
		                }
		            });
		        }
		    });
		},
        onDeleteProductStandard(id) {
            this.$Modal.confirm({
                title: '删除产品标准',
                content: '确定删除该产品标准吗？删除后无法重新启用，请谨慎操作。',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.productStandardDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getProductStandardList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onModifyProductStandard(id, readOnly = false) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.productStandardModal.id = b.Id;
            this.productStandardModal.code = b.Code;
            this.productStandardModal.name = b.Name;
            this.productStandardModal.grade = b.Grade;
            this.productStandardModal.color = b.Color.split(",");
            this.productStandardModal.mainCategory = b.MainCategory;
            this.productStandardModal.subCategory = b.SubCategory;
            this.productStandardModal.unit = b.Unit;
            this.productStandardModal.packCount = b.PackCount;
            this.productStandardModal.pinYinCode = b.PinYinCode;
            this.productStandardModal.keyword = b.Keyword;
            this.productStandardModal.picType = b.PicType || 0;
            this.productStandardModal.paraShow = b.ParaShow;
            this.productStandardModal.length = b.Length;
            this.productStandardModal.blade = b.Blade;
            this.productStandardModal.bud = b.Bud;
            this.productStandardModal.stalk = b.Stalk;
            this.productStandardModal.bending = b.Bending;
            this.productStandardModal.diseased = b.Diseased;
            this.productStandardModal.weight = b.Weight;
            this.productStandardModal.openness = b.Openness;
            this.productStandardModal.damage = b.Damage;
            this.productStandardModal.tieStandard = b.TieStandard;
            this.productStandardModal.remark = b.Remark;
            this.productStandardModal.volumn = b.Volumn;
            this.productStandardModal.isNew = b.IsNew + '';
            this.productStandardModal.patentFeeRate = b.PatentFeeRate;
            this.productStandardModal.picPath = b.PicPath;
            this.productStandardModal.mode = 'modify';
            this.productStandardModal.readOnly = readOnly;

            this.approveRemark = b.ApproveRemark;

            this.echoSelect(b);
            this.getProductColorList();
            this.productStandardStatus(b.Code);

            this.productStandardModal.show = true;
        },
        onSaveProductStandard() {
            if(!this.productStandardModal.weight||this.productStandardModal.weight<=0) {
                return this.showWarningModal('重量要求大于0！');
            }
            if(!this.productStandardModal.volumn||this.productStandardModal.volumn<=0) {
                return this.showWarningModal('体积要求大于0！');
            }

            if(!this.productStandardModal.patentFeeRate) {
                this.productStandardModal.patentFeeRate = 0;
            }

            this.productStandardModal.isNew = parseInt(this.productStandardModal.isNew);

            if(this.productStandardModal.patentFeeRate < 0) {
                return this.showWarningModal('专利费率不允许为负数！');
            }
            if(this.productStandardModal.patentFeeRate > 100) {
                return this.showWarningModal('专利费率不可以超过100%！');
            }
            if(parseInt(this.productStandardModal.patentFeeRate) === parseFloat(this.productStandardModal.patentFeeRate)) {
                this.productStandardModal.patentFeeRate = parseInt(this.productStandardModal.patentFeeRate);
            }
            else {
                this.productStandardModal.patentFeeRate = parseFloat(parseFloat(this.productStandardModal.patentFeeRate).toFixed(2));
            }
            let subCategory = this.subCateFilterList.find(item => item.Name === this.productStandardModal.subCategory);
            if(subCategory){
                this.productStandardModal.subCategoryId = subCategory.Id;
            }
            this.productStandardModal.color = this.productStandardModal.color.join(",");

            this.productStandardModal.loading = true;
            api.productStandardSave(this.productStandardModal).then(res => {
                this.productStandardModal.loading = false;
                if (res.code === 0) {
                    this.productStandardModal.show = false;
                    this.getProductStandardList();
                    this.showSuccessModal();
                }
            });
        },
        getProductStandardList() {
            this.mainTable.tableLoading = true;
            api.productStandardList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedProductStandard = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        async onMainCateChange(e) {
            let mainId = this.mainCateList.find(item => item.Name === e).Id;
            this.subCateFilterList = await this.productSubCategoryList(mainId);
            this.productStandardModal.subCategory = '';
        },
        async echoSelect(standard) {
            let mainId = this.mainCateList.find(item => item.Name === standard.MainCategory).Id;
            this.subCateFilterList = await this.productSubCategoryList(mainId);
            let subCategory = this.subCateFilterList.find(item => item.Name === standard.SubCategory);
            if(subCategory){
                this.isPatentFeeRateEdit = this.checkAccessArray('PatentFeeRateEdit');
                    // && this.getLoginInfo().userName === subCategory.Operator;
            }
        },
        getUnitList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersUnitList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.unitList = res.data.records;
                }
            });
        },
        productMainCategoryList() {
            this.mainCateList = this.allProductCategoryList.filter(item => item.ParentId === null);
            this.setSelectOptions(this.mainTable.searchForm, 'MainCategory', this.mainCateList, 'Name', 'Name');
        },
        async getAllProductCategoryList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            const { data } = await api.productCategoryList(tempSearch);
            return data.records;
        },
        async productSubCategoryList(parentId) {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            tempSearch.filters.enable = true;
            tempSearch.filters.conditions = [{ name: 'ParentId', type:'eq', value: parentId }];
            const { data } = await api.productCategoryList(tempSearch);
            return data.records;
        },
        getGradeList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersGradeList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.productGrades = res.data.records;
                    this.setSelectOptions(this.mainTable.searchForm, 'Grade', this.productGrades, 'ParaName', 'ParaName');
                }
            });
        },
        async changeSelectContent(e) {

            if(e.name === 'MainCategory') {
                let mainCate = this.mainCateList.find(item => item.Name === e.value);
                this.searchSubCateFilterList = [];
                if(mainCate){
                    this.searchSubCateFilterList = await this.productSubCategoryList(mainCate.Id);
                }
                if(this.searchSubCateFilterList.length == 0) {
                    let subCate = this.mainTable.searchForm.find(item => item.name === 'SubCategory');
                    subCate.value = undefined;
                }
                this.setSelectOptions(this.mainTable.searchForm, 'SubCategory', this.searchSubCateFilterList, 'Name', 'Name');
            }
            search.globalSetFilterWithSearchForm(this.mainTable.tableSearch, this.$refs.searchCard.filterForm());
            this.getProductStandardList();
        },
        onToHistory() {
            this.$router.replace({ name: "ProductStandardHistoryList" });
        },
        getProductColorList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.productColorList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.colorList = res.data.records;
                }
            });
        },
        handleFormatError(request, file) {
            this.$Notice.warning({
                title: '格式错误',
                desc: '请上传png、jpg、jpeg格式文件'
            });
        },
        async beforeUpload(request) {
            // **注意这里的参数必须通过属性赋值，如果直接修改整个uploadParams是无效的。**
            // 上传之前设置上传参数,
            let filename = Date.now() + Math.random().toString(36).slice(2) + ".png";
            await api.qiniuToken({}).then(res => {
                this.uploadParams.token = res.data.token;
                // 七牛response hash key的值自定义，后面如果需要浏览或者下载都需要这个地址，如果key不定义，那么七牛生成的key === hash的值
                this.uploadParams.key = filename;
            });
        },
        handleUploadSuccess(request, file, list) {
            this.productStandardModal.picPath = file.response.key;
        },
        productStandardStatus(code) {
            this.approvalStatus = '';
            api.productStandardStatus({ code: code }).then(res => {
                if (res.code === 0) {
                    this.approvalStatus = res.data.approvalStatus;
                }
            });
        },
        onSubCateChange(subCate){
            let subCategory = this.subCateFilterList.find(item => item.Name === subCate);
            if (!subCategory) {
                this.productStandardModal.patentFeeRate = 0;
                this.isPatentFeeRateEdit = false;
                return ;
            }

            // if (!subCategory.Operator) {
            //     this.productStandardModal.patentFeeRate = 0;
            //     this.isPatentFeeRateEdit = false;
            //     return ;
            // }

            this.isPatentFeeRateEdit = this.checkAccessArray('PatentFeeRateEdit');
                // && this.getLoginInfo().userName === subCategory.Operator;
            if (this.isPatentFeeRateEdit) {
                this.productStandardModal.patentFeeRate = 0;
            }
        },
        checkAccessEdit(standard) {
            let mainCategory = this.mainCateList.find(item => item.Name === standard.MainCategory);
            if (!mainCategory) {
                return false;
            }

            let subCategoryList = this.allProductCategoryList.filter(item => item.ParentId === mainCategory.Id);
            if (!subCategoryList) {
                return false;
            }

            // let subCategory = subCategoryList.find(item => item.Name === standard.SubCategory);
            // if (!subCategory) {
            //     return false;
            // }

            if( this.getLoginInfo().userId === standard.Creater){
                return true;
            }

            return this.checkAccessArray('ProductStandardEdit');
                // && this.getLoginInfo().userName === subCategory.Operator;
        }


    }
};
</script>
<style>
</style>

