<template>
    <div class="">
        <Modal v-model="show" title="产品关联供应商" :mask-closable="false" width="60" cancel-text="关闭">
            <Spin v-if="loading" size="large" fix></Spin>

            <Form ref="productAndSupplierModalForm"
                :label-width="100">
                <Row>
                    <Col span="12">
                    <FormItem label="供货商:" required>
                        <Select v-model="supCode" filterable style="width:100%">
                            <Option v-for="item in supplierList"
                                :value="item.Code"
                                :key="item.Code">{{ item.Code + " " + item.Name }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="">
                        <Button type="primary" @click="onSaveProductAndSupplier">新增</Button>
                    </FormItem>
                    </Col>
                </Row>
            </Form>

            <Row>
                <Col span="24">
                <pagination-table
                    :columns="mainTable.tableColumns"
                    :data="mainTable.tableData"
                    :loading="mainTable.tableLoading"
                    :table-search="mainTable.tableSearch"
                    :table-load-event="getProductAndSupplierList"
                    :highlight="true"
                    @on-row-click="onProductAndSupplierSelect" />
                </Col>
            </Row>

        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
export default {
    name: 'ProductAndSupplierList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            show: false,
            supCode: '',
            loading: false,
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '产品编码', key: "ProductCode", align: 'center' },
                    { title: '供货商编码', key: "SupCode", align: 'center' },
                    { title: '供货商名称', key: "SupName", align: 'center' },
                    { title: '集货站代码', key: "CollectCode", align: 'center' },
                    { title: '集货站名称', key: "CollectName", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 40,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showDelete: this.checkAccessArray('ProductAndSupplierEdit')
                                    },
                                    on: {
                                        handleDelete: dataId => {
                                            this.onDeleteProductAndSupplier(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: []
            },
            selectedProductAndSupplier: null,
            productCode: '',
            productStandardCode: '',
            supplierList: []
        };
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        showModal(product) {
            this.show = true;
            this.productCode = product.Code;
            this.productStandardCode = product.ProductStandardCode;
            this.getSupplierList();
            this.getProductAndSupplierList();
        },
        onProductAndSupplierSelect(row, index) {
            this.selectedProductAndSupplier = row;
        },

        onDeleteProductAndSupplier(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '确定删除吗？',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.productAndSupplierDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getProductAndSupplierList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },

        onSaveProductAndSupplier() {

            if(!this.supCode){
                return this.showWarningModal('请选择供应商！');
            }

            this.loading = true;
            api.productAndSupplierSave({ productCode: this.productCode, supCode: this.supCode, productStandardCode: this.productStandardCode }).then(res => {
                this.loading = false;
                if (res.code === 0) {
                    this.getProductAndSupplierList();
                    this.showSuccessModal(res.message);
                }
            });
        },
        getProductAndSupplierList() {
            this.mainTable.tableLoading = true;
            this.mainTable.tableSearch.filters.enable = true;
            this.mainTable.tableSearch.filters.conditions = [{ name: 'ProductCode', type:'eq', value: this.productCode }];
            api.productAndSupplierList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedProductAndSupplier = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        getSupplierList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.supplierListByUser(tempSearch).then(res => {
                if (res.code === 0) {
                    this.supplierList = res.data.records;
                }
            });
        }

    }
};
</script>
<style media="print" scoped lang="less">

/deep/ .ivu-modal-footer > .ivu-btn-primary {
    display: none;
}
</style>

