<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getParametersList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4" />

            <Button v-if="checkAccessArray('ParametersEdit')"
                size="small"
                @click="onAddParameters">新增参数</Button>
        </div>
        <Row>
            <Col span="24">
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getParametersList"
                :highlight="true"
                @on-row-click="onParametersSelect" />
            </Col>
        </Row>

        <Modal v-model="parametersModal.show"
            title="参数信息"
            :mask-closable="false">
            <Spin v-if="parametersModal.loading"
                size="large"
                fix></Spin>
            <Form ref="parametersModalForm"
                :label-width="100">
                <Row>
                    <Col span="12">
                    <FormItem label="参数类型:"
                        required>
                        <Input v-model.number="parametersModal.paraType"></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="参数名称:"
                        required>
                        <Input v-model="parametersModal.paraName"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="12">
                    <FormItem label="参数代码:"
                        required>
                        <Input v-model="parametersModal.paraCode"></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="参数描述:"
                        required>
                        <Input v-model="parametersModal.paraDec"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="12">
                    <FormItem label="是否启用:"
                        required>
                        <Input v-model.number="parametersModal.isFlag"></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="排序:"
                        required>
                        <Input v-model.number="parametersModal.sort"></Input>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveParameters">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
export default {
    name: 'ParametersList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            parametersModal: {
                show: false,
                loading: false,
                paraType: 0,
                paraName: '',
                paraCode: '',
                paraDec: '',
                isFlag: 0,
                sort: 0,
                mode: 'add',
            },
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '参数类型', key: "ParaType", align: 'center' },
                    { title: '参数名称', key: "ParaName", align: 'center' },
                    { title: '参数代码', key: "ParaCode", align: 'center' },
                    { title: '参数描述', key: "ParaDec", align: 'center' },
                    { title: '是否启用', key: "IsFlag", align: 'center' },
                    { title: '排序', key: "Sort", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 80,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showEdit: this.checkAccessArray('ParametersEdit'),
                                        showDelete: this.checkAccessArray('ParametersEdit')
                                    },
                                    on: {
                                        handleEdit: dataId => {
                                            this.onModifyParameters(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteParameters(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '参数名称', type: 'Input', value: '', name: 'ParaName', sql: 'like' },
                    { label: '参数代码', type: 'Input', value: '', name: 'ParaCode', sql: 'like' },
                    { label: '参数描述', type: 'Input', value: '', name: 'ParaDec', sql: 'like' },
                ]
            },
            selectedParameters: null
        };
    },
    computed: {

    },
    mounted() {
        this.getParametersList();
    },
    methods: {
        onParametersSelect(row, index) {
            this.selectedParameters = row;
        },
        onAddParameters() {
            this.parametersModal.mode = 'add';
            this.parametersModal.id = '';
            this.parametersModal.paraType = 0,
            this.parametersModal.paraName = '',
            this.parametersModal.paraCode = '',
            this.parametersModal.paraDec = '',
            this.parametersModal.isFlag = 0,
            this.parametersModal.sort = 0,
            this.parametersModal.show = true;
        },
        onDeleteParameters(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '确定删除吗？',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.parametersDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getParametersList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onModifyParameters(id) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.parametersModal.id = b.Id;
            this.parametersModal.paraType = b.ParaType;
            this.parametersModal.paraName = b.ParaName;
            this.parametersModal.paraCode = b.ParaCode;
            this.parametersModal.paraDec = b.ParaDec;
            this.parametersModal.isFlag = b.IsFlag;
            this.parametersModal.sort = b.Sort;
            this.parametersModal.mode = 'modify';
            this.parametersModal.show = true;
        },
        onSaveParameters() {
            this.parametersModal.loading = true;
            api.parametersSave(this.parametersModal).then(res => {
                this.parametersModal.loading = false;
                if (res.code === 0) {
                    this.parametersModal.show = false;
                    this.getParametersList();
                    this.showSuccessModal();
                }
            });
        },
        getParametersList() {
            this.mainTable.tableLoading = true;
            api.parametersList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedParameters = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        }
    }
};
</script>
<style>
</style>


