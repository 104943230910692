<template>
    <div class="">
        <div class="flex searchButtonContainer">
           <search-card :form="mainTable.searchForm"
                :table-load-event="getMainCateList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4" />
            <Button v-if="checkAccessArray('ProductCategoryEdit')"
                size="small"
                @click="onAddMainCate">新增大类</Button>
            <Button v-if="checkAccessArray('ProductCategoryEdit')"
                size="small"
                @click="onAddSubCate">新增小类</Button>
        </div>
        <Row :gutter="16">
            <Col span="12">
            <pagination-table ref="mainCateTable"
                tableCaption="大类列表"
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getMainCateList"
                :highlight="true"
                :showPage="false"
                :windowHeight="true"
                @on-row-click="onMainCateSelect" />
            </Col>
            <Col span="12">
            <pagination-table ref="mainCateTable"
                :tableCaption="subCateTable.caption"
                :columns="subCateTable.tableColumns"
                :data="subCateTable.tableData"
                :loading="subCateTable.tableLoading"
                :table-search="subCateTable.tableSearch"
                :table-load-event="getSubCateList"
                :highlight="true"
                :windowHeight="true"
                :showPage="false" />
            </Col>
        </Row>

        <Modal v-model="mainCateModal.show"
            title="大类信息"
            :mask-closable="false">
            <Spin v-if="mainCateModal.loading"
                size="large"
                fix></Spin>
            <Form ref="mainCateModalForm"
                :label-width="80">
                <Row>
                    <Col span="12">
                    <FormItem label="大类名称:">
                        <Input v-model="mainCateModal.name"></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="大类序号:">
                        <Input v-model="mainCateModal.sort"></Input>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveMainCate">保存</Button>
            </div>
        </Modal>

        <Modal v-model="subCateModal.show"
            title="小类信息"
            :mask-closable="false">
            <Spin v-if="subCateModal.loading"
                size="large"
                fix></Spin>
            <Form ref="subCateModalForm"
                :label-width="80">
                <Row>
                    <Col span="24">
                    <FormItem label="所属大类:">
                        <Input v-model="subCateModal.parentName" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="24">
                    <FormItem label="小类名称:">
                        <Input v-model="subCateModal.name"></Input>
                    </FormItem>
                    </Col>
                    <Col span="24">
                    <FormItem label="小类序号:">
                        <Input v-model="subCateModal.sort"></Input>
                    </FormItem>
                    </Col>
                    <Col span="24">
                    <FormItem label="专家账号:" required>
                        <Select v-model="subCateModal.Operator"
                            filterable
                            style="width:100%">
                            <Option v-for="item in operatorList"
                                :value="item.UserName"
                                :key="item.UserName">{{ item.Name }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveSubCate">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import { USER_TYPE_EXPERT } from '@/view/common/userType';
export default {
    name: 'MainCateList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            USER_TYPE_EXPERT,
            mainCateModal: {
                show: false,
                loading: false,
                mode: 'add',
                name: '',
                sort: 1,
                id: ''
            },
            subCateModal: {
                show: false,
                loading: false,
                mode: 'add',
                parentId: '',
                parentName: '',
                name: '',
                sort: 1,
                id: '',
                Operator: ''
            },
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '大类序号', key: "Sort", align: 'center' },
                    { title: '大类名称', key: "Name", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 80,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'mainCateStatus',
                                        showEdit: this.checkAccessArray('ProductCategoryEdit'),
                                        showDelete: this.checkAccessArray('ProductCategoryEdit')
                                    },
                                    on: {
                                        handleEdit: dataId => {
                                            this.onModifyMainCate(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteMainCate(dataId);
                                        }
                                    }
                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '大类名称', type: 'Input', value: '', name: 'T_ProductCategory.Name', sql: 'like' }
                ]
            },
            subCateTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '小类序号', key: "Sort", align: 'center' },
                    { title: '小类名称', key: "Name", align: 'center' },
                    { title: '专家', key: "ExpertName", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 80,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'subCateStatus',
                                        showEdit: this.checkAccessArray('ProductCategoryEdit'),
                                        showDelete: this.checkAccessArray('ProductCategoryEdit')
                                    },
                                    on: {
                                        handleEdit: dataId => {
                                            this.onModifySubCate(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteSubCate(dataId);
                                        }
                                    }
                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                caption: '小类列表'
            },
            selectedMainCate: null,
            operatorList: []
        };
    },
    computed: {

    },
    mounted() {
        this.getMainCateList();
        this.getOperatorList();
    },
    methods: {
        // 大类CURD
        onDeleteMainCate(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '大类删除后，属于该大类的相关信息将一同被删除。',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.productCategoryDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getMainCateList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onAddMainCate() {
            this.mainCateModal.mode = 'add';
            this.mainCateModal.name = '';
            this.mainCateModal.sort = 1;
            this.mainCateModal.show = true;
        },
        onModifyMainCate(id) {
            let m = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.mainCateModal.id = id;
            this.mainCateModal.name = m.Name;
            this.mainCateModal.sort = m.Sort;
            this.mainCateModal.mode = 'modify';
            this.mainCateModal.show = true;
        },
        onSaveMainCate() {
            this.mainCateModal.loading = true;
            this.mainCateModal.sort = parseInt(this.mainCateModal.sort);
            api.productCategorySave(this.mainCateModal).then(res => {
                this.mainCateModal.loading = false;
                if (res.code === 0) {
                    this.mainCateModal.show = false;
                    this.getMainCateList();
                    this.showSuccessModal();
                }
            });
        },
        //小类CURD
        onDeleteSubCate(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '小类删除后，属于该小类的相关信息将一同被删除。',
                onOk: () => {
                    this.subCateTable.tableLoading = true;
                    api.productCategoryDelete({ id: id }).then(res => {
                        this.subCateTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getSubCateList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onAddSubCate() {
            if (this.selectedMainCate === null) {
                this.showWarningModal('请先选择大类');
            }
            else {
                this.subCateModal.mode = 'add';
                this.subCateModal.parentId = this.selectedMainCate.Id;
                this.subCateModal.Operator = '';
                this.subCateModal.name = '';
                this.subCateModal.sort = 1;
                this.subCateModal.show = true;
            }
        },
        onModifySubCate(id) {
            let s = this.subCateTable.tableData.find(e => {
                return e.Id === id;
            });
            this.subCateModal.id = id;
            this.subCateModal.name = s.Name;
            this.subCateModal.sort = s.Sort;
            this.subCateModal.parentId = this.selectedMainCate.Id;
            this.subCateModal.Operator = s.Operator;
            this.subCateModal.mode = 'modify';
            this.subCateModal.show = true;
        },
        onSaveSubCate() {
            if (!this.subCateModal.Operator) {
                return this.showWarningModal('请先选择专家再保存');
            }

            this.subCateModal.loading = true;
            this.subCateModal.sort = parseInt(this.subCateModal.sort);
            api.productCategorySave(this.subCateModal).then(res => {
                this.subCateModal.loading = false;
                if (res.code === 0) {
                    this.subCateModal.show = false;
                    this.subCateModal.Operator = '';
                    this.getSubCateList();
                    this.showSuccessModal();
                }
            });
        },
        onMainCateSelect(row, index) {
            this.selectedMainCate = row;
            this.subCateModal.parentName = row.Name;
            this.subCateTable.caption = row.Name + ' - 小类列表';
            search.globalAddFilterConditionKey(this.subCateTable.tableSearch, "T_ProductCategory.ParentId", "eq", row.Id);
            this.getSubCateList();
        },
        getMainCateList() {
            this.mainTable.tableLoading = true;
            this.mainTable.tableSearch.limit.enable = false;
            this.mainTable.tableSearch.filters.enable = true;
            this.mainTable.tableSearch.filters.conditions.push({ name: 'T_ProductCategory.ParentId', type:'eq', value: '_DB_NULL_' });
            api.productCategoryList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.mainTable.tableData = res.data.records;
                    this.selectedMainCate = null;
                }
            });
        },
        getSubCateList() {
            this.subCateTable.tableLoading = true;
            this.subCateTable.tableSearch.limit.enable = false;
            api.productCategoryList(this.subCateTable.tableSearch).then(res => {
                this.subCateTable.tableLoading = false;
                if (res.code === 0) {
                    this.subCateTable.tableData = res.data.records;
                }
            });
        },
        getOperatorList() {
            api.userListByProductMgr().then(res => {
                if (res.code === 0) {
                    this.operatorList = res.data.records;
                }
            });
        }
    }
};
</script>
<style>
</style>
