<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getBrandList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4" />

            <Button v-if="checkAccessArray('BrandEdit')"
                size="small"
                @click="onAddBrand">新增品牌</Button>
        </div>
        <Row>
            <Col span="24">
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getBrandList"
                :highlight="true"
                @on-row-click="onBrandSelect" />
            </Col>
        </Row>

        <Modal v-model="brandModal.show"
            title="品牌信息"
            :mask-closable="false">
            <Spin v-if="brandModal.loading"
                size="large"
                fix></Spin>
            <Form ref="brandModalForm" :label-width="100">
                <Row>
                    <Col span="24">
                    <FormItem label="品牌类型:" required>
                        <RadioGroup v-model.number="brandModal.brandType" @on-change="onChangeType">
                            <Radio v-for="item in brandType" :label="item.key" :key="item.key">
                                <span>{{ item.value }}</span>
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    </Col>
                </Row>
                <Row v-if="brandModal.brandType == 1">
                    <Col span="24">
                    <FormItem label="产品经理:" required>
                        <Select v-model="brandModal.managerCode" filterable style="width:100%">
                            <Option v-for="item in noBrandObjects"
                                :value="item.key"
                                :key="item.key">{{ item.key + " " + item.value }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                </Row>
                <Row  v-if="brandModal.brandType == 2">
                    <Col span="24">
                    <FormItem label="供应商名称:" required>
                        <Select v-model="brandModal.supCode" filterable style="width:100%">
                            <Option v-for="item in noBrandObjects"
                                :value="item.key"
                                :key="item.key">{{ item.value+"("+item.key+")" }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="品牌代码:"
                        required>
                        <Input v-model="brandModal.code" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="品牌名称:"
                        required>
                        <Input v-model="brandModal.name" placeholder="品牌名称尽量控制在4个字以内。"></Input>
                    </FormItem>
                    </Col>
                </Row>

            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveBrand">保存</Button>
            </div>
        </Modal>
		<SalesPromotionAndBrand ref="salesPromotionAndBrand" />
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import { USER_TYPE_SUPPLY, USER_TYPE_JOIN } from '@/view/common/userType';
import SalesPromotionAndBrand from './sales-promotion-and-brand.vue';
export default {
    name: 'BrandList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction,
		SalesPromotionAndBrand
    },
    data() {
        return {
            USER_TYPE_SUPPLY,
            USER_TYPE_JOIN,
            brandModal: {
                show: false,
                loading: false,
                joinName: '',
                code: '',
                name: '',
                sort: 0,
                brandType: 0,
                managerCode: '',
                supCode: '',
                supName: '',
                mode: 'add'
            },
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '品牌所属', key: "JoinName", align: 'center',
                        render: (h, params) => {
                            let text = params.row.BrandType === 1 ? params.row.JoinName : params.row.BrandType === 2 ? params.row.SupName : '';
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    { title: '品牌代码', key: "Code", align: 'center' },
                    { title: '品牌名称', key: "Name", align: 'center' },
                    { title: '品牌类型', key: "BrandType", align: 'center',
                        render: (h, params) => {
                            let text = params.row.BrandType === 1 ? '缤纷自营' : params.row.BrandType === 2 ? '供应商自营' : '';
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    { title: '产品经理', key: "JoinName", align: 'center',
                        render: (h, params) => {
                            let text = params.row.JoinName;
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    {
                        title: "操作",
                        align: "center",
                        width: 120,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showEdit: this.checkAccessArray('BrandEdit'),
                                        showDelete: this.checkAccessArray('BrandEdit'),
                                        showCustom2: this.checkAccessArray('BrandEdit'),
                                        customText2: '关联促销活动'
                                    },
                                    on: {
                                        handleEdit: dataId => {
                                            this.onModifyBrand(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteBrand(dataId);
                                        },
                                        handleCustom2: dataId => {
                                            this.onRelationSalesPromotion(dataId);
                                        }
                                    }
                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '品牌代码', type: 'Input', value: '', name: 'Code', sql: 'like' },
                    { label: '品牌名称', type: 'Input', value: '', name: 'Name', sql: 'like' }
                ]
            },
            selectedBrand: null,
            brandType:[],
            isProductManager: false,
            noBrandObjects:[]
        };
    },
    computed: {

    },
    mounted() {
        if (this.checkAccessArray('SelfSupportBrandCreate')) {
            this.brandType = [{ key: 1, value: '缤纷自营' }, { key: 2, value: '供应商自营' }];
        }
        else {
            this.brandType = [{ key: 2, value: '供应商自营' }];
        }

        this.getBrandList();
        this.checkUserType();
    },
    methods: {
        onBrandSelect(row, index) {
            this.selectedBrand = row;
        },
        onAddBrand() {
            this.brandModal.mode = 'add';
            this.brandModal.id = '';
            this.brandModal.joinName = '';
            this.brandModal.code = '系统自动分配';
            this.brandModal.name = '';
            this.brandModal.sort = 0;
            if (this.isProductManager) {
                this.brandModal.brandType = 2;
            }
            else{
                this.brandModal.brandType = 0;
            }
            this.brandModal.managerCode = '';
            this.brandModal.supName = '';
            this.brandModal.supCode = '';
            this.brandModal.show = true;
            this.getNoBrandObjects();
        },
        onDeleteBrand(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '确定删除吗？',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.brandDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getBrandList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onModifyBrand(id) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.brandModal.id = b.Id;
            this.brandModal.joinName = b.JoinName;
            this.brandModal.code = b.Code;
            this.brandModal.name = b.Name;
            this.brandModal.sort = b.Sort;
            this.brandModal.brandType = b.BrandType;
            this.brandModal.managerCode = b.ManagerCode;
            this.brandModal.supCode = b.SupCode;
            this.brandModal.supName = b.SupName;
            this.brandModal.mode = 'modify';
            this.brandModal.show = true;
            this.getNoBrandObjects();
        },
        onSaveBrand() {
            if (this.brandModal.brandType == 1) {
                this.brandModal.joinName = this.noBrandObjects.find(item => item.key === this.brandModal.managerCode).value;
                this.brandModal.supCode = '';
                this.brandModal.supName = '';
            }

            if (this.brandModal.brandType == 2) {
                this.brandModal.supName = this.noBrandObjects.find(item => item.key === this.brandModal.supCode).value;
                this.brandModal.joinName = '';
                this.brandModal.managerCode = '';
            }

            if (this.brandModal.name) {
                if (this.brandModal.name.slice(0, 1) !== '《') {
                    this.brandModal.name = '《' + this.brandModal.name;
                }
                if (this.brandModal.name.slice(this.brandModal.name.length - 1) !== '》') {
                    this.brandModal.name = this.brandModal.name + '》';
                }
            }

            this.brandModal.loading = true;
            api.brandSave(this.brandModal).then(res => {
                this.brandModal.loading = false;
                if (res.code === 0) {
                    this.brandModal.show = false;
                    this.getBrandList();
                    this.showSuccessModal();
                }
            });
        },
        getBrandList() {
            this.mainTable.tableLoading = true;
            api.brandList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedBrand = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        getNoBrandObjects() {
            let supCode = this.brandModal.supCode;
            let supName = this.brandModal.supName;

            let joinName = this.brandModal.joinName;
            let joinCode = this.brandModal.managerCode;

            api.noBrandForUser({ brandType: this.brandModal.brandType }).then(res => {
                if (res.code === 0) {
                    this.noBrandObjects = res.data.records;
                    if (this.brandModal.mode === 'modify' && this.brandModal.brandType === 1 && joinName) {
                        let exist = this.noBrandObjects.find(e => {
                            return e.key === joinCode;
                        });
                        if (!exist){
                            this.noBrandObjects.push({ key: joinCode, value: joinName });
                        }
                        this.brandModal.managerCode = joinCode;
                    }
                    if (this.brandModal.mode === 'modify' && this.brandModal.brandType === 2 && supName) {
                        let exist = this.noBrandObjects.find(e => {
                            return e.key === supCode;
                        });
                        if (!exist){
                            this.noBrandObjects.push({ key: supCode, value: supName });
                        }
                        this.brandModal.supCode = supCode;
                    }
                }
            });
        },
        checkUserType() {
            this.isProductManager = this.getUserType() == this.USER_TYPE_JOIN;
        },
        onChangeType(e) {
            if(e == 1 && this.isProductManager){
                this.brandModal.managerCode = this.getUserOrgCode();
            }
            this.getNoBrandObjects();
        },
        onRelationSalesPromotion(brandId) {
            let brand = this.mainTable.tableData.find(e => {
                return e.Id === brandId;
            });

            this.$refs.salesPromotionAndBrand.showModal(brand);
        }

    }
};
</script>
<style>
</style>


