<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getProductStandardHistoryList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4"
                :handleSearchWhenSelectChange="true"
                @handleSearch="handleSearch"/>
        </div>
        <Row>
            <Col span="24">
            <pagination-table 
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getProductStandardHistoryList"
                :highlight="true"
                @on-row-click="onProductStandardSelect" />
            </Col>
        </Row>

        <Modal v-model="productStandardModal.show" title="产品标准历史信息" :mask-closable="false" width="70">
            <Spin v-if="productStandardModal.loading"
                size="large"
                fix></Spin>
            <Form ref="productStandardModalForm" :label-width="120">
                <Row>
                    <Col span="8">
                    <FormItem label="产品标准编码:" required>
                        <Input v-model="productStandardModal.code" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="名称:" required>
                        <Input v-model="productStandardModal.name" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="等级:" required>
                        <Select v-model="productStandardModal.grade" disabled
                            filterable
                            style="width:100%">
                            <Option v-for="item in productGrades"
                                :value="item.ParaName"
                                :key="item.ParaName">{{ item.ParaName }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="大类:" required>
                        <Select v-model="productStandardModal.mainCategory" disabled
                            filterable
                            style="width:100%"
                            @on-change="onMainCateChange">
                            <Option v-for="item in mainCateList"
                                :value="item.Name"
                                :key="item.Name">{{ item.Name }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="小类:" required>
                        <Select v-model="productStandardModal.subCategory" disabled
                            filterable
                            style="width:100%">
                            <Option v-for="item in subCateFilterList"
                                :value="item.Name"
                                :key="item.Name">{{ item.Name }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="单位:" required>
                        <Select v-model="productStandardModal.unit" disabled
                            filterable
                            style="width:100%">
                            <Option v-for="item in unitList"
                                :value="item.ParaName"
                                :key="item.ParaName">{{ item.ParaName }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="颜色:" required>
                        <Input v-model="productStandardModal.color" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="包装数:" required>
                        <Input v-model="productStandardModal.packCount" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="拼音编码:" >
                        <Input v-model="productStandardModal.pinYinCode" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="关键字:" >
                        <Input v-model="productStandardModal.keyword" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="长度:" >
                        <Input v-model="productStandardModal.length" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="叶片:" >
                        <Input v-model="productStandardModal.blade" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="花苞:" >
                        <Input v-model="productStandardModal.bud" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="茎杆:" >
                        <Input v-model="productStandardModal.stalk" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="枝条弯曲度:" >
                        <Input v-model="productStandardModal.bending" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="开放度:" >
                        <Input v-model="productStandardModal.openness" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="病虫害:" >
                        <Input v-model="productStandardModal.diseased" readonly></Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="机械损伤:" >
                        <Input v-model="productStandardModal.damage" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                    <FormItem label="重量:" >
                        <Input v-model.number="productStandardModal.weight" type="number" readonly>
                            <template #suffix>
                                <span style="display: inline-block; line-height: 32px;">kg</span>
                            </template>
                        </Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="体积:" >
                        <Input v-model.number="productStandardModal.volumn"  type="number" readonly>
                            <template #suffix>
                                <span style="display: inline-block; line-height: 32px;">m³</span>
                            </template>
                        </Input>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="是否为新品:">
                        <RadioGroup v-model="productStandardModal.isNew">
                            <Radio label="0" disabled>
                                <span>非新品</span>
                            </Radio>
                            <Radio label="1" disabled>
                                <span>新品</span>
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="整扎标准:" >
                        <Input v-model="productStandardModal.tieStandard" type="textarea" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="备注:" >
                        <Input v-model="productStandardModal.remark" type="textarea" readonly></Input>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import { setSelectOptions } from '@/libs/selectOption';
export default {
    name: 'ProductStandardList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            productStandardModal: {
                show: false,
                loading: false,
                code: '',
                name: '',
                grade: '',
                color: '',
                mainCategory: '',
                subCategory: '',
                unit: '',
                packCount: '',
                pinYinCode: '',
                keyword: '',
                picType: 0,
                paraShow: '',
                length: '',
                blade: '',
                bud: '',
                stalk: '',
                bending: '',
                diseased: '',
                weight: 0,
                openness: '',
                damage: '',
                tieStandard: '',
                remark: '',
                volumn: 0,
                isNew: -1,
                mode: 'add'
            },
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '产品标准编码', key: "Code", align: 'center' },
                    { title: '名称', key: "Name", align: 'center' },
                    { title: '颜色', key: "Color", align: 'center' },
                    { title: '大类', key: "MainCategory", align: 'center' },
                    { title: '小类', key: "SubCategory", align: 'center' },
                    { title: '等级', key: "Grade", align: 'center' },
                    { title: '单位', key: "Unit", align: 'center' },
                    { title: '包装数', key: "PackCount", align: 'center' },
                    { title: '长度', key: "Length", align: 'center' },
                    { title: '叶片', key: "Blade", align: 'center' },
                    { title: '花苞', key: "Bud", align: 'center' },
                    { title: '茎杆', key: "Stalk", align: 'center' },
                    { title: '枝条弯曲度', key: "Bending", align: 'center' },
                    { title: '病虫害', key: "Diseased", align: 'center' },
                    { title: '开放度', key: "Openness", align: 'center' },
                    { title: '机械损伤', key: "Damage", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 40,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showInfo: true
                                    },
                                    on: {
                                        handleInfo: dataId => {
                                            this.onModifyProductStandard(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '产品标准编码', type: 'Input', value: '', name: 'Code', sql: 'like', labelWidth: '120' }
                ]
            },
            selectedProductStandard: null,
            unitList: [],
            mainCateList: [],
            subCateList: [],
            subCateFilterList: [],
            searchSubCateFilterList: [],
            productGrades:[]
        };
    },
    computed: {

    },
    mounted() {
        this.getProductStandardHistoryList();
        this.getUnitList();
        this.getCategoryList();
        this.getGradeList();
    },
    methods: {
        setSelectOptions,
        onProductStandardSelect(row, index) {
            this.selectedProductStandard = row;
        },
        onModifyProductStandard(id) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.productStandardModal.id = b.Id;
            this.productStandardModal.code = b.Code;
            this.productStandardModal.name = b.Name;
            this.productStandardModal.grade = b.Grade;
            this.productStandardModal.color = b.Color;
            this.productStandardModal.mainCategory = b.MainCategory;
            this.productStandardModal.subCategory = b.SubCategory;
            this.productStandardModal.unit = b.Unit;
            this.productStandardModal.packCount = b.PackCount;
            this.productStandardModal.pinYinCode = b.PinYinCode;
            this.productStandardModal.keyword = b.Keyword;
            this.productStandardModal.picType = b.PicType;
            this.productStandardModal.paraShow = b.ParaShow;
            this.productStandardModal.length = b.Length;
            this.productStandardModal.blade = b.Blade;
            this.productStandardModal.bud = b.Bud;
            this.productStandardModal.stalk = b.Stalk;
            this.productStandardModal.bending = b.Bending;
            this.productStandardModal.diseased = b.Diseased;
            this.productStandardModal.weight = b.Weight;
            this.productStandardModal.openness = b.Openness;
            this.productStandardModal.damage = b.Damage;
            this.productStandardModal.tieStandard = b.TieStandard;
            this.productStandardModal.remark = b.Remark;
            this.productStandardModal.volumn = b.Volumn;
            this.productStandardModal.isNew = b.IsNew + '';
            this.productStandardModal.mode = 'modify';
            this.productStandardModal.show = true;
            this.echoSelect(b);
        },
        getProductStandardHistoryList() {
            this.mainTable.tableLoading = true;
            api.productStandardHistoryList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedProductStandard = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        onMainCateChange(e) {
            let mainId = this.mainCateList.find(item => item.Name === e).Id;
            this.subCateFilterList = this.subCateList.filter(v => {
                return v.ParentId === mainId;
            });
            this.productStandardModal.subCategory = '';
        },
        echoSelect(standard) {
            let mainId = this.mainCateList.find(item => item.Name === standard.MainCategory).Id;
            this.subCateFilterList = this.subCateList.filter(v => {
                return v.ParentId === mainId;
            });
        },
        getUnitList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersUnitList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.unitList = res.data.records;
                }
            });
        },
        getCategoryList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.productCategoryList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.mainCateList = res.data.records.filter(c=> c.ParentId == null);
                    this.subCateList = res.data.records.filter(c=> c.ParentId !== null);

                    this.setSelectOptions(this.mainTable.searchForm, 'MainCategory', this.mainCateList, 'Name', 'Name');
                }
            });
        },
        getGradeList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersGradeList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.productGrades = res.data.records;
                    this.setSelectOptions(this.mainTable.searchForm, 'Grade', this.productGrades, 'ParaName', 'ParaName');
                }
            });
        },
        handleSearch(e) {
            const selectElem = e[0];

            if(!selectElem){
                return;
            }

            if(selectElem.name === 'MainCategory') {
                let mainId = this.mainCateList.find(item => item.Name === selectElem.value).Id;
                this.searchSubCateFilterList = this.subCateList.filter(v => {
                    return v.ParentId === mainId;
                });
                this.setSelectOptions(this.mainTable.searchForm, 'SubCategory', this.searchSubCateFilterList, 'Name', 'Name');
            }
        }
    }
};
</script>
<style media="print" scoped lang="less">

/deep/ .ivu-modal-footer > .ivu-btn-primary {
    display: none;
}
</style>

