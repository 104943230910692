<template>
    <div class="">
        <div class="flex searchButtonContainer" style="margin-bottom: 10px;border-bottom: none;">
            <Button v-if="checkAccessArray('ProductTagEdit')"
                size="small"
                @click="onAddProductTag">新增产品标签</Button>
        </div>
        <Row>
            <Col span="24">
            <pagination-table tableCaption="产品标签列表"
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getProductTagList"
                :highlight="true"
                :windowHeight="true"
                :showPage="false"
                @on-row-click="onProductTagSelect" />
            </Col>
        </Row>

        <Modal v-model="productTagModal.show" title="产品标签信息" :mask-closable="false" width="700">
            <Spin v-if="productTagModal.loading" size="large" fix></Spin>
            <Form ref="productTagModalForm"
                :label-width="100">
                <Row>
                    <Col span="24">
                    <FormItem label="标签名称:" required>
                        <Input v-model="productTagModal.name"></Input>
                    </FormItem>
                    </Col>

                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="排序:" required>
                        <Input v-model.number="productTagModal.sort" type="number"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="自定义图标:">
                        <template>
                            <Upload action="https://up-z2.qiniup.com/"
                                    :data="uploadParams"
                                    :format="['jpg','jpeg','png']"
                                    :on-format-error ="handleFormatError"
                                    :on-success="handleUploadSuccess"
                                    :before-upload="beforeUpload"
                                    :show-upload-list="showUploadList"
                                    :max-size="100"
                                    :on-exceeded-size="handleMaxSize"
                                    type="drag"
                                    style="display: inline-block;width:58px;"
                            >
                                <div style="width: 58px;height:58px;line-height: 58px;">
                                    <img :src="productTagModal.icon" fit="fill" width="58px" height="58px" v-if="productTagModal.icon" />
                                    <Icon type="ios-camera" size="20" v-else></Icon>
                                </div>

                            </Upload>
                        </template>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="">
                        <span>图片文件大小限制：100kb以内</span>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveProductTag">保存</Button>
            </div>
        </Modal>

        <TagAndProductModal ref="tagAndProductModal" />
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import TagAndProductModal from './tag-and-product.vue';
import { Modal } from 'view-design';
export default {
    name: 'ProductTagList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction,
        TagAndProductModal
    },
    data() {
        return {
            productTagModal: {
                show: false,
                loading: false,
                name: '',
                sort: 0,
                icon: '',
                mode: 'add'
            },
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '分类名称', key: "Name", align: 'center' },
                    { title: '排序', key: "Sort", align: 'center' },
                    { title: '自定义图标', key: "Icon", align: 'center',
                        render: (h, params) => {
                            const imageSrc = params.row.Icon;
                            return h('img', {
                                style: {
                                    width: '20px',
                                    height: '20px'
                                },
                                attrs: {
                                    src: imageSrc
                                },
                                on: {
                                    click: () => {
                                        Modal.info({
                                            title: '',
                                            closable: true,
                                            okText: '关闭',
                                            render: h => {
                                                return h("img", {
                                                    attrs: {
                                                        src: imageSrc,
                                                        style: "margin-top:20px;width: 350px;height: 350px;"
                                                    }
                                                });
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    },
                    {
                        title: "操作",
                        align: "center",
                        width: 120,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showEdit: this.checkAccessArray('ProductTagEdit'),
                                        showDelete: this.checkAccessArray('ProductTagEdit'),
                                        showCustom1: this.checkAccessArray('ProductTagEdit'),
                                        customText1: '关联产品'
                                    },
                                    on: {
                                        handleEdit: dataId => {
                                            this.onModifyProductTag(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteProductTag(dataId);
                                        },
                                        handleCustom1: dataId => {
                                            this.onRelationProduct(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: []
            },
            selectedProductTag: null,
            showUploadList: false,
            imageDomain: this.imageDomain,
            uploadParams: {}
        };
    },
    computed: {

    },
    mounted() {
        this.getProductTagList();
    },
    methods: {
        onProductTagSelect(row, index) {
            this.selectedProductTag = row;
        },
        onAddProductTag() {
            this.productTagModal.mode = 'add';
            this.productTagModal.id = '';
            this.productTagModal.name = '',
            this.productTagModal.sort = 0,
            this.productTagModal.icon = '',
            this.productTagModal.show = true;
        },
        onDeleteProductTag(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '删除标签会连同产品关联的信息也一同删除且不可恢复，确定删除吗？',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.productTagDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getProductTagList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onModifyProductTag(id) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.productTagModal.id = b.Id;
            this.productTagModal.name = b.Name;
            this.productTagModal.sort = b.Sort;
            this.productTagModal.icon = b.Icon;
            this.productTagModal.mode = 'modify';
            this.productTagModal.show = true;
        },
        onSaveProductTag() {
            if (!this.productTagModal.sort) {
                return this.showSuccessModal('请先填入标签序号!!');
            }
            this.productTagModal.sort = parseInt(this.productTagModal.sort);
            this.productTagModal.loading = true;
            api.productTagSave(this.productTagModal).then(res => {
                this.productTagModal.loading = false;
                if (res.code === 0) {
                    this.productTagModal.show = false;
                    this.getProductTagList();
                    this.showSuccessModal();
                }
            });
        },
        getProductTagList() {
            this.mainTable.tableLoading = true;
            api.productTagList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedProductTag = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        onRelationProduct(id) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.$refs.tagAndProductModal.showModal(id, b.Name);
        },
        handleFormatError(request, file) {
            this.$Notice.warning({
                title: '格式错误',
                desc: '请上传png、jpg、jpeg格式文件'
            });
        },
        async beforeUpload(request) {
            // **注意这里的参数必须通过属性赋值，如果直接修改整个uploadParams是无效的。**
            // 上传之前设置上传参数,
            let filename = Date.now() + Math.random().toString(36).slice(2) + ".png";
            await api.qiniuToken({}).then(res => {
                this.uploadParams.token = res.data.token;
                // 七牛response hash key的值自定义，后面如果需要浏览或者下载都需要这个地址，如果key不定义，那么七牛生成的key === hash的值
                this.uploadParams.key = filename;
            });
        },
        handleUploadSuccess(request, file, list) {
            this.productTagModal.icon = this.imageDomain + file.response.key;
        },
        handleMaxSize(file) {
            this.$Notice.warning({
                title: '图片过大提醒',
                desc: '图片  ' + file.name + ' 超过了100kb，请重新裁剪后再试'
            });
        }
    }
};
</script>
<style>
</style>

