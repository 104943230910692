<template>
	<Modal v-model="memberAddressModal.show" title="会员地址信息" :mask-closable="false" width="40" @on-cancel="onCancel">
		<Spin v-if="memberAddressModal.loading" size="large" fix></Spin>
		<Form ref="memberAddressModalForm" :label-width="110" :model="memberAddressModal">
			<Row>
				<Col span="12">
				<FormItem label="会员编码:" prop="memCode">
					<Input v-model="memberAddressModal.memCode" disabled></Input>
				</FormItem>
				</Col>
				<Col span="12">
				<FormItem label="姓名:" prop="memName">
					<Input v-model="memberAddressModal.memName" disabled></Input>
				</FormItem>
				</Col>
			</Row>
			<Row>
				<Col span="12">
				<FormItem label="联系人:" prop="linkMan">
					<Input v-model="memberAddressModal.linkMan"></Input>
				</FormItem>
				</Col>
				<Col span="12">
				<FormItem label="手机号:" prop="phone">
					<Input v-model="memberAddressModal.phone"></Input>
				</FormItem>
				</Col>
			</Row>
			<Row>
				<Col span="12">
				<FormItem label="省:" prop="province">
					<Select v-model="memberAddressModal.province" filterable @on-change="selectedProvince">
						<Option v-for="item in provinceList" :label="item.Province" :value="item.Province"
							:key="item.Province">
							{{ item.Province }}
						</Option>
					</Select>
				</FormItem>
				</Col>
				<Col span="12">
				<FormItem label="市:" prop="city">
					<Select v-model="memberAddressModal.city" filterable @on-change="selectedCity">
						<Option v-for="item in cityList" :value="item.City" :label="item.City" :key="item.City">
							{{ item.City }}
						</Option>
					</Select>
				</FormItem>
				</Col>
			</Row>
			<Row>
				<Col span="12">
				<FormItem label="区:" prop="area">
					<Select v-model="memberAddressModal.area" filterable @on-change="selectedArea">
						<Option v-for="item in areaList" :value="item.Area" :label="item.Area" :key="item.Area">
							{{ item.Area }}
						</Option>
					</Select>
				</FormItem>
				</Col>
				<Col span="12">
				<FormItem label="街道:" prop="street">
					<Select v-model="memberAddressModal.street" filterable>
						<Option v-for="item in streetList" :value="item.Street" :label="item.Street"
							:key="item.Street">{{ item.Street }}
						</Option>
					</Select>
				</FormItem>
				</Col>
			</Row>
			<Row>
				<Col span="24">
				<FormItem label="详细地址:" prop="detailAddr">
					<Input v-model="memberAddressModal.detailAddr"></Input>
				</FormItem>
				</Col>
			</Row>
			<Row>
				<Col span="12">
					<FormItem label="物流方案:" prop="deliveryWay">
						<Select v-model="memberAddressModal.deliveryWay" filterable>
							<Option v-for="item in deliveryWayList" :value="item.DeliveryWay" :label="item.DeliveryWay"
								:key="item.DeliveryWay">
								{{ item.DeliveryWay }}
							</Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
				<FormItem label="是否默认地址:" prop="isDefault">
					<RadioGroup v-model="memberAddressModal.isDefault">
						<Radio :label="1">是</Radio>
						<Radio :label="0">否</Radio>
					</RadioGroup>
				</FormItem>
				</Col>
				
			</Row>
			<Row>
				<Col span="24">
				<FormItem label="收货地址状态:" prop="addrState">
					<RadioGroup v-model="memberAddressModal.addrState">
						<Radio :label="0">审核中</Radio>
						<Radio :label="1">审核通过</Radio>
						<Radio :label="2">审核不通过</Radio>
					</RadioGroup>
				</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button type="info" v-if="memberAddressModal.readOnly == false" @click="onSaveMemberAddress">保存</Button>
		</div>
	</Modal>
</template>

<script>
	import api from '@/api/api';
	import search from '@/libs/search';
	import axiosOri from 'axios';
	import validate from "@/libs/validate";
	export default {
		name: 'MemberAddressModal',
		components: {},
		data() {
			return {
				memberAddressModal: {
					show: false,
					loading: false,
					readOnly: false,
					memberId: '',
					memCode: '',
					memName: '',
					linkMan: '',
					phone: '',
					province: '',
					city: '',
					area: '',
					street: '',
					detailAddr: '',
					isDefault: 0,
					addrState: 0,
					serviceDept: '',
					deliveryWay:'',
					mode: 'add',
				},
				provinceList: [],
				cityList: [],
				areaList: [],
				streetList: [],
				deliveryWayList:[]
			};
		},
		computed: {

		},
		mounted() {
			
		},
		methods: {
			showModal(memberAddress, readOnly) {
				this.memberAddressModal.show = true;
				this.initData(memberAddress, readOnly)
				this.getProvinceList();
				this.getDistrictCityList();
				this.getDeliveryWay();
				this.getDistrictAreaList();
				this.getDistrictStreetList();
			},
			initData(memberAddress, readOnly) {
				if (memberAddress) {
					this.memberAddressModal.readOnly = readOnly;
					this.memberAddressModal.id = memberAddress.Id;
					this.memberAddressModal.memberId = memberAddress.MemberId;
					this.memberAddressModal.memCode = memberAddress.MemCode;
					this.memberAddressModal.memName = memberAddress.MemName;
					this.memberAddressModal.linkMan = memberAddress.LinkMan;
					this.memberAddressModal.phone = memberAddress.Phone;
					this.memberAddressModal.province = memberAddress.Province;
					this.memberAddressModal.city = memberAddress.City;
					this.memberAddressModal.area = memberAddress.Area;
					this.memberAddressModal.street = memberAddress.Street;
					this.memberAddressModal.detailAddr = memberAddress.DetailAddr;
					this.memberAddressModal.isDefault = memberAddress.IsDefault;
					this.memberAddressModal.addrState = memberAddress.AddrState;
					this.memberAddressModal.deliveryWay = memberAddress.DeliveryWay;
					this.memberAddressModal.mode = 'modify';
				} else {
					this.memberAddressModal.mode = 'add';
					this.memberAddressModal.id = '';
					this.memberAddressModal.memberId = '';
					this.memberAddressModal.memCode = '';
					this.memberAddressModal.memName = '';
					this.memberAddressModal.linkMan = '';
					this.memberAddressModal.phone = '';
					this.memberAddressModal.province = '';
					this.memberAddressModal.city = '';
					this.memberAddressModal.area = '';
					this.memberAddressModal.street = '';
					this.memberAddressModal.detailAddr = '';
					this.memberAddressModal.isDefault = 0;
					this.memberAddressModal.addrState = 0;
					this.memberAddressModal.deliveryWay ='';
				}
			},
			//获取省份
			getProvinceList() {
				api.districtProvinceList({}).then(res => {
					if (res.code === 0) {
						this.provinceList = res.data.records;
					}
				});
			},
			//选择省份
			selectedProvince(e) {
				this.memberAddressModal.province = e;
				this.memberAddressModal.city='';
				this.memberAddressModal.area='';
				this.memberAddressModal.street='';
				this.getDistrictCityList()
			},
			
			//根据省份获取城市列表
			getDistrictCityList() {
				if(this.memberAddressModal.province){
					api.districtCityList({
						province: this.memberAddressModal.province
					}).then(res => {
						if (res.code === 0) {
							this.cityList = res.data.records;
						}
					});
				}
			},
			//选择城市
			selectedCity(e) {
				this.memberAddressModal.city = e;
				this.memberAddressModal.area='';
				this.memberAddressModal.street='';
				this.getDistrictAreaList()
				this.getDeliveryWay();
			},
			//根据城市获取区列表
			getDistrictAreaList() {
				if(this.memberAddressModal.province&&this.memberAddressModal.city){
					api.districtAreaList({
						province: this.memberAddressModal.province,
						city: this.memberAddressModal.city,
					}).then(res => {
						if (res.code === 0) {
							this.areaList = res.data.records;
						}
					});
				}
			},
			//选择区
			selectedArea(e) {
				this.memberAddressModal.area = e;
				this.memberAddressModal.street='';
				this.getDistrictStreetList();
			},
			//根据区县获取街道列表
			getDistrictStreetList() {
				if(this.memberAddressModal.province&&this.memberAddressModal.city&&this.memberAddressModal.area){
					api.districtStreetList({
						province: this.memberAddressModal.province,
						city: this.memberAddressModal.city,
						area: this.memberAddressModal.area
					}).then(res => {
						if (res.code === 0) {
							this.streetList = res.data.records;
						}
					});
				}
			},
			//获取物流方式列表
			getDeliveryWay() {
				if(this.memberAddressModal.city){
					let tempSearch = {
						filters: {
							enable: true,
							join: "and",
							conditions: [{
								name: 'Destination',
								type: 'eq',
								value: this.memberAddressModal.city
							}]
						}
					}
					api.freightConfigCityList(tempSearch).then(res => {
						if (res.code === 0) {
							this.deliveryWayList = res.data.records;
						}
					});
				}
			},
			onSaveMemberAddress() {
				if(this.memberAddressModal.addrState==1){
					if(!this.memberAddressModal.linkMan){
					    return this.showWarningModal('联系人不能为空！');
					}
					if(!this.memberAddressModal.phone){
					    return this.showWarningModal('手机号不能为空！');
					}
					if(this.memberAddressModal.phone && !validate.validatePhoneNumber(this.memberAddressModal.phone)){
					    return this.showWarningModal('请输入正确的手机号码！');
					}
					
					if(!this.memberAddressModal.province){
					    return this.showWarningModal('请选择省！');
					}
					if(!this.memberAddressModal.city){
					    return this.showWarningModal('请选择市！');
					}
					if(!this.memberAddressModal.area){
					    return this.showWarningModal('请选择区！');
					}
					if(!this.memberAddressModal.street){
					    return this.showWarningModal('请选择街道！');
					}
					if(!this.memberAddressModal.detailAddr){
					    return this.showWarningModal('请输入详细地址！');
					}
					if(!this.memberAddressModal.deliveryWay){
					    return this.showWarningModal('请选择发货方式！');
					}
				}
				this.memberAddressModal.loading = true;
				api.memberAddressSave(this.memberAddressModal).then(res => {
					this.memberAddressModal.loading = false;
					if (res.code === 0) {
						this.memberAddressModal.show = false;
						this.$parent.getMemberAddressList();
						this.showSuccessModal();
					}
				});
			},
			onCancel(){
				this.$refs.memberAddressModalForm.resetFields();
				this.memberAddressModal.show = false;
			},
		}
	};
</script>

<style>
</style>