import { render, staticRenderFns } from "./cate-list.vue.js?vue&type=template&id=45fa8278!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./cate-list.vue?vue&type=template&id=45fa8278"
import script from "./cate-list.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./cate-list.vue?vue&type=script&lang=js"
export * from "./cate-list.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./cate-list.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports