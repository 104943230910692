<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getBlacklist"
                :table-search="mainTable.tableSearch"
                :labelWidth="60"
                :defaultFieldWidth="150"
                :defaultSelectFieldWidth="200"
                :handleSearchWhenSelectChange="true" />
            <Button
                size="small"
                @click="onAddMemberBlacklist">新增会员黑名单</Button>
            <Button
                size="small"
                @click="onAddSupplierBlacklist">新增供应商黑名单</Button>
        </div>
        <div>
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getBlacklist"
                :highlight="true"
                @on-row-click="onBlacklistSelect" />
        </div>
        <Modal v-model="blacklistModal.show"
            :title="modalTitle"
            :mask-closable="false">
            <Spin v-if="blacklistModal.loading"
                size="large"
                fix></Spin>
            <Form ref="unitModalForm"
                :label-width="100">
                <Row>
                    <Col span="24">
                    <FormItem label="名称:"
                        required>
                        <Input v-model="blacklistModal.name"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="手机号:" required>
                        <Input v-model="blacklistModal.phone"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="联系人:" required>
                        <Input v-model="blacklistModal.linkMan"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="备注:" required>
                        <Input v-model="blacklistModal.remark"></Input>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveBlacklist">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
export default {
    name: 'UnitList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            modalTitle: '',
            blacklistModal: {
                show: false,
                loading: false,
                mode: 'add',
                name: '',
                phone: '',
                linkMan: '',
                objectType: 1,
                remark: ''
            },
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '类型', key: "ObjectType", align: 'center',
                        render: (h, params) => {
                            let text = params.row.ObjectType === 1 ? '会员' : '供应商';
                            let color = params.row.ObjectType === 1 ? 'black' : 'red';
                            return h('div', [
                                h('span', {
                                    style: {
                                        color: color
                                    }
                                }, text)
                            ]);
                        }
                    },
                    { title: '名称', key: "Name", align: 'center' },
                    { title: '手机号码', key: "Phone", align: 'center' },
                    { title: '联系人', key: "LinkMan", align: 'center' },
                    { title: '时间', key: "ModifyTime", align: 'center' },
                    { title: '备注', key: "Remark", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 40,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'RecordStatus',
                                        showDelete: this.checkAccessArray('BlackListEdit')
                                    },
                                    on: {
                                        handleDelete: dataId => {
                                            this.onDeleteBlacklist(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '', type: 'RadioGroup', value: '', name: 'ObjectType', sql: 'eq', options: [{ key: '会员', value: '1' }, { key: '供应商', value: '2' }], fieldWidth: '150', labelWidth: 0 },
                    { label: '名称', type: 'Input', value: '', name: 'Name', sql: 'like' },
                    { label: '手机号', type: 'Input', value: '', name: 'Phone', sql: 'like' },
                    { label: '联系人', type: 'Input', value: '', name: 'LinkMan', sql: 'like' }
                ]
            },
            selectedBlacklist: null
        };
    },
    computed: {

    },
    mounted() {
        this.getBlacklist();
    },
    methods: {
        onBlacklistSelect(row, index) {
            this.selectedBlacklist = row;
        },
        onAddBlacklist() {
            this.blacklistModal.mode = 'add';
            this.blacklistModal.name = '';
            this.blacklistModal.phone = '';
            this.blacklistModal.linkMan = '';
            this.blacklistModal.remark = '';
            this.blacklistModal.show = true;
        },
        onDeleteBlacklist(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '确定删除?',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.blacklistDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getBlacklist();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onAddMemberBlacklist(){
            this.modalTitle = '添加会员黑名单';
            this.blacklistModal.objectType = 1,
            this.onAddBlacklist();
        },
        onAddSupplierBlacklist(){
            this.modalTitle = '添加供应商黑名单';
            this.blacklistModal.objectType = 2,
            this.onAddBlacklist();
        },
        onSaveBlacklist() {
            this.blacklistModal.loading = true;
            api.blacklistSave(this.blacklistModal).then(res => {
                this.blacklistModal.loading = false;
                if (res.code === 0) {
                    this.blacklistModal.show = false;
                    this.getBlacklist();
                    this.showSuccessModal();
                }
            });
        },
        getBlacklist() {
            this.mainTable.tableLoading = true;
            api.blacklist(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedBlacklist = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        }
    }
};
</script>
<style>
</style>
