<template>
	<div>
		<Modal v-model="modalType.show" title="编辑个人快捷导航"
			:mask-closable="false" width="800" :closable="false"
			class-name="presellModal">
			<Spin v-if="modalType.loading"size="large" fix></Spin>
				
			<Tree :data="treeData" show-checkbox multiple ref="tree" @on-check-change="onCheckChange"></Tree>
			<div slot="footer">
				<Button @click="onCancel()">关闭</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
	export default {
		name: 'NavItemDetails',
		data(){
			return {
				tableLoading:false,
				treeData:[],
				shortcutList:[],
				modalType:{
					show:false,
					loading: false,
					readOnly: false,
				},
			}
		},
		computed: {

		},
		methods :{
			async showModal(){
				this.modalType.show = true;
				await this.getShortcut()
			},
			onCancel(){
				this.modalType.show = false
				this.showSuccessModal("刷新页面获取最新体验");
			},
			onCheckChange(){
				let selectedResource = this.$refs.tree.getCheckedNodes();
				let resourceIds = selectedResource.map(obj => {
				    return obj.id;
				});
				
				let data = {
				    resourceIds: resourceIds
				};
				
				api.shortcutSave(data).then(res => {
				    if (res.code === 0) {
				        this.showSuccessModal("修改成功");
				    }
				});
			},
			async getShortcut(){
				this.tableLoading = true;
				const {data:data} = await api.getShortcut()
				this.tableLoading = false;
				const checkedKeys = []
				this.shortcutList = data.shortcutList
				this.shortcutList.forEach(item =>{
					checkedKeys.push(item.Id)
				})
				await this.getResourceTree(checkedKeys)
			},
			getResourceTree(checkedKeys) {
			    let array = [];
			    let tempSearch = search.getSearchParams({ closeAllConditions: true });
			    api.resourceList(tempSearch).then(res => {
			        if (res.code === 0) {
			            let records = res.data.records.map(it => {
			                return { id: it.Id, title: it.Name, parentId: it.ParentId, sort: it.Sort, checked: checkedKeys.includes(it.Id) };
			            }).sort(this.compareTo('sort', 'asc'));
			            records.forEach(item=>{
			                // 找到每个对象的子节点
			                item.children = records.filter(info => info.parentId === item.id);
			                if (item.parentId === '') {
			                    // 将一层节点放入新数组中
			                    item.expand = true;
			                    array.push(item);
			                }
			            });
			        }
			        this.treeData = array.sort(this.compareTo('sort', 'asc'));
					this.treeData[0].children.forEach( item =>{
						item.children.forEach( i =>{
							i.children = []
						})
					})
			    });
			}
			
			
		}
	}
</script>

<style scoped>

</style>
