<template>
    <Row>
        <Col span="6">
            <Form ref="form" :model="userModel" :label-width="160">
                <FormItem label="角色：" >
                    <Input type="text" v-model="userModel.role" readonly></Input>
                </FormItem>
                <FormItem label="账号：">
                    <Input type="text" v-model="userModel.userName" readonly></Input>
                </FormItem>
                <FormItem label="名称：">
                    <Input type="text" v-model="userModel.name" readonly></Input>
                </FormItem>
                <FormItem label="手机号：">
                    <Input type="text" v-model="userModel.phone" readonly></Input>
                </FormItem>
                <FormItem label="所属部门：">
                    <Input type="text" v-model="userModel.department" readonly></Input>
                </FormItem>
                <FormItem label="工作区域：" >
                    <Input type="text" v-model="userModel.region" readonly></Input>
                </FormItem>
                <FormItem label="用户类型：">
                    <Input type="text" v-model="userModel.typeName" readonly></Input>
                </FormItem>
                <FormItem label="关联的供应商：" v-if="userModel.userType == USER_TYPE_SUPPLY">
                    <Input type="text" v-model="userModel.orgName" readonly></Input>
                </FormItem>
                <FormItem label="关联的加盟项目：" v-if="userModel.userType == USER_TYPE_JOIN">
                    <Input type="text" v-model="userModel.orgName" readonly></Input>
                </FormItem>
                <FormItem label="关联的集货站：" v-if="userModel.userType == USER_TYPE_COLLECT">
                    <Input type="text" v-model="userModel.orgName" readonly></Input>
                </FormItem>
                <!-- <FormItem label="关联的客户经理：" v-if="userModel.userType == USER_TYPE_CUSTOMER_MANAGER">
                    <Input type="text" v-model="userModel.orgName" readonly></Input>
                </FormItem> -->
                <FormItem label="配送站点：" v-if="userModel.userType == USER_TYPE_DELIVER">
                    <Input type="text" v-model="userModel.orgName" readonly></Input>
                </FormItem>
            </Form>
        </Col>
    </Row>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import { USER_TYPE_SUPPLY, USER_TYPE_JOIN, USER_TYPE_COLLECT, USER_TYPE_CUSTOMER_MANAGER, USER_TYPE_OHTER, USER_TYPE_DELIVER, USER_TYPE_INSIDER } from '@/view/common/userType';
export default {
    name: 'UserInfo',
    data() {
        return {
            USER_TYPE_SUPPLY,
            USER_TYPE_JOIN,
            USER_TYPE_COLLECT,
            USER_TYPE_CUSTOMER_MANAGER,
            USER_TYPE_OHTER,
            USER_TYPE_DELIVER,
            USER_TYPE_INSIDER,
            userModel: {
                id: '',
                userCode: '',
                userName: '',
                name: '',
                region: '',
                userType: '',
                typeName: '',
                phone: '',
                remark: '',
                role: '',
                department: '',
                orgCode: '',
                orgName: ''
            },
            userTypes:[]
        };
    },
    mounted() {
        this.getUserInfo();
    },
    methods: {
        getUserInfo() {
            api.userInfo({ id: this.getUserId() }).then(res => {
                if (res.code === 0) {
                    this.userModel.userCode = res.data.userInfo.userCode;
                    this.userModel.userName = res.data.userInfo.userName;
                    this.userModel.name = res.data.userInfo.name;
                    this.userModel.region = res.data.userInfo.region;
                    this.userModel.userType = res.data.userInfo.userType;
                    this.userModel.phone = res.data.userInfo.phone;
                    this.userModel.role = res.data.userInfo.roleName;
                    this.userModel.department = res.data.userInfo.department;
                    this.userModel.orgCode = res.data.userInfo.orgCode;
                    this.getUserTypeList();
                    this.renderOrgName();
                }
            });
        },
        getUserTypeList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersUserTypeList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.userModel.typeName = res.data.records.find(r=> r.ParaCode == this.userModel.userType).ParaName;
                }
            });
        },
        renderOrgName() {
            if(!this.userModel.orgCode) {
                return;
            }

            if(this.userModel.userType == USER_TYPE_SUPPLY) {
                this.getSupplierList();
            }

            if(this.userModel.userType == USER_TYPE_JOIN) {
                this.getJoinList();
            }

            if(this.userModel.userType == USER_TYPE_COLLECT) {
                this.getCollectList();
            }

            if(this.userModel.userType == USER_TYPE_CUSTOMER_MANAGER) {
                // do something
            }

            if(this.userModel.userType == USER_TYPE_DELIVER) {
                this.getDeliverList();
            }

        },
        getSupplierList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.supplierList(tempSearch).then(res => {
                if (res.code === 0) {
                    const item = res.data.records.find(s=> s.Code == this.userModel.orgCode);
                    this.userModel.orgName = item ? item.Name: '';
                }
            });
        },
        getJoinList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersJoinList(tempSearch).then(res => {
                if (res.code === 0) {
                    const item = res.data.records.find(s=> s.ParaCode == this.userModel.orgCode);
                    this.userModel.orgName = item ? item.ParaName: '';
                }
            });
        },
        getCollectList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersCollectList(tempSearch).then(res => {
                if (res.code === 0) {
                    let orgNames = [];
                    let orgCodes = this.userModel.orgCode.split(",");
                    for (let index in orgCodes) {
                        let item = res.data.records.find(s=> s.ParaCode == orgCodes[index]);
                        if(item){
                            orgNames.push(item.ParaName);
                        }
                    }
                    this.userModel.orgName = orgNames.join(',');
                }
            });
        },
        getDeliverList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersDelivList(tempSearch).then(res => {
                if (res.code === 0) {
                    const item = res.data.records.find(s=> s.ParaCode == this.userModel.orgCode);
                    this.userModel.orgName = item ? item.ParaName: '';
                }
            });
        },

    },
    components: {}
};
</script>

<style></style>
