import MyHome from '@/view/MyHome';

export default {
    path: '/sales',
    name: 'sales',
    component: MyHome,
    meta: { title: '销售管理', keepAlive: true },
    children: [
        {
            path: 'product-management',
            name: 'productManagement',
            component: () => import("@/view/sales-management/product-management/curSale"),
            meta: { title: '商品管理', keepAlive: true }
        },
        {
            path: 'order-management',
            name: 'orderManagement',
            component: () => import("@/view/sales-management/order-management"),
            meta: { title: '订单管理', keepAlive: false }
        },
        {
            path: 'insertion-management',
            name: 'insertionManagement',
            component: () => import("@/view/sales-management/insertion-management"),
            meta: { title: '入位管理', keepAlive: false }
        },
        {
            path: 'sales-summary',
            name: 'salesSummary',
            component: () => import("@/view/sales-management/sales-summary"),
            meta: { title: '销售汇总', keepAlive: false }
        },
        {
            path: 'sales-printing',
            name: 'salesPrinting',
            component: () => import("@/view/sales-management/sales-printing"),
            meta: { title: '销售打印', keepAlive: true }
        },
        {
            path: 'bill-review',
            name: 'billTeview',
            component: () => import("@/view/sales-management/bill-review"),
            meta: { title: '单据审核', keepAlive: true }
        },
        {
            path: 'order-complain',
            name: 'orderComplain',
            component: () => import("@/view/sales-management/order-complain"),
            meta: { title: '订单投诉', keepAlive: false }
        },
        {
            path: 'data-analysis',
            name: 'dataAnalysis',
            component: () => import("@/view/sales-management/data-analysis"),
            meta: { title: '大数据分析', keepAlive: false }
        },
        {
            path: 'product-price',
            name: 'ProductPrice',
            component: () => import("@/view/sales-management/product-price"),
            meta: { title: '产品定价', keepAlive: false }
        },
        {
		    path: 'add-order',
		    name: 'AddOrder',
		    component: () => import("@/view/sales-management/add-order"),
		    meta: { title: '市场加单', keepAlive: false }
        },
        {
		    path: 'supply-data',
		    name: 'SupplySata',
		    component: () => import("@/view/container-management/supply-data/index"),
		    meta: { title: '上传供货', keepAlive: false }
        },
		{
		    path: 'goods-refund',
		    name: 'GoodsRefund',
		    component: () => import("@/view/sales-management/goods-refund/index"),
		    meta: { title: '缺货退款', keepAlive: false }
		}
    ]
};
