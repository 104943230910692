import MyHome from '@/view/MyHome';

export default {
    path: '/purchase-sales-inventory',
    name: 'PurchaseSalesInventory',
    component: MyHome,
    meta: { title: '进销存管理', keepAlive: true },
    children: [
        //供货商
        {
            path: 'supSend-memOrder-list',
            name: 'SupSendMemOrderList',
            component: () => import('@/view/purchase-sales-inventory/supplier/supSend-memOrder-list'),
            meta: { title: '供货商发货和客户订单差异表', keepAlive: false }
        },
        {
            path: 'supplier-PSI',
            name: 'SupplierPSI',
            component: () => import('@/view/purchase-sales-inventory/supplier/supplier-PSI'),
            meta: { title: '供货商进销存', keepAlive: false }
        },
        //集货站
        // {
        //     path: 'collRevd-supSend-list',
        //     name: 'CollectReceiveSupplierSendList',
        //     component: () => import('@/view/purchase-sales-inventory/collect-station/collRevd-supSend-list'),
        //     meta: { title: '集货站收货与供货商发货差异表', keepAlive: false }
        // },
        {
            path: 'collSend-collRevd-list',
            name: 'CollectSendCollectReceiveList',
            component: () => import('@/view/purchase-sales-inventory/collect-station/collSend-collRevd-list'),
            meta: { title: '集货站发货与集货站收货差异表', keepAlive: false }
        },
        //配送站
        {
            path: 'delivRevd-collSend-list',
            name: 'DeliveryReceiveCollectSendList',
            component: () => import('@/view/purchase-sales-inventory/delivery-station/delivRevd-collSend-list'),
            meta: { title: '配送站收货与集货站发货差异表', keepAlive: false }
        },
        {
            path: 'delivSend-delivRevd-list',
            name: 'DeliverySendDeliveryReceiveList',
            component: () => import('@/view/purchase-sales-inventory/delivery-station/delivSend-delivRevd-list'),
            meta: { title: '配送站发货与配送站收货差异表', keepAlive: false }
        },
        {
            path: 'diff-summary',
            name: 'PSIDiffSummary',
            component: () => import('@/view/purchase-sales-inventory/diff-summary'),
            meta: { title: '差异汇总', keepAlive: false }
        },
        {
            path: 'purchase-order',
            name: 'CollStaPurchaseOrder',
            component: () => import("@/view/purchase-sales-inventory/collect-station/purchase-order"),
            meta: { title: '进货单', keepAlive: false }
        }

    ]
};
