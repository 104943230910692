<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getProductManagerList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4" />
				<Button v-if="checkAccessArray('ProductManagerEdit')" size="small"
					@click="onAddProductManager">新增客户经理</Button>
        </div>
        <Row>
            <Col span="24">
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getProductManagerList"
                :highlight="true"
                @on-row-click="onProductManagerSelect" />
            </Col>
        </Row>

        <Modal v-model="productManagerModal.show" title="客户经理信息" :mask-closable="false" width="50" @on-cancel="onCancel">
            <Spin v-if="productManagerModal.loading" size="large" fix></Spin>
            <Form ref="productManagerModal" :model="productManagerModal" :label-width="100">
                <Row>
                    <Col span="12">
                    <FormItem label="用户编码:" prop="userCode">
                        <Input v-model="productManagerModal.userCode" disabled></Input>
                    </FormItem>
                    </Col>
                   <Col span="12">
                   <FormItem label="姓名:" prop="managerName">
                       <Input v-model="productManagerModal.managerName"></Input>
                   </FormItem>
                   </Col>
                </Row>
                <Row>
					<Col span="12">
					<FormItem label="部门:" prop="department">
						<Select v-model="productManagerModal.department" filterable style="width:100%">
						    <!-- <Option v-for="item in departmentList" :value="item.ParaName" :key="item.ParaName">{{ item.ParaName }}</Option> -->
							<Option v-for="item in departmentList" :value="item.ParaName" :label="item.ParaName"
								:key="item.ParaName">
								{{ item.ParaName }}
							</Option>
						</Select>
					</FormItem>
					</Col>
                    <Col span="12">
                    <FormItem label="手机号:" prop="tel" >
                        <Input v-model="productManagerModal.tel"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="12">
                    <FormItem label="头像:" prop="headImg" >
						<template>
						    <Upload action="https://up-z2.qiniup.com/"
						            :data="headImgUploadParams"
						            :format="['jpg','jpeg','png']"
						            :on-format-error ="headImgHandleFormatError"
						            :on-success="headImgHandleUploadSuccess"
						            :before-upload="headImgBeforeUpload"
						            :show-upload-list="showHeadImgUploadList">
						        <Button icon="ios-cloud-upload-outline">上传图片</Button>
						    </Upload>

						    <Row :gutter="16" style="margin-top: 10px;" v-if="productManagerModal.headImg">
						        <Col span="6">
						            <img :src="productManagerModal.headImg" fit="fill" width="150px" height="200px" />
						        </Col>
						    </Row>
						</template>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="微信头像:" prop="weiXinImg">
						<template>
						    <Upload action="https://up-z2.qiniup.com/"
						            :data="weiXinImgUploadParams"
						            :format="['jpg','jpeg','png']"
						            :on-format-error ="weiXinImgHandleFormatError"
						            :on-success="weiXinImgHandleUploadSuccess"
						            :before-upload="weiXinImgBeforeUpload"
						            :show-upload-list="showWeiXinImgUploadList">
						        <Button icon="ios-cloud-upload-outline">上传图片</Button>
						    </Upload>

						    <Row :gutter="16" style="margin-top: 10px;" v-if="productManagerModal.weiXinImg">
						        <Col span="6">
						            <img :src="productManagerModal.weiXinImg" fit="fill" width="150px" height="200px" />
						        </Col>
						    </Row>
						</template>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveProductManager" v-if="this.checkAccessArray('ProductManagerEdit')">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
//import {getManagerDepartmentOptions} from '@/libs/selectOption';
import validate from "@/libs/validate";
import { Modal } from 'view-design';
export default {
    name: 'ProductManagerList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            productManagerModal: {
                show: false,
                loading: false,
                userCode: '',
                managerName: '',
                tel: '',
                headImg: '',
                weiXinImg: '',
                department: '',
                mode: 'add',
            },
			departmentList:[],
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '用户编码', key: "UserCode", align: 'center' },
                    { title: '姓名', key: "ManagerName", align: 'center' },
                    { title: '手机号', key: "Tel", align: 'center' },
					{ title: '头像', key: "HeadImg", align: 'center' ,
					    render: (h, params) => {
					        const imageSrc = params.row.HeadImg;
					        return h('img', {
					            style: {
					                width: '30px',
					                height: '40px'
					            },
					            attrs: {
					                src: imageSrc
					            },
					            on: {
					                click: () => {
					                    Modal.info({
					                        title: '',
					                        closable: true,
					                        okText: '关闭',
					                        render: h => {
					                            return h("img", {
					                                attrs: {
					                                    src: imageSrc,
					                                    style: "margin-top:20px;width: 300px;height: 400px;"
					                                }
					                            });
					                        }
					                    });
					                }
					            }
					        });
					    }
					},
					{ title: '微信', key: "WeiXinImg", align: 'center' ,
					    render: (h, params) => {
					        const imageSrc = params.row.WeiXinImg;
					        return h('img', {
					            style: {
					                width: '30px',
					                height: '40px'
					            },
					            attrs: {
					                src: imageSrc
					            },
					            on: {
					                click: () => {
					                    Modal.info({
					                        title: '',
					                        closable: true,
					                        okText: '关闭',
					                        render: h => {
					                            return h("img", {
					                                attrs: {
					                                    src: imageSrc,
					                                    style: "margin-top:20px;width: 300px;height: 400px;"
					                                }
					                            });
					                        }
					                    });
					                }
					            }
					        });
					    }
					},
                    { title: '部门', key: "Department", align: 'center' },
					{
						title: "操作",
						align: "center",
						width: 120,
						render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'status',
                                        showEdit: this.checkAccessArray('ProductManagerEdit'),
                                        showDelete: this.checkAccessArray('ProductManagerEdit')
                                    },
                                    on: {
                                        handleEdit: dataId => {
                                            this.onModifyProductManager(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteProductManager(dataId);
                                        }
                                    }

                                }
                            );
						}
					}
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '用户编码', type: 'Input', value: '', name: 'UserCode', sql: 'like',clearable: true },
                    { label: '姓名', type: 'Input', value: '', name: 'ManagerName', sql: 'like',clearable: true },
                    { label: '手机号', type: 'Input', value: '', name: 'Tel', sql: 'like',clearable: true },
					{ label: '部门', type: 'Input', value: '', name: 'Department', sql: 'like',clearable: true },
					//{ label: '部门', type: 'Select', value: '', name: 'Department', sql: 'eq', options: [] },
                ]
            },
            selectedProductManager: null,
			showHeadImgUploadList: false,
			headImgUploadParams: {},
			showWeiXinImgUploadList: false,
			weiXinImgUploadParams: {},
        };
    },
    computed: {

    },
    mounted() {
		//this.getManagerDepartmentOptions(this.mainTable.searchForm, 'Department');
        this.getProductManagerList();
		this.getDepartmentList();
    },
    methods: {
		//getManagerDepartmentOptions,
		//头像上传
		headImgHandleFormatError(request, file) {
		    this.$Notice.warning({
		        title: '格式错误',
		        desc: '请上传png、jpg、jpeg格式文件'
		    });
		},
		async headImgBeforeUpload(request) {
		    // **注意这里的参数必须通过属性赋值，如果直接修改整个uploadParams是无效的。**
		    // 上传之前设置上传参数,
		    let filename = Date.now() + Math.random().toString(36).slice(2) + ".png";
		    await api.qiniuToken({}).then(res => {
		        this.headImgUploadParams.token = res.data.token;
		        // 七牛response hash key的值自定义，后面如果需要浏览或者下载都需要这个地址，如果key不定义，那么七牛生成的key === hash的值
		        this.headImgUploadParams.key = filename;
		    });
		},
		headImgHandleUploadSuccess(request, file, list) {
		    this.productManagerModal.headImg = this.imageDomain + file.response.key;

		},
		//微信上传
		weiXinImgHandleFormatError(request, file) {
		    this.$Notice.warning({
		        title: '格式错误',
		        desc: '请上传png、jpg、jpeg格式文件'
		    });
		},
		async weiXinImgBeforeUpload(request) {
		    // **注意这里的参数必须通过属性赋值，如果直接修改整个uploadParams是无效的。**
		    // 上传之前设置上传参数,
		    let filename = Date.now() + Math.random().toString(36).slice(2) + ".png";
		    await api.qiniuToken({}).then(res => {
		        this.weiXinImgUploadParams.token = res.data.token;
		        // 七牛response hash key的值自定义，后面如果需要浏览或者下载都需要这个地址，如果key不定义，那么七牛生成的key === hash的值
		        this.weiXinImgUploadParams.key = filename;
		    });
		},
		weiXinImgHandleUploadSuccess(request, file, list) {
		    this.productManagerModal.weiXinImg = this.imageDomain + file.response.key;

		},

        onProductManagerSelect(row, index) {
            this.selectedProductManager = row;
        },
        onAddProductManager() {
            this.productManagerModal.mode = 'add';
            this.productManagerModal.id = '';
            this.productManagerModal.userCode = '',
            this.productManagerModal.managerName = '',
            this.productManagerModal.tel = '',
            this.productManagerModal.headImg = '',
            this.productManagerModal.weiXinImg = '',
            this.productManagerModal.department = '',
            this.productManagerModal.show = true;
        },
        onModifyProductManager(id) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.productManagerModal.id = b.Id;
            this.productManagerModal.userCode = b.UserCode;
            this.productManagerModal.managerName = b.ManagerName;
            this.productManagerModal.tel = b.Tel;
            this.productManagerModal.headImg = b.HeadImg;
            this.productManagerModal.weiXinImg = b.WeiXinImg;
            this.productManagerModal.department = b.Department;
            this.productManagerModal.mode = 'modify';
            this.productManagerModal.show = true;
        },
        onDeleteProductManager(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '确定删除吗？',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.productManagerDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getProductManagerList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
		//获取部门
		getDepartmentList() {
			let tempSearch = {
				filters: {
					enable: true,
					join: 'and',
					conditions: [{
						name: 'ParaType',
						type: 'eq',
						value: '23'
					}]
				}
			}
			api.parametersList(tempSearch).then(res => {
				if (res.code === 0) {
					this.departmentList = res.data.records;
				}
			});
		},

        onSaveProductManager() {
			if (!this.productManagerModal.managerName) {
				return this.showWarningModal('姓名不能为空！');
			}
			if (!this.productManagerModal.department) {
				return this.showWarningModal('部门不能为空！');
			}
			if (!this.productManagerModal.tel) {
				return this.showWarningModal('手机号不能为空！');
			}
			if (this.productManagerModal.tel && !validate.validatePhoneNumber(
					this.productManagerModal.tel)) {
				return this.showWarningModal('请输入正确的手机号码！');
			}
			if (!this.productManagerModal.headImg) {
				return this.showWarningModal('头像不能为空！');
			}
			if (!this.productManagerModal.weiXinImg) {
				return this.showWarningModal('微信二维码不能为空！');
			}
            this.productManagerModal.loading = true;
            api.productManagerSave(this.productManagerModal).then(res => {
                this.productManagerModal.loading = false;
                if (res.code === 0) {
                    this.productManagerModal.show = false;
                    this.getProductManagerList();
                    this.showSuccessModal();
                }
            });
        },
        getProductManagerList() {
            this.mainTable.tableLoading = true;
            api.productManagerList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedProductManager = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
		//关闭弹窗重置表单
		onCancel(){
			this.$refs.productManagerModal.resetFields();
			this.productManagerModal.show = false;
		}
    }
};
</script>
<style>
</style>


