<template>
    <Modal v-model="passwordModel.show"
        title="重置密码"
        :mask-closable="false">
        <Spin v-if="passwordModel.loading"
            size="large"
            fix></Spin>
        <Form ref="passwordModelForm"
            :label-width="80">
            <Row>
                <Col span="12">
                <FormItem label="新密码:"
                    required>
                    <Input v-model="passwordModel.password"
                        type="password"></Input>
                </FormItem>
                </Col>
                <Col span="12">
                <FormItem label="确认密码:"
                    required>
                    <Input v-model="passwordModel.passwordConfirm"
                        type="password"></Input>
                </FormItem>
                </Col>
            </Row>
        </Form>
        <div slot="footer">
            <Button type="info"
                @click="resetPassword">保存</Button>
        </div>
    </Modal>
</template>

<script>
import api from '@/api/api';

export default {
    name: 'ResetUserPassWordModal2',
    components: {
    },
    data() {
        return {
            passwordModel: {
                show: false,
                loading: false,
                userId: '',
                password: '',
                passwordConfirm: ''
            }
        };
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        showModal(userId) {
            this.passwordModel.userId = userId;
            this.passwordModel.password = '';
            this.passwordModel.passwordConfirm = '';
            this.passwordModel.show = true;
        },
        resetPassword() {
            this.passwordModel.loading = true;
            api.userResetPassword(this.passwordModel).then(res => {
                this.passwordModel.loading = false;
                if (res.code === 0) {
                    this.passwordModel.show = false;
                    this.showSuccessModal();
                }
            });
        }
    }
};
</script>
<style>
</style>
