import getPostRequest from './base-api';

export default {
    // 商品编码打印列表
    productCodePrintList(data) {
        return getPostRequest(data, 'api/product/productPrintList');
    },
    // 配货单打印列表
    orderDeliveryList(data) {
        return getPostRequest(data, 'api/order/orderDeliveryList');
    },
    // 配货单打印
    orderDeliveryPrint(data) {
        return getPostRequest(data, 'api/order/orderDeliveryPrint');
    },
    // 配货单打印修改打印次数
    orderDeliveryPrintNum(data) {
        return getPostRequest(data, 'api/order/orderDeliveryPrintNum');
    },
    // 装箱单打印列表
    orderBoxesList(data) {
        return getPostRequest(data, 'api/order/orderBoxesList');
    },
    // 贴箱单打印列表
    orderPasteBoxList(data) {
        return getPostRequest(data, 'api/order/orderPasteBoxList');
    },
    //获取供应商供货产品
    getSupplyProduct(data) {
        return getPostRequest(data, 'api/purchase/supplyProduct');
    },
    // 获取供货产品
    getPurchaseProduct(data) {
        return getPostRequest(data, 'api/purchase/purchaseProduct');
    },
    // 获取供货明细列表
    getPurchaseDetailList(data) {
        return getPostRequest(data, 'api/purchaseDetail/purchaseDetailList');
    },
    // 获取供货打印列表
    getPurchaseDetailPrintList(data) {
        return getPostRequest(data, 'api/purchaseDetail/purchaseDetailPrintList');
    },
    // 添加供货产品
    purchaseDetailSave(data) {
        return getPostRequest(data, 'api/purchaseDetail/purchaseDetailSave');
    },
    //删除供货产品
    purchaseDetailDelete(data) {
        return getPostRequest(data, 'api/purchaseDetail/purchaseDetailDelete');
    },
    // 提交供货产品
    purchaseSave(data) {
        return getPostRequest(data, 'api/purchase/purchaseSave');
    },
    // 查看供货列表
    purchaseList(data) {
        return getPostRequest(data, 'api/purchase/purchaseList');
    },
    // 获取预售列表
    getProductPresellList(data) {
        return getPostRequest(data, 'api/productPresell/productPresellList');
    },
    // 新增保存预售产品
    productPresellSave(data) {
        return getPostRequest(data, 'api/productPresell/productPresellSave');
    },
    // 删除预售产品
    productPresellDelete(data) {
        return getPostRequest(data, 'api/productPresell/productPresellDelete');
    },
    // 获取产品价格
    productPriceList(data) {
        return getPostRequest(data, 'api/productPrice/productPriceList');
    },
    productPriceInfo(data) {
        return getPostRequest(data, 'api/productPrice/productPriceInfo');
    },
    // 保存产品价格
    productPriceSave(data) {
        return getPostRequest(data, 'api/productPrice/productPriceSave');
    },
    // 保存产品三级价格
    productLevelPriceSave(data) {
        return getPostRequest(data, 'api/productPrice/productLevelPriceSave');
    },
    // 获取现售列表
    getProductSaleList(data) {
        return getPostRequest(data, 'api/productSale/productSaleList');
    },
    // 保存现售数据
    productSaleSave(data) {
        return getPostRequest(data, 'api/productSale/productSaleList');
    },
    // 未提交订单列表详情
    unSubmitOrderList(data) {
        return getPostRequest(data, 'api/orderDetail/unSubmitOrderList');
    },
    // 提交订单列表详情
    submitedOrderList(data) {
        return getPostRequest(data, 'api/orderDetail/submitedOrderList');
    },
    //预售订单明细列表详情
    presaleOrderList(data) {
        return getPostRequest(data, 'api/orderDetail/presaleOrderList');
    },
    //预售订单汇总列表详情
    preOrderSumList(data) {
        return getPostRequest(data, 'api/orderDetail/preOrderSumList');
    },
    // 预订单列表
    bookOrderList(data) {
        return getPostRequest(data, 'api/bookOrder/bookOrderList');
    },
    // 现售保存
    curSaleSave(data) {
        return getPostRequest(data, 'api/productSale/productSaleSave');
    },
    // 竞购单列表
    getOrderBidQuery(data) {
        return getPostRequest(data, 'api/orderBid/orderBidQuery');
    },
    // 获取订单主表列表  { submitStatus:0-未提交;1-已提交}
    getOrderList(data) {
        return getPostRequest(data, 'api/order/orderList');
    },
	handSettleAccountsWithHaveOrder(data) {
	    return getPostRequest(data, 'api/order/handSettleAccountsWithHaveOrder');
	},
    // 销售打印
    settlementPrintList(data) {
        return getPostRequest(data, 'api/settlement/settlementPrintList');
    },
    // 销售打印条码
    settlementPrintBarCodeList(data) {
        return getPostRequest(data, 'api/settlement/settlementPrintBarCodeList');
    },
    //订单投诉
    getOrderComplainList(data) {
        return getPostRequest(data, 'api/orderComplain/orderComplainList');
    },
    //订单投诉审核
    orderComplainReviewList(data) {
        return getPostRequest(data, 'api/orderComplain/orderComplainReviewList');
    },
    //订单投诉明细
    orderComplainInfo(data) {
        return getPostRequest(data, 'api/orderComplain/orderComplainInfo');
    },
    //订单投诉处理退回
    orderComplainReturn(data) {
        return getPostRequest(data, 'api/orderComplain/orderComplainReturn');
    },
    //订单投诉处理确认
    orderComplainConfirmSave(data) {
        return getPostRequest(data, 'api/orderComplainConfirm/orderComplainConfirmSave');
    },
    //订单投诉审核确认
    orderComplainConfirmReview(data) {
        return getPostRequest(data, 'api/orderComplainConfirm/review');
    },
    //订单投诉确认列表
    orderComplainConfirmList(data) {
        return getPostRequest(data, 'api/orderComplainConfirm/orderComplainConfirmList');
    },
    // 获取入位管理列表  { isInPosition:0-未入位；1-已入位}
    getInPositionList(data) {
        return getPostRequest(data, 'api/orderDetail/inPositionList');
    },
    // 获取入位管理列表详情  {orderId, isInPosition:0-未入位；1-已入位}
    getInPositionDetail(data) {
        return getPostRequest(data, 'api/orderDetail/inPositionDetail');
    },
    // 获取入框管理列表  { isInBox:0-未入位；1-已入位}
    getInBoxList(data) {
        return getPostRequest(data, 'api/orderDetail/inBoxList');
    },
    // 获取入位管理列表详情  {orderId, isInBox:0-未入位；1-已入位}
    getInBoxDetail(data) {
        return getPostRequest(data, 'api/orderDetail/inBoxDetail');
    },
    // 获取供货明细
    getPurchaseDetailSupplyList(data) {
        return getPostRequest(data, 'api/purchaseDetail/supplyList');
    },
    purchaseUnSubmitList(data) {
        return getPostRequest(data, 'api/purchaseDetail/unSubmitDetailList');
    },
    // 批量保存供货明细
    purchaseDetailSupplySave(data) {
        return getPostRequest(data, 'api/purchaseDetail/supplySave');
    },
    // 获取预订单明细
    getBookDetailList(data) {
        return getPostRequest(data, 'api/bookOrder/bookDetailList');
    },
    // 获取预订单明细
    saveOrderCountConfirm(data) {
        return getPostRequest(data, 'api/bookOrderDetail/orderCountConfirm');
    },
    // 保存阶梯定价
    saveGradePrice(data) {
        return getPostRequest(data, 'api/productSale/gradePriceSave');
    },
    getOrderBidDetail(data) {
        return getPostRequest(data, 'api/orderBid/orderBidDetail');
    },
    //缺货单
    orderLackList(data) {
        return getPostRequest(data, 'api/orderLack/orderLackList');
    },
    //缺货单明细
    orderLackDetailList(data) {
        return getPostRequest(data, 'api/orderLackDetail/orderLackDetailList');
    },
    //缺货单审核确认
    orderLackConfirm(data) {
        return getPostRequest(data, 'api/orderLack/orderLackConfirm');
    },
    //获取现售列表
    productSaleList(data) {
        return getPostRequest(data, 'api/productSale/productSaleList');
    },
    //加单订购
    addOrder(data) {
        return getPostRequest(data, 'api/productSale/addOrder');
    },
    //加单提交
    submitAddOrder(data) {
        return getPostRequest(data, 'api/productSale/submitAddOrder');
    },
    //加单列表
    addOrderList(data) {
        return getPostRequest(data, 'api/orderDetail/addOrderList');
    },
    //加单修改
    addOrderSave(data) {
        return getPostRequest(data, 'api/orderDetail/addOrderSave');
    },
    //获取订单投诉图表数据
    orderComplainList(data) {
        return getPostRequest(data, 'api/order/orderComplainList');
    },
    //大数据分析-投诉排行榜接口
    orderComplainRankingList(data) {
        return getPostRequest(data, 'api/order/orderComplainRankingList');
    },
    //获取订单缺货图表数据
    orderLackStatList(data) {
        return getPostRequest(data, 'api/analysis/orderLackList');
    },
    //大数据分析-缺货排行榜接口
    orderLackRankingList(data) {
        return getPostRequest(data, 'api/analysis/orderLackRankingList');
    },
    //获取订单销售统计图表数据
    orderSaleList(data) {
        return getPostRequest(data, 'api/analysis/orderSaleList');
    },
    //【销售汇总】按客户汇总
    saleStatMember(data) {
        return getPostRequest(data, 'api/stat/saleStatMember');
    },
    //【销售汇总】按客户汇总-详情
    saleStatMemberDetail(data) {
        return getPostRequest(data, 'api/stat/saleStatMemberDetail');
    },
    //【销售汇总】按加盟项目汇总
    saleStatProject(data) {
        return getPostRequest(data, 'api/stat/saleStatProject');
    },
    //【销售汇总】按加盟项目汇总-详情
    saleStatProjectDetail(data) {
        return getPostRequest(data, 'api/stat/saleStatProjectDetail');
    },
    //【销售汇总】按集货站汇总
    saleStatCollectStation(data) {
        return getPostRequest(data, 'api/stat/saleStatCollectStation');
    },
    //【销售汇总】按集货站汇总-详情
    saleStatCollectStationDetail(data) {
        return getPostRequest(data, 'api/stat/saleStatCollectStationDetail');
    },
    //【销售汇总】按大类汇总
    saleStatMainCategory(data) {
        return getPostRequest(data, 'api/stat/saleStatMainCategory');
    },
    //【销售汇总】按大类汇总-详情
    saleStatMainCategoryDetail(data) {
        return getPostRequest(data, 'api/stat/saleStatMainCategoryDetail');
    },
    //【销售汇总】按小类汇总
    saleStatSubCategory(data) {
        return getPostRequest(data, 'api/stat/saleStatSubCategory');
    },
    //【销售汇总】按小类汇总-详情
    saleStatSubCategoryDetail(data) {
        return getPostRequest(data, 'api/stat/saleStatSubCategoryDetail');
    },
    //【销售汇总】按省份
    saleStatProvince(data) {
        return getPostRequest(data, 'api/stat/saleStatProvince');
    },
    //【销售汇总】按城市
    saleStatCity(data) {
        return getPostRequest(data, 'api/stat/saleStatCity');
    },
    //【销售汇总】按城市-详情
    saleStatCityDetail(data) {
        return getPostRequest(data, 'api/stat/saleStatCityDetail');
    },
    //【销售汇总】按服务部门
    saleServiceDept(data) {
        return getPostRequest(data, 'api/stat/saleServiceDept');
    },
    //【销售汇总】按服务部门
    saleServiceDeptDetail(data) {
        return getPostRequest(data, 'api/stat/saleServiceDeptDetail');
    },
    //【现售批量保存】
    productSaleBatchSave(data) {
	    return getPostRequest(data, 'api/productSale/productSaleBatchSave');
    },
    orderListForComplain(data){
        return getPostRequest(data, 'api/order/orderListForComplain');
    },
    orderDetailForComplain(data){
        return getPostRequest(data, 'api/orderDetail/orderDetailForComplain');
    },
    adminOrderComplainSave(data){
        return getPostRequest(data, 'api/orderComplain/adminOrderComplainSave');
    },
    // 缺货退款列表
    orderReturnList(data){
	    return getPostRequest(data, 'api/order/orderReturnList');
    },
    // 缺货退款详情
    orderDetailSupplyList(data){
	    return getPostRequest(data, 'api/orderDetail/orderDetailSupplyList');
    },
    //退货退款确认
    orderReturnConfirmation(data){
        return getPostRequest(data, 'api/order/Confirmation');
    },
    // 缺货确认提交
    saveLackSubmit(data){
	    return getPostRequest(data, 'api/orderDetailSupply/lackSubmit');
    },
    // 订单评价
    orderEvaluateListByProduct(data){
        return getPostRequest(data, 'api/orderEvaluate/orderEvaluateListByProduct');
    },
    // 订单评价删除
    orderEvaluateDeleteById(data){
        return getPostRequest(data, 'api/orderEvaluate/orderEvaluateDeleteById');
    },
    // 获取赔付部门
    getCompensateDepartment(data){
        return getPostRequest(data, 'api/orderComplainConfirm/getCompensateDepartment');
    }

};
