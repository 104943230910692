import Vue from 'vue';
import Router from 'vue-router';
import AppHome from '@/view/home/home';
import MyHome from '@/view/MyHome';

import UserLogin from '@/view/system-management/user/user-login';

// 基础信息
import basicInfo from './modules/info-management';

// 财务管理
import financialManagement from './modules/financial-management';

//进销存管理
import purchaseSalesInventory from './modules/purchase-sales-inventory';

// 扫码管理
import scanManagement from './modules/scan-management';

// 销售管理
import salesManagement from './modules/sales-management';

// 集货站管理
import containerManagement from './modules/container-management';

// 物流管理
import logisticsManagement from './modules/logistics-management';

// 系统管理
import systemManagement from './modules/system-management';

Vue.use(Router);

const LOGIN_PAGE_NAME = 'UserLogin';

const router = new Router({
    routes: [
        // 用户登录
        { path: '/system-management/user/user-login', name: LOGIN_PAGE_NAME, component: UserLogin, meta: { title: '用户登录', keepAlive: true } },
        // 首页
        {
            path: '/',
            name: 'MyHome',
            redirect: '/home',
            component: MyHome,
            children: [
                { path: 'home', name: 'AppHome', component: AppHome, meta: { title: '首页', keepAlive: true } }
            ]
        },
        basicInfo,
        financialManagement,
        purchaseSalesInventory,
        scanManagement,
        salesManagement,
        containerManagement,
        logisticsManagement,
        systemManagement
    ]
});

router.beforeEach((to, from, next) => {
    let vm = new Vue();
    const userToken = vm.getUserToken();
    const userId = vm.getUserId();

    if (!userToken && to.name !== LOGIN_PAGE_NAME) {
        // 未登录且要跳转的页面不是登录页
        next({
            name: LOGIN_PAGE_NAME // 跳转到登录页
        });
    }
    else if (!userToken && to.name === LOGIN_PAGE_NAME) {
        // 未登陆且要跳转的页面是登录页
        next(); // 跳转
    }
    else if (userToken && to.name === LOGIN_PAGE_NAME) {
        // 已登录且要跳转的页面是登录页
        next({
            name: 'AppHome' // 跳转到homeName页
        });
    }
    else {
        next();
    }
});

export default router;
