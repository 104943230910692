import MyHome from '@/view/MyHome';

// 基础信息
import ProductList from '@/view/base-info/product/product-list';
import SupplierList from '@/view/base-info/supplier/supplier';
import CollectStationList from '@/view/base-info/collect-station';
import BrandList from '@/view/base-info/product/brand-list';
import ActivityList from '@/view/base-info/activity';
import MemberList from '@/view/base-info/member';
import AddressList from '@/view/base-info/address';
import BoxInfo from '@/view/base-info/box-info';
import Memberlnvoicelnfo from '@/view/base-info/invoice';
import Blacklist from '@/view/base-info/blacklist';
import CateList from '@/view/base-info/product/cate-list';
import UnitList from '@/view/base-info/product/color-list';
import ActiveSupplierList from '@/view/base-info/supplier/supplier-active';
import ProductStandardList from '@/view/base-info/product-standard/product-standard';
import ProductApprovalList from '@/view/base-info/product-approval/approval-list';
import ManagerList from '@/view/base-info/manager';
import ProductStandardHistoryList from '@/view/base-info/product-standard/product-standard-history';
import ShareProductList from '@/view/base-info/share-product/share-product';
import ProductTagList from '@/view/base-info/product-tag/product-tag';
export default {
    path: '/basic',
    name: 'basicInfo',
    component: MyHome,
    meta: { title: '基础信息', keepAlive: true },
    children: [
        // 基础信息
        { path: 'product-list', name: 'ProductList', component: ProductList, meta: { title: '产品管理', keepAlive: true } },
        { path: 'supplier-list', name: 'SupplierList', component: SupplierList, meta: { title: '供应商管理', keepAlive: true } },
        { path: 'collection-station-list', name: 'CollectStationList', component: CollectStationList, meta: { title: '集货站管理', keepAlive: true } },
        { path: 'brand-list', name: 'BrandList', component: BrandList, meta: { title: '品牌管理', keepAlive: true } },
        { path: 'activity-list', name: 'ActivityList', component: ActivityList, meta: { title: '活动管理', keepAlive: true } },
        { path: 'member-list', name: 'MemberList', component: MemberList, meta: { title: '会员管理', keepAlive: true } },
        { path: 'address-list', name: 'AddressList', component: AddressList, meta: { title: '收货地址管理', keepAlive: true } },
        { path: 'boxInfo-list', name: 'BoxInfo', component: BoxInfo, meta: { title: '箱面信息', keepAlive: true } },
        { path: 'invoiceList', name: 'memberlnvoicelnfo', component: Memberlnvoicelnfo, meta: { title: '发票信息审核', keepAlive: true } },
        { path: 'blacklist', name: 'Blacklist', component: Blacklist, meta: { title: '黑名单', keepAlive: true } },
        { path: 'cate-list', name: 'CateList', component: CateList, meta: { title: '产品分类', keepAlive: true } },
        { path: 'color-list', name: 'UnitList', component: UnitList, meta: { title: '产品颜色', keepAlive: true } },
        { path: 'supplier-active-list', name: 'ActiveSupplierList', component: ActiveSupplierList, meta: { title: '有效供应商', keepAlive: true } },
        { path: 'product-standard', name: 'ProductStandardList', component: ProductStandardList, meta: { title: '产品标准', keepAlive: true } },
        { path: 'approval-list', name: 'ProductApprovalList', component: ProductApprovalList, meta: { title: '产品审核', keepAlive: true } },
        { path: 'manager-list', name: 'ManagerList', component: ManagerList, meta: { title: '客户经理管理', keepAlive: true } },
        { path: 'product-standard-history', name: 'ProductStandardHistoryList', component: ProductStandardHistoryList, meta: { title: '产品标准历史记录', keepAlive: true } },
        { path: 'share-product', name: 'ShareProductList', component: ShareProductList, meta: { title: '我看花管理', keepAlive: true } },
        { path: 'product-tag-list', name: 'ProductTagList', component: ProductTagList, meta: { title: '产品标签', keepAlive: true } }
    ]
};
