<template>
    <Dropdown ref="dropdown" :placement="placement" style="max-height:324px">
        <a class="drop-menu-a">
            <Icon :type="parentItem.icon || defaultIcon" :size="iconSize" />
        </a>
        <DropdownMenu ref="dropdown" slot="list">
            <template v-for="child in children">
                <collapsed-menu v-if="showChildren(child)" :key="`drop-${child.name}`"></collapsed-menu>
                <DropdownItem v-else :key="`drop-${child.name}`" :name="child.name">
                    <router-link class="drop-menu-a" :to="child.link">
                        <span class="menu-title">{{ child.name }}</span>
                    </router-link>
                </DropdownItem>
            </template>
        </DropdownMenu>
    </Dropdown>
</template>
<script>

export default {
    name: 'CollapsedMenu',
    props: {
        parentItem: {
            type: Object,
            default: () => {}
        },
        iconSize: {
            type: Number,
            default: 24
        }
    },
    data() {
        return {
            placement: 'right',
            defaultIcon: 'md-settings'
        };
    },
    methods: {
        showChildren(item) {
            return item.children && (item.children.length > 1 || (item.meta && item.meta.showAlways));
        },
        handleMousemove(event, children) {
            const { pageY } = event;
            const height = children.length * 38;
            const isOverflow = pageY + height < window.innerHeight;
            this.placement = isOverflow ? 'right-start' : 'right-end';
        }
    },
    computed: {
        children(){
            return this.parentItem.children;
        }
    }
};
</script>
