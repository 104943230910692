<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card ref="searchCard"
                :form="mainTable.searchForm"
                :table-load-event="getProductList"
                :table-search="mainTable.tableSearch"
                :labelWidth="60"
                :defaultFieldWidth="150"
                :defaultSelectFieldWidth="200"
                @changeSelectContent="changeSelectContent"/>

            <Button v-if="checkAccessArray('ProductEdit')"
                size="small"
                @click="onAddProduct">新增商品</Button>

        </div>

        <div>
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getProductList"
                :highlight="true"
                :showCollapse="true"
                @on-row-click="onProductSelect" />
        </div>

        <ProductModal ref="productModal" />
        <ProductAndSupplier ref="productAndSupplier" />
        <SalesPromotionAndProduct ref="salesPromotionAndProduct" />
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import { dateTimeRender, currencyRender } from '@/libs/tableRowRender';
import { setSelectOptions, getBrandOptions } from '@/libs/selectOption';
import BaseTableFunction from '@/components/base-table-function.vue';
import ProductModal from './product-modal.vue';
import ProductAndSupplier from './product-and-supplier.vue';
import SalesPromotionAndProduct from './sales-promotion-and-product.vue';
import { Modal } from 'view-design';
export default {
    name: 'ProductList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction,
        ProductModal,
        ProductAndSupplier,
        SalesPromotionAndProduct
    },
    data() {
        return {
            mainTable: {
                tableColumns: [
                    { title: '图片', key: "PicPath", width: 40, align: 'center',
                        render: (h, params) => {
                            const imageSrc = this.imageDomain + params.row.PicPath;
                            return h('img', {
                                style: {
                                    width: '20px',
                                    height: '20px'
                                },
                                attrs: {
                                    src: imageSrc
                                },
                                on: {
                                    click: () => {
                                        Modal.info({
                                            title: '',
                                            closable: true,
                                            okText: '关闭',
                                            render: h => {
                                                return h("img", {
                                                    attrs: {
                                                        src: imageSrc,
                                                        style: "margin-top:20px;width: 350px;height: 350px;"
                                                    }
                                                });
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    },
                    { title: '编号', key: "Code", width: 80, align: 'center', excel: true },
                    { title: '名称', key: "Name", tooltip: true, width: 130, align: 'left', excel: true },
                    { title: '品牌', key: "Brand", tooltip: true, width: 80, align: 'left', excel: true },
                    { title: '产品经理', key: "JoinName", tooltip: true, width: 100, align: 'left', excel: true },
                    { title: '大类', key: "MainCategory", width: 60, align: 'center', excel: true },
                    { title: '小类', key: "SubCategory", tooltip: true, width: 80, align: 'center', excel: true },
                    { title: '产品标准', key: "ProductStandardCode",width: 80, align: 'center', excel: true },
                    { title: '等级', key: "Grade", tooltip: true, width: 60, align: 'center', excel: true },
                    { title: '包装', key: "PackCount", tooltip: true, width: 60, align: 'center', excel: true },
                    { title: '花色', key: "Color", width: 60, align: 'center', excel: true },
                    { title: '单位', key: "Unit", width: 40, align: 'center', excel: true },
                    { title: '体积(m³)', key: "Volumn", width: 50, align: 'center', excel: true },
					{ title: '重量(kg)', key: "Weight", width: 50, align: 'center', excel: true },
                    {
                        title: "操作",
                        align: "center",
                        width: 160,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'RecordStatus',
                                        showInfo: true,
                                        showEdit: (this.checkAccessArray('ProductEdit') && this.checkCreator(params.row.Creater)) || params.row.ProductManager,
                                        showCustom1: this.checkAccessArray('ProductAndSupplierEdit'),
                                        customText1: '供应商',
                                        showCustom2: this.checkAccessArray('SalesPromotionAndProductEdit'),
                                        customText2: '关联促销活动',
                                        customBackgroundColor2: params.row.isSalesProduct === 1 ? '#dad198' : '#ffffff',
                                        showCustom3: this.checkAccessArray('ProductDisable'),
                                        customText3: params.row.IsFlag == 1 ? '禁用' : '启用',
                                        customBackgroundColor3: params.row.IsFlag === 0 ? '#dad198' : '#ffffff',
                                    },
                                    on: {
                                        handleInfo: dataId => {
                                            this.onModifyProduct(dataId, true);
                                        },
                                        handleEdit: dataId => {
                                            this.onModifyProduct(dataId, false);
                                        },
                                        handleCustom1: dataId => {
                                            this.onRelationProduct(dataId);
                                        },
                                        handleCustom2: dataId => {
                                            this.onRelationSalesPromotion(dataId);
                                        },
                                        handleCustom3: dataId => {
                                            this.onDisableProduct(dataId, params.row.IsFlag);
                                        }
                                    }

                                }
                            );
                        }
                    },
                    { title: '状态', key: "IsFlag", width: 40, align: 'center',
                        render: (h, params) => {
                            let text = params.row.IsFlag == 1 ? '启用' : '禁用';
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    { title: '创建时间', key: "CreateTime", tooltip: true, width: 120, align: 'left', excel: true },
                    { title: '创建人', key: "ProductCreater", tooltip: true, width: 50, align: 'left', excel: true }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '编码/品名', type: 'Input', value: '', name: 'concat(T_Product.Name,T_Product.Code)', sql: 'like', labelWidth:80, fieldWidth:200 },
                    { label: '产品标准', type: 'Input', value: '', name: 'T_Product.ProductStandardCode', sql: 'eq', labelWidth:80, fieldWidth:200 },
                    { label: '大类', type: 'Select', value: '', name: 'MainCategory', sql: 'eq', options: [], clearable: true },
                    { label: '小类', type: 'Select', value: '', name: 'SubCategory', sql: 'eq', options: [], clearable: true },
                    { label: '品牌', type: 'Select', value: '', name: 'BrandCode', sql: 'eq', options: [], clearable: true }
                ]
            },
            selectedProduct: null,
            mainCateList: [],
            subCateList: [],
            subCateFilterList: []
        };
    },
    computed: {

    },
    mounted() {
        this.getProductList();
        this.getCategoryList();
        this.getBrandOptions(this.mainTable.searchForm, 'BrandCode');
    },
    methods: {
        setSelectOptions,
        getBrandOptions,
        onProductSelect(row, index) {
            this.selectedProduct = row;
        },
        onAddProduct() {
            this.$refs.productModal.showModal();
        },
        onModifyProduct(id, readOnly = false) {
            let pp = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.$refs.productModal.showModal(pp, readOnly);
        },
        onDisableProduct(id, isFlag) {
            let tips = isFlag == 1 ? '确定禁用吗？' : '确定启用吗？';
            this.$Modal.confirm({
                title: '确认',
                content: tips,
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    let isEnable = isFlag == 1 ? 0 : 1;
                    api.productDisable({ id: id, isEnable: isEnable }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getProductList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onExportProduct() {
            this.mainTable.tableSearch.excelExport.enable = true;
            this.mainTable.tableSearch.excelExport.columns = this.mainTable.tableColumns
                .filter(col => col.excel)
                .map(col => {
                    return {
                        Key: col.key,
                        Title: col.title
                    };
                });
            this.mainTable.tableLoading = true;
            api.productList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableSearch.excelExport.enable = false;
                if (res.code === 0) {
                    let url = api.excelServerUrl + res.data.fileName;
                    this.$downLoadFile(url);
                }
                this.mainTable.tableLoading = false;
            });
        },
        getProductList() {
            this.mainTable.tableLoading = true;
            api.productList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedProduct = null;

                    let salesProductIds = res.data.salesProductIds;
                    res.data.records.forEach(function (item, index) {
                        if (salesProductIds && salesProductIds.includes(item.Id)) {
                            item.isSalesProduct = 1;
                        }
                        else {
                            item.isSalesProduct = 0;
                        }
                    });
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        onRelationProduct(productId) {
            let product = this.mainTable.tableData.find(e => {
                return e.Id === productId;
            });

            this.$refs.productAndSupplier.showModal(product);
        },
        getCategoryList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.productCategoryList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.mainCateList = res.data.records.filter(c=> c.ParentId == null);
                    this.subCateList = res.data.records.filter(c=> c.ParentId !== null);
                    this.setSelectOptions(this.mainTable.searchForm, 'MainCategory', this.mainCateList, 'Name', 'Name');
                }
            });
        },
        changeSelectContent(e) {

            if(e.name === 'MainCategory') {
                let mainCate = this.mainCateList.find(item => item.Name === e.value);
                this.subCateFilterList = [];
                if(mainCate){
                    this.subCateFilterList = this.subCateList.filter(v => {
                        return v.ParentId === mainCate.Id;
                    });
                }
                if(this.subCateFilterList.length == 0) {
                    let subCate = this.mainTable.searchForm.find(item => item.name === 'SubCategory');
                    subCate.value = undefined;
                }
                this.setSelectOptions(this.mainTable.searchForm, 'SubCategory', this.subCateFilterList, 'Name', 'Name');
            }
            search.globalSetFilterWithSearchForm(this.mainTable.tableSearch, this.$refs.searchCard.filterForm());
            this.getProductList();
        },
        onRelationSalesPromotion(productId) {
            let product = this.mainTable.tableData.find(e => {
                return e.Id === productId;
            });

            this.$refs.salesPromotionAndProduct.showModal(product);
        }
    }
};
</script>
<style>
</style>
