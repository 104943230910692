import { render, staticRenderFns } from "./complaint.vue.js?vue&type=template&id=df365ad0!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./complaint.vue?vue&type=template&id=df365ad0"
import script from "./complaint.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./complaint.vue?vue&type=script&lang=js"
export * from "./complaint.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./complaint.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports