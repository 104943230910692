<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card ref="searchCard" :form="mainTable.searchForm" :table-load-event="getSupplierList"
                :table-search="mainTable.tableSearch" />
        </div>
        <div>
            <pagination-table :columns="mainTable.tableColumns" :data="mainTable.tableData"
                :loading="mainTable.tableLoading" :table-search="mainTable.tableSearch"
                :table-load-event="getSupplierList" :highlight="true" :windowHeight="true" />
        </div>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import { setSelectOptions } from '@/libs/selectOption';
import { getThisMonthStart, getThisMonthEnd } from '@/libs/logic';
export default {
    name: 'SupplierList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'left' },
                    { title: '编码', key: "Code", align: 'left' },
                    { title: '名称', key: "Name", align: 'left' },
                    { title: '类型', key: "SupplierType", align: 'left' },
                    { title: '集货站', key: "CollectName", align: 'left' },
                    { title: '加盟项目', key: "JoinName", align: 'left' },
                    { title: '销售金额', key: "OrderMoney", align: 'left' },
                    { title: '未供货天数', key: "DayCount", align: 'left',
                        render: (h, params) => {
                            let count = params.row.DayTotal - params.row.DayCount;
                            return h('div', [
                                h('span', {
                                    style: {
                                        color: 'red'
                                    }
                                }, count)
                            ]);
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '', type: 'RadioGroup', value: '1', name: 'ActiveType', sql: 'in', options: [{ key: '有效供应商', value: '1' }, { key: '非有效供应商', value: '2' }], fieldWidth: 206, labelWidth: 0 },
                    { label: '日期', type: 'Daterange', value: [], name: 'Daterange', sql: 'dateBetween', fieldWidth: '280' },
                    { label: '编码/名称', type: 'Input', value: '', name: 'concat(T_Supplier.Code,T_Supplier.Name)', sql: 'like', labelWidth: 100 },
                    { label: '类型', type: 'Select', value: '', name: 'T_Supplier.SupplierType', sql: 'eq', options: [] }
                ],
                supplierTypes: []
            }
        };
    },
    computed: {
    },
    mounted() {
        this.setDefaultFilters();
        this.getSupplierList();
        this.getSupplierTypeList();
    },
    methods: {
        setSelectOptions,
        getThisMonthStart,
        getThisMonthEnd,
        getSupplierList() {
            let searchFormItem = this.mainTable.searchForm.find(e => {
                return e.name === 'Daterange';
            });

            let curr = searchFormItem.value;
            this.mainTable.tableSearch.filters.conditions.push({ name: 'StartDate', type:'eq', value: this.formatDateTime(curr[0]) });
            this.mainTable.tableSearch.filters.conditions.push({ name: 'EndDate', type:'eq', value: this.formatDateTime(curr[1]) });

            let activeType = this.mainTable.tableSearch.filters.conditions.find(e => {
                return e.name === 'ActiveType';
            });

            if (!activeType) {
                this.mainTable.tableSearch.filters.conditions.push({ name: 'ActiveType', type:'eq', value: '1' });
            }

            this.mainTable.tableLoading = true;
            api.supplierActiveList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        getSupplierTypeList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersSupplierTypeList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.supplierTypes = res.data.records;
                    this.setSelectOptions(this.mainTable.searchForm, 'T_Supplier.SupplierType', this.supplierTypes, 'ParaName', 'ParaName');
                }
            });
        },
        setDefaultFilters(){
            let daterange = this.mainTable.searchForm.find(e => {
                return e.name === 'Daterange';
            });

            if (daterange) {
                daterange.value = [this.getThisMonthStart(), this.getThisMonthEnd()];
            }
        },
        formatDateTime(dateStr) {
            const date = new Date(dateStr);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hour = date.getHours();
            const minute = date.getMinutes();
            const second = date.getSeconds();
            return `${year}-${this.pad(month)}-${this.pad(day)}`;
        },
        pad(num) {
            return num.toString().padStart(2, '0');
        }


    }
};
</script>
<style></style>
