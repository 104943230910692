<template>
  <div class="main-body bg">
    <div class="main-container bg">
      <div class="loginForm">
        <p class="title">缤纷鲜花网</p>
        <div>
          <Form ref="formInline" :model="loginForm" :rules="ruleInline">
            <FormItem prop="userName">
              <Input prefix="md-person" v-model="loginForm.userName" placeholder="用户名"/>
            </FormItem>
            <FormItem prop="password">
              <Input prefix="md-lock" type="password" v-model="loginForm.password" placeholder="账户密码"/>
            </FormItem>
            <FormItem>
              <Button :loading="loading" class="onLogin" type="primary" @click="onLogin('formInline')">登录</Button>
            </FormItem>
            <FormItem style="display: flex; align-items: flex-end; justify-content: flex-end">
              <Button
                  style="background-color: #ffff; border: none; color: #2d8cf0; font-size: 14px; padding: 0px"
                  to="http://site.brightenflower.cn:81/Mysys/Login.aspx"
                  target="_blank"
                  long
                  type="primary"
              >前往旧版
              </Button
              >
            </FormItem>
          </Form>
        </div>
        <span class="version">版本号：1.0.0</span>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/api';

export default {
  name: 'UserLogin',
  data() {
    return {
      loginForm: {
        userName: '',
        password: ''
      },
      loading: false,
      ruleInline: {}
    };
  },
  mounted() {
    let lastLoginInfo = this.getLastLoginInfo();
    if (lastLoginInfo != null) {
      this.loginForm.userName = lastLoginInfo.userName;
      this.loginForm.password = this.decode(lastLoginInfo.password);
    }
  },
  methods: {
    onLogin(name) {
      let data = {
        userName: this.loginForm.userName,
        password: this.loginForm.password
      };
      this.loading = true;
      api.userLogin(data).then(res => {
        this.loading = false;
        if (res.code === 0) {
          delete data.password;
          this.setLastLoginInfo(data);
          this.setLoginInfo(res.data.userInfo);
          this.$store.dispatch('delAllViews');
          this.$router.push('/');
        }
      });
    },
    encode(str) {
      let encode = encodeURI(str);
      let base64 = btoa(encode);
      return base64;
    },
    decode(base64) {
      let decode = atob(base64);
      let str = decodeURI(decode);
      return str;
    }
  },
  components: {}
};
</script>

<style scoped>
/deep/ .ivu-input {
  border: 1px solid #999999;
  border-radius: 20px !important;
  width: 300px !important;
  height: 40px !important;
  line-height: 40px !important;
  margin-bottom: 20px;
  font-size: 18px;
}

/deep/ .ivu-input-prefix {
  line-height: 40px !important;
}

.loginTitle {
  font-size: 24px;
  text-align: center;
  padding: 10px;
}

.bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: lightslategray;
}

.main-body {
  width: 100vw;
  height: 100vh;
  background-image: url('../../../assets/images/bg.png');
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-body .main-container {
  width: calc(100vw - 274px);
  height: calc(100vh - 220px);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-image: url('../../../assets/images/bg2.png');
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.loginForm {
  margin-right: 140px;
  width: 400px;
  height: 448px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: 54px;
  font-size: 46px;
  font-weight: bold;
  color: #17a1ff;
  line-height: 66px;
}

.onLogin {
  margin-top: 34px;
  width: 100%;
  height: 40px;
  background: #17a1ff;
  border-radius: 27px;
  font-size: 18px;
}

.version {
  ont-size: 14px;
  color: #a0a0a0;
}
</style>
