import MyHome from '@/view/MyHome';

export default {
    path: '/financial',
    name: 'Financial',
    component: MyHome,
    meta: { title: '财务管理', keepAlive: true },
    children: [
        {
		    path: 'member-fund-list/fee-list',
		    name: 'MemberFee',
		    component: () => import('@/view/financial-management/member-fund-list/fee-list'),
		    meta: { title: '会员年费', keepAlive: true }
		},
		{
		    path: 'member-balance',
		    name: 'MemberBalance',
		    component: () => import('@/view/financial-management/member-balance'),
		    meta: { title: '会员余额', keepAlive: true }
		},
		{
		    path: 'member-income-sum',
		    name: 'MemberIncomeSum',
		    component: () => import('@/view/financial-management/member-income-sum'),
		    meta: { title: '会员收支汇总表', keepAlive: true }
		},
        {
            path: 'member-fund-list/member-fund-list',
            name: 'MemberFundList',
            component: () => import('@/view/financial-management/member-fund-list/member-fund-list'),
            meta: { title: '会员收支表', keepAlive: true }
        },
        {
            path: 'org-fund-list/org-fund-list/supplier/:t',
            name: 'OrgFundList1',
            component: () => import('@/view/financial-management/org-fund-list/org-fund-list'),
            meta: { title: '供货商收支表', keepAlive: true }
        },
        {
            path: 'org-fund-list/org-fund-list/collect/:t',
            name: 'OrgFundList2',
            component: () => import('@/view/financial-management/org-fund-list/org-fund-list'),
            meta: { title: '集货站收支表', keepAlive: true }
        },
        {
            path: 'org-fund-list/org-fund-list/delivery/:t',
            name: 'OrgFundList3',
            component: () => import('@/view/financial-management/org-fund-list/org-fund-list'),
            meta: { title: '配送站收支表', keepAlive: true }
        },
        {
            path: 'user-fund-list/user-fund-list/product/:t',
            name: 'UserFundList1',
            component: () => import('@/view/financial-management/user-fund-list/user-fund-list'),
            meta: { title: '产品经理收支表', keepAlive: true }
        },
        {
            path: 'user-fund-list/user-fund-list/customer/:t',
            name: 'UserFundList2',
            component: () => import('@/view/financial-management/user-fund-list/user-fund-list'),
            meta: { title: '客户经理收支表', keepAlive: true }
        },
        {
            path: 'commission/member-commission',
            name: 'MemberCommission',
            component: () => import('@/view/financial-management/commission/member-commission'),
            meta: { title: '会员端提成', keepAlive: true }
        },
        {
            path: 'commission/supplier-commission',
            name: 'SupplierCommission',
            component: () => import('@/view/financial-management/commission/supplier-commission'),
            meta: { title: '农户端提成', keepAlive: true }
        },
        {
            path: 'invoice/invoice-list',
            name: 'InvoiceList',
            component: () => import('@/view/financial-management/invoice/invoice-list'),
            meta: { title: '开票', keepAlive: true }
        },
        {
            path: 'business-summary/business-summary-list',
            name: 'BusinessSummaryList',
            component: () => import('@/view/financial-management/business-summary/business-summary-list'),
            meta: { title: '营业日结', keepAlive: true }
        },
        {
            path: 'manual-payin/manual-payin',
            name: 'ManualPayin',
            component: () => import('@/view/financial-management/manual-payin/manual-payin'),
            meta: { title: '手动录款', keepAlive: true }
        },
        {
            path: 'abc-bill/abc-bill',
            name: 'ABCBill',
            component: () => import('@/view/financial-management/abc-bill/abc-bill'),
            meta: { title: '农行账单', keepAlive: true }
        },
        {
            path: 'financial-reconciliation/list',
            name: 'FinancialReconciliationList',
            component: () => import('@/view/financial-management/financial-reconciliation/list'),
            meta: { title: '财务对账', keepAlive: true }
        },
        {
            path: 'compensation-summary/order-complaints-list',
            name: 'OrderComplaintsList',
            component: () => import('@/view/financial-management/compensation-summary/order-complaints-list'),
            meta: { title: '订单投诉赔偿汇总', keepAlive: true }
        },
        {
            path: 'compensation-summary/order-lack-list',
            name: 'LackGoodsList',
            component: () => import('@/view/financial-management/compensation-summary/order-lack-list'),
            meta: { title: '缺货赔偿汇总', keepAlive: true }
        },
        {
            path: 'withdraw/withdraw-list',
            name: 'WithdrawList',
            component: () => import('@/view/financial-management/withdraw/withdraw-list'),
            meta: { title: '提现审核管理', keepAlive: true }
        },
		{
		    path: 'commission-modif-record',
		    name: 'CommissionModifRecord',
		    component: () => import('@/view/financial-management/commission-modif-record'),
		    meta: { title: '提成修改记录', keepAlive: true }
		},
		{
		    path: 'patent-fee-modif-record',
		    name: 'PatentFeeModifRecord',
		    component: () => import('@/view/financial-management/patent-fee-modif-record'),
		    meta: { title: '专利费修改记录', keepAlive: true }
		},
		{
		    path: 'supplier-balance',
		    name: 'SupplierBalance',
		    component: () => import('@/view/financial-management/supplier-balance'),
		    meta: { title: '供应商余额', keepAlive: true }
		},
    ]
};
