<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card ref="searchCard" :form="mainTable.searchForm" :table-load-event="getSupplierList"
                :table-search="mainTable.tableSearch" :handleSearchWhenSelectChange="true" />
            <Button v-if="checkAccessArray('SupplierEdit')" size="small" @click="onAddSupplier">新增供应商</Button>
            <Button v-if="checkAccessArray('SupplierViewActive')" size="small" @click="onToActiveSupplier">有效供应商</Button>
        </div>
        <div>
            <pagination-table :columns="mainTable.tableColumns" :data="mainTable.tableData"
                :loading="mainTable.tableLoading" :table-search="mainTable.tableSearch"
                :table-load-event="getSupplierList" :highlight="true" :windowHeight="true" />
        </div>

        <SuppliertModal ref="supplierModal" />
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import SuppliertModal from './supplier-modal.vue';
import { setSelectOptions } from '@/libs/selectOption';
import { dateTimeRender } from '@/libs/tableRowRender';
export default {
    name: 'SupplierList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction,
        SuppliertModal
    },
    data() {
        return {
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '编码', key: "Code", align: 'center' },
                    { title: '名称', key: "Name", align: 'center' },
                    { title: '类型', key: "SupplierType", align: 'center' },
                    { title: '联系人', key: "LinkMan", align: 'center' },
                    { title: '集货站', key: "CollectName", align: 'center' },
                    { title: '手机', key: "Phone", align: 'center' },
                    { title: '提成', key: "CommissionRate", align: 'center',
                        render: (h, params) => {
                            let text = params.row.CommissionRate + '%';
                            if (params.row.CommissionType === 1) {
                                text = params.row.AmountCommission + '元';
                            }
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    { title: '城市', key: "City", align: 'center' },
                    { title: '区县', key: "Area", align: 'center' },
                    { title: '街道', key: "Street", align: 'center' },
                    { title: '创建日期', key: "CreateTime", align: 'center' },
                    { title: '产品经理', key: "JoinName", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 70,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'status',
                                        showEdit: this.checkAccessArray('SupplierEdit') || this.checkAccessArray('SupplierEditByAdmin'),
                                        showDelete: this.checkAccessArray('SupplierEdit')
                                    },
                                    on: {
                                        handleEdit: dataId => {
                                            this.onModifySupplier(dataId, false);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteSupplier(dataId);
                                        }
                                    }
                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '编码/名称', type: 'Input', value: '', name: 'concat(Code,Name)', sql: 'like' },
                    { label: '手机', type: 'Input', value: '', name: 'Phone', sql: 'like' },
                    { label: '类型', type: 'Select', value: '', name: 'SupplierType', sql: 'eq', options: [], clearable: true },
                    { label: '产品经理', type: 'Select', value: '', name: 'JoinName', sql: 'eq', options: [], clearable: true, show: !this.checkAccessArray('SearchByManager') }
                ],
                supplierTypes: []
            }
        };
    },
    computed: {
    },
    mounted() {
        this.getSupplierList();
        this.getSupplierTypeList();
        this.getJoinList();
    },
    methods: {
        setSelectOptions,
        getSupplierList() {
            this.mainTable.tableLoading = true;
            api.supplierList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        onAddSupplier() {
            this.$refs.supplierModal.showModal();
        },
        onModifySupplier(id, readOnly = false) {
            let s = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            readOnly = !(this.getUserOrgCode() == s.JoinCode || this.getUserDepartmentName() == s.JoinName);
            this.$refs.supplierModal.showModal(s, readOnly);
        },
        onDeleteSupplier(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '供应商删除后，属于该供应商的相关信息将一同被删除。',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.supplierDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getSupplierList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        getSupplierTypeList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersSupplierTypeList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.supplierTypes = res.data.records;
                    this.setSelectOptions(this.mainTable.searchForm, 'SupplierType', this.supplierTypes, 'ParaName', 'ParaName');
                }
            });
        },
        onToActiveSupplier(){
            this.$router.replace({ name: "ActiveSupplierList" });
        },
        getJoinList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersJoinList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.setSelectOptions(this.mainTable.searchForm, 'JoinName', res.data.records, 'ParaName', 'ParaName');
                }
            });
        }

    }
};
</script>
<style></style>
