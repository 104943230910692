<template>
    <div class="">
        <Modal v-model="show" title="产品关联供应商" :mask-closable="false" width="60" cancel-text="关闭">
            <Spin v-if="loading" size="large" fix></Spin>

            <Form ref="productAndSupplierModalForm" :label-width="100">
                <Row>
                    <Col span="6">
                    <FormItem label="所属品牌:">
                        <Select v-model="brandCode"
                            filterable
                            clearable
                            style="width:100%">
                            <Option v-for="item in brandList"
                                :value="item.code"
                                :key="item.code">{{ item.name }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="6">
                    <FormItem label="所属大类:">
                        <Select v-model="mainCategory"
                            filterable
                            clearable
                            style="width:100%">
                            <Option v-for="item in mainCategoryList"
                                :value="item.Name"
                                :key="item.Name">{{ item.Name }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="6">
                    <FormItem label="产品名称:" required>
                        <Input v-model="productName"></Input>
                    </FormItem>
                    </Col>
                    <Col span="6">
                    <FormItem label="">
                        <Button type="primary" @click="getProductList">搜索</Button>
                    </FormItem>
                    </Col>
                </Row>
            </Form>

            <Row>
                <Col span="24">
                <pagination-table
                    :columns="mainTable.tableColumns"
                    :data="mainTable.tableData"
                    :loading="mainTable.tableLoading"
                    :table-search="mainTable.tableSearch"
                    :table-load-event="getProductList"
                    :highlight="true"
                    :show-page="false"/>
                </Col>
            </Row>

        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
export default {
    name: 'ProductAndSupplierList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            show: false,
            supCode: '',
            loading: false,
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '产品名称', key: "Name", align: 'center' },
                    { title: '所属品牌', key: "Brand", align: 'center' },
                    { title: '所属品牌编码', key: "BrandCode", align: 'center' },
                    { title: '产品经理', key: "JoinName", align: 'center' },
                    { title: '产品经理编码', key: "JoinCode", align: 'center' },
                    { title: '所属大类', key: "MainCategory", align: 'center' },
                    { title: '产品等级', key: "Grades", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 40,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row._index + '',
                                        statusField: 'Status',
                                        showCustom1: true,
                                        customText1: '绑定'
                                    },
                                    on: {
                                        handleCustom1: () => {
                                            this.onSaveProductAndSupplier(params.row);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: []
            },
            supCode: '',
            productName: '',
            brandCode: '',
            mainCategory: '',
            brandList: [],
            mainCategoryList: []
        };
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        showModal(supCode) {
            this.show = true;
            this.supCode = supCode;
            this.getBrandList();
            this.getMainCategoryList();
        },

        onSaveProductAndSupplier(data) {

            if(!data.BrandCode){
                return this.showWarningModal('品牌编码不能未空，请先检查产品数据是否完整！');
            }

            this.loading = true;
            let params = { productName: data.Name, supCode: this.supCode, brandCode: data.BrandCode, joinCode: data.JoinCode, mainCategory: data.MainCategory };
            api.productAndSupplierItemSave(params).then(res => {
                this.loading = false;
                if (res.code === 0) {
                    this.showSuccessModal(res.message);
                    this.$parent.getProductAndSupplierItem();
                }
            });
        },

        getProductList() {
            if(!this.productName){
                return this.showWarningModal('请先输入产品名称！');
            }
            this.mainTable.tableData = [];
            api.productSaleItem({ productName: this.productName, brandCode: this.brandCode, mainCategory: this.mainCategory }).then(res => {
                if (res.code === 0) {
                    this.mainTable.tableData = res.data.records;
                }
            });
        },

        getBrandList() {
            this.brandList = [];
            api.brandListByUser({}).then(res => {
                if (res.code === 0) {
                    this.brandList = res.data.records;
                }
            });
        },
        getMainCategoryList() {
            this.mainCategoryList = [];
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.productMainCategoryList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.mainCategoryList = res.data.records;
                }
            });
        }

    }
};
</script>
<style media="print" scoped lang="less">

/deep/ .ivu-modal-footer > .ivu-btn-primary {
    display: none;
}
</style>

