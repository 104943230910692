import getPostRequest from './base-api';

export default {
    getBarcodeScanRecordLsit(data) {
        return getPostRequest(data, 'api/orderBarcode/orderBarCodeScanRecordList');
    },
    //进货单明细
    purchaseDetailBarCodeList(data) {
        return getPostRequest(data, 'api/scan/purchaseOrderDetailList');
    },
    orderBarCodeTrackList(data) {
        return getPostRequest(data, 'api/orderBarcode/orderBarCodeTrackList');
    },
    collStaScanInUpdate(data) {
        return getPostRequest(data, 'api/scan/CollStaScanInUpdate');
    },
    collStaScanInConfirm(data) {
        return getPostRequest(data, 'api/scan/CollStaScanInConfirm');
    }
};
