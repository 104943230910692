<template>
    <div class="tags-view-container">
		<div class="btn-con left-btn">
		  <Button type="text" @click="handleScroll(240)">
		    <Icon :size="18" type="ios-arrow-back" />
		  </Button>
		</div>
		
		<div class="btn-con right-btn">
		  <Button type="text" @click="handleScroll(-240)">
		    <Icon :size="18" type="ios-arrow-forward" />
		  </Button>
		  <Button type="text" style="border-left: 1px solid #fff;">
		  	<Icon type="ios-close-circle-outline" size="20" @click.prevent.stop='closeAllTags()' />
		  </Button>
		</div>
        <div class="scroll-outer" ref="scrollOuter" @DOMMouseScroll="handlescroll" @mousewheel="handlescroll">
          <div ref="scrollBody" class="scroll-body" :style="{left: tagBodyLeft + 'px'}">
           <router-link ref="tag" 
			class="tags-view-item" 
			:class="isActive(tag)?'tagActive':''" 
			:to="tag" v-for="tag in Array.from(visitedViews)" 
			:key="tag.path">
                {{tag.meta.title}}
                <Icon v-if="tag.name !== 'AppHome'" type="md-close" size="14" @click.prevent.stop='closeSelectedTag(tag)' />
            </router-link>
			
			</div>
       </div>
	   
        <!-- <Icon type="ios-close-circle-outline" size="20" style="z-index: 100;padding-right:10px;" @click.prevent.stop='closeAllTags()' /> -->
    </div>
</template>

<script>
export default {
    name: "tags-view",
    components: {},
    data() {
        return {
            visible: false,
            top: 0,
            left: 0,
            selectedTag: {},
			tagBodyLeft: 0,
        };
    },
    computed: {
        visitedViews() {
            // console.log(this.$store.state.tagsView);
            // console.log(this.$store);
            return this.$store.state.tagsView.visitedViews;
        }
    },
    watch: {
        $route() {
            this.addViewTags();
            // this.moveToCurrentTag();
        },
        visible(value) {
            if (value) {
                document.body.addEventListener("click", this.closeMenu);
            }
            else {
                document.body.removeEventListener("click", this.closeMenu);
            }
        }
    },
    mounted() {
        this.addHomeRoute();
        this.addViewTags();
    },
    methods: {
        addHomeRoute() {
            let homeRoute = this.$router.options.routes[1].children.find(e => {
                return e.name === "AppHome";
            });
            this.$store.dispatch("addVisitedViews", homeRoute);
        },
        generateRoute() {
            if (this.$route.name === 'AppHome') {
			    return false;
            }
            else{
                return this.$route;
            }
            return false;
        },
        isActive(route) {
            return route.path === this.$route.path;
        },
        addViewTags() {
            const route = this.generateRoute();
            if (!route) {
                return false;
            }
            this.$store.dispatch("addVisitedViews", route);
            if (this.$store.state.tagsView.visitedViews.length > 15) {
                this.showWarningModal('打开的窗口已超过15个，为提高系统性能，请关闭不必要的窗口。');
            }
			
			
        },
        moveToCurrentTag() {
            const tags = this.$refs.tag;
            this.$nextTick(() => {
                for (const tag of tags) {
                    if (tag.to.path === this.$route.path) {
                        break;
                    }
                }
            });
        },
        closeSelectedTag(view) {
            this.$store.dispatch("delVisitedViews", view).then(views => {
                if (this.isActive(view)) {
                    const latestView = views.slice(-1)[0];
                    if (latestView) {
                        this.$router.push(latestView);
                    }
                    else {
                        this.$router.push("/");
                    }
                }
            });
        },
        closeOthersTags() {
            this.$router.push(this.selectedTag);
            this.$store
                .dispatch("delOthersViews", this.selectedTag)
                .then(() => {
                    this.moveToCurrentTag();
                });
        },
        closeAllTags() {
			if (this.$route.name === 'AppHome') {
			    return false;
			}
            this.$store.dispatch("delAllViews");
            this.$router.push("/");
            this.addHomeRoute();
        },
        openMenu(tag, e) {
            this.visible = true;
            this.selectedTag = tag;
            const offsetLeft = this.$el.getBoundingClientRect().left;
            // this.left = e.clientX;
            // this.top = e.clientY;
        },
        closeMenu() {
            this.visible = false;
        },
		handlescroll(e) {
		    let type = e.type;
		    let delta = 0;
		    if (type === 'DOMMouseScroll' || type === 'mousewheel') {
		        delta = (e.wheelDelta) ? e.wheelDelta : -(e.detail || 0) * 40;
		    }
		    this.handleScroll(delta);
		},
		handleScroll(offset) {
		    const outerWidth = this.$refs.scrollOuter.offsetWidth;
		    const bodyWidth = this.$refs.scrollBody.offsetWidth;
		    if (offset > 0) {
		        this.tagBodyLeft = Math.min(0, this.tagBodyLeft + offset);
		    }
		    else {
		        if (outerWidth < bodyWidth) {
		            if (this.tagBodyLeft < -(bodyWidth - outerWidth)) {
		                this.tagBodyLeft = this.tagBodyLeft;
		            }
		            else {
		                this.tagBodyLeft = Math.max(this.tagBodyLeft + offset, outerWidth - bodyWidth);
		            }
		        }
		        else {
		            this.tagBodyLeft = 0;
		        }
		    }
		}
    }
};
</script>

<style lang="less" scoped>
.tags-view-container {
    background-color: #dae6f3;
	width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #d8dce5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
	position: relative;
}
.no-select{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn-con{
    position: absolute;
    top: 2px;
    height: 100%;
    background: #dae6f3;
    // padding-top: 3px;
    z-index: 10;
    button{
      padding: 6px 4px;
      line-height: 14px;
      text-align: center;
    }
    &.left-btn{
      left: 0px;
    }
    &.right-btn{
      right: 0px;
    }
  }
.scroll-outer{
    position: absolute;
    left: 28px;
    right: 61px;
    top: 0;
    bottom: 0;
    box-shadow: 0px 0 3px 2px rgba(100,100,100,.1) inset;
    .scroll-body{
      height: ~"calc(100% - 1px)";
      display: inline-block;
      padding: 1px 4px 0;
      position: absolute;
      overflow: visible;
      white-space: nowrap;
      transition: left .3s ease;
      .ivu-tag-dot-inner{
        transition: background .2s ease;
      }
    }
  }
.tags-view-item {
	padding-left: 20px;
    display: inline-block;
    position: relative;
    height: 24px;
    line-height: 22px;
    border: 1px solid grey;
    border-radius: 4px;
    color: grey;
    background-color: #dae6f3;
    padding: 0 8px;
    font-size: 14px;
    margin: 5px;
}
.tagActive {
    border: 1px solid #196d6d;
    color: #196d6d;
}
</style>

