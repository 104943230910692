<template>
	<Modal v-model="show" title="箱面信息打印" :width="modalWidth" :mask-closable="false" :scrollable="true">
	    <div id='printBoxInfo'>
			<div class="printDate">
				<div class="printInfoTitle">发货日期:</div>
				<div class="printInfoDate">{{mainTable.saleDate}}</div>
			</div>
	        <Row>
	            <Col span="24">
	            <pagination-table size="small"
	                :columns="mainTable.tableColumns"
	                :data="mainTable.tableData"
	                :loading="mainTable.tableLoading"
	                :table-search="mainTable.tableSearch"
	                :table-load-event="getBoxFaceList"
	                :highlight="true"
					:autoHeight="true"/>
	            </Col>
	        </Row>
	    </div>
	    <div slot="footer">
	        <Button type="info" v-print="printObj">打印</Button>
	    </div>
	</Modal>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import { changeDate } from '@/libs/changeTime';
export default {
    name: 'BoxFaceList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
			printObj: {
			    id: 'printBoxInfo',
			    popTitle: '',
			    ignoreClass: 'noPrint'
			},
			show: false,
			modalWidth: '',
			tableLoading: false,
            mainTable: {
                tableColumns: [
					{
					    title: '会员', key: '',align: 'center',width:70,
					    render: (h, params) => {
					        return h('div', [
					            h('span', { style: 'color:#2b85e4' }, params.row.MemberCode),
					            h('span', {}, params.row.MemberName)
					        ]);
					    }
					},
                    { title: '订单号', key: "OrderNo", align: 'center',width:80},
					{ title: '城市', key: "City", align: 'center',width:70},
					{ title: '箱面', key: "DetailAddr", align: 'center',width:200},
					{ title: '物流方案', key: "DeliveryWay", align: 'center',width:70 },
					{ title: '物流公司', key: "DeliveryCompany", align: 'center',width:70 },
					{ title: '备注', key: "Remark", align: 'center',width:154}
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [],
				saleDate: '',
            },
			//saleDate: '',
        };
    },
    computed: {
		
    },
    mounted() {
        this.getBoxFaceList();
    },
    methods: {
        //获取列表
        getBoxFaceList() {
        	this.mainTable.tableLoading = true;
			let tempSearch = search.getSearchParams();
			tempSearch.limit.enable = true;
			tempSearch.filters.enable = true;
			tempSearch.filters.conditions.push({name: 'SaleDate',type: 'eq',value: this.mainTable.saleDate});
            api.boxFaceList(tempSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
		showModal(caption,saleDate,modalWidth = '748') {
			this.mainTable.saleDate = saleDate;
			console.log('this.mainTable.saleDate',this.mainTable.saleDate)
		    this.printObj.popTitle = caption;
		    this.show = true;
		    this.modalWidth = modalWidth;
		    this.getBoxFaceList();
		}	
    }
};
</script>
<style>
	.printDate{display:flex;
	width:100%;
	border:1px solid #e8eaec;
	border-bottom:none;
	color: #196d6d;
	height:32px;
	line-height:32px;
	font-size:12px;
	font-weight: bold;
	}
	.printInfoTitle{
		display:inline-block;
		width:65px;
		border-right:1px solid #e8eaec;
		text-align: center;
	}
	.printInfoDate{padding-left:10px;}
</style>


