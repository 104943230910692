<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getActionList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4" />
        </div>
        <div>
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getActionList"
                :windowHeight="true" />
        </div>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import { dateTimeRender, currencyRender } from '@/libs/tableRowRender';
export default {
    name: 'ActionList',
    components: {
        PaginationTable,
        SearchCard
    },
    data() {
        return {
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '账号', key: "Operater", align: 'center' },
                    { title: '操作', key: "Title", align: 'center' },
                    { title: '操作内容', key: "Content", align: 'center' },
                    { title: 'IP地址', key: "Ip", align: 'center' },
                    { title: '操作时间', key: "CreateTime", align: 'center'}
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams({ pageSize: 20 }),
                searchForm: [
                    { label: '账号', type: 'Input', value: '', name: 'Operater', sql: 'like' },
                    { label: '操作', type: 'Input', value: '', name: 'Title', sql: 'like' },
                    { label: '操作内容', type: 'Input', value: '', name: 'Content', sql: 'like' },
                ]
            }
        };
    },
    computed: {

    },
    mounted() {
        this.getActionList();
    },
    methods: {
        getActionList() {
            this.mainTable.tableLoading = true;
            api.operateLogList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        }
    }
};
</script>
<style>
</style>
