<template>
    <Layout style="height: 100%" class="main">
        <Sider ref="leftSider" hide-trigger collapsible :width="width" v-model="isCollapse" :collapsed-width="60" class="left-sider">
            <app-aside-menu ref="sideMenu" :isCollapse="isCollapse" :active-menu="$route.path">
                <a href="/home">
                    <div class="logo-con">
                        <!-- <img v-show="isCollapse" :src="maxLogo" /> -->
                        <!-- <img v-show="!isCollapse" :src="minLogo" /> -->
                        <span v-show="!isCollapse" style="font-size: 24px;">缤纷鲜花网</span>
                        <span v-show="isCollapse" style="font-size: 24px;">花</span>
                    </div>
                </a>
            </app-aside-menu>
        </Sider>
        <Layout>
            <AppHeader :isCollapse="isCollapse" @on-coll-change="handleCollapsedChange"></AppHeader>
            <Content>
                <tagsView style="height: 36px;"></tagsView>
                <Card>
                    <keep-alive>
                        <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
                    </keep-alive>
                    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"></router-view>
                </Card>
            </Content>
        </Layout>
    </Layout>
</template>

<script>
import AppHeader from "@/components/layout/AppHeader";
import tagsView from '@/view/tagsView';
import AppAsideMenu from '@/components/layout/AppAsideMenu.vue';
export default {
    name: 'MyHome',
    components: {
        AppHeader,
        tagsView,
        AppAsideMenu
    },
    data() {
        return {
            isCollapse: false,
            width: '200px'
        };
    },
    watch: {
        '$route'(newRoute){
            this.$refs.sideMenu.updateOpenPath(newRoute.path);
        }
    },
    methods: {
        handleCollapsedChange(e) {
            this.isCollapse = e;
            this.$refs.leftSider.toggleCollapse();
        }
    }
};
</script>

<style lang="less">
.main {
    .left-sider {
        .ivu-layout-sider-children {
            overflow-y: scroll;
            &::-webkit-scrollbar {
                display: none;
                /* Chrome Safari */
            }
        }
    }
}
</style>

<style scoped lang="less">
.logo-con {
    box-sizing: border-box;
    height: 60px;
    padding: 10px;
    text-align: center;

    img {
        height: 44px;
        width: auto;
        display: block;
        margin: 0 auto;
    }

    span {
        font-size: 28px;
        color: white;
    }
}
</style>
