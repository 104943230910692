<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getSalesPromotionList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4" />

            <Button v-if="checkAccessArray('SalesPromotionEdit')" size="small" @click="onAddSalesPromotion">新增促销活动</Button>
        </div>
        <Row>
            <Col span="24">
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getSalesPromotionList"
                :highlight="true"
                @on-row-click="onSalesPromotionSelect" />
            </Col>
        </Row>

        <Modal v-model="salesPromotionModal.show"
            title="促销活动信息"
            :mask-closable="false">
            <Spin v-if="salesPromotionModal.loading"
                size="large"
                fix></Spin>
            <Form ref="salesPromotionModalForm"
                :label-width="100">
                <Row>
                    <Col span="14">
                    <FormItem label="促销名称:"
                        required>
                        <Input v-model="salesPromotionModal.name"></Input>
                    </FormItem>
                    </Col>
                    <Col span="14">
                    <FormItem label="促销类型"
                        required>
                        <Select v-model="salesPromotionModal.promotionType"
                            filterable
                            style="width:100%"
							@on-change="promotionTypeChange">
                            <Option v-for="item in promotionTypeList"
                                :value="item.key"
                                :key="item.key">{{ item.value }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="14">
                    <FormItem label="开始时间:"
                        required>
                        <DatePicker :editable="false"
                        	v-model="salesPromotionModal.startTime"
                        	type="date" placeholder="请选择销售日期"
                            @on-change="startTimeChange(salesPromotionModal.startTime)">
                        </DatePicker>
                    </FormItem>
                    </Col>
                    <Col span="14">
                    <FormItem label="结束时间:"
                        required>
                        <DatePicker :editable="false"
                        	v-model="salesPromotionModal.endTime"
                        	type="date" placeholder="请选择销售日期"
                            @on-change="endTimeChange(salesPromotionModal.endTime)">
                        </DatePicker>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="满足条件数字"
                        required>
                        <Input style="width: 80px;" type="number" v-model.number="salesPromotionModal.conditionNum"></Input>
						<span style="">{{cUnit}}</span>
                        <span style="color:#ff0000;display:block;">例子：如满170元减10元，则该值为170:</span>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="触发数字"
                        required>
                        <Input style="width: 80px;" type="number" v-model.number="salesPromotionModal.triggerNum"></Input>
						<span style="">{{tUnit}}</span>
                        <span style="color:#ff0000;display:block;" >例子：如满170元减10元，则该值为10，若满3扎打88折，则该值为0.88</span>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="14">
                    <FormItem label="是否启用:"
                        required>
                        <Select v-model="salesPromotionModal.isEnabled"
                            filterable
                            style="width:50%">
                            <Option v-for="item in whether"
                                :value="item.key"
                                :key="item.key">{{ item.value }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveSalesPromotion">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import {changeDate} from '@/libs/changeTime';
export default {
    name: 'SalesPromotionList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
			cUnit: '元',
			tUnit: '元',
            salesPromotionModal: {
                show: false,
                loading: false,
                name: '',
                supCode: '',
                supName: '',
                promotionType: '',
                startTime: '',
                endTime: '',
                conditionNum: '',
                triggerNum: '',
                isEnabled: '',
                mode: 'add',
            },
			whether:[{ key: 1, value: '是' }, { key: 0, value: '否' }],
            promotionTypeList:[{ key: 1, value: '满减活动(满金额)' }, { key: 2, value: '满折活动' }, { key: 3, value: '满送活动' }, { key: 4, value: '满减活动(满件数)' }],
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '促销名称', key: "Name", align: 'center' },
                    { title: '促销类型', key: "PromotionType", align: 'center',
                        render: (h, params) => {
                            let text = this.promotionTypeList[params.row.PromotionType-1].value;
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    { title: '开始时间', key: "StartTime", align: 'center' },
                    { title: '结束时间', key: "EndTime", align: 'center' },
                    { title: '满足条件数字', key: "ConditionNum", align: 'center' },
                    { title: '触发数字', key: "TriggerNum", align: 'center' },
                    { title: '是否启用', key: "IsEnabled", align: 'center',
                        render: (h, params) => {
                            let text = params.row.IsEnabled === 0 ? '禁用' : '启用';
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    {
                        title: "操作",
                        align: "center",
                        width: 80,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showEdit: this.checkAccessArray('SalesPromotionEdit'),
                                        showDelete: this.checkAccessArray('SalesPromotionEdit')
                                    },
                                    on: {
                                        handleEdit: dataId => {
                                            this.onModifySalesPromotion(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteSalesPromotion(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '促销名称', type: 'Input', value: '', name: 'Name', sql: 'like' },
                    { label: '促销类型', type: 'Select', value:'', options: [{ key: '满减活动', value: '1' }, { key: '满折活动', value: '2' }, { key: '满送活动', value: '3' }], name: 'PromotionType', sql: 'eq' }
                ]
            },
            selectedSalesPromotion: null,
            supplierList: []
        };
    },
    computed: {

    },
    mounted() {
        this.getSalesPromotionList();
        this.getSupplierList();
    },
    methods: {
        async getSupplierList() {
        	let {data:data} =await api.supplierList({});
            this.supplierList = data.records;
        },
        onSalesPromotionSelect(row, index) {
            this.selectedSalesPromotion = row;
        },
        onAddSalesPromotion() {
            this.salesPromotionModal.mode = 'add';
            this.salesPromotionModal.id = '';
            this.salesPromotionModal.name = '',
            this.salesPromotionModal.promotionType = '',
            this.salesPromotionModal.startTime = '',
            this.salesPromotionModal.endTime = '',
            this.salesPromotionModal.conditionNum = '',
            this.salesPromotionModal.triggerNum = '',
            this.salesPromotionModal.isEnabled = '',
            this.salesPromotionModal.show = true;
        },
        onDeleteSalesPromotion(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '确定删除吗？',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.salesPromotionDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getSalesPromotionList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onModifySalesPromotion(id) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.salesPromotionModal.id = b.Id;
            this.salesPromotionModal.name = b.Name;
            this.salesPromotionModal.promotionType = b.PromotionType;
			var promotionType=this.salesPromotionModal.promotionType;
			if(promotionType==1){
				this.cUnit="元";
				this.tUnit="元";
			}
			else if(promotionType==2){
				this.cUnit="扎";
				this.tUnit="折";
			}
			else if(promotionType==3){
				this.cUnit="扎";
				this.tUnit="扎";
			}
			else if(promotionType==4){
				this.cUnit="扎";
				this.tUnit="元";
			}
            this.salesPromotionModal.startTime = b.StartTime;
            this.salesPromotionModal.endTime = b.EndTime;
            this.salesPromotionModal.conditionNum = b.ConditionNum;
            this.salesPromotionModal.triggerNum = b.TriggerNum;
            this.salesPromotionModal.isEnabled = b.IsEnabled;
            this.salesPromotionModal.mode = 'modify';
            this.salesPromotionModal.show = true;
        },
        onSaveSalesPromotion() {
            this.salesPromotionModal.loading = true;
			this.salesPromotionModal.startTime = changeDate(this.salesPromotionModal.startTime);
			this.salesPromotionModal.endTime = changeDate(this.salesPromotionModal.endTime);
            api.salesPromotionSave(this.salesPromotionModal).then(res => {
                this.salesPromotionModal.loading = false;
                if (res.code === 0) {
                    this.salesPromotionModal.show = false;
                    this.getSalesPromotionList();
                    this.showSuccessModal();
                }
            });
        },
        getSalesPromotionList() {
            this.mainTable.tableLoading = true;
            api.salesPromotionList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedSalesPromotion = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        supCodeChange(option){
        	this.salesPromotionModal.supName = option.label;
        },
        startTimeChange(time){
        	this.salesPromotionModal.startTime = changeDate(time);
        },
        endTimeChange(time){
        	this.salesPromotionModal.endTime = changeDate(time);
        },
		promotionTypeChange(){
			var promotionType=this.salesPromotionModal.promotionType;
			if(promotionType==1){
				this.cUnit="元";
				this.tUnit="元";
			}
			else if(promotionType==2){
				this.cUnit="扎";
				this.tUnit="折";
			}
			else if(promotionType==3){
				this.cUnit="扎";
				this.tUnit="扎";
			}
			else if(promotionType==4){
				this.cUnit="扎";
				this.tUnit="元";
			}
		}
    }
};
</script>
<style>
</style>
