import getPostRequest from './base-api';

export default {
    // 用户相关
    userLogin(data) {
        return getPostRequest(data, 'api/user/userLogin');
    },
    userInfo(data) {
        return getPostRequest(data, 'api/user/userInfo');
    },
    userSaveByPerson(data) {
        return getPostRequest(data, 'api/user/userSaveByPerson');
    },
    userList(data) {
        return getPostRequest(data, 'api/user/userList');
    },
    userShortList(data) {
        return getPostRequest(data, 'api/user/userShortList');
    },
    userSave(data) {
        return getPostRequest(data, 'api/user/userSave');
    },
    userInactive(data) {
        return getPostRequest(data, 'api/user/userInactive');
    },
    userResetPassword(data) {
        return getPostRequest(data, 'api/user/userResetPassword');
    },
    permissionList(data) {
        return getPostRequest(data, 'api/user/permissionList');
    },
    userChangePassword(data) {
        return getPostRequest(data, 'api/user/userChangePassword');
    },
    userListByType(data) {
        return getPostRequest(data, 'api/user/userListByType');
    },
    userListByProductMgr(data) {
        return getPostRequest(data, 'api/user/userListByProductMgr');
    },
    
    // 角色相关
    roleList(data) {
        return getPostRequest(data, 'api/role/roleList');
    },
    roleInfo(data) {
        return getPostRequest(data, 'api/role/roleInfo');
    },
    roleEdit(data) {
        return getPostRequest(data, 'api/role/roleEdit');
    },
    roleSave(data) {
        return getPostRequest(data, 'api/role/roleSave');
    },
    roleDelete(data) {
        return getPostRequest(data, 'api/role/roleDelete');
    },
    leafRoleList(data) {
        return getPostRequest(data, 'api/role/leafRoleList');
    },

    // 资源相关
    resourceList(data) {
        return getPostRequest(data, 'api/resource/resourceList');
    },
    resourceInfo(data) {
        return getPostRequest(data, 'api/resource/resourceInfo');
    },
    resourceSave(data) {
        return getPostRequest(data, 'api/resource/resourceSave');
    },
    resourceDelete(data) {
        return getPostRequest(data, 'api/resource/resourceDelete');
    },
    subResourceList(data) {
        return getPostRequest(data, 'api/resource/subResourceList');
    },
    resourceMenu(data) {
        return getPostRequest(data, 'api/resource/resourceMenu');
    },
    leafResourceList(data) {
        return getPostRequest(data, 'api/resource/leafResourceList');
    },

    // 黑名单相关
    blacklist(data) {
        return getPostRequest(data, 'api/blackList/blackListList');
    },
    blacklistInfo(data) {
        return getPostRequest(data, 'api/blacklist/blacklistInfo');
    },
    blacklistSave(data) {
        return getPostRequest(data, 'api/blacklist/blacklistSave');
    },
    blacklistDelete(data) {
        return getPostRequest(data, 'api/blacklist/blacklistDelete');
    },

    // 授权相关
    grantList(data) {
        return getPostRequest(data, 'api/grant/grantList');
    },
    grantSave(data) {
        return getPostRequest(data, 'api/grant/grantSave');
    },
    grantEchoList(data) {
        return getPostRequest(data, 'api/grant/grantEchoList');
    },

    // 附件相关
    attachmentList(data) {
        return getPostRequest(data, 'api/attachment/attachmentList');
    },
    attachmentDelete(data) {
        return getPostRequest(data, 'api/attachment/attachmentDelete');
    },

    // 广告相关
    advertList(data) {
        return getPostRequest(data, 'api/advert/advertList');
    },
    advertSave(data) {
        return getPostRequest(data, 'api/advert/advertSave');
    },
    advertDelete(data) {
        return getPostRequest(data, 'api/advert/advertDelete');
    },

    // 用户投诉相关
    complainList(data) {
        return getPostRequest(data, 'api/complain/complainList');
    },
    complainSave(data) {
        return getPostRequest(data, 'api/complain/complainSave');
    },
    complainDelete(data) {
        return getPostRequest(data, 'api/complain/complainDelete');
    },
    complainReply(data) {
        return getPostRequest(data, 'api/complain/complainReply');
    },
    complainByOrderList(data) {
        return getPostRequest(data, 'api/complain/orderComplainList');
    },

    // 客户经理投诉相关
    managerComplainList(data) {
        return getPostRequest(data, 'api/managerComplain/managerComplainList');
    },
    managerComplainSave(data) {
        return getPostRequest(data, 'api/managerComplain/managerComplainSave');
    },
    managerComplainDelete(data) {
        return getPostRequest(data, 'api/managerComplain/managerComplainDelete');
    },
    managerComplainReply(data) {
        return getPostRequest(data, 'api/managerComplain/managerComplainReply');
    },

    // 参数相关
    parametersRegionList(data) {
        return getPostRequest(data, 'api/parameters/parametersRegionList');
    },
    parametersCollectList(data) {
        return getPostRequest(data, 'api/parameters/parametersCollectList');
    },
    parametersCollectByUserList(data) {
        return getPostRequest(data, 'api/parameters/parametersCollectByUserList');
    },
    parametersGradeList(data) {
        return getPostRequest(data, 'api/parameters/parametersGradeList');
    },
    parametersJoinList(data) {
        return getPostRequest(data, 'api/parameters/parametersJoinList');
    },
    parametersJoinByUserList(data) {
        return getPostRequest(data, 'api/parameters/parametersJoinByUserList');
    },
    parametersSupplierTypeList(data) {
        return getPostRequest(data, 'api/parameters/parametersSupplierTypeList');
    },
    parametersUnitList(data) {
        return getPostRequest(data, 'api/parameters/parametersUnitList');
    },
    parametersUserTypeList(data) {
        return getPostRequest(data, 'api/parameters/parametersUserTypeList');
    },
    parametersSave(data) {
        return getPostRequest(data, 'api/parameters/parametersSave');
    },
    parametersDelete(data) {
        return getPostRequest(data, 'api/parameters/parametersDelete');
    },
    parametersLogisticsTypeList(data) {
        return getPostRequest(data, 'api/parameters/logisticsTypeList');
    },
    parametersArticleTypeList(data) {
        return getPostRequest(data, 'api/parameters/parametersArticleTypeList');
    },
    parametersServiceDeptList(data) {
        return getPostRequest(data, 'api/parameters/parametersServiceDeptList');
    },
    //配送站
    parametersDelivList(data) {
        return getPostRequest(data, 'api/parameters/parametersDelivList');
    },
    parametersAdvertLocationList(data) {
        return getPostRequest(data, 'api/parameters/parametersAdvertLocationList');
    },

    // 通知相关
    articleList(data) {
        return getPostRequest(data, 'api/article/articleList');
    },
    articleSave(data) {
        return getPostRequest(data, 'api/article/articleSave');
    },
    articleDelete(data) {
        return getPostRequest(data, 'api/article/articleDelete');
    },
    articleShowOrHide(data) {
        return getPostRequest(data, 'api/article/articleShowOrHide');
    },

    // 部门相关
    departmentList(data){
        return getPostRequest(data, 'api/department/departmentList');
    },
    departmentSave(data) {
        return getPostRequest(data, 'api/department/departmentSave');
    },
    departmentDelete(data) {
        return getPostRequest(data, 'api/department/departmentDelete');
    },
    memberResetPassword(data) {
	    return getPostRequest(data, 'api/member/memberResetPassword');
    },
	
	// 评论汇总相关
	orderEvaluateAdminList(data) {
	    return getPostRequest(data, 'api/orderEvaluate/orderEvaluateAdminList');
	},
	orderEvaluateDeleteById(data) {
	    return getPostRequest(data, 'api/orderEvaluate/orderEvaluateDeleteById');
	},
};
