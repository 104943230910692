import MyHome from '@/view/MyHome';
// 系统管理

import UserList from '@/view/system-management/user/user-list';
import RoleList from '@/view/system-management/user/role-list';
import LeafUserList from '@/view/system-management/user/leaf-user-list';
import LeafRoleList from '@/view/system-management/user/leaf-role-list';
import ActionList from '@/view/system-management/action/action-list';
import NotificationList from '@/view/system-management/notice/notice-list';
import ChangePassword from '@/view/system-management/user/change-password';
import ComplaintList from '@/view/system-management/complaint/complaint';
import OrderComplaintList from '@/view/system-management/complaint/order-complaint';
import ManagerComplainList from '@/view/system-management/complaint/manager-complaint';
import ResourceList from '@/view/system-management/resource/resource';
import AdvertList from '@/view/system-management/advert/advert';
import ParameterList from '@/view/system-management/parameter/index';
import UserInfo from '@/view/system-management/user/user-info';
import DepartmentList from '@/view/system-management/department/index';
import resMemberPsw from '@/view/system-management/res-member-psw';
import GatherEvaluate from '@/view/system-management/gather-evaluate';

export default {
    path: '/sys',
    name: 'sys',
    component: MyHome,
    meta: { title: '系统管理', keepAlive: true },
    children: [
        // 系统管理
        { path: 'user-list', name: 'UserList', component: UserList, meta: { title: '用户管理', keepAlive: true } },
        { path: 'role-list', name: 'RoleList', component: RoleList, meta: { title: '权限管理', keepAlive: true } },
        { path: 'leaf-user-list', name: 'LeafUserList', component: LeafUserList, meta: { title: '子账号管理', keepAlive: true } },
        { path: 'leaf-role-list', name: 'LeafRoleList', component: LeafRoleList, meta: { title: '子权限管理', keepAlive: true } },
        { path: 'change-password', name: 'ChangePassword', component: ChangePassword, meta: { title: '修改密码', keepAlive: true } },
        { path: 'action-list', name: 'ActionList', component: ActionList, meta: { title: '操作日志', keepAlive: true } },
        { path: 'notice-list', name: 'NoticeList', component: NotificationList, meta: { title: '通知公告', keepAlive: true } },
        { path: 'complaint', name: 'ComplaintList', component: ComplaintList, meta: { title: '用户投诉', keepAlive: true } },
        { path: 'order-complaint', name: 'OrderComplaintList', component: OrderComplaintList, meta: { title: '订单留言', keepAlive: true } },
        { path: 'manager-complaint', name: 'ManagerComplainList', component: ManagerComplainList, meta: { title: '投诉建议', keepAlive: true } },
        { path: 'gather-evaluate', name: 'GatherEvaluate', component: GatherEvaluate, meta: { title: '评价汇总', keepAlive: true } },
        { path: 'resource-list', name: 'ResourceList', component: ResourceList, meta: { title: '资源管理', keepAlive: true } },
        { path: 'advert-list', name: 'AdvertList', component: AdvertList, meta: { title: '广告管理', keepAlive: true } },
        { path: 'parameter-list', name: 'ParameterList', component: ParameterList, meta: { title: '参数管理', keepAlive: true } },
        { path: 'user-info', name: 'UserInfo', component: UserInfo, meta: { title: '用户信息', keepAlive: true } },
        { path: 'department-list', name: 'DepartmentList', component: DepartmentList, meta: { title: '部门管理', keepAlive: true } },
        { path: 'res-member-psw', name: 'resMemberPsw', component: resMemberPsw, meta: { title: '修改用户密码', keepAlive: true } }
		
    ]
};
