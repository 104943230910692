import getPostRequest from './base-api';

export default {
    // 会员年费
    memberFeeList(data) {
        return getPostRequest(data, 'api/financialManagement/memberFeeList/list');
    },
    // 财务管理/会员收支表
    memberFundRecordList(data) {
        return getPostRequest(data, 'api/financialManagement/memberFundRecordList/list');
    },
    selectRelateOrderType(data) {
        return getPostRequest(data, 'api/financialManagement/memberFundRecordList/type');
    },
    // 财务管理/产品经理收支表、客户经理收支表
    userFundRecordList(data) {
        return getPostRequest(data, 'api/financialManagement/userFundRecordList/list');
    },
    // 财务管理/供货商收支表、集货站收支表、配送站收支表
    orgFundRecordList(data) {
        return getPostRequest(data, 'api/financialManagement/orgFundRecord/list');
    },
    selectSupplierJoinName(data) {
        return getPostRequest(data, 'api/supplier/joinNameList');
    },
    selectOrgFundRelateOrderType(data) {
        return getPostRequest(data, 'api/financialManagement/orgFundRecord/type');
    },
    // 财务管理/会员端提成
    memberCommission(data) {
        return getPostRequest(data, 'api/financialManagement/commission/memberCommission');
    },
    // 财务管理/农户端提成
    supplierCommissionByJoin(data) {
        return getPostRequest(data, 'api/financialManagement/commission/supplierCommission/join');
    },
    supplierCommissionByCollect(data) {
        return getPostRequest(data, 'api/financialManagement/commission/supplierCommission/collect');
    },
    supplierCommissionByBid(data) {
        return getPostRequest(data, 'api/financialManagement/commission/supplierCommission/bid');
    },
    supplierCommissionByGather(data) {
        return getPostRequest(data, 'api/financialManagement/commission/supplierCommission/gather');
    },
    // 财务管理/开票
    invoiceList(data) {
        return getPostRequest(data, 'api/financialManagement/invoice/invoiceList');
    },
    invoiceProductList(data) {
        return getPostRequest(data, 'api/financialManagement/invoice/invoiceProductList');
    },
    submmitOrderInvoiceReverse(data) {
        return getPostRequest(data, 'api/financialManagement/invoice/submmitOrderInvoiceReverse');
    },
    sumbitSpecialInvoice(data) {
        return getPostRequest(data, 'api/financialManagement/invoice/sumbitSpecialInvoice');
    },
    // 财务管理/营业日结
    businessSummaryList(data) {
        return getPostRequest(data, 'api/financialManagement/memberFundRecordList/businessSummaryList');
    },
    // 财务管理/农行账单
    ABCBillList(data) {
        return getPostRequest(data, 'api/financialManagement/memberPayResultList/ABCBillList');
    },
    // 财务管理/财务对账
    financialReconciliationList(data) {
        return getPostRequest(data, 'api/financialManagement/memberFundRecord/financialReconciliationList');
    },
    // 财务管理/订单投诉赔偿汇总(集货站)
    orderComplainConfirmSummaryList(data) {
        return getPostRequest(data, 'api/financialManagement/compensationSummary/orderComplainList');
    },
	// 财务管理/订单投诉赔偿汇总(赔付部门)
	orderComplainListByCompensationDept(data) {
	    return getPostRequest(data, 'api/financialManagement/compensationSummary/orderComplainListByCompensationDept');
	},
	// 财务管理/订单投诉赔偿汇总(大类)
	orderComplainListByMainCategory(data) {
	    return getPostRequest(data, 'api/financialManagement/compensationSummary/orderComplainListByMainCategory');
	},
	// 财务管理/订单投诉赔偿汇总(服务部门)
	orderComplainListByServiceDept(data) {
	    return getPostRequest(data, 'api/financialManagement/compensationSummary/orderComplainListByServiceDept');
	},
    // 财务管理/缺货赔偿汇总(集货站)
    orderLackConfirmSummaryList(data) {
        return getPostRequest(data, 'api/financialManagement/compensationSummary/orderLackList');
    },
	// 财务管理/缺货赔偿汇总(赔付部门)
	orderLackListByCompensationDept(data) {
	    return getPostRequest(data, 'api/financialManagement/compensationSummary/orderLackListByCompensationDept');
	},
	// 财务管理/缺货赔偿汇总(大类)
	orderLackListByMainCategory(data) {
	    return getPostRequest(data, 'api/financialManagement/compensationSummary/orderLackListByMainCategory');
	},
	// 财务管理/缺货赔偿汇总(服务部门)
	orderLackListByServiceDept(data) {
	    return getPostRequest(data, 'api/financialManagement/compensationSummary/orderLackListByServiceDept');
	},
    // 财务管理/手动录款
    manualOperateSave(data) {
        return getPostRequest(data, 'api/memberFund/manualOperate');
    },
    // 获取提现申请列表
    withdrawList(data) {
        return getPostRequest(data, 'api/withdraw/withdrawList');
    },
    // 提现审核
    withdrawApproval(data) {
        return getPostRequest(data, 'api/withdraw/withdrawApproval');
    },
	memberFundInfoByCodeOrPhone(data) {
	    return getPostRequest(data, 'api/memberFund/memberFundInfoByCodeOrPhone');
	},
	memberInfoAndAmountList(data) {
	    return getPostRequest(data, 'api/memberFund/memberInfoAndAmountList');
	},
	orgInfoAndAmountList(data) {
	    return getPostRequest(data, 'api/orgFund/orgInfoAndAmountList');
	},
	memberSumAmountList(data) {
	    return getPostRequest(data, 'api/memberFund/memberSumAmountList');
	},
	orgInfoList(data) {
	    return getPostRequest(data, 'api/orgFund/orgInfoList');
	},
	// 提成(产品佣金比率)修改记录列表接口
	commissionRateRecordList(data) {
	    return getPostRequest(data, 'api/commissionRateRecord/commissionRateRecordList');
	},
	// 提成(产品佣金比率)修改记录列表接口
	patentfeeRateRecordList(data) {
	    return getPostRequest(data, 'api/patentfeeRateRecord/patentfeeRateRecordList');
	},
	//会员提现审核列表
	memberWithdrawList(data) {
	    return getPostRequest(data, 'api/withdraw/memberWithdrawList');
	},
	supplierWithdrawList(data) {
	    return getPostRequest(data, 'api/withdraw/supplierWithdrawList');
	},
};
