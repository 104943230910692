import { render, staticRenderFns } from "./VStep.vue?vue&type=template&id=0436c460&scoped=true&"
import script from "./VStep.vue?vue&type=script&lang=js&"
export * from "./VStep.vue?vue&type=script&lang=js&"
import style0 from "./VStep.vue?vue&type=style&index=0&id=0436c460&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0436c460",
  null
  
)

export default component.exports