import { render, staticRenderFns } from "./box-info-modal.vue.js?vue&type=template&id=43df2d7e!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./box-info-modal.vue?vue&type=template&id=43df2d7e"
import script from "./box-info-modal.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./box-info-modal.vue?vue&type=script&lang=js"
export * from "./box-info-modal.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./box-info-modal.vue?vue&type=script&lang=js"
import style0 from "./box-info-modal.vue.css?vue&type=style&index=0&id=43df2d7e&prod&lang=css!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./box-info-modal.vue?vue&type=style&index=0&id=43df2d7e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports