import getPostRequest from './base-api';

export default {
    /** 获取服务器时间辍*/
    getServeTimeTamp(data) {
        return getPostRequest(data, 'api/serverTimeTamp/timeTamp');
    },

    departmentList(data) {
        return getPostRequest(data, 'api/department/departmentList');
    },
    departmentInfo(data) {
        return getPostRequest(data, 'api/department/departmentInfo');
    },
    departmentSave(data) {
        return getPostRequest(data, 'api/department/departmentSave');
    },
    departmentDelete(data) {
        return getPostRequest(data, 'api/department/departmentDelete');
    },

    // 区域
    regionList(data) {
        return getPostRequest(data, 'api/region/regionList');
    },
    regionSave(data) {
        return getPostRequest(data, 'api/region/regionSave');
    },
    regionSwitch(data) {
        return getPostRequest(data, 'api/region/regionSwitch');
    },
    regionDelete(data) {
        return getPostRequest(data, 'api/region/regionDelete');
    },

    // 商品分类
    mainCateList(data) {
        return getPostRequest(data, 'api/mainCate/mainCateList');
    },
    mainCateSave(data) {
        return getPostRequest(data, 'api/mainCate/mainCateSave');
    },
    mainCateDelete(data) {
        return getPostRequest(data, 'api/mainCate/mainCateDelete');
    },
    mainCateInfo(data) {
        return getPostRequest(data, 'api/mainCate/mainCateInfo');
    },


    // 商品
    productList(data) {
        return getPostRequest(data, 'api/product/productList');
    },
    productShortList(data) {
        return getPostRequest(data, 'api/product/productShortList');
    },
    productListWithTransferQuantity(data) {
        return getPostRequest(data, 'api/product/productListWithTransferQuantity');
    },
    getProductWithRegionName(data) {
        return getPostRequest(data, 'api/product/getProductWithRegionName');
    },
    yearScheduledPurchasingSave(data) {
        return getPostRequest(data, 'api/product/yearScheduledPurchasingSave');
    },
    productSave(data) {
        return getPostRequest(data, 'api/product/productSave');
    },
    productDelete(data) {
        return getPostRequest(data, 'api/product/productDelete');
    },
    productSwitch(data) {
        return getPostRequest(data, 'api/product/productSwitch');
    },
    //产品禁用或启用
    productDisable(data) {
        return getPostRequest(data, 'api/product/productDisable');
    },
    productInfo(data) {
        return getPostRequest(data, 'api/product/productInfo');
    },
    productReview(data) {
        return getPostRequest(data, 'api/product/productReview');
    },
    productSaleItem(data) {
        return getPostRequest(data, 'api/product/productSaleItem');
    },
    productSimpleInfoList(data) {
        return getPostRequest(data, 'api/product/productSimpleInfoList');
    },


    // 品牌
    brandList(data) {
        return getPostRequest(data, 'api/brand/brandList');
    },
    brandShortList(data) {
        return getPostRequest(data, 'api/brand/brandShortList');
    },
    brandDelete(data) {
        return getPostRequest(data, 'api/brand/brandDelete');
    },
    brandSave(data) {
        return getPostRequest(data, 'api/brand/brandSave');
    },
    brandListByUser(data) {
        return getPostRequest(data, 'api/brand/brandListByUser');
    },
    noBrandForUser(data) {
        return getPostRequest(data, 'api/brand/noBrandForUser');
    },

    // 产品分类
    productCategoryList(data) {
        return getPostRequest(data, 'api/productCategory/productCategoryList');
    },
    productCategoryDelete(data) {
        return getPostRequest(data, 'api/productCategory/productCategoryDelete');
    },
    productCategorySave(data) {
        return getPostRequest(data, 'api/productCategory/productCategorySave');
    },
    productMainCategoryList(data) {
        return getPostRequest(data, 'api/productCategory/productMainCategoryList');
    },
    productSubCategoryList(data) {
        return getPostRequest(data, 'api/productCategory/productSubCategoryList');
    },
    productCategoryAndFeeList(data) {
        return getPostRequest(data, 'api/productCategoryAndFee/list');
    },
    productCategoryAndFeeSave(data) {
        return getPostRequest(data, 'api/productCategoryAndFee/save');
    },

    // 产品颜色
    productColorList(data) {
        return getPostRequest(data, 'api/productColor/productColorList');
    },
    productColorDelete(data) {
        return getPostRequest(data, 'api/productColor/productColorDelete');
    },
    productColorSave(data) {
        return getPostRequest(data, 'api/productColor/productColorSave');
    },

    // 供应商
    supplierList(data) {
        return getPostRequest(data, 'api/supplier/supplierList');
    },
    supplierListByUser(data) {
        return getPostRequest(data, 'api/supplier/supplierListByUser');
    },
    supplierDelete(data) {
        return getPostRequest(data, 'api/supplier/supplierDelete');
    },
    supplierSave(data) {
        return getPostRequest(data, 'api/supplier/supplierSave');
    },
    salesPromotionAndProductCancel(data) {
        return getPostRequest(data, 'api/salesPromotionAndProduct/salesPromotionAndProductCancel');
    },
    supplierActiveList(data) {
        return getPostRequest(data, 'api/supplier/supplierActiveList');
    },
    supplierLisByProductt(data) {
        return getPostRequest(data, 'api/supplier/supplierLisByProductt');
    },

    qiniuToken(data) {
        return getPostRequest(data, 'api/qiniu/token');
    },

    // 供应商关联产品
    productAndSupplierSave(data) {
        return getPostRequest(data, 'api/productAndSupplier/productAndSupplierSave');
    },
    productAndSupplierDelete(data) {
        return getPostRequest(data, 'api/productAndSupplier/productAndSupplierDelete');
    },
    productAndSupplierList(data) {
        return getPostRequest(data, 'api/productAndSupplier/productAndSupplierList');
    },
    productAndSupplierItem(data) {
        return getPostRequest(data, 'api/productAndSupplier/productAndSupplierItem');
    },
    productAndSupplierItemSave(data) {
        return getPostRequest(data, 'api/productAndSupplier/productAndSupplierItemSave');
    },

    // 促销活动
    salesPromotionList(data) {
        return getPostRequest(data, 'api/salesPromotion/salesPromotionList');
    },
    // 生效中的促销活动列表
    salesPromotionActiveList(data) {
        return getPostRequest(data, 'api/salesPromotion/salesPromotionActiveList');
    },
    salesPromotionDelete(data) {
        return getPostRequest(data, 'api/salesPromotion/salesPromotionDelete');
    },
    salesPromotionSave(data) {
        return getPostRequest(data, 'api/salesPromotion/salesPromotionSave');
    },

    // 产品关联促销活动
    salesPromotionAndProductList(data) {
        return getPostRequest(data, 'api/salesPromotionAndProduct/salesPromotionAndProductList');
    },
    salesPromotionAndProductDelete(data) {
        return getPostRequest(data, 'api/salesPromotionAndProduct/salesPromotionAndProductDelete');
    },
    salesPromotionAndProductSave(data) {
        return getPostRequest(data, 'api/salesPromotionAndProduct/salesPromotionAndProductSave');
    },

    // 会员管理
    memberList(data) {
        return getPostRequest(data, 'api/member/memberList');
    },
    //会员个人信息修改
    memberSave(data) {
        return getPostRequest(data, 'api/member/memberSave');
    },
    //会员编辑、审核
    memberEdit(data) {
        return getPostRequest(data, 'api/member/memberEdit');
    },

    //获取省份列表
    districtProvinceList(data) {
        return getPostRequest(data, 'api/district/districtProvinceList');
    },

    //根据省份获取城市列表
    districtCityList(data) {
        return getPostRequest(data, 'api/district/districtCityList');
    },

    //根据城市获取区列表
    districtAreaList(data) {
        return getPostRequest(data, 'api/district/districtAreaList');
    },

    //根据区县获取街道列表
    districtStreetList(data) {
        return getPostRequest(data, '/api/district/districtStreetList');
    },

    // 根据省份获取对应的部门和部门对应的客户经理名称列表
    userListByDeptAndDeptByProvince(data) {
        return getPostRequest(data, 'api/user/userListByDeptAndDeptByProvince');
    },
    //获取经营范围
    parametersScopeList(data) {
        return getPostRequest(data, 'api/parameters/parametersScopeList');
    },
    //获取经营性质
    parametersNatureList(data) {
        return getPostRequest(data, 'api/parameters/parametersNatureList');
    },
    //获取物流列表
    logisticsList(data) {
        return getPostRequest(data, 'api/logistics/logisticsList');
    },

    //获取参数/数据字典列表
    parametersList(data) {
        return getPostRequest(data, 'api/parameters/parametersList');
    },

    // 产品标准
    productStandardList(data) {
        return getPostRequest(data, 'api/productStandard/productStandardList');
    },
    // 审核通过的产品标准列表
    appProductStandardList(data) {
        return getPostRequest(data, 'api/productStandard/appProductStandardList');
    },
    productStandardCancel(data) {
        return getPostRequest(data, 'api/productStandard/productStandardCancel');
    },
    productStandardDelete(data) {
        return getPostRequest(data, 'api/productStandard/productStandardDelete');
    },
    productStandardSave(data) {
        return getPostRequest(data, 'api/productStandard/productStandardSave');
    },
    productStandardHistoryList(data) {
        return getPostRequest(data, 'api/productStandardHistory/productStandardHistoryList');
    },
    productStandardStatus(data) {
        return getPostRequest(data, 'api/productStandard/productStandardStatus');
    },
    productStandardInfo(data) {
        return getPostRequest(data, 'api/productStandard/productStandardInfo');
    },


    //获取会员收货地址列表
    memberAddressList(data) {
        return getPostRequest(data, 'api/memberAddress/memberAddressList');
    },
    //保存会员收货地址
    memberAddressSave(data) {
        return getPostRequest(data, 'api/memberAddress/memberAddressSave');
    },
    //删除会员收货地址
    memberAddressDelete(data) {
        return getPostRequest(data, 'api/memberAddress/memberAddressDelete');
    },

    // 产品审核
    productApprovalList(data) {
        return getPostRequest(data, 'api/productApproval/productApprovalList');
    },
    productApprovalSave(data) {
        return getPostRequest(data, 'api/productApproval/productApprovalSave');
    },
    productApprovalInfo(data) {
        return getPostRequest(data, 'api/productApproval/productApprovalInfo');
    },

    // 客户经理
    productManagerList(data) {
        return getPostRequest(data, 'api/productManager/productManagerList');
    },
    // 客户经理保存
    productManagerSave(data) {
        return getPostRequest(data, 'api/productManager/productManagerSave');
    },
    // 客户经理删除
    productManagerDelete(data) {
        return getPostRequest(data, 'api/productManager/productManagerDelete');
    },
    //箱面信息
    boxFaceList(data) {
        return getPostRequest(data, 'api/order/boxFaceList');
    },
    //保存箱面信息
    boxFaceSave(data) {
        return getPostRequest(data, 'api/order/boxFaceSave');
    },

    // 产品标签
    productTagList(data) {
        return getPostRequest(data, 'api/productTag/productTagList');
    },
    productTagDelete(data) {
        return getPostRequest(data, 'api/productTag/productTagDelete');
    },
    productTagSave(data) {
        return getPostRequest(data, 'api/productTag/productTagSave');
    },
    productTagBindProduct(data) {
        return getPostRequest(data, 'api/productTag/productTagBindProduct');
    },
    ProductTagUnbindProduct(data) {
        return getPostRequest(data, 'api/productTag/ProductTagUnbindProduct');
    },

    //发票抬头列表
    memberInvoiceTitleList(data) {
        return getPostRequest(data, 'api/memberInvoiceTitle/memberInvoiceTitleList');
    },
    //发票抬头审核接口
    memberInvoiceTitleApprove(data) {
        return getPostRequest(data, 'api/memberInvoiceTitle/memberInvoiceTitleApprove');
    },
    //获取发票抬头详情
    memberInvoiceTitleInfo(data) {
        return getPostRequest(data, 'api/memberInvoiceTitle/memberInvoiceTitleInfo');
    },
    //发票抬头删除接口
    memberInvoiceTitleDelete(data) {
        return getPostRequest(data, 'api/memberInvoiceTitle/memberInvoiceTitleDelete');
    },
    //编辑发票抬头接口
    updateMemberInvoiceTitle(data) {
        return getPostRequest(data, 'api/memberInvoiceTitle/updateMemberInvoiceTitle');
    },
    //我看花管理
    shareProductList(data) {
        return getPostRequest(data, 'api/shareProduct/shareProductList');
    },
    //获取我看花详情信息
    shareProductInfo(data) {
        return getPostRequest(data, 'api/shareProduct/shareProductInfo');
    },
    //我看花审核
    shareProductApprove(data) {
        return getPostRequest(data, 'api/shareProduct/shareProductApprove');
    },
    //加了汇总条件的会员列表接口
    memberListSum(data) {
        return getPostRequest(data, 'api/member/memberListSum');
    },
    //新会员列表
    newMemberList(data) {
        return getPostRequest(data, 'api/member/newMemberList');
    }
};
