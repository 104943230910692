<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import MainHeader from '@/view/main-header';
// import MainFooter from '@/view/main-footer';
// import tagsView from '@/view/tagsView';
import '@/style/global.less';
export default {
  name: 'app',
  // components: {
  //     MainHeader,
  //     MainFooter,
  //     tagsView
  // },
  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="less">
.size {
  width: 100%;
  height: 100%;
}
html,
body {
  .size;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  .size;
}

/* ::-webkit-scrollbar {
    display: none;
} */
.pswp {
  z-index: 99999 !important;
}
</style>
