<template>
    <Breadcrumb class="app-breadcrumb" separator="/">
        <BreadcrumbItem v-for="(item,index) in levelList" :key="item.path">
            <span v-if="item.redirect==='noRedirect' || index==levelList.length-1 || !item.parent" class="no-redirect">{{ item.meta.title }}</span>
            <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
        </BreadcrumbItem>
    </Breadcrumb>
</template>

<script>

export default {
    data() {
        return {
            levelList: null
        };
    },
    watch: {
        $route(route) {
            // if you go to the redirect page, do not update the breadcrumbs
            // if (route.path.startsWith('/')) {
            //     return;
            // }
            this.getBreadcrumb();
        }
    },
    created() {
        this.getBreadcrumb();
    },
    methods: {
        getBreadcrumb() {
            // only show routes with meta.title
            let matched = this.$route.matched.filter(item => item.meta && item.meta.title);
            const first = matched[0];

            if (!this.isDashboard(first)) {
                matched = [{ path: '/', meta: { title: '缤纷鲜花' } }].concat(matched);
            }
            this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false);
        },
        isDashboard(route) {
            const name = route && route.name;
            if (!name) {
                return false;
            }
            return name.trim().toLocaleLowerCase() === '缤纷鲜花'.toLocaleLowerCase();
        },
        handleLink(item) {
            const { redirect, path } = item;
            if (redirect) {
                this.$router.push(redirect);
                return;
            }
            this.$router.push(path);
        }
    }
};
</script>

// <style lang="less" scoped>
// .app-breadcrumb.el-breadcrumb {
//     display: inline-block;
//     font-size: 14px;
//     line-height: 50px;
//     margin-left: 8px;

//     .no-redirect {
//         color: #97a8be;
//         cursor: text;
//     }
// }
//
</style>
