<template>
    <Row>
        <Col span="6">
            <Form ref="form" :model="passwordModel" :label-width="120">
                <FormItem label="请输入旧密码：" >
                    <Input type="password" v-model="passwordModel.oldPassword"></Input>
                </FormItem>
                <FormItem label="请输入新密码：">
                    <Input type="password" v-model="passwordModel.password"></Input>
                </FormItem>
                <FormItem label="重输入新密码：">
                    <Input type="password" v-model="passwordModel.passwordConfirm"></Input>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="onSubmit">提交</Button>
                </FormItem>
            </Form>
        </Col>
    </Row>
</template>

<script>
import api from '@/api/api';
export default {
    name: 'ChangePassword',
    data() {
        return {
            passwordModel: {
                oldPassword: '',
                password: '',
                passwordConfirm: ''
            }
        };
    },

    methods: {
        onSubmit() {

            if (!this.passwordModel.oldPassword) {
                return this.$message({
                    message: '请输入旧密码！',
                    type: 'warning'
                });
            }
            this.passwordModel.oldPassword = this.passwordModel.oldPassword.trim();
            if (!this.passwordModel.password) {
                return this.$message({
                    message: '请输入新密码！',
                    type: 'warning'
                });
            }
            this.passwordModel.password = this.passwordModel.password.trim();
            if (!this.passwordModel.passwordConfirm) {
                return this.$message({
                    message: '重输入新密码！',
                    type: 'warning'
                });
            }
            this.passwordModel.passwordConfirm = this.passwordModel.passwordConfirm.trim();
            if (this.passwordModel.passwordConfirm !== this.passwordModel.password) {
                return this.$message({
                    message: '新密码和确认新密码不一致！',
                    type: 'warning'
                });
            }

            api.userChangePassword(this.passwordModel).then(res => {
                if (res.code === 0) {
                    this.passwordModel.oldPassword = '';
                    this.passwordModel.password = '';
                    this.passwordModel.passwordConfirm = '';
                    this.showSuccessModal("修改成功，请用新密码重新登录。");
                    this.$router.push('/system-management/user/user-login');
                }
            });
        }
    },
    components: {}
};
</script>

<style></style>
