<template>
  <div class="">
    <Modal v-model="supplierModal.show" title="供应商信息" :mask-closable="false" width="1000">
      <Spin v-if="supplierModal.loading" size="large" fix></Spin>
      <Form ref="supplierModalForm" :label-width="110">
        <Row>
          <Col span="8">
            <FormItem label="编码:" required>
              <Input v-model="supplierModal.code" readonly></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="名称:" required>
              <Input v-model="supplierModal.name" :disabled="supplierModal.mode === 'modify'"></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="供应商类型:">
              <RadioGroup v-model="supplierModal.supplierType">
                <Radio v-for="(item, index) in supplierTypes" :label="item.ParaName" :key="index">
                  <span>{{ item.ParaName }}</span>
                </Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="集货站:" required>
              <Select v-model="supplierModal.collectCode" filterable style="width: 100%">
                <Option v-for="item in collectList" :value="item.ParaCode" :key="item.ParaCode">{{
                  item.ParaName
                }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="产品经理:" required>
              <Select v-model="supplierModal.joinCode" filterable style="width: 100%">
                <Option v-for="item in joinList" :value="item.ParaCode" :key="item.ParaCode">{{
                  item.ParaName
                }}</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="省市区:">
              <Cascader :data="cityData" v-model="location" />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="街道:">
              <Input v-model="supplierModal.street"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="24">
            <FormItem label="详细地址:">
              <Input v-model="supplierModal.addr"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="手机号:" required>
              <Input v-model="supplierModal.phone"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="微信号:">
              <Input v-model="supplierModal.weiXin"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="电子邮箱:">
              <Input v-model="supplierModal.email"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="联系人:">
              <Input v-model="supplierModal.linkMan"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="账号名称:" required>
              <Input v-model="supplierModal.accountName" :disabled="supplierModal.mode === 'modify'"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="银行卡账号:" required>
              <Input v-model="supplierModal.accountNum"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="开户行:" required>
              <Input v-model="supplierModal.accountBank"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="账户:">
              <Input v-model="supplierModal.accountTitel"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="种植面积:">
              <Input v-model="supplierModal.plantArea"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="提成类型:">
              <RadioGroup v-model="supplierModal.commissionType">
                <Radio label="0" :disabled="!isCommissionRateEdit">
                  <span>按比例提成</span>
                </Radio>
                <Radio label="1" :disabled="!isCommissionRateEdit">
                  <span>按金额提成</span>
                </Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12" v-if="supplierModal.commissionType == 0">
            <FormItem label="比例提成:">
              <Input v-model="supplierModal.commissionRate" type="number" :disabled="!isCommissionRateEdit">
                <template #suffix>
                  <span style="display: inline-block; line-height: 32px">%</span>
                </template>
              </Input>
            </FormItem>
          </Col>
          <Col span="12" v-if="supplierModal.commissionType == 1">
            <FormItem label="金额提成:">
              <Input v-model="supplierModal.amountCommission" type="number" :disabled="!isCommissionRateEdit">
                <template #suffix>
                  <span style="display: inline-block; line-height: 32px">元</span>
                </template>
              </Input>
            </FormItem>
          </Col>
        </Row>
        <Row v-if="supplierModal.mode == 'modify'">
          <Col span="24">
            <FormItem label="销售品种:">
              <Tag color="primary" v-for="item in items" :value="item.Name" :key="item.Name"
                >{{ item.Name }}
                <span
                  style="color: red; font-size: 1rem; cursor: pointer; padding-left: 0.5rem"
                  @click="cancelItem(item.Name, supplierModal.code)"
                  >X</span
                >
              </Tag>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div slot="footer">
        <Button
          v-if="supplierModal.mode == 'modify' && supplierModal.readOnly == false"
          type="success"
          @click="onAddItem"
          >新增品种</Button
        >
        <Button
          v-if="supplierModal.readOnly == false || checkAccessArray('SupplierEditByAdmin')"
          type="info"
          @click="onSaveProduct"
          >保存</Button
        >
      </div>
    </Modal>
    <ProductAndSupplier ref="productAndSupplier" />
  </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import cityData from '@/libs/city.data';
import validate from '@/libs/validate';
import { USER_TYPE_JOIN } from '@/view/common/userType';
import ProductAndSupplier from './product-and-supplier.vue';
export default {
  name: 'supplierModal',
  components: {
    cityData,
    ProductAndSupplier
  },
  data() {
    return {
      USER_TYPE_JOIN,
      supplierModal: {
        show: false,
        loading: false,
        readOnly: false,
        mode: 'add',
        id: '',
        name: '',
        code: '系统自动分配',
        collectCode: '',
        collectName: '',
        supplierType: '',
        province: '',
        city: '',
        area: '',
        street: '',
        addr: '',
        phone: '',
        weiXin: '',
        email: '',
        linkMan: '',
        plantArea: '',
        accountName: '',
        accountNum: '',
        accountBank: '',
        accountTitel: '',
        joinCode: '',
        joinName: '',
        commissionRate: 10,
        commissionType: 0,
        amountCommission: 0
      },
      joinList: [],
      collectList: [],
      cityData,
      location: [],
      supplierTypes: [],
      isCommissionRateEdit: true,
      items: []
    };
  },
  computed: {},
  mounted() {
    this.getSupplierTypeList();
    this.getJoinList();
    this.getCollectList();
  },
  methods: {
    showModal(supplier, readOnly) {
      this.location = [];
      this.supplierModal.show = true;
      this.initData(supplier, readOnly);
    },
    initData(supplier, readOnly) {
      if (supplier) {
        this.supplierModal.readOnly = readOnly;

        this.supplierModal.id = supplier.Id;
        this.supplierModal.name = supplier.Name;
        this.supplierModal.code = supplier.Code;
        this.supplierModal.collectCode = supplier.CollectCode;
        this.supplierModal.collectName = supplier.CollectName;
        this.supplierModal.supplierType = supplier.SupplierType;
        this.supplierModal.province = supplier.Province;
        this.supplierModal.city = supplier.City;
        this.supplierModal.area = supplier.Area;
        this.supplierModal.street = supplier.Street;
        this.supplierModal.addr = supplier.Addr;
        this.supplierModal.phone = supplier.Phone;
        this.supplierModal.weiXin = supplier.WeiXin;
        this.supplierModal.email = supplier.Email;
        this.supplierModal.linkMan = supplier.LinkMan;
        this.supplierModal.plantArea = supplier.PlantArea;
        this.supplierModal.accountName = supplier.AccountName;
        this.supplierModal.accountNum = supplier.AccountNum;
        this.supplierModal.accountBank = supplier.AccountBank;
        this.supplierModal.accountTitel = supplier.AccountTitel;
        this.supplierModal.joinCode = supplier.JoinCode;
        this.supplierModal.joinName = supplier.JoinName;
        this.supplierModal.commissionRate = supplier.CommissionRate;
        if (supplier.CommissionType) {
          this.supplierModal.commissionType = supplier.CommissionType + '';
        } else {
          this.supplierModal.commissionType = '0';
        }
        if (supplier.AmountCommission) {
          this.supplierModal.amountCommission = supplier.AmountCommission;
        } else {
          this.supplierModal.amountCommission = 0;
        }
        this.parseArea(true);
        this.checkCommissionRateEdit();
        this.getProductAndSupplierItem();

        this.supplierModal.mode = 'modify';
      } else {
        this.supplierModal.mode = 'add';
        this.supplierModal.id = '';
        this.supplierModal.name = '';
        this.supplierModal.code = '系统自动分配';
        this.supplierModal.collectCode = '';
        this.supplierModal.collectName = '';
        this.supplierModal.supplierType = '';
        this.supplierModal.province = '';
        this.supplierModal.city = '';
        this.supplierModal.area = '';
        this.supplierModal.street = '';
        this.supplierModal.addr = '';
        this.supplierModal.phone = '';
        this.supplierModal.weiXin = '';
        this.supplierModal.email = '';
        this.supplierModal.linkMan = '';
        this.supplierModal.plantArea = '';
        this.supplierModal.accountName = '';
        this.supplierModal.accountNum = '';
        this.supplierModal.accountBank = '';
        this.supplierModal.accountTitel = '';
        this.supplierModal.joinCode = '';
        this.supplierModal.joinName = '';
        this.supplierModal.commissionRate = 10;
        this.supplierModal.commissionType = 0 + '';
        this.supplierModal.amountCommission = 0;
        this.location = [53, 5301, 530181];

        if (this.getUserType() == this.USER_TYPE_JOIN) {
          this.supplierModal.joinCode = this.getUserOrgCode();
        }
      }
    },

    onSaveProduct() {
      if (this.supplierModal.phone && !validate.validatePhoneNumber(this.supplierModal.phone)) {
        return this.showWarningModal('请输入正确的手机号码！');
      }
      if (this.supplierModal.email && !validate.validateEmail(this.supplierModal.email)) {
        return this.showWarningModal('请输入正确的电子邮箱！');
      }
      if (this.supplierModal.joinCode) {
        this.supplierModal.joinName = this.joinList.find(
          item => item.ParaCode === this.supplierModal.joinCode
        ).ParaName;
      }
      if (this.supplierModal.collectCode) {
        this.supplierModal.collectName = this.collectList.find(
          item => item.ParaCode === this.supplierModal.collectCode
        ).ParaName;
      }

      this.supplierModal.commissionType = parseInt(this.supplierModal.commissionType);

      if (this.supplierModal.commissionType === 0) {
        if (this.supplierModal.commissionRate < 0) {
          return this.showWarningModal('提成比例不允许为负数！');
        }
        if (this.supplierModal.commissionRate > 100) {
          return this.showWarningModal('提成比例不可以超过100%！');
        }
        if (parseInt(this.supplierModal.commissionRate) === parseFloat(this.supplierModal.commissionRate)) {
          this.supplierModal.commissionRate = parseInt(this.supplierModal.commissionRate);
        } else {
          this.supplierModal.commissionRate = parseFloat(parseFloat(this.supplierModal.commissionRate).toFixed(2));
        }
        this.supplierModal.amountCommission = 0;
      } else {
        if (this.supplierModal.amountCommission < 0) {
          return this.showWarningModal('提成金额不允许为负数！');
        }
        if (this.supplierModal.amountCommission > 100) {
          return this.showWarningModal('提成金额不可以超过100！');
        }
        if (parseInt(this.supplierModal.amountCommission) === parseFloat(this.supplierModal.amountCommission)) {
          this.supplierModal.amountCommission = parseInt(this.supplierModal.amountCommission);
        } else {
          this.supplierModal.amountCommission = parseFloat(parseFloat(this.supplierModal.amountCommission).toFixed(2));
        }
        this.supplierModal.commissionRate = 10;
      }

      this.parseArea();
      this.supplierModal.loading = true;
      api.supplierSave(this.supplierModal).then(res => {
        this.supplierModal.loading = false;
        if (res.code === 0) {
          this.supplierModal.show = false;
          this.$parent.getSupplierList();
          this.showSuccessModal();
        }
      });
    },
    parseArea(isEcho = false) {
      if (isEcho) {
        if (!this.supplierModal.province) {
          return;
        }
        if (!this.supplierModal.city) {
          return;
        }
        if (!this.supplierModal.area) {
          return;
        }
        let province = this.cityData.find(item => item.label === this.supplierModal.province);
        if (province) {
          let city = province.children.find(item => item.label === this.supplierModal.city);
          if (city) {
            let area = city.children.find(item => item.label === this.supplierModal.area);
            if (area) {
              this.location = [province.value, city.value, area.value];
            }
          }
        }
      } else {
        if (this.location.length === 0) {
          return;
        }
        let province = this.cityData.find(item => item.value === this.location[0]);
        if (province) {
          let city = province.children.find(item => item.value === this.location[1]);
          if (city) {
            let area = city.children.find(item => item.value === this.location[2]);
            if (area) {
              this.supplierModal.province = province.label;
              this.supplierModal.city = city.label;
              this.supplierModal.area = area.label;
            }
          }
        }
      }
    },
    getSupplierTypeList() {
      let tempSearch = search.getSearchParams({ closeAllConditions: true });
      api.parametersSupplierTypeList(tempSearch).then(res => {
        if (res.code === 0) {
          this.supplierTypes = res.data.records;
        }
      });
    },
    getJoinList() {
      let tempSearch = search.getSearchParams({ closeAllConditions: true });
      api.parametersJoinList(tempSearch).then(res => {
        if (res.code === 0) {
          this.joinList = res.data.records;
        }
      });
    },
    getCollectList() {
      let tempSearch = search.getSearchParams({ closeAllConditions: true });
      api.parametersCollectList(tempSearch).then(res => {
        if (res.code === 0) {
          this.collectList = res.data.records;
        }
      });
    },
    checkCommissionRateEdit() {
      this.isCommissionRateEdit = this.checkAccessArray('SupplierCommissionRateEdit');
    },
    getProductAndSupplierItem() {
      this.items = [];
      api.productAndSupplierItem({ supCode: this.supplierModal.code }).then(res => {
        if (res.code === 0) {
          this.items = res.data.records;
        }
      });
    },
    onAddItem() {
      this.$refs.productAndSupplier.showModal(this.supplierModal.code);
    },
    cancelItem(selectProductName, curSupCode) {
      this.$Modal.confirm({
        title: '确认',
        content: '是否删除该销售品种与当前供应商的绑定关系?',
        onOk: () => {
          api.salesPromotionAndProductCancel({ productName: selectProductName, supCode: curSupCode }).then(res => {
            this.supplierModal.show = false;
            if (res.code === 0) {
              this.$parent.getSupplierList();
              this.showSuccessModal();
            }
          });
        }
      });
    }
  }
};
</script>
<style></style>
