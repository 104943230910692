<template>
	<div class="">
		<div class="searchButtonContainer">
			<div class="flex">
				<search-card
					:form="mainTable.searchForm"
					:table-load-event="getList"
					:table-search="mainTable.tableSearch"
					:handleSearchWhenSelectChange="true"
					:colSpan="4"
					:searchBtnColSpan="4"
				>
					<template #appendBtn>
						<Button
							size="small"
							type="success"
							:disabled="mainTable.tableLoading"
							@click="onExportMemberList"
						>导出</Button>
					</template>
				</search-card>
			</div>
			<div class="flex" style="font-size: 13px !important;color: #196d6d !important;margin-bottom: 8px;">
				<RadioGroup v-model="mainTable.groupBy" size="small" @on-change="onNoLoginDaysGroupByChange" style="margin-left:5px;">
					<Radio :label="-1">全部</Radio>
					<Radio :label="1">欠会员费</Radio>
					<Radio :label="2">即将到期</Radio>
					<Radio :label="3">新注册</Radio>
					<Radio :label="4">VIP申请</Radio>
					<Radio :label="5">失效会员</Radio>
				</RadioGroup>
			</div>
		</div>
		<Row>
			<Col span="24">
			<pagination-table :columns="mainTable.tableColumns" :data="mainTable.tableData"
				:loading="mainTable.tableLoading" :table-search="mainTable.tableSearch"
				:table-load-event="getList" :highlight="true" @on-row-click="onMemberSelect" />
			</Col>
		</Row>

		<Modal v-model="memberModal.show" title="会员信息管理" :mask-closable="false" width="80" @on-cancel="onCancel">
			<Spin v-if="memberModal.loading" size="large" fix></Spin>
			<Form ref="memberModalForm" :model="memberModal" :show-message="false" :rules="ruleValidate" :label-width="130">
				<Row class="u-row-padding">
					<Col span="8">
					<FormItem label="会员号:" prop="code">
						<Input v-model="memberModal.code" disabled></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="名称:" prop="name">
						<Input v-model="memberModal.name"></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="联系人:" prop="linkMan">
						<Input v-model="memberModal.linkMan"></Input>
					</FormItem>
					</Col>
				</Row>
				<Row class="u-row-padding">
					<Col span="8">
					<FormItem label="手机号:" prop="phone">
						<Input v-model="memberModal.phone"></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="微信号:" prop="weiXin">
						<Input v-model="memberModal.weiXin"></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="电子邮箱:" prop="email">
						<Input v-model="memberModal.email"></Input>
					</FormItem>
					</Col>
				</Row>
				<Row class="u-row-padding">
					<!-- <Col span="8">
					<FormItem label="默认账号:">
						<Input v-model="memberModal.defaultAccount"></Input>
					</FormItem>
					</Col> -->
					<Col span="8">
					<FormItem label="会员类型" prop="memberType">
						<Select v-model="memberModal.memberType">
							<Option value="普通会员" key="普通会员">普通会员</Option>
							<Option value="VIP会员" key="VIP会员">VIP会员</Option>
							<Option value="城市战略合作伙伴" key="城市战略合作伙伴">城市战略合作伙伴</Option>
						</Select>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="状态:" prop="state">
						<Select v-model="memberModal.state">
							<Option value="正式" key="正式">正式</Option>
							<Option value="体验" key="体验">体验</Option>
							<Option value="锁定" key="锁定">锁定</Option>
						</Select>
					</FormItem>
					</Col>
				</Row>
				<Row class="u-row-padding">
					<Col span="6">
					<FormItem label="省份:" prop="province">
						<Select v-model="memberModal.province" filterable @on-change="selectedProvince">
							<Option v-for="item in provinceList" :label="item.Province" :value="item.Province"
								:key="item.Province">
								{{ item.Province }}
							</Option>
						</Select>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="城市:" prop="city">
						<Select v-model="memberModal.city" filterable @on-change="selectedCity">
							<Option v-for="item in cityList" :value="item.City" :label="item.City" :key="item.City">
								{{ item.City }}
							</Option>
						</Select>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="区县:" prop="area">
						<Select v-model="memberModal.area" filterable @on-change="selectedArea">
							<Option v-for="item in areaList" :value="item.Area" :label="item.Area" :key="item.Area">
								{{ item.Area }}
							</Option>
						</Select>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="街道:" prop="street">
						<Select v-model="memberModal.street" filterable>
							<Option v-for="item in streetList" :value="item.Street" :label="item.Street"
								:key="item.Street">{{ item.Street }}
							</Option>
						</Select>
					</FormItem>
					</Col>
				</Row>
				<Row class="u-row-padding">
					<Col span="24">
					<FormItem label="详细地址:" prop="addr">
						<Input v-model="memberModal.addr"></Input>
					</FormItem>
					</Col>
				</Row>
				<Row class="u-row-padding">
					<Col span="21">
					<FormItem label="收货地址:" prop="deliAddress">
						<Input v-model="memberModal.deliAddress"></Input>
					</FormItem>
					</Col>
					<Col span="3">
						<!-- <Button @click="defaultAddr()">编辑</Button> -->
						<Button type="info"  style="margin-left:8px" @click="memberAddrList()">会员收货地址</Button>
					</Col>
				</Row>
				<Row class="u-row-padding">
					<Col span="8">
					<FormItem label="服务部门:" prop="serviceDept">
						<Input v-model="memberModal.serviceDept"></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="客户经理:" prop="managerId">
						<Select v-model="memberModal.managerId" filterable>
							<Option v-for="item in managerList" :value="item.Id" :label="item.Name" :key="item.Id">
								{{ item.Name }}
							</Option>
						</Select>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="物流部门:" prop="logisticsDept">
						<Input v-model="memberModal.logisticsDept"></Input>
					</FormItem>
					</Col>
				</Row>
				<Row class="u-row-padding">
					<Col span="8">
					<FormItem label="物流方案:" prop="logistics">
						<Select v-model="memberModal.logistics" filterable @on-change="selectedLogistics">
							<Option v-for="item in logisticsList" :value="item.DeliveryWay" :label="item.DeliveryWay"
								:key="item.DeliveryWay">
								{{ item.DeliveryWay }}
							</Option>
						</Select>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="物流公司:" prop="logisticsName">
						<Input v-model="memberModal.logisticsName"></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="运费预估(元/千克):" prop="freightEstimate">
						<Input v-model="memberModal.freightEstimate"></Input>
					</FormItem>
					</Col>
				</Row>

				<Row class="u-row-padding">
					<Col span="8">
					<FormItem label="申请时间:" prop="applyTime">
						<Input v-model="memberModal.applyTime" disabled></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="到期时间:" prop="recentTime">
						<Input v-model="memberModal.recentTime" disabled></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="登录次数:" prop="loginNum">
						<Input v-model.number="memberModal.loginNum" disabled></Input>
					</FormItem>
					</Col>
				</Row>

				<Row class="u-row-padding">
					<Col span="8">
					<FormItem label="允许余额为负:" prop="isCanArrears">
						<RadioGroup v-model="memberModal.isCanArrears">
							<Radio :label="1">是</Radio>
							<Radio :label="0">否</Radio>
						</RadioGroup>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="银行名称:" prop="bank">
						<Input v-model="memberModal.bank"></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="支行名称:" prop="subbranch">
						<Input v-model="memberModal.subbranch"></Input>
					</FormItem>
					</Col>
					<!-- <Col span="8">
					<FormItem label="密码:" required>
						<Input v-model="memberModal.pwd"></Input>
					</FormItem>
					</Col> -->
				</Row>
				<Row class="u-row-padding">
					<Col span="8">
					<FormItem label="账户姓名:"  prop="accountName">
						<Input v-model="memberModal.accountName"></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="银行账号:" prop="defaultAccount">
						<Input v-model="memberModal.defaultAccount"></Input>
					</FormItem>
					</Col>
				</Row>
				<Row class="u-row-padding">
					<Col span="12">
					<FormItem label="经营范围:" prop="scopeList">
						<CheckboxGroup v-model="memberModal.scopeList">
							<Checkbox v-for="item in scopeCheckBoxList" :key="item.ParaName" :label="item.ParaName"></Checkbox>
						</CheckboxGroup>
					</FormItem>
					</Col>
					<Col span="12">
					<FormItem label="营业性质:" prop="natureList">
						<CheckboxGroup v-model="memberModal.natureList">
							<Checkbox v-for="item in natureCheckBoxList" :key="item.ParaName" :label="item.ParaName"></Checkbox>
						</CheckboxGroup>
					</FormItem>
					</Col>
				</Row>
				<Row class="u-row-padding">
					<Col span="24">
					<FormItem label="备注:" prop="remark">
						<Input v-model="memberModal.remark"></Input>
					</FormItem>
					</Col>
				</Row>
				<!-- <Row v-if="!this.memberModal.reviewTime">
					<Col span="8">
					<FormItem label="审核人:">
						<Input v-model="memberModal.reviewer" disabled></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="审核时间:">
						<Input v-model="memberModal.reviewTime" disabled></Input>
					</FormItem>
					</Col>
				</Row> -->

			</Form>
			<div slot="footer">
				<Button @click="onCancel()">关闭</Button>
				<Button type="info" @click="onSaveMember()">保存</Button>
			</div>

			<MemberAddress ref="memberAddressModal"></MemberAddress>
		</Modal>

	</div>
</template>

<script>
	import api from '@/api/api';
	import search from '@/libs/search';
	import PaginationTable from '@/components/pagination-table.vue';
	import SearchCard from '@/components/searchCard.vue';
	import BaseTableFunction from '@/components/base-table-function.vue';
	import MemberAddress from './memberAddress.vue';
	import validate from "@/libs/validate";
	export default {
		name: 'MemberList',
		components: {
			PaginationTable,
			SearchCard,
			BaseTableFunction,
			MemberAddress
		},
		data() {
			return {
				codeOrder:0,
				lastLoginTimeOrder: 0,
				applyTimeOrder: 0,
				recentTimeOrder: 0,
				memberModal: {
					show: false,
					loading: false,
					id:'',
					code: '',
					name: '',
					linkMan: '',
					phone: '',
					weiXin: '',
					email: '',
					memberType: '',
					state: '',
					logisticsDept: '鲜花物流部',
					logistics: '',
					logisticsName: '',
					loginNum: 0,
					recentTime: '',
					applyTime: '',
					serviceDept: '',
					manager: '',
					managerId:'',
					isCanArrears: 0,
					province: '',
					city: '',
					area: '',
					street: '',
					addr: '',
					deliAddress: '',
					scopeList: [],
					natureList: [],
					pwd: '',
					//reviewTime: '',
					//reviewer: '',
					remark: '',
					mode: 'add',
					freightEstimate:0,
					defaultAccount: '',
					accountName:'',
					bank:'',
					subbranch:''

				},
				provinceList: [],
				cityList: [],
				areaList: [],
				streetList: [],
				managerList: [],
				logisticsList: [],
				noLoginDays:null,//未登录天数
				expireDays:null,//到期天数
				scopeCheckBoxList:[],//经营范围
				natureCheckBoxList:[],//经营性质
				mainTable: {
					searchType:'',
					groupBy:-1,
					memberType:'',
					isNewMember:false,
					tableColumns: [{
							type: 'index',
							width: 40,
							align: 'center'
						},
						{
							title: '会员',
							key: "Code",
							align: 'center',
							excel: true,
							renderHeader: (h, { column, index }) => {
							    return h('div',
							        [
							            h('span', {}, column.title),
							            h('icon', {
							                props: {
							                    type: this.codeOrderIcon,
							                    size: 16
							                },
							                style: {
							                    marginLeft: '4px'
							                },
							                on: {
							                    click: () => {
							                        this.onCodeOrderChange();
							                    }
							                }
							            })
							        ]
							    );
							}
						},
						{
							title: '名称',
							key: "Name",
							align: 'center',
							excel: true
						},
						{
							title: '会员类型',
							key: "MemberType",
							align: 'center',
							excel: true
						},
						{
							title: '状态',
							key: "State",
							align: 'center',
							excel: true
						},
						{
							title: '联系人',
							key: "LinkMan",
							align: 'center',
							excel: true
						},
						{
							title: '手机号',
							key: "Phone",
							align: 'center',
							excel: true
						},
						{
							title: '省份',
							key: "Province",
							align: 'center',
							excel: true
						},
						{
							title: '城市',
							key: "City",
							align: 'center',
							excel: true
						},
						{
							title: '余额',
							key: "Balance",
							align: 'center',
							excel: true
						},
						{
							title: '冻结金额',
							key: "FrozenAmount",
							align: 'center',
							excel: true
						},
						{
							title: '登录次数',
							key: "LoginNum",
							align: 'center',
							excel: true
						},
						{
							title: '最后登录时间',
							key: "LastLoginTime",
							align: 'center',
							excel: true,
							renderHeader: (h, { column, index }) => {
							    return h('div',
							        [
							            h('span', {}, column.title),
							            h('icon', {
							                props: {
							                    type: this.lastLoginTimeOrderIcon,
							                    size: 16
							                },
							                style: {
							                    marginLeft: '4px'
							                },
							                on: {
							                    click: () => {
							                        this.onLastLoginTimeOrderChange();
							                    }
							                }
							            })
							        ]
							    );
							}
						},
						{
							title: '最后订购时间',
							key: "LastOrderTime",
							align: 'center',
							excel: true
						},
						{
							title: '未登录天数',
							key: "NoLoginDays",
							align: 'center',
							excel: true
						},
						{
							title: '未订购天数',
							key: "NoOrderDays",
							align: 'center',
							excel: true
						},
						{
							title: '申请时间',
							key: "ApplyTime",
							align: 'center',
							excel: true,
							renderHeader: (h, { column, index }) => {
							    return h('div',
							        [
							            h('span', {}, column.title),
							            h('icon', {
							                props: {
							                    type: this.applyTimeOrderIcon,
							                    size: 16
							                },
							                style: {
							                    marginLeft: '4px'
							                },
							                on: {
							                    click: () => {
							                        this.onApplyTimeOrderChange();
							                    }
							                }
							            })
							        ]
							    );
							}
						},
						{
							title: '到期时间',
							key: "RecentTime",
							align: 'center',
							excel: true,
							renderHeader: (h, { column, index }) => {
							    return h('div',
							        [
							            h('span', {}, column.title),
							            h('icon', {
							                props: {
							                    type: this.recentTimeOrderIcon,
							                    size: 16
							                },
							                style: {
							                    marginLeft: '4px'
							                },
							                on: {
							                    click: () => {
							                        this.onRecentTimeOrderChange();
							                    }
							                }
							            })
							        ]
							    );
							}
						},
						{
							title: '客户经理',
							key: "Manager",
							align: 'center',
							excel: true
						},
						{
							title: "操作",
							align: "center",
							width: 80,
							render: (h, params) => {
								return h('div', [
									h('Button', {
										props: {
											size: 'small',
											shape: 'circle',
											statusField: 'Status',
											showEdit: this.checkAccessArray('MemberEdit')
										},
										style: {
											margin: '2px'
										},
										on: {
											click: () => {
												this.onModifyMember(params.row.Id);
											}
										}
									}, '编辑')
								]);
							}
						}
					],
					tableData: [],
					tableLoading: false,
					tableSearch: search.getSearchParams(),
					searchForm: [
						{
							label: '会员类型',type: 'Select',value: '',name: 'MemberType', sql: 'eq', 	clearable: true,
							options: [{
									key: '普通会员',
									value: '普通会员'
								},{
									key: 'VIP会员',
									value: 'VIP会员'
								},
								{
									key: '城市战略合作伙伴',
									value: '城市战略合作伙伴'
								}
							]
						},
						{
							label: '会员状态',type: 'Select',value: '',name: 'State', sql: 'eq', 	clearable: true,
							options: [{
									key: '删除',
									value: '删除'
								},{
									key: '锁定',
									value: '锁定'
								},
								{
									key: '正式',
									value: '正式'
								},
								{
									key: '体验',
									value: '体验'
								}
							]
						},
						{
							label: '未登录时间',type: 'Select',value: '',name: 'NoLoginDays', 	clearable: true, labelWidth: 100,
							sql: {
								"7": "ge",
								"30": "ge",
								"60": "ge",
								"90": "ge",
							},
							options: [{
									key: '1周未登录',
									value: '7'
								},{
									key: '1个月未登录',
									value: '30'
								},
								{
									key: '2个月未登录',
									value: '60'
								},
								{
									key: '3个月未登录',
									value: '90'
								},
							]
						},
						{
							label: '未订货时间',type: 'Select',value: '',name: 'NoOrderDays', clearable: true, labelWidth: 100,
							sql: {
								"7": "gt",
								"30": "ge",
							},
							options: [{
									key: '1周未订货',
									value: '7'
								},{
									key: '1个月未订货',
									value: '30'
								},
							]
						},
						{ label: '查询项', type: 'SelectInput', value: ['Code'], name: '', clearable: false, fieldWidth: 200, labelWidth: 70,
								sql: {
									"Code": 'eq',
									"Name": 'like',
									"LinkMan": 'eq',
									"Phone": 'eq',
									"Manager" : 'like'
								},
						    options: [
									{ key: '会员号', value: 'Code'},
									{ key: '会员名称', value: 'Name'},
									{ key: '联系人', value: 'LinkMan'},
									{ key: '联系电话', value: 'Phone'},
									{ key: '客户经理', value: 'Manager'},
						    ],
						    input:{ label: '关键字', type: 'Input', value: '', placeholder:'请输入查询信息' , name: '', labelWidth: 70 ,clearable: true}
						},
					]
				},
				selectedMember: null,
				ruleValidate: {
					name: [{
						required: true,
						message: '名称不能为空',
						trigger: 'blur'
					}],
					linkMan: [{
						required: true,
						message: '联系人不能为空',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '手机号不能为空',
						trigger: 'blur'
					}],
					state: [{
						required: true,
						message: '状态不能为空',
						trigger: ["blur",'change']
					}],
					province: [{
						required: true,
						message: '省份不能为空',
						trigger: ["blur",'change']
					}],
					city: [{
						required: true,
						message: '市不能为空',
						trigger: ["blur",'change']
					}],
					area: [{
						required: true,
						message: '区县不能为空',
						trigger: ["blur",'change']
					}],
					street: [{
						required: true,
						message: '街道不能为空',
						trigger: ["blur",'change']
					}],
					addr: [{
						required: true,
						message: '详细地址不能为空',
						trigger: 'blur'
					}],
					deliAddress: [{
						required: true,
						message: '收货地址不能为空',
						trigger: 'blur'
					}],
					serviceDept: [{
						required: true,
						message: '服务部门不能为空',
						trigger: 'blur'
					}],
					managerId: [{
						required: true,
						message: '客户经理不能为空',
						trigger: ["blur",'change']
					}],
					logistics: [{
						required: true,
						message: '物流方案不能为空',
						trigger: ["blur",'change']
					}],
					isCanArrears: [{
						required: true,
						message: '是否允许余额为负不能为空',
						type: 'number',
						trigger: 'blur'
					}]
				}
			};
		},
		computed: {
			codeOrderIcon() {
			    if (this.codeOrder == 1) {
			        return 'md-arrow-round-up';
			    }
			    else if (this.codeOrder == 2) {
			        return 'md-arrow-round-down';
			    }
			    else {
			        return 'md-reorder';
			    }
			},
			lastLoginTimeOrderIcon() {
			    if (this.lastLoginTimeOrder == 1) {
			        return 'md-arrow-round-up';
			    }
			    else if (this.lastLoginTimeOrder == 2) {
			        return 'md-arrow-round-down';
			    }
			    else {
			        return 'md-reorder';
			    }
			},
			applyTimeOrderIcon() {
			    if (this.applyTimeOrder == 1) {
			        return 'md-arrow-round-up';
			    }
			    else if (this.applyTimeOrder == 2) {
			        return 'md-arrow-round-down';
			    }
			    else {
			        return 'md-reorder';
			    }
			},
			recentTimeOrderIcon() {
			    if (this.recentTimeOrder == 1) {
			        return 'md-arrow-round-up';
			    }
			    else if (this.recentTimeOrder == 2) {
			        return 'md-arrow-round-down';
			    }
			    else {
			        return 'md-reorder';
			    }
			},
		},
		async mounted() {
			await this.getMemberList();
		},
		methods: {
			//切换排序
			//会员号
			onCodeOrderChange(){
				this.codeOrder = (this.codeOrder + 1) % 3;
				this.getList();
			},
			//最后登录时间
			onLastLoginTimeOrderChange(){
				this.lastLoginTimeOrder = (this.lastLoginTimeOrder + 1) % 3;
				this.getList();
			},
			//申请时间
			onApplyTimeOrderChange(){
				this.applyTimeOrder = (this.applyTimeOrder + 1) % 3;
				this.getList();
			},
			//到期时间
			onRecentTimeOrderChange(){
				this.recentTimeOrder = (this.recentTimeOrder + 1) % 3;
				this.getList();
			},
			//排序条件
			setOrderCondition(){
				this.mainTable.tableSearch.order.enable = false;
				this.mainTable.tableSearch.order.conditions = [];
				//会员号
				if (this.codeOrder == 1) {
				    this.mainTable.tableSearch.order.conditions.push({
				        name: "Code",
				        type: "asc"
				    });
				    this.mainTable.tableSearch.order.enable = true;
				}
				else if (this.codeOrder == 2) {
				    this.mainTable.tableSearch.order.conditions.push({
				        name: "Code",
				        type: "desc"
				    });
				    this.mainTable.tableSearch.order.enable = true;
				}
				//最后登录时间
				if (this.lastLoginTimeOrder == 1) {
				    this.mainTable.tableSearch.order.conditions.push({
				        name: "LastLoginTime",
				        type: "asc"
				    });
				    this.mainTable.tableSearch.order.enable = true;
				}
				else if (this.lastLoginTimeOrder == 2) {
				    this.mainTable.tableSearch.order.conditions.push({
				        name: "LastLoginTime",
				        type: "desc"
				    });
				    this.mainTable.tableSearch.order.enable = true;
				}
				//申请时间
				if (this.applyTimeOrder == 1) {
				    this.mainTable.tableSearch.order.conditions.push({
				        name: "ApplyTime",
				        type: "asc"
				    });
				    this.mainTable.tableSearch.order.enable = true;
				}
				else if (this.applyTimeOrder == 2) {
				    this.mainTable.tableSearch.order.conditions.push({
				        name: "ApplyTime",
				        type: "desc"
				    });
				    this.mainTable.tableSearch.order.enable = true;
				}
				//到期时间
				if (this.recentTimeOrder == 1) {
				    this.mainTable.tableSearch.order.conditions.push({
				        name: "RecentTime",
				        type: "asc"
				    });
				    this.mainTable.tableSearch.order.enable = true;
				}
				else if (this.recentTimeOrder == 2) {
				    this.mainTable.tableSearch.order.conditions.push({
				        name: "RecentTime",
				        type: "desc"
				    });
				    this.mainTable.tableSearch.order.enable = true;
				}
			},

			//获取经营范围
			getScopeCheckBoxList() {
				api.parametersScopeList({}).then(res => {
					if (res.code === 0) {
						this.scopeCheckBoxList = res.data.records;
					}
				});
			},
			//获取经营性质
			getNatureCheckBoxList() {
				api.parametersNatureList({}).then(res => {
					if (res.code === 0) {
						this.natureCheckBoxList = res.data.records;
					}
				});
			},
			//获取省份
			getProvinceList() {
				api.districtProvinceList({}).then(res => {
					if (res.code === 0) {
						this.provinceList = res.data.records;
						this.getUserListByDeptAndDept();
					}
				});
			},
			//选择省份
			selectedProvince(e) {
				this.memberModal.province = e;
				this.memberModal.city='';
				this.memberModal.area='';
				this.memberModal.street='';
				this.getDistrictCityList();
				this.getUserListByDeptAndDept();
			},

			//根据省份获取城市列表
			getDistrictCityList() {
				api.districtCityList({
					province: this.memberModal.province
				}).then(res => {
					if (res.code === 0) {
						this.cityList = res.data.records;
						this.getLogisticsList();
					}
				});
			},
			//选择城市
			selectedCity(e) {
				this.memberModal.city = e;
				this.memberModal.area='';
				this.memberModal.street='';
				this.getDistrictAreaList();
				this.memberModal.freightEstimate=0;
				this.getLogisticsList();
				this.getFreight();
			},
			//根据城市获取区列表
			getDistrictAreaList() {
				api.districtAreaList({
					province: this.memberModal.province,
					city: this.memberModal.city,
				}).then(res => {
					if (res.code === 0) {
						this.areaList = res.data.records;
					}
				});
			},
			//选择区
			selectedArea(e) {
				this.memberModal.area = e;
				this.memberModal.street='';
				this.getDistrictStreetList()
			},
			//根据区县获取街道列表
			getDistrictStreetList() {
				api.districtStreetList({
					province: this.memberModal.province,
					city: this.memberModal.city,
					area: this.memberModal.area
				}).then(res => {
					if (res.code === 0) {
						this.streetList = res.data.records;
					}
				});
			},
			// 根据省份获取对应的部门和部门对应的客户经理名称列表
			getUserListByDeptAndDept() {
				if(this.memberModal.province){
					api.userListByDeptAndDeptByProvince({
						province: this.memberModal.province
					}).then(res => {
						if (res.code === 0) {
							this.memberModal.serviceDept = res.data.serviceDept;
							this.managerList = res.data.managerList;
						}
					});
				}
			},
			//获取物流方案
			getLogisticsList() {
				if(this.memberModal.city){
					let tempSearch = {
						filters: {
							enable: true,
							join: "and",
							conditions: [{
								name: 'Destination',
								type: 'eq',
								value: this.memberModal.city
							}]
						}
					}
					api.freightConfigCityList(tempSearch).then(res => {
						if (res.code === 0) {
							this.logisticsList = res.data.records;
						}
					});
				}
			},
			//选择物流方案
			selectedLogistics(e){
				this.memberModal.logistics=e;
				this.memberModal.freightEstimate=0;
				this.getFreight();
			},
			//根据物流方案和用户城市获取运费预估
			getFreight() {
				if(!!this.memberModal.logistics&&!!this.memberModal.city){
					let tempSearch = {
						filters: {
							enable: true,
							join: "and",
							conditions: [{
								name: 'DeliveryWay',
								type: 'eq',
								value: this.memberModal.logistics
							},{
								name: 'Destination',
								type: 'eq',
								value: this.memberModal.city
							},{
								name: 'IsEnable',
								type: 'eq',
								value: '1'
							}
							]
						}
					}
					api.freightConfigList(tempSearch).then(res => {
						if (res.code === 0&&res.data.records.length>0) {
							this.memberModal.freightEstimate = res.data.records[0].PricePerKg;
						}
					});
				}
			},
			onMemberSelect(row, index) {
				this.selectedMember = row;
			},
			onAddMember() {
				this.memberModal.mode = 'add';
				this.memberModal.id = '';
				this.memberModal.code = '';
				this.memberModal.name = '';
				this.memberModal.pwd = '';
				this.memberModal.linkMan = '';
				this.memberModal.phone = '';
				this.memberModal.weiXin = '';
				this.memberModal.email = '';
				this.memberModal.introducDept = '';
				this.memberModal.introducer = '';
				this.memberModal.province = '';
				this.memberModal.city = '';
				this.memberModal.area = '';
				this.memberModal.street = '';
				this.memberModal.addr = '';
				this.memberModal.deliAddress = '';
				this.memberModal.memberType = '';
				this.memberModal.serviceDept = '';
				this.memberModal.manager = '';
				this.memberModal.scopeList = [];
				this.memberModal.natureList = [];
				this.memberModal.logisticsDept = '鲜花物流部';
				this.memberModal.logistics = '';
				this.memberModal.logisticsName = '';
				this.memberModal.loginNum = 0;
				this.memberModal.recentTime = '';
				this.memberModal.applyTime = '';
				this.memberModal.state = '';
				//this.memberModal.reviewTime = '';
				//this.memberModal.reviewer = '';
				this.memberModal.remark = '';
				this.memberModal.isCanArrears = 0;
				this.memberModal.serviceDeptId = '';
				this.memberModal.managerId = '';
				this.memberModal.defaultAccount = '';
				this.memberModal.accountName = '';
				this.memberModal.bank= '';
				this.memberModal.subbranch = '';
			},
			onMember(id) {
				let b = this.mainTable.tableData.find(e => {
					return e.Id === id;
				});
				this.memberModal.id = b.Id;
				this.memberModal.code = b.Code;
				this.memberModal.name = b.Name;
				this.memberModal.pwd = b.Pwd;
				this.memberModal.linkMan = b.LinkMan;
				this.memberModal.phone = b.Phone;
				this.memberModal.weiXin = b.WeiXin;
				this.memberModal.email = b.Email;
				this.memberModal.introducDept = b.IntroducDept;
				this.memberModal.introducer = b.Introducer;
				this.memberModal.province = b.Province;
				this.memberModal.city = b.City;
				this.memberModal.area = b.Area;
				this.memberModal.street = b.Street;
				this.memberModal.addr = b.Addr;
				this.memberModal.deliAddress = b.DeliAddress;
				this.memberModal.memberType = b.MemberType;
				this.memberModal.serviceDept = b.ServiceDept;
				this.memberModal.manager = b.Manager;
				this.memberModal.scopeList = b.ScopeList ? b.ScopeList.split(';') : [];
				this.memberModal.natureList = b.NatureList ? b.NatureList.split(';') : [];
				this.memberModal.logisticsDept ='鲜花物流部';
				this.memberModal.logistics = b.Logistics;
				this.memberModal.logisticsName = b.LogisticsName;
				this.memberModal.loginNum = b.LoginNum;
				this.memberModal.lastLoginTime = b.LastLoginTime;
				this.memberModal.loginIP = b.LoginIP;
				this.memberModal.recentTime = b.RecentTime;
				this.memberModal.applyTime = b.ApplyTime;
				this.memberModal.state = b.State;
				this.memberModal.remark = b.Remark;
				this.memberModal.isCanArrears = b.IsCanArrears;
				this.memberModal.serviceDeptId = b.ServiceDeptId;
				this.memberModal.managerId = b.ManagerId;
				this.memberModal.defaultAccount = b.DefaultAccount;
				this.memberModal.accountName = b.AccountName;
				this.memberModal.bank = b.Bank;
				this.memberModal.subbranch = b.Subbranch;
			},
			onModifyMember(id) {
				this.memberModal.show = true;
				this.onMember(id);
				this.getProvinceList();
				this.getDistrictCityList();
				this.getDistrictAreaList();
				this.getDistrictStreetList();
				this.getLogisticsList();
				this.getScopeCheckBoxList();
				this.getNatureCheckBoxList();
				this.getFreight();
				this.memberModal.mode = 'modify';
			},
			onSaveMember() {
				this.memberModal.loading = true;
				if(!this.memberModal.name){
					this.showWarningModal('名称不能为空!');
					this.memberModal.loading = false;
					return;
				}
				if(!this.memberModal.linkMan){
					this.showWarningModal('联系人不能为空!');
					this.memberModal.loading = false;
					return;
				}
				if(!this.memberModal.phone){
					this.showWarningModal('手机号不能为空!');
					this.memberModal.loading = false;
					return;
				}
				if(!this.memberModal.state){
					this.showWarningModal('状态不能为空!');
					this.memberModal.loading = false;
					return;
				}
				if(!this.memberModal.province){
					this.showWarningModal('省份不能为空!');
					this.memberModal.loading = false;
					return;
				}
				if(!this.memberModal.city){
					this.showWarningModal('城市不能为空!');
					this.memberModal.loading = false;
					return;
				}
				if(!this.memberModal.area){
					this.showWarningModal('区县不能为空!');
					this.memberModal.loading = false;
					return;
				}
				if(!this.memberModal.street){
					this.showWarningModal('街道不能为空!');
					this.memberModal.loading = false;
					return;
				}
				if(!this.memberModal.addr){
					this.showWarningModal('详细地址不能为空!');
					this.memberModal.loading = false;
					return;
				}
				if(!this.memberModal.deliAddress){
					this.showWarningModal('收货地址不能为空!');
					this.memberModal.loading = false;
					return;
				}
				if(!this.memberModal.serviceDept){
					this.showWarningModal('服务部门不能为空!');
					this.memberModal.loading = false;
					return;
				}
				if(!this.memberModal.managerId){
					this.showWarningModal('客户经理不能为空!');
					this.memberModal.loading = false;
					return;
				}
				if(!this.memberModal.logistics){
					this.showWarningModal('物流方案不能为空!');
					this.memberModal.loading = false;
					return;
				}
				if(this.memberModal.accountName){
					if(this.memberModal.accountName!==this.memberModal.linkMan){
						this.showWarningModal('账户姓名需与预留联系人姓名一致!');
						this.memberModal.loading = false;
						return;
					}
				}
				if(this.memberModal.defaultAccount){
					if(!this.memberModal.bank){
						this.showWarningModal('银行名称不能为空!');
						this.memberModal.loading = false;
						return;
					}
					if(!this.memberModal.subbranch){
						this.showWarningModal('支行名称不能为空!');
						this.memberModal.loading = false;
						return;
					}
					if(!validate.validateBankAccount(this.memberModal.defaultAccount)){
						this.showWarningModal('请输入正确的银行卡号!');
						this.memberModal.loading = false;
						return;
					}
				}
				let scopeList = this.memberModal.scopeList;
				let scope = scopeList.join(';');
				let natureList = this.memberModal.natureList;
				let nature = natureList.join(';');
				if(this.memberModal.managerId) {
				    this.memberModal.manager = this.managerList.find(item => item.Id === this.memberModal.managerId).Name;
				}
				let dataForm={
					mode:this.memberModal.mode,
					id:this.memberModal.id,
					code:this.memberModal.code,
					name:this.memberModal.name,
					linkMan :this.memberModal.linkMan,
					phone:this.memberModal.phone,
					weiXin :this.memberModal.weiXin,
					email:this.memberModal.email,
					province:this.memberModal.province,
					city:this.memberModal.city,
					area:this.memberModal.area,
					street:this.memberModal.street,
					addr :this.memberModal.addr,
					deliAddress :this.memberModal.deliAddress,
					memberType :this.memberModal.memberType,
					serviceDept :this.memberModal.serviceDept,
					manager :this.memberModal.manager,
					scopeList:scope,
					natureList:nature,
					logisticsDept:this.memberModal.logisticsDept,
					logistics :this.memberModal.logistics,
					logisticsName:this.memberModal.logisticsName,
					loginNum :this.memberModal.loginNum,
					recentTime:this.memberModal.recentTime,
					applyTime:this.memberModal.applyTime,
					state :this.memberModal.state,
					//reviewTime :this.memberModal.reviewTime,
					//reviewer:this.memberModal.reviewer,
					remark :this.memberModal.remark,
					isCanArrears :this.memberModal.isCanArrears,
					managerId :this.memberModal.managerId,
					defaultAccount:this.memberModal.defaultAccount,
					accountName:this.memberModal.accountName,
					bank:this.memberModal.bank,
					subbranch:this.memberModal.subbranch,
				}
				api.memberEdit(dataForm).then(res => {
					this.memberModal.loading = false;
					if (res.code === 0) {
						this.memberModal.show = false;
						this.getMemberList();
						this.showSuccessModal();
					}
				});
			},
			getList(){
				if(this.mainTable.groupBy==3){
					this.getNewMemberList();
				}else if(this.mainTable.groupBy==4){
					this.getVipMemberList();
				}else{
					this.getMemberList();
				}
			},
			//更改普通会员登录天数
			onNoLoginDaysGroupByChange(val) {
				this.mainTable.groupBy=val;
				if(this.mainTable.groupBy==4){
					this.mainTable.searchType='';
					this.mainTable.memberType='';
				}else{
					this.mainTable.searchType='';
				}
				this.clearFilters();
				this.getList();
			},
			//新会员列表
			getNewMemberList() {
				this.mainTable.tableLoading = true;
				this.mainTable.tableSearch.filters.enable = true;
				if(this.mainTable.groupBy==3&&this.mainTable.memberType){
					this.mainTable.tableSearch.filters.conditions.push({ name: 'MemberType', type: 'eq', value:this.mainTable.memberType});
				}
				this.setOrderCondition();
				api.newMemberList(this.mainTable.tableSearch).then(res => {
					this.mainTable.tableLoading = false;
					if (res.code === 0) {
						this.selectedMember = null;
						this.mainTable.tableData = res.data.records;
						search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
					}
				});
			},
			//会员汇总列表
			getMemberList() {
				this.mainTable.tableLoading = true;
				this.mainTable.tableSearch.filters.enable = true;

				this.setOrderCondition();
				api.memberListSum(this.mainTable.tableSearch).then(res => {
					this.mainTable.tableLoading = false;
					if (res.code === 0) {
						this.selectedMember = null;
						this.mainTable.tableData = res.data.records;
						search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
					}
				});
			},
			getVipMemberList(){
				this.mainTable.tableLoading = true;
				this.mainTable.tableSearch.filters.enable = true;
				if(this.mainTable.groupBy==4){
					this.mainTable.tableSearch.filters.conditions.push({ name: 'AutoVip', type: 'eq', value:'2'});
				}
				this.setOrderCondition();
				api.memberList(this.mainTable.tableSearch).then(res => {
					this.mainTable.tableLoading = false;
					if (res.code === 0) {
						this.selectedMember = null;
						this.mainTable.tableData = res.data.records;
						search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
					}
				});
			},
			//切换条件时清空上次的条件
			clearFilters(){
				search.globalRemoveFilterConditionKey(this.mainTable.tableSearch, 'NoLoginDays','gt');
				search.globalRemoveFilterConditionKey(this.mainTable.tableSearch, 'NoLoginDays','lt');
				search.globalRemoveFilterConditionKey(this.mainTable.tableSearch, 'NoLoginDays','ge');
				search.globalRemoveFilterConditionKey(this.mainTable.tableSearch, 'ExpireDays','ge');
				search.globalRemoveFilterConditionKey(this.mainTable.tableSearch, 'ExpireDays','lt');
				search.globalRemoveFilterConditionKey(this.mainTable.tableSearch, 'NoOrderDays','gt');
				search.globalRemoveFilterConditionKey(this.mainTable.tableSearch, 'NoOrderDays','lt');
				search.globalRemoveFilterConditionKey(this.mainTable.tableSearch, 'NoOrderDays','ge');
				search.globalRemoveFilterConditionKey(this.mainTable.tableSearch, 'MemberType');
				search.globalRemoveFilterConditionKey(this.mainTable.tableSearch, 'AutoVip');
			},
			//导出
			onExportMemberList() {
			    this.mainTable.tableSearch.excelExport.enable = true;
			    this.mainTable.tableSearch.excelExport.columns = this.mainTable.tableColumns
			        .filter(col => col.excel)
			        .map(col => {
			            return {
			                Key: col.key,
			                Title: col.title
			            };
			        });
                this.mainTable.tableSearch.excelExport.columns.push({Key:"DeliAddress",Title:'收货地址'});
                this.mainTable.tableSearch.excelExport.columns.push({Key:"Logistics",Title:'物流方案'});
			    this.mainTable.tableLoading = true;
				if(this.mainTable.groupBy==3){
					api.newMemberList(this.mainTable.tableSearch).then(res => {
					    this.mainTable.tableSearch.excelExport.enable = false;
					    if (res.code === 0) {
					        let url = api.excelServerUrl + res.data.fileName;
					        this.$downLoadFile(url);
					    }
					    this.mainTable.tableLoading = false;
					});
				}else{
					api.memberListSum(this.mainTable.tableSearch).then(res => {
					    this.mainTable.tableSearch.excelExport.enable = false;
					    if (res.code === 0) {
					        let url = api.excelServerUrl + res.data.fileName;
					        this.$downLoadFile(url);
					    }
					    this.mainTable.tableLoading = false;
					});
				}
			},
			memberAddrList(id){
				this.$refs.memberAddressModal.showModal(this.memberModal.id);
			},
			// //关闭弹窗
			onCancel(){
				this.$refs.memberModalForm.resetFields();
				this.memberModal.show = false;
			}
		}

	};
</script>
<style>
	.u-row-padding{
		margin-bottom:10px !important;
	}
</style>
