<template>
	<div class="">
		<div class="flex searchButtonContainer">
			<search-card :form="mainTable.searchForm" :table-load-event="getMemberInvoiceTitleList"
				:table-search="mainTable.tableSearch" :colSpan="4" :searchBtnColSpan="4" />
		</div>
		<Row>
			<Col span="24">
			<pagination-table :columns="mainTable.tableColumns" :data="mainTable.tableData"
				:loading="mainTable.tableLoading" :table-search="mainTable.tableSearch"
				:table-load-event="getMemberInvoiceTitleList" :highlight="true"
				@on-row-click="onMemberInvoiceTitleSelect" />
			</Col>
		</Row>

		<Modal v-model="memberInvoiceTitleModal.show" title="会员发票抬头信息" :mask-closable="false" width="40"
			@on-cancel="onCancel">
			<Spin v-if="memberInvoiceTitleModal.loading" size="large" fix></Spin>
			<Form ref="memberInvoiceTitleModal" :model="memberInvoiceTitleModal" :label-width="90">
				<Row>
					<Col span="12">
					<FormItem label="发票类型:">
						<span v-if="memberInvoiceTitleModal.invoiceType==1">普票</span>
						<span v-if="memberInvoiceTitleModal.invoiceType==2">专票</span>
					</FormItem>
					</Col>
					<Col span="12">
					<FormItem label="抬头类型:">
						<span v-if="memberInvoiceTitleModal.titleType==1">个人</span>
						<span v-if="memberInvoiceTitleModal.titleType==2">企业</span>
					</FormItem>
					</Col>
					<Col span="12">
					<FormItem label="发票材质:">
						<span>电子版</span>
						<!-- <span v-if="memberInvoiceTitleModal.invoiceMode==2">纸质版</span> -->
					</FormItem>
					</Col>
					<Col span="12">
					<FormItem label="发票抬头:" prop="invoiceTitle">
						<Input v-model="memberInvoiceTitleModal.invoiceTitle"></Input>
					</FormItem>
					</Col>
					<Col span="12" v-if="memberInvoiceTitleModal.titleType==2">
					<FormItem label="税号:" prop="taxNum">
						<Input v-model="memberInvoiceTitleModal.taxNum"></Input>
					</FormItem>
					</Col>
					<Col span="12">
					<FormItem label="邮箱:" prop="email">
						<Input v-model="memberInvoiceTitleModal.email"></Input>
					</FormItem>
					</Col>
					<!-- <Col span="24" v-if="memberInvoiceTitleModal.titleType==2">
					<FormItem label="注册地址:" prop="address">
						<Input v-model="memberInvoiceTitleModal.address"></Input>
					</FormItem>
					</Col>
					<Col span="8" v-if="memberInvoiceTitleModal.titleType==2">
					<FormItem label="注册电话:" prop="phone">
						<Input v-model="memberInvoiceTitleModal.phone"></Input>
					</FormItem>
					</Col>
					<Col span="8" v-if="memberInvoiceTitleModal.titleType==2">
					<FormItem label="开户银行:" prop="bankName">
						<Input v-model="memberInvoiceTitleModal.bankName"></Input>
					</FormItem>
					</Col>
					<Col span="8" v-if="memberInvoiceTitleModal.titleType==2">
					<FormItem label="银行账号:" prop="bankAccount">
						<Input v-model="memberInvoiceTitleModal.bankAccount"></Input>
					</FormItem>
					</Col>
					<Col span="8" v-if="memberInvoiceTitleModal.titleType==2&&memberInvoiceTitleModal.invoiceMode==2">
					<FormItem label="收票人:" prop="deliveryContact">
						<Input v-model="memberInvoiceTitleModal.deliveryContact"></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="收票手机:" prop="deliveryPhone">
						<Input v-model="memberInvoiceTitleModal.deliveryPhone"></Input>
					</FormItem>
					</Col>
					<Col span="24" v-if="memberInvoiceTitleModal.titleType==2&&memberInvoiceTitleModal.invoiceMode==2">
					<FormItem label="收票地址:" prop="deliveryAddress">
						<Input v-model="memberInvoiceTitleModal.deliveryAddress"></Input>
					</FormItem>
					</Col> -->
					<Col span="24">
					<FormItem label="默认抬头:" prop="isDefaultTitle">
						<RadioGroup v-model="memberInvoiceTitleModal.isDefaultTitle">
							<Radio :label="0">否</Radio>
							<Radio :label="1">是</Radio>
						</RadioGroup>
					</FormItem>
					</Col>
				</Row>
				<!-- <Row v-if="checkAccessArray('MemberInvoiceInfoEdit')" >
				    <Col span="12">
				    <FormItem label="状态:" prop="state">
						<RadioGroup v-model="memberInvoiceTitleModal.state">
							<Radio :label="0">审核中</Radio>
							<Radio :label="1">审核通过</Radio>
							<Radio :label="2">审核不通过</Radio>
						</RadioGroup>
				    </FormItem>
				    </Col>
				</Row> -->
			</Form>
			<div slot="footer">
				<Button @click="onCancel()">关闭</Button>
				<Button type="info" @click="onSaveMemberInvoiceTitle">保存</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import api from '@/api/api';
	import search from '@/libs/search';
	import PaginationTable from '@/components/pagination-table.vue';
	import SearchCard from '@/components/searchCard.vue';
	import BaseTableFunction from '@/components/base-table-function.vue';
	import validate from "@/libs/validate";
	export default {
		name: 'MemberInvoiceTitleList',
		components: {
			PaginationTable,
			SearchCard,
			BaseTableFunction
		},
		data() {
			return {
				memberInvoiceTitleModal: {
					show: false,
					loading: false,
					memCode: '',
					invoiceTitle: '',
					invoiceType: 0,
					invoiceMode: 0,
					taxNum: '',
					address: '',
					phone: '',
					bankName: '',
					bankAccount: '',
					email: '',
					deliveryAddress: '',
					deliveryPhone: '',
					deliveryContact: '',
					titleType: 0,
					//state: 0,
					isDefaultTitle: 0,
					mode: 'modify',
				},
				// provinceList: [],
				// cityList: [],
				// areaList: [],
				// streetList: [],
				mainTable: {
					tableColumns: [{
							type: 'index',
							width: 40,
							align: 'center'
						},
						{
							title: '会员号',
							key: "MemCode",
							align: 'center'
						},
						{
							title: '发票抬头',
							key: "InvoiceTitle",
							align: 'center'
						},
						{
							title: '邮箱',
							key: "Email",
							align: 'center'
						},
						{
							title: '税号',
							key: "TaxNum",
							align: 'center'
						},
						// {
						// 	title: '注册地址',
						// 	key: "Address",
						// 	align: 'center'
						// },
						// {
						// 	title: '注册电话',
						// 	key: "Phone",
						// 	align: 'center'
						// },
						// {
						// 	title: '开户银行',
						// 	key: "BankName",
						// 	align: 'center'
						// },
						// {
						// 	title: '银行账号',
						// 	key: "BankAccount",
						// 	align: 'center'
						// },
						{
							title: '状态',
							key: "State",
							align: 'center',
							render: (h, params) => {
								let text = '';
								let color = '';
								switch (parseInt(params.row.State)) {
									case 0:
										text = '待审核';
										color = 'red';
										break;
									case 1:
										text = '审核通过';
										color = 'black';
										break;
									case 2:
										text = '审核不通过';
										color = 'blue';
										break;
									default:
										text = '状态异常';
										color = 'blue';
								}
								return h('div', [
									h('span', {
										style: {
											color: color
										}
									}, text)
								]);
							}
						},
						{
							title: "操作",
							align: "center",
							width: 120,
							render: (h, params) => {
								return h('div', [
									h('Button', {
										props: {
											size: 'small',
											shape: 'circle',
											showEdit: this.checkAccessArray('MemberInvoiceInfoEdit')
										},
										style: {
											margin: '2px',
											display: params.row.State == 1 ? 'inline-block' : 'none'
										},
										on: {
											click: () => {
												this.onModifyMemberInvoiceTitle(params.row.Id);
											}
										}
									}, '修改'),
									h('Button', {
										props: {
											size: 'small',
											shape: 'circle',
											showEdit: this.checkAccessArray(
												'MemberInvoiceInfoApproval'),
										},
										style: {
											margin: '2px',
											display: params.row.State == 0 ? 'inline-block' : 'none'
										},
										on: {
											click: () => {
												this.onApprovedInvoiceTitle(params.row.Id);
											}
										}
									}, '通过'),
									h('Button', {
										props: {
											size: 'small',
											shape: 'circle',
											showEdit: this.checkAccessArray(
												'MemberInvoiceInfoApproval')
										},
										style: {
											margin: '2px',
											display: params.row.State == 0 ? 'inline-block' : 'none'
										},
										on: {
											click: () => {
												this.onUnApprovedInvoiceTitle(params.row.Id);
											}
										}
									}, '不通过'),
								]);
							}
						}
					],
					tableData: [],
					tableLoading: false,
					tableSearch: search.getSearchParams(),
					searchForm: [{
							label: '会员号',
							type: 'Input',
							value: '',
							name: 'MemCode',
							sql: 'like',
							clearable: true
						},
						{
							label: '抬头',
							type: 'Input',
							value: '',
							name: 'InvoiceTitle',
							sql: 'like',
							clearable: true
						}
					]
				},
				selectedMemberInvoiceTitle: null,
			};
		},
		computed: {

		},
		mounted() {
			this.getMemberInvoiceTitleList();
		},
		methods: {
			onMemberInvoiceTitleSelect(row, index) {
				this.selectedMemberInvoiceTitle = row;
			},
			//编辑
			onModifyMemberInvoiceTitle(id) {
				let b = this.mainTable.tableData.find(e => {
					return e.Id === id;
				});
				this.memberInvoiceTitleModal.id = b.Id;
				this.memberInvoiceTitleModal.memCode = b.MemCode;
				this.memberInvoiceTitleModal.invoiceTitle = b.InvoiceTitle;
				this.memberInvoiceTitleModal.invoiceType = b.InvoiceType;
				this.memberInvoiceTitleModal.invoiceMode = b.InvoiceMode;
				this.memberInvoiceTitleModal.taxNum = b.TaxNum;
				// this.memberInvoiceTitleModal.address = b.Address;
				// this.memberInvoiceTitleModal.phone = b.Phone;
				// this.memberInvoiceTitleModal.bankName = b.BankName;
				// this.memberInvoiceTitleModal.bankAccount = b.BankAccount;
				this.memberInvoiceTitleModal.email = b.Email;
				// this.memberInvoiceTitleModal.deliveryAddress = b.DeliveryAddress;
				// this.memberInvoiceTitleModal.deliveryPhone = b.DeliveryPhone;
				// this.memberInvoiceTitleModal.deliveryContact = b.DeliveryContact;
				this.memberInvoiceTitleModal.titleType = b.TitleType;
				//this.memberInvoiceTitleModal.state = b.State;
				this.memberInvoiceTitleModal.isDefaultTitle = b.IsDefaultTitle;
				this.memberInvoiceTitleModal.mode = 'modify';
				this.memberInvoiceTitleModal.show = true;
			},
			//审核通过
			onApprovedInvoiceTitle(id) {
				this.mainTable.tableLoading = true;
				api.memberInvoiceTitleApprove({
					id: id,
					state: 1
				}).then(res => {
					this.mainTable.tableLoading = false;
					if (res.code === 0) {
						this.getMemberInvoiceTitleList();
						this.showSuccessModal();
					}
				});
			},
			//审核不通过
			onUnApprovedInvoiceTitle(id) {
				this.mainTable.tableLoading = true;
				api.memberInvoiceTitleApprove({
					id: id,
					state: 2
				}).then(res => {
					this.mainTable.tableLoading = false;
					if (res.code === 0) {
						this.getMemberInvoiceTitleList();
						this.showSuccessModal();
					}
				});
			},
			onSaveMemberInvoiceTitle() {
				if (!this.memberInvoiceTitleModal.invoiceTitle) {
					return this.showWarningModal('发票抬头不能为空！');
				}
				//抬头类型为企业时
				if(this.memberInvoiceTitleModal.titleType==2){
					if (!this.memberInvoiceTitleModal.taxNum) {
						return this.showWarningModal('税号不能为空！');
					}
					if (this.memberInvoiceTitleModal.taxNum && !validate.validatetaxNum(this.memberInvoiceTitleModal.taxNum)) {
						return this.showWarningModal('请输入正确的税号！');
					}
					// if (!this.memberInvoiceTitleModal.address) {
					// 	return this.showWarningModal('注册地址不能为空！');
					// }
					// if (!this.memberInvoiceTitleModal.phone) {
					// 	return this.showWarningModal('注册电话不能为空！');
					// }
					// if (this.memberInvoiceTitleModal.phone && !validate.validatePhTelNumber(this.memberInvoiceTitleModal
					// 	.phone)) {
					// 	return this.showWarningModal('请输入正确的注册电话！');
					// }
					// if (!this.memberInvoiceTitleModal.bankName) {
					// 	return this.showWarningModal('开户银行不能为空！');
					// }
					// if (!this.memberInvoiceTitleModal.bankAccount) {
					// 	return this.showWarningModal('银行账号不能为空！');
					// }
					// if (this.memberInvoiceTitleModal.bankAccount && !validate.validateBankAccount(this.memberInvoiceTitleModal
					// 		.bankAccount)) {
					// 	return this.showWarningModal('请输入正确的银行账号！');
					// }
				}
				if (this.memberInvoiceTitleModal.email && !validate.validateEmail(this.memberInvoiceTitleModal.email)) {
					return this.showWarningModal('请输入正确的邮箱！');
				}
				//抬头类型为企业且发票材质为纸质版
				// if(this.memberInvoiceTitleModal.titleType==2&&this.memberInvoiceTitleModal.invoiceMode==2){
				// 	if (!this.memberInvoiceTitleModal.deliveryContact) {
				// 		return this.showWarningModal('收票人不能为空！');
				// 	}
				// 	if (!this.memberInvoiceTitleModal.deliveryAddress) {
				// 		return this.showWarningModal('收票地址不能为空！');
				// 	}
				// }
				//抬头类型为个人
				// if(this.memberInvoiceTitleModal.titleType==1){
				// 	if (this.memberInvoiceTitleModal.email && !validate.validateEmail(this.memberInvoiceTitleModal.email)) {
				// 		return this.showWarningModal('请输入正确的邮箱！');
				// 	}
				// }
				
				// if (!this.memberInvoiceTitleModal.deliveryPhone) {
				// 	return this.showWarningModal('收票人手机不能为空！');
				// }
				// if (this.memberInvoiceTitleModal.deliveryPhone && !validate.validatePhoneNumber(this
				// 		.memberInvoiceTitleModal.deliveryPhone)) {
				// 	return this.showWarningModal('请输入正确的手机号码！');
				// }
				
				this.memberInvoiceTitleModal.loading = true;
				api.updateMemberInvoiceTitle(this.memberInvoiceTitleModal).then(res => {
					this.memberInvoiceTitleModal.loading = false;
					if (res.code === 0) {
						this.memberInvoiceTitleModal.show = false;
						this.getMemberInvoiceTitleList();
						this.showSuccessModal();
					}
				});
			},
			getMemberInvoiceTitleList() {
				this.mainTable.tableLoading = true;
				api.memberInvoiceTitleList(this.mainTable.tableSearch).then(res => {
					this.mainTable.tableLoading = false;
					if (res.code === 0) {
						this.selectedMemberInvoiceTitle = null;
						this.mainTable.tableData = res.data.records;
						search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
					}
				});
			},
			//关闭弹窗时重置表单
			onCancel() {
				this.$refs.memberInvoiceTitleModal.resetFields();
				this.memberInvoiceTitleModal.show = false;
			}
		}
	};
</script>
<style>
</style>