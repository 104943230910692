<template>
  <div class="side-menu-wrapper">
    <slot></slot>
    <Menu ref="menu" v-show="!isCollapse" accordion :active-name="activeMenu" :width="menuWidth"
          :open-names="openedNames" theme="dark">
      <MenuItem to="/home" name="/home">
        <Icon type="md-home" :size="iconSize"/>
        <span>首页</span>
      </MenuItem>

      <Submenu v-for="menu in menuList" :name="menu.id" :key="menu.id">
        <template #title>
          <Icon type="md-settings" :size="iconSize"/>
          <span>{{ menu.name }}</span>
        </template>

        <template v-for="subMenu in menu.children">
          <template v-if="subMenu.resCode === 'PRESALE'">
            <MenuItem :name="subMenu.link" :to="subMenu.link" target="_blank">
              <span>{{ subMenu.name }}</span>
            </MenuItem>
          </template>
          <template v-else>
            <MenuItem :name="subMenu.link" :to="subMenu.link">
              <span>{{ subMenu.name }}</span>
            </MenuItem>
          </template>

        </template>

      </Submenu>
    </Menu>
    <div class="menu-collapsed" v-show="isCollapse">
      <Tooltip transfer content="首页" placement="right">
        <router-link class="drop-menu-a" to="/home">
          <Icon type="md-home" :size="iconSize"/>
        </router-link>
      </Tooltip>
      <template v-for="menu in menuList">
        <collapsed-menu v-if="menu.children && menu.children.length > 1" :key="menu.id" :parentItem="menu"
                        :icon-size="iconSize"></collapsed-menu>
        <Tooltip transfer v-else :content="menu.name" placement="right" :key="menu.id">
          <router-link class="drop-menu-a" :to="menu.link">
            <Icon type="md-settings" :size="iconSize"/>
          </router-link>
        </Tooltip>
      </template>
    </div>
  </div>
</template>

<script>
import api from '@/api/api';
import CollapsedMenu from './CollapsedMenu.vue';

const getTheMenu = function (link, arr) {
  for (const item of arr) {
    if (item.link === link) return item;
    if (item.children && item.children.length) {
      const _item = getTheMenu(link, item.children);
      if (_item) return _item;
    }
  }
};

export default {
  name: 'AppAsideMenu',
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    },
    menuWidth: {
      type: String,
      default: '200px'
    },
    /**
     * @description: 路径path
     * @return {*}
     */
    activeMenu: {
      type: String,
      default: ''
    }
  },
  components: {CollapsedMenu},
  data() {
    return {
      collapse: this.isCollapse,
      openedNames: [],
      menuList: [],
      iconSize: 20
    };
  },
  watch: {
    openedNames() {
      this.$nextTick(() => {
        this.$refs.menu.updateOpened();
        this.$refs.menu.updateActiveName();
      });
    }
  },
  mounted() {
    this.getResourceMenus();
  },
  methods: {
    getOpenedNamesByActiveLink(path) {
      let menuList = this.menuList.concat([]);
      let menu = getTheMenu(path, menuList);
      if (menu) {
        return [menu.parentId];
      } else {
        return [];
      }
    },
    updateOpenPath(path) {
      if (path === '/home') this.openedNames = [];
      else this.openedNames = this.getOpenedNamesByActiveLink(path);
    },
    getUnion(arr1, arr2) {
      return Array.from(new Set([...arr1, ...arr2]));
    },
    getResourceMenus() {
      api.resourceMenu({}).then(res => {
        if (res.code === 0) {
          this.menuList = Object.freeze(this.getMenus(res.data.resourceList));
          this.setUserPermission(res.data.permissionList);
          this.openedNames = this.getOpenedNamesByActiveLink(this.activeMenu);
        }
      });
    }
  }
};
</script>
<style lang="less">
@menu-bg: #363e4f;
.side-menu-wrapper {
  .menu-collapsed .ivu-tooltip,
  .menu-collapsed .ivu-dropdown {
    width: 100%;
  }

  .drop-menu-a {
    display: inline-block;
    padding: 6px 15px;
    width: 100%;
    height: 50px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
  }

  .ivu-dropdown-item {
    &:hover {
      background: @menu-bg;

      .menu-title {
        color: #fff;
      }
    }

    .drop-menu-a {
      height: 30px;
    }
  }

  .ivu-select-dropdown {
    min-width: 200px;
    width: auto;
    background: @menu-bg;
  }
}
</style>
