import getPostRequest from './base-api';

export default {
    getCommissionSettlementList(data) {
        return getPostRequest(data, 'api/settlement/settlementList');
    },
    commissionSettlementUpdate(data) {
        return getPostRequest(data, 'api/settlement/settlementUpdate');
    },
    commissionSettlementUpdateRemark(data) {
        return getPostRequest(data, 'api/settlement/settlementUpdateRemark');
    },
    commissionSettlementSubmit(data) {
        return getPostRequest(data, 'api/settlement/settlementSubmit');
    },
    commissionSettlementSingleSubmit(data) {
        return getPostRequest(data, 'api/settlement/settlementSingleSubmit');
    },
    commissionSettlementSummaryList(data){
        return getPostRequest(data, 'api/settlement/settlementSummaryList');
    },
    saveInBoxOrderLack(data) {
	    return getPostRequest(data, 'api/order/inBoxOrderLack');
    },
    commissionSettlementPayToSup(data) {
	    return getPostRequest(data, 'api/settlement/settlementPayToSup');
    }
};
