<template>
    <Form :label-width="labelWidth" class="not-star" label-position="right" :label-colon="labelColon">
        <div class="flex">
            <div v-for="(item,index) in form" :key="index">
                <div v-if="item.type === 'Input'"
                     :style="{width: (item.fieldWidth ? item.fieldWidth : defaultFieldWidth) + 'px'}">
                    <FormItem :label="item.label" :key="index" :label-width="item.labelWidth">
                        <Input v-model="item.value" size="small" clearable :placeholder="item.placeholder"
                               @on-enter="handleSearchWhenChange"
                               @on-clear="handleSearchWhenChange"></Input>
                    </FormItem>
                </div>
                <div class="flex"  v-if="item.type === 'SelectDaterange'">
                    <div :style="{width: (item.fieldWidth ? item.fieldWidth : defaultSelectFieldWidth) + 'px'}">
                        <FormItem :label="item.label" :key="index+'_1'" :label-width="item.labelWidth">
                            <Select v-model="item.value" filterable :clearable="item.clearable"
                                    :multiple="item.sql === 'in'" @on-change="onSelectChange(item)" size="small">
                                <Option v-for="(optionItem) in item.options" :key="optionItem.value"
                                        :value="optionItem.value">{{ optionItem.key }}
                                </Option>
                            </Select>
                        </FormItem>
                    </div>
                    <div :style="{width: (item.Daterange.fieldWidth ? item.Daterange.fieldWidth : defaultFieldWidth) + 'px'}">
                        <FormItem :label="item.Daterange.label" :key="index" :label-width="item.Daterange.labelWidth">
                            <DatePicker transfer :clearable="item.Daterange.clearable" v-model="item.Daterange.value" format="yyyy-MM-dd"
                                        type="daterange" placement="bottom-start" style="width:100%;"
                                        @on-change="formatDate(item.Daterange.value)" size="small"
                                        :options="item.Daterange.onlyFuture === true ? DaterangeOptionsFuture : DaterangeOptions"/>
                        </FormItem>
                    </div>
                </div>
                <div v-if="item.type === 'Select' && !item.show "
                     :style="{width: (item.fieldWidth ? item.fieldWidth : defaultSelectFieldWidth) + 'px'}">
                    <FormItem :label="item.label" :key="index" :label-width="item.labelWidth">
                        <Select v-model="item.value" transfer filterable :disabled="item.disabled"
                                :clearable="item.clearable" :multiple="item.sql === 'in'"
                                @on-change="onSelectChange(item)" size="small">
                            <Option v-for="(optionItem) in item.options" :key="optionItem.value"
                                    :value="optionItem.value">{{ optionItem.key }}
                            </Option>
                        </Select>
                    </FormItem>
                </div>
                <div class="flex" v-if="item.type === 'SelectInput'">
                    <div :style="{width: (item.fieldWidth ? item.fieldWidth : defaultSelectFieldWidth) + 'px'}">
                        <FormItem :label="item.label" :key="index+'_1'" :label-width="item.labelWidth">
                            <Select v-model="item.value" filterable :clearable="item.clearable"
                                    :multiple="item.sql === 'in'" @on-change="onSelectChange(item)" size="small">
                                <Option v-for="(optionItem) in item.options" :key="optionItem.value"
                                        :value="optionItem.value">{{ optionItem.key }}
                                </Option>
                            </Select>
                        </FormItem>
                    </div>
                    <div :style="{width: (item.input.fieldWidth ? item.input.fieldWidth : defaultFieldWidth) + 'px'}">
                        <FormItem :label="item.input.label" :key="index" :label-width="item.input.labelWidth">
                            <Input v-model="item.input.value" size="small" :placeholder="item.input.placeholder"
                                   clearable @on-enter="handleSearchWhenChange"
                                   @on-clear="handleSearchWhenChange"></Input>
                        </FormItem>
                    </div>
                </div>

                <div v-if="item.type === 'RadioGroup'"
                     :style="{width: (item.fieldWidth ? item.fieldWidth : defaultFieldWidth) + 'px'}">
                    <FormItem :label="item.label" :key="index" :label-width="item.labelWidth">
                        <RadioGroup v-model="item.value" size="small" @on-change="handleSearchWhenChange">
                            <Radio v-for="(optionItem,optionKey) in item.options" :key="optionKey"
                                   :label="optionItem.value">{{ optionItem.key }}
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                </div>
                <div v-if="item.type === 'CheckboxGroup'"
                     :style="{width: (item.fieldWidth ? item.fieldWidth : defaultFieldWidth) + 'px'}">
                    <FormItem :label="item.label" :key="index" :label-width="item.labelWidth">
                        <CheckboxGroup v-model="item.value" size="small">
                            <Checkbox v-for="(optionItem,optionKey) in item.options" :key="optionKey"
                                      :label="optionItem.value">{{ optionItem.key }}
                            </Checkbox>
                        </CheckboxGroup>
                    </FormItem>
                </div>
                <div v-if="item.type === 'Checkbox'"
                     :style="{width: (item.fieldWidth ? item.fieldWidth : defaultFieldWidth) + 'px'}">
                    <FormItem :label="item.label" :key="index" :label-width="item.labelWidth">
                        <Checkbox v-model="item.value" size="small"/>
                    </FormItem>
                </div>
                <div v-if="item.type === 'Switch'"
                     :style="{width: (item.fieldWidth ? item.fieldWidth : defaultFieldWidth) + 'px'}">
                    <FormItem :label="item.label" :key="index" :label-width="item.labelWidth">
                        <i-switch v-model="item.value" size="small"/>
                    </FormItem>
                </div>
                <div v-if="item.type === 'Daterange'"
                     :style="{width: (item.fieldWidth ? item.fieldWidth : defaultDateRangeFieldWidth) + 'px'}">
                    <FormItem :label="item.label" :key="index" :label-width="item.labelWidth">
                        <DatePicker transfer :clearable="item.clearable" v-model="item.value" format="yyyy-MM-dd"
                                    type="daterange" placement="bottom-start" style="width:100%;"
                                    @on-change="formatDate(item.value)" size="small"
                                    :options="item.onlyFuture === true ? DaterangeOptionsFuture : DaterangeOptions"/>
                    </FormItem>
                </div>
                <div v-if="item.type === 'Date'"
                     :style="{width: (item.fieldWidth ? item.fieldWidth : defaultFieldWidth) + 'px'}">
                    <FormItem :label="item.label" :key="index" :label-width="item.labelWidth">
                        <DatePicker transfer :clearable="item.clearable" v-model="item.value" format="yyyy-MM-dd"
                                    type="date" size="small"/>
                    </FormItem>
                </div>
                <div v-if="item.type === 'Cascader'"
                     :style="{width: (item.fieldWidth ? item.fieldWidth : defaultSelectFieldWidth) + 'px'}">
                    <FormItem :label="item.label" :key="index" :label-width="item.labelWidth">
                        <Cascader :data="item.cascaderData" :change-on-select="item.changeOnSelect" v-model="item.value"
                                  size="small" style="display:inline-block"
                                  @on-change="handleSearchWhenChange"/>
                    </FormItem>
                </div>
            </div>

            <Button size="small" icon="ios-search" style="margin-left: 10px;" @click="handleSearch">搜索
            </Button>
            <Button v-if="showResetBtn" size="small" icon="ios-refresh" style="margin-left: 10px;" @click="resetSearch">
                重置
            </Button>
            <div>
                <slot name="appendBtn"></slot>
            </div>
        </div>
    </Form>
</template>

<script>
import search from '@/libs/search';
import { getThisMonthStart, getThisMonthEnd, getTodayDateString } from '@/libs/logic.js';

export default {
    name: 'SearchCard',
    props: {
        form: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        labelWidth: {
            type: Number,
            default: 75
        },
        labelColon: {
            type: Boolean,
            default: true
        },
        colSpan: {
            type: Number,
            default: 6
        },
        searchBtnColSpan: {
            type: Number,
            default: 6
        },
        tableLoadEvent: {
            type: Function,
            required: true
        },
        tableSearch: {
            type: Object,
            required: true
        },
        defaultFieldWidth: {
            type: Number,
            default: 200
        },
        defaultSelectFieldWidth: {
            type: Number,
            default: 200
        },
        defaultDateRangeFieldWidth: {
            type: Number,
            default: 275
        },
        showResetBtn: {
            type: Boolean,
            default: false
        },
        handleSearchWhenSelectChange: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            daterangeOptions: {
                shortcuts: [
                    {
                        text: '今天',
                        value() {
                            return [getTodayDateString(), getTodayDateString()];
                        }
                    },
                    {
                        text: '这个月',
                        value() {
                            return [getThisMonthStart(), getThisMonthEnd()];
                        }
                    }
                ]
            },
            DaterangeOptionsFuture: {
                disabledDate(date) {
                    return date && date.valueOf() < Date.now() - 86400000;
                }
            },
            DaterangeOptions: {
                disabledDate(date) {
                    return date && false;
                }
            }
        };
    },
    methods: {
        filterForm() {
            // 前端过滤
            let formEd = [];
            console.log('2222222',this.form)
            this.form.forEach(function (element) {
                switch (element.type) {
                    case 'Input':
                        if (element.value.replace(/(^s*)|(s*$)/g, '').length !== 0) {
                            formEd.push(element);
                        }
                        break;
                    case 'Checkbox':
                        formEd.push(element);
                        break;
                    case 'Switch':
                        formEd.push(element);
                        break;
                    case 'Daterange':
                        if (element.value.length > 0 && element.value[0] != '1970-01-01 08:00:00') {
                            let dateBegin = element.value[0];
                            let dateEnd = element.value[1];
                            if (dateBegin !== '' && dateEnd !== '') {
                                formEd.push(Object.assign({}, element, {
                                    value: JSON.stringify([dateBegin, dateEnd])
                                }));
                            }
                        }
                        break;
                    case 'Date':
                        if (element.value.replace(/(^s*)|(s*$)/g, '').length !== 0) {
                            formEd.push(element);
                        }
                        break;
                    case 'Select':
                        console.log('select', element)
                        if (element.value != null && element.value !== undefined) {
                            if (Array.isArray(element.value)) {
                                if (element.value.length > 0) {
                                    formEd.push(Object.assign({}, element, {
                                        value: JSON.stringify(element.value)
                                    }));
                                }
                            }
                            else {
                                // let arr = [];
                                // arr.push(element.value);
                                // formEd.push(Object.assign({}, element, {
                                //     value: JSON.stringify(arr)
                                // }));
                                if (element.value) {

                                  if (element.sql == "nneq"){
                                    const list = element.value.split(',')
                                    formEd.push({
                                      name: element.name,
                                      sql: list[0],
                                      value: list[1]
                                    });
                                  }else {
                                    formEd.push({
                                      name: element.name,
                                      sql: (typeof element.sql === 'object') ? element.sql[element.value] : element.sql,
                                      value: element.value
                                    });
                                  }
                                }
                            }
                        }
                        break;
                    case 'SelectInput':
                        if (element.value != null && element.value !== undefined) {
                            if (element.input.value.replace(/(^s*)|(s*$)/g, '').length !== 0) {
                                formEd.push({
                                    name: element.value,
                                    sql: (typeof element.sql === 'object') ? element.sql[element.value] : element.sql,
                                    value: element.input.value
                                });
                            }
                        }
                        break;
                    case 'SelectDaterange':
                        console.log('ssssssss',element)
                        if (element.value != null && element.value !== undefined) {
                            if (element.Daterange.value.length > 0 && element.Daterange.value[0] != '1970-01-01 08:00:00') {
                                let dateBegin = element.Daterange.value[0];
                                let dateEnd = element.Daterange.value[1];
                                if (dateBegin !== '' && dateEnd !== '') {
                                    formEd.push({
                                        name: element.value,
                                        sql: (typeof element.sql === 'object') ? element.sql[element.value] : element.sql,
                                        value: JSON.stringify([dateBegin, dateEnd])
                                    })
                                }
                            }
                        }
                        break;
                    case 'RadioGroup':
                        if (element.value.replace(/(^s*)|(s*$)/g, '').length !== 0) {
                            formEd.push(element);
                        }
                        break;
                    case 'CheckboxGroup':
                        if (element.value.length > 0) {
                            formEd.push(element);
                        }
                        break;
                    case 'Cascader':
                        if (element.changeOnSelect) {
                            formEd.push(Object.assign({}, element, {
                                value: JSON.stringify(element.value)
                            }));
                        }
                        else {
                            if (element.value.length >= 2) {
                                formEd.push(Object.assign({}, element, {
                                    value: element.value[element.valueIndex]
                                }));
                            }
                        }
                        break;
                    default:
                        break;
                }
            }, this);

            return formEd;
        },
        handleSearch() {
            const filterFormData = this.filterForm();
            console.log('3333333',filterFormData)
            search.globalSetFilterWithSearchForm(this.tableSearch, filterFormData);
            this.tableLoadEvent();

            this.$emit('handleSearch', filterFormData);
        },
        resetSearch() {
            this.form.forEach(function (element) {
                switch (element.type) {
                    case 'Input':
                        element.value = '';
                        break;
                    case 'Checkbox':
                        element.value = false;
                        break;
                    case 'Switch':
                        element.value = true;
                        break;
                    case 'Daterange':
                        element.value = [];
                        break;
                    case 'Date':
                        element.value = '';
                        break;
                    case 'Select':
                        element.value = [];
                        break;
                    case 'SelectInput':
                        element.value = [];
                        element.input.value = '';
                        break;
                    case 'RadioGroup':
                        element.value = '';
                        break;
                    case 'CheckboxGroup':
                        element.value = [];
                        break;
                    case 'Cascader':
                        element.value = [];
                        break;
                    default:
                        break;
                }
            }, this);

            this.$emit('handleSearch', []);
        },
        onSelectChange(item) {
            if (item.change) {
                item.change(item);
            }
            if (this.handleSearchWhenSelectChange) {
                this.handleSearch();
            }
            this.$emit('changeSelectContent', item);
        },
        formatDate(dateA) {
            let a = dateA[0];
            let aJson = new Date(a).toJSON();
            let aNewDate = new Date(+new Date(aJson) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');

            let b = dateA[1];
            let bJson = new Date(b).toJSON();
            let bNewDate = new Date(+new Date(bJson) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');

            dateA[0] = aNewDate;
            dateA[1] = bNewDate;

            this.handleSearchWhenChange();
        },
        handleSearchWhenChange() {
            if (this.handleSearchWhenSelectChange) {
                this.$nextTick(() => {
                    this.handleSearch();
                });
            }
        }
    }
};

</script>

<style lang="less" scoped>
.not-star {
    padding: 5px 5px;
    // border-left: 1px solid lightgrey;
    // border-right: 1px solid lightgrey;
    // border-bottom: 1px solid lightgrey;

    .ivu-form-item-required .ivu-form-item-label:before {
        content: "";
    }

    .ivu-form-item {
        margin-bottom: 0px !important;
    }
}

.search-card {
    padding: 10px;
    margin-bottom: 5px;
}
</style>
