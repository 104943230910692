import axios from '@/libs/api.request';
import baseInfo from './baseInfo';
import containerManagement from './container-management';
import financialManagement from './financial-management';
import logisticsManagement from './logistics-management';
import purchaseSalesInventory from './purchase-sales-inventory';
import salesManagement from './sales-management';
import scanManagement from './scan-management';
import setting from './setting';
import stat from './stat';
import systemManagement from './system-management';

const uploadFileConfig = {
    uploadFileAction: axios.getBaseUrl() + '/api/file/uploadFile',
    fileServer: axios.getFileServerUrl() + '/',
    excelServerUrl: axios.getExcelServerUrl()
};

const combine = Object.assign(
    {},
    systemManagement,
    setting,
    baseInfo,
    stat,
    financialManagement,
    containerManagement,
    scanManagement,
    salesManagement,
    logisticsManagement,
    purchaseSalesInventory,
    uploadFileConfig
);

export default combine;
