<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <div class="flex searchButtonContainer">
                <search-card :form="mainTable.searchForm"
                    :table-load-event="getResourceList"
                    :table-search="mainTable.tableSearch"
                    :colSpan="4"
                    :searchBtnColSpan="4" />
            </div>
            <Button v-if="checkAccessArray('ResourceEdit')" size="small" @click="onAddResource">新增资源</Button>
        </div>
        <div>
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getResourceList"
                :highlight="true"
                @on-row-click="onRowSelect" />
        </div>
        <Modal v-model="ResourceModal.show"
            title="资源信息"
            :mask-closable="false">
            <Spin v-if="ResourceModal.loading"
                size="large"
                fix></Spin>
            <Form ref="unitModalForm"
                :label-width="100">
                <Row>
                    <Col span="24">
                    <FormItem label="资源名称:"
                        required>
                        <Input v-model="ResourceModal.name"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="资源编码:" required>
                        <Input v-model="ResourceModal.resCode"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="资源类型:">
                        <Select v-model="ResourceModal.resType"
                            filterable
                            style="width:100%"
                            @on-change="onResTypeChange">
                            <Option v-for="item in resourceType"
                                :value="item.key"
                                :key="item.key">{{ item.value }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="所属权限:">
                        <Select v-model="ResourceModal.parentId"
                            filterable
                            style="width:100%">
                            <Option v-for="item in subResourceList"
                                :value="item.key"
                                :key="item.key">{{ item.value }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="排序:" required>
                        <Input v-model="ResourceModal.sort"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="请求链接:" required>
                        <Input v-model="ResourceModal.link"></Input>
                    </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span="24">
                    <FormItem label="图标:">
                        <Input v-model="ResourceModal.icon"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="图标样式:">
                        <Input v-model="ResourceModal.iconStyle"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="资源描述:">
                        <Input v-model="ResourceModal.description"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="快捷菜单:">
                        <RadioGroup v-model="ResourceModal.isShortCut">
                            <Radio label="1">
                                <span>是</span>
                            </Radio>
                            <Radio label="0">
                                <span>否</span>
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="是否启用:">
                        <RadioGroup v-model="ResourceModal.isFlag">
                            <Radio label="1">
                                <span>启用</span>
                            </Radio>
                            <Radio label="0">
                                <span>禁用</span>
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveResource">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
export default {
    name: 'ResourceList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            ResourceModal: {
                show: false,
                loading: false,
                mode: 'add',
                id: '',
                name: '',
                resCode: '',
                sort: 1,
                link:'/',
                resType: 0,
                icon: '',
                iconStyle: '',
                description: '',
                isShortCut: '0',
                isFlag: '1',
                parentId: ''
            },
            resourceType:[{ key: 0, value: '根目录' }, { key: 1, value: '目录' }, { key: 2, value: '菜单' }, { key: 3, value: '按钮' }],
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '资源名称', key: "Name", align: 'center', tree: true },
                    { title: '资源编码', key: "ResCode", align: 'center' },
                    { title: '请求链接', key: "Link", align: 'center' },
                    { title: '资源类型', key: "ResType", width: 100, align: 'center',
                        render: (h, params) => {
                            let text = params.row.ResType === 0 ? '根目录' : params.row.ResType === 1 ? '目录' : params.row.ResType === 2 ? '菜单' : '按钮';
                            let color = params.row.ResType === 0 ? '#ff9900' : params.row.ResType === 1 ? '#19be6b' : params.row.ResType === 2 ? '#2b85e4' : '#ed4014';
                            return h('div', [
                                h('span', { style: { color: color } }, text)
                            ]);
                        }
                    },
                    { title: '排序', key: "Sort", width: 100, align: 'center' },
                    { title: '快捷菜单', key: "IsShortCut", width: 100, align: 'center',
                        render: (h, params) => {
                            let text = params.row.IsShortCut === 0 ? '否' : '是';
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    { title: '是否启用', key: "IsFlag", width: 100, align: 'center',
                        render: (h, params) => {
                            let text = params.row.IsFlag === 0 ? '禁用' : '启用';
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    {
                        title: "操作",
                        align: "center",
                        width: 80,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'unitStatus',
                                        showEdit: this.checkAccessArray('ResourceEdit'),
                                        showDelete: this.checkAccessArray('ResourceDel')
                                    },
                                    on: {
                                        handleDelete: dataId => {
                                            this.onDeleteResource(dataId);
                                        },
                                        handleEdit: dataId => {
                                            this.onModifyResource(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '资源名称', type: 'Input', value: '', name: 'Name', sql: 'like' },
                    { label: '资源类型', type: 'Select', value: [], name: 'ResType', sql: 'in', options: [{ key: '根目录', value: '0' }, { key: '目录', value: '1' }, { key: '菜单', value: '2' }, { key: '按钮', value: '3' }], clearable: true }
                ]
            },
            selectedRow: null,
            subResourceList: []
        };
    },
    computed: {

    },
    mounted() {
        this.getResourceList();
    },
    methods: {
        onResTypeChange(d) {
            this.ResourceModal.parentId = '';
            this.getSubResourceList();
        },
        onRowSelect(row, index) {
            this.selectedRow = row;
        },
        onAddResource() {
            this.ResourceModal.mode = 'add';
            this.ResourceModal.show = true;
            this.ResourceModal.name = '';
            this.ResourceModal.resCode = '';
            this.ResourceModal.sort = 1;
            this.ResourceModal.link = '/';
            this.ResourceModal.resType = 0;
            this.ResourceModal.icon = '';
            this.ResourceModal.iconStyle = '';
            this.ResourceModal.description = '';
            this.ResourceModal.isShortCut = '0';
            this.ResourceModal.isFlag = '1';
            this.ResourceModal.parentId = '';
            this.subResourceList = [];
        },
        onDeleteResource(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '确定删除?',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.resourceDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getResourceList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onSaveResource() {
            if(this.ResourceModal.resType != 0 && !this.ResourceModal.parentId){
                return this.showWarningModal("非根目录都需要选择所属权限！");
            }

            this.ResourceModal.loading = true;
            this.ResourceModal.sort = parseInt(this.ResourceModal.sort);
            this.ResourceModal.isShortCut = parseInt(this.ResourceModal.isShortCut);
            this.ResourceModal.isFlag = parseInt(this.ResourceModal.isFlag);
            api.resourceSave(this.ResourceModal).then(res => {
                this.ResourceModal.loading = false;
                if (res.code === 0) {
                    this.ResourceModal.show = false;
                    this.getResourceList();
                    this.showSuccessModal();
                }
            });
        },
        getResourceList() {
            this.mainTable.tableLoading = true;
            api.resourceList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedRow = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        getSubResourceList() {
            this.subResourceList = [];
            if(this.ResourceModal.resType == 0){
                return;
            }

            let tempSearch = search.getSearchParams();
            tempSearch.limit.enable = false;
            tempSearch.filters.enable = true;
            tempSearch.filters.conditions = [{ name: 'ResType', type:'eq', value: this.ResourceModal.resType.toString() }];
            api.subResourceList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.subResourceList = res.data.records;
                    if(this.ResourceModal.mode == 'modify'){
                        let currRow = this.mainTable.tableData.find(e => {
                            return e.Id === this.ResourceModal.id;
                        });
                        this.ResourceModal.parentId = currRow.ParentId;
                    }
                }
            });
        },
        onModifyResource(id) {
            let currRow = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.ResourceModal.id = currRow.Id;
            this.ResourceModal.name = currRow.Name;
            this.ResourceModal.resCode = currRow.ResCode;
            this.ResourceModal.sort = currRow.Sort;
            this.ResourceModal.link = currRow.Link;
            this.ResourceModal.resType = currRow.ResType;
            this.ResourceModal.icon = currRow.Icon;
            this.ResourceModal.iconStyle = currRow.IconStyle;
            this.ResourceModal.description = currRow.Description;
            this.ResourceModal.isShortCut = currRow.IsShortCut + '';
            this.ResourceModal.isFlag = currRow.IsFlag + '';
            this.ResourceModal.parentId = currRow.ParentId;
            this.getSubResourceList();
            this.ResourceModal.mode = 'modify';
            this.ResourceModal.show = true;
        }

    }
};
</script>

