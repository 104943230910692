<template>
	<Modal v-model="memberAddrListModal.show" title="会员收货地址" :mask-closable="false" width="75" draggable>
		<Spin v-if="memberAddrListModal.loading" size="large" fix></Spin>
			<div class="">
				<Row>
				    <Col span="24">
				    <pagination-table 
				        :columns="mainTable.tableColumns"
				        :data="mainTable.tableData"
				        :loading="mainTable.tableLoading"
				        :table-search="mainTable.tableSearch"
				        :table-load-event="getMemberAddressList"
				        :highlight="true"
				        @on-row-click="onMemberAddressSelect" />
				    </Col>
				</Row>
				<MemberAddressModal ref="memberAddressModal" />
			</div>
			
		<div slot="footer">
		</div>
	</Modal>
</template>

<script>
	import api from '@/api/api';
	import axiosOri from 'axios';
	import search from '@/libs/search';
	import SearchCard from '@/components/searchCard.vue';
	import PaginationTable from '@/components/pagination-table.vue';
	import BaseTableFunction from '@/components/base-table-function.vue';
	import MemberAddressModal from './address-model.vue';
	export default {
		name: 'memberAddrListModal',
		components: {
			PaginationTable,
			BaseTableFunction,
			SearchCard,
			MemberAddressModal
		},
		data() {
			return {
				id:'',
				//memberId:'',
				memberAddrListModal: {
					show: false,
					loading: false,
					
				},
				mainTable: {
				    tableColumns: [
				        { type: 'index', width: 40, align: 'center' },
				        { title: '收货人', key: "LinkMan", align: 'center' },
						{ title: '手机号', key: "Phone", align: 'center' },
						{ title: '省', key: "Province", align: 'center' },
						{ title: '市', key: "City", align: 'center' },
						{ title: '区', key: "Area", align: 'center' },
						{ title: '街道', key: "Street", align: 'center' },
						{ title: '详细地址', key: "DetailAddr", align: 'center' },
						{ title: '发货方式', key: "DeliveryWay", align: 'center' },
						{
						    title: '是否默认地址', key: "IsDefault", align: 'center', 
						    render: (h, params) => {
						        let text = '';
						        let color = '';
						        switch(parseInt(params.row.IsDefault)) {
						            case 0: text = '否'; color = 'black'; break;
						            case 1: text = '是'; color = 'red'; break;
						            default: text = '未知'; color = 'red';
						        }
						        return h('div', [
						            h('span', {
						                style: {
						                    color: color
						                }
						            }, text)
						        ]);
						    }
						},
						{
						    title: '状态', key: "AddrState", align: 'center', 
						    render: (h, params) => {
						        let text = '';
						        let color = '';
						        switch(parseInt(params.row.AddrState)) {
						            case 0: text = '待审核'; color = 'red'; break;
						            case 1: text = '审核通过'; color = 'black'; break;
						            case 2: text = '审核不通过'; color = 'blue'; break;
						            default: text = '状态异常'; color = 'blue';
						        }
						        return h('div', [
						            h('span', {
						                style: {
						                    color: color
						                }
						            }, text)
						        ]);
						    }
						},
						{
							title: "操作",
							align: "center",
							width: 80,
							render: (h, params) => {
								return h('div', [
									h('Button', {
										props: {
											size: 'small',
											shape: 'circle',
											statusField: 'Status',
											showEdit: this.checkAccessArray('MemberAddressEdit')
										},
										style: {
											margin: '2px'
										},
										on: {
											click: () => {
												this.onModifyMemberAddress(params.row.Id);
											}
										}
									}, '编辑')
								]);
							}
						}
				    ],
				    tableData: [],
				    tableLoading: false,
				    tableSearch: search.getSearchParams(),
				    searchForm: []
				},
				 selectedMemberAddress: null
				
			};
		},
		computed: {

		},
		mounted() {
			//this.getMemberAddressList();
		},
		methods: {
			showModal(id) {
				this.id=id;
				this.memberAddrListModal.show = true;
				this.getMemberAddressList();
			},
			onMemberAddressSelect(row, index) {
			    this.selectedMemberAddress = row;
			},
			getMemberAddressList() {
			    this.mainTable.tableLoading = true;
				let tempSearch = search.getSearchParams();
				tempSearch.limit.enable = false;
				tempSearch.filters.enable = true;
				tempSearch.filters.conditions.push({name: 'MemberId',type: 'eq',value: this.id},{name: 'AddrState',type: 'eq',value: '1'});
			    api.memberAddressList(tempSearch).then(res => {
			        this.mainTable.tableLoading = false;
			        if (res.code === 0) {
			            this.selectedMemberAddress = null;
			            this.mainTable.tableData = res.data.records;
			        }
			    });
			},
			onModifyMemberAddress(id,readOnly = false) {
			    let b = this.mainTable.tableData.find(e => {
			        return e.Id === id;
			    });
			    this.$refs.memberAddressModal.showModal(b,readOnly);
			}
		}
	};
</script>

<style>
</style>