<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getAdvertList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4" />

            <Button v-if="checkAccessArray('AdvertEdit')"
                size="small"
                @click="onAddAdvert">新增广告</Button>
        </div>
        <Row>
            <Col span="24">
            <pagination-table 
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getAdvertList"
                :highlight="true"
                @on-row-click="onAdvertSelect" />
            </Col>
        </Row>

        <Modal v-model="advertModal.show" title="广告信息" :mask-closable="false">
            <Spin v-if="advertModal.loading" size="large" fix></Spin>
            <Form ref="advertModalForm"
                :label-width="100">
                <Row>
                    <Col span="24">
                    <FormItem label="图片:" required>
                        <template>
                            <Upload action="https://up-z2.qiniup.com/"
                                    :data="uploadParams"
                                    :format="['jpg','jpeg','png']"
                                    :on-format-error ="handleFormatError"
                                    :on-success="handleUploadSuccess"
                                    :before-upload="beforeUpload"
                                    :show-upload-list="showUploadList"
                            >
                                <Button icon="ios-cloud-upload-outline">上传图片</Button>
                            </Upload>

                            <Row :gutter="16" style="margin-top: 10px;" v-if="advertModal.picUrl">
                                <Col span="6">
                                    <img :src="advertModal.picUrl" fit="fill" width="150px" height="150px" />
                                </Col>
                            </Row>
                        </template>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="投放位置:" required>
                        <Select v-model="advertModal.location"
                            filterable
                            style="width:100%">
                            <Option v-for="item in advertLocations"
                                :value="item.ParaName"
                                :key="item.ParaName">{{ item.ParaName }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="跳转链接:" >
                        <Input v-model="advertModal.linkUrl"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="备注:" >
                        <Input v-model="advertModal.remark"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="是否启用:" >
                        <RadioGroup v-model="advertModal.enable">
                            <Radio label="1">
                                <span>启用</span>
                            </Radio>
                            <Radio label="0">
                                <span>禁用</span>
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="排序号:" >
                        <Input v-model.number="advertModal.sort" type="number"></Input>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info" @click="onSaveAdvert">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import { setSelectOptions } from '@/libs/selectOption';
import { Modal } from 'view-design';
export default {
    name: 'AdvertList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            advertModal: {
                show: false,
                loading: false,
                picUrl: '',
                location: '',
                linkUrl: '',
                remark: '',
                enable: '1',
                sort: 0,
                mode: 'add',
            },
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '图片', key: "PicUrl", align: 'center' ,
                        render: (h, params) => {
                            const imageSrc = params.row.PicUrl;
                            return h('img', {
                                style: {
                                    width: '20px',
                                    height: '20px'
                                },
                                attrs: {
                                    src: imageSrc
                                },
                                on: {
                                    click: () => {
                                        Modal.info({
                                            title: '',
                                            closable: true,
                                            okText: '关闭',
                                            render: h => {
                                                return h("img", {
                                                    attrs: {
                                                        src: imageSrc,
                                                        style: "margin-top:20px;width: 350px;height: 350px;"
                                                    }
                                                });
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    },
                    { title: '投放位置', key: "Location", align: 'center' },
                    { title: '跳转链接', key: "LinkUrl", align: 'center' },
                    { title: '备注', key: "Remark", align: 'center' },
                    { title: '是否启用', key: "Enable", align: 'center',
                        render: (h, params) => {
                            let text = params.row.Enable === 0 ? '否' : '是';
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    { title: '排序号', key: "Sort", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 80,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showEdit: this.checkAccessArray('AdvertEdit'),
                                        showDelete: this.checkAccessArray('AdvertEdit')
                                    },
                                    on: {
                                        handleEdit: dataId => {
                                            this.onModifyAdvert(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteAdvert(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '投放位置', type: 'Select', value: '', name: 'Location', sql: 'eq', options: [] },
                ]
            },
            selectedAdvert: null,
            showUploadList: false,
            uploadParams: {},
            advertLocations: []
        };
    },
    computed: {

    },
    mounted() {
        this.getAdvertList();
        this.getAdvertLocationList()
    },
    methods: {
        setSelectOptions,
        handleFormatError(request, file) {
            this.$Notice.warning({
                title: '格式错误',
                desc: '请上传png、jpg、jpeg格式文件'
            });
        },
        async beforeUpload(request) {
            // **注意这里的参数必须通过属性赋值，如果直接修改整个uploadParams是无效的。**
            // 上传之前设置上传参数,
            let filename = Date.now() + Math.random().toString(36).slice(2) + ".png";
            await api.qiniuToken({}).then(res => {
                this.uploadParams.token = res.data.token;
                // 七牛response hash key的值自定义，后面如果需要浏览或者下载都需要这个地址，如果key不定义，那么七牛生成的key === hash的值
                this.uploadParams.key = filename;
            });
        },
        handleUploadSuccess(request, file, list) {
            this.advertModal.picUrl = this.imageDomain + file.response.key;
        },
        onAdvertSelect(row, index) {
            this.selectedAdvert = row;
        },
        onAddAdvert() {
            this.advertModal.mode = 'add';
            this.advertModal.id = '';
            this.advertModal.picUrl = '',
            this.advertModal.location = '',
            this.advertModal.linkUrl = '',
            this.advertModal.remark = '',
            this.advertModal.enable = '1',
            this.advertModal.sort = 0,
            this.advertModal.show = true;
        },
        onDeleteAdvert(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '确定删除吗？',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.advertDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getAdvertList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onModifyAdvert(id) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.advertModal.id = b.Id;
            this.advertModal.picUrl = b.PicUrl;
            this.advertModal.location = b.Location;
            this.advertModal.linkUrl = b.LinkUrl;
            this.advertModal.remark = b.Remark;
            this.advertModal.enable = b.Enable + '';
            this.advertModal.sort = b.Sort;
            this.advertModal.mode = 'modify';
            this.advertModal.show = true;
        },
        onSaveAdvert() {
            this.advertModal.loading = true;
            this.advertModal.enable = parseInt(this.advertModal.enable);
            api.advertSave(this.advertModal).then(res => {
                this.advertModal.loading = false;
                if (res.code === 0) {
                    this.advertModal.show = false;
                    this.getAdvertList();
                    this.showSuccessModal();
                }
            });
        },
        getAdvertList() {
            this.mainTable.tableLoading = true;
            api.advertList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedAdvert = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        getAdvertLocationList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersAdvertLocationList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.advertLocations = res.data.records;
                    this.setSelectOptions(this.mainTable.searchForm, 'Location', this.advertLocations, 'ParaName', 'ParaName');
                }
            });
        },
    }
};
</script>
<style>
</style>

