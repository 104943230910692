import { render, staticRenderFns } from "./nav-item.vue.js?vue&type=template&id=6e457112&scoped=true!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./nav-item.vue?vue&type=template&id=6e457112&scoped=true"
import script from "./nav-item.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./nav-item.vue?vue&type=script&lang=js"
export * from "./nav-item.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./nav-item.vue?vue&type=script&lang=js"
import style0 from "./nav-item.vue.less?vue&type=style&index=0&id=6e457112&prod&lang=less&scoped=true!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./nav-item.vue?vue&type=style&index=0&id=6e457112&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e457112",
  null
  
)

export default component.exports