<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getUserList"
                :table-search="mainTable.tableSearch">
                <div slot="appendBtn">
                    <Button v-if="checkAccessArray('LeafUserEdit')"
                        size="small"
                        @click="onAddUser">新增用户</Button>
                </div>
            </search-card>
        </div>
        <div>
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getUserList"
                :highlight="true"
                :windowHeight="true" />
        </div>

        <Modal v-model="userModal.show"
            title="用户信息"
            :mask-closable="false"
            width="800">
            <Spin v-if="userModal.loading"
                size="large"
                fix></Spin>
            <Form ref="userModalForm"
                :label-width="90">
                <Row>
                    <Col span="12">
                    <FormItem label="角色:"
                        required>
                        <Select v-model="userModal.roleId"
                            filterable
                            multiple
                            style="width:100%">
                            <Option v-for="item in roleList"
                                :value="item.Id"
                                :key="item.Id">{{ item.Name }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="账号:"
                        required>
                        <Input v-model="userModal.userName"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="12">
                    <FormItem label="名称:"
                        required>
                        <Input v-model="userModal.name"></Input>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="电话:">
                        <Input v-model="userModal.phone"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="12">
                    <FormItem label="所属部门:">
                        <Select v-model="userModal.department"
                            filterable
                            style="width:100%">
                            <Option v-for="item in departmentList"
                                :value="item.DeptName"
                                :key="item.DeptName">{{ item.DeptName }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="12">
                    <FormItem label="工作区域:">
                        <Select v-model="userModal.region"
                            filterable
                            style="width:100%">
                            <Option v-for="item in regionList"
                                :value="item.ParaName"
                                :key="item.ParaName">{{ item.ParaName }}</Option>
                        </Select>
                    </FormItem>
                    </Col>

                </Row>
                <Row>
                    <Col span="12">
                    <FormItem label="用户类型:">
                        <Select v-model="userModal.userType"
                            filterable
                            style="width:100%"
                            @on-change="onUserTypeChange">
                            <Option v-for="item in userTypes"
                                :value="item.ParaCode"
                                :key="item.ParaCode">{{ item.ParaName }}</Option>
                        </Select>
                    </FormItem>
                    </Col>

                    <Col span="12" v-if="userModal.userType == USER_TYPE_SUPPLY">
                    <FormItem label="供应商:">
                        <Select v-model="userModal.orgCode"
                            filterable
                            style="width:100%">
                            <Option v-for="item in supplierList"
                                :value="item.key"
                                :key="item.key">{{ item.value }}</Option>
                        </Select>
                    </FormItem>
                    </Col>

                    <Col span="12" v-if="userModal.userType == USER_TYPE_JOIN">
                    <FormItem label="加盟项目:">
                        <Select v-model="userModal.orgCode"
                            filterable
                            style="width:100%">
                            <Option v-for="item in joinList"
                                :value="item.ParaCode"
                                :key="item.ParaCode">{{ item.ParaName }}</Option>
                        </Select>
                    </FormItem>
                    </Col>

                    <Col span="12" v-if="userModal.userType == USER_TYPE_COLLECT">
                    <FormItem label="集货站:">
                        <Select v-model="userModal.orgCode"
                            filterable
                            multiple
                            style="width:100%">
                            <Option v-for="item in collectList"
                                :value="item.ParaCode"
                                :key="item.ParaCode">{{ item.ParaName }}</Option>
                        </Select>
                    </FormItem>
                    </Col>

                    <Col span="12" v-if="userModal.userType == USER_TYPE_CUSTOMER_MANAGER">
                    <FormItem label="客户经理:">
                        <Select v-model="userModal.orgCode"
                            filterable
                            style="width:100%">
                            <Option v-for="item in customerManagers"
                                :value="item.key"
                                :key="item.key">{{ item.value }}</Option>
                        </Select>
                    </FormItem>
                    </Col>

                    <Col span="12" v-if="userModal.userType == USER_TYPE_DELIVER">
                    <FormItem label="配送站点:">
                        <Select v-model="userModal.orgCode"
                            filterable
                            style="width:100%">
                            <Option v-for="item in deliverList"
                                :value="item.ParaCode"
                                :key="item.ParaCode">{{ item.ParaName }}</Option>
                        </Select>
                    </FormItem>
                    </Col>

                    <Col span="12" v-if="userModal.mode == 'add'">
                    <FormItem label="密码:">
                        <Input v-model="userModal.pwd" placeholder="不设置密码, 默认是123456"></Input>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveUser">保存</Button>
            </div>
        </Modal>

        <ResetUserPassWordModal2 ref="resetUserPassWordModal" />
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import ResetUserPassWordModal2 from '@/view/modal/reset-user-password-modal2.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import { setSelectOptions } from '@/libs/selectOption';
import { dateTimeRender } from '@/libs/tableRowRender';
import validate from "@/libs/validate";
import { USER_TYPE_SUPPLY, USER_TYPE_JOIN, USER_TYPE_COLLECT, USER_TYPE_CUSTOMER_MANAGER, USER_TYPE_OHTER, USER_TYPE_DELIVER, USER_TYPE_INSIDER } from '@/view/common/userType';
export default {
    name: 'UserList',
    components: {
        PaginationTable,
        SearchCard,
        ResetUserPassWordModal2,
        BaseTableFunction
    },
    data() {
        return {
            USER_TYPE_SUPPLY,
            USER_TYPE_JOIN,
            USER_TYPE_COLLECT,
            USER_TYPE_CUSTOMER_MANAGER,
            USER_TYPE_OHTER,
            USER_TYPE_DELIVER,
            USER_TYPE_INSIDER,
            userModal: {
                show: false,
                loading: false,
                mode: 'add',
                id: '',
                userName: '',
                pwd: '',
                name: '',
                userState: '',
                region: '',
                userType: '',
                isFlag: 1,
                phone: '',
                remark: '',
                roleId: '',
                department: '',
                orgCode: ''
            },
            whether:[{ key: 1, value: '是' }, { key: 0, value: '否' }],
            userTypes:[],
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '账号', key: "UserName", align: 'center' },
                    { title: '名称', key: "Name", align: 'center' },
                    { title: '所属部门', key: "Department", align: 'center' },
                    { title: '用户类型', key: "UserType", tooltip: true, align: 'center',
                        render: (h, params) => {
                            let text = '';
                            let type = this.userTypes.find(item => item.ParaCode === params.row.UserType);
                            if(type){
                                text = type.ParaName;
                            }
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    { title: '登录次数', key: "LoginNum", tooltip: true, align: 'center' },
                    { title: '最后一次登录', key: "LastLoginTime", align: 'center' },
                    { title: '角色', key: "RoleName", align: 'center', align: 'center' },
                    { title: '电话', key: "Phone", align: 'center' },
                    {
                        title: '状态', align: 'center',
                        render: (h, params) => {
                            let text = params.row.UserState === '1' ? '激活' : '冻结';
                            let color = params.row.UserState === '1' ? 'black' : 'red';
                            return h('div', [
                                h('span', {
                                    style: {
                                        color: color
                                    }
                                }, text)
                            ]);
                        }
                    },
                    {
                        title: "操作",
                        align: "center",
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'UserState',
                                        showEdit: this.checkAccessArray('LeafUserEdit'),
                                        showActive: this.checkAccessArray('LeafUserEdit'),
                                        showCustom: this.checkAccessArray('LeafUserEdit'),
                                        customText: '修改密码'
                                    },
                                    on: {
                                        handleActive: dataId => {
                                            this.onInactiveUser(dataId);
                                        },
                                        handleEdit: dataId => {
                                            this.onModifyUser(dataId);
                                        },
                                        handleCustom: dataId => {
                                            this.onResetPassword(dataId);
                                        }
                                    }
                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '账号', type: 'Input', value: '', name: 'SYS_User.UserName', sql: 'like' },
                    { label: '所属部门', type: 'Input', value: '', name: 'SYS_User.Department', sql: 'like' },
                    { label: '角色', type: 'Select', value: '', name: 'SYS_UserRole.RoleId', sql: 'eq', options: [], clearable: true },
                    { label: '用户类型', type: 'Select', value: '', name: 'SYS_User.UserType', sql: 'in', options: [], clearable: true },
                    { label: '状态', type: 'Select', value: [], name: 'SYS_User.UserState', sql: 'in', options: [{ key: '激活', value: '1' }, { key: '冻结', value: '0' }], clearable: true }
                ]
            },
            departmentList: [],
            roleList: [],
            regionList: [],
            supplierList: [],
            joinList: [],
            collectList: [],
            deliverList: [],
            customerManagers: []

        };
    },
    computed: {

    },
    mounted() {
        this.getUserList();
        this.getDepartmentList();
        this.getRoleList();
        this.getRegionList();
        this.getUserTypeList();
        this.getSupplierList();
        this.getJoinList();
        this.getCollectList();
        this.getDeliverList();
        this.getProductManagerList();
    },
    methods: {
        setSelectOptions,
        getRegionList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersRegionList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.regionList = res.data.records;
                }
            });
        },
        getRoleList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.leafRoleList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.roleList = res.data.records;
                    this.setSelectOptions(this.mainTable.searchForm, 'SYS_UserRole.RoleId', this.roleList, 'Name', 'Id');
                }
            });
        },
        getDepartmentList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.departmentList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.departmentList = res.data.records;
                }
            });
        },
        onResetPassword(id) {
            this.$refs.resetUserPassWordModal.showModal(id);
        },
        onAddUser() {
            this.userModal.mode = 'add';
            this.userModal.id = '';
            this.userModal.userName = '';
            this.userModal.pwd = '';
            this.userModal.name = '';
            this.userModal.phone = '';
            this.userModal.department = '';
            this.userModal.userRole = '';
            this.userModal.region = '';
            this.userModal.roleId = '';
            this.userModal.isFlag = 1;
            this.userModal.userType = '';
            this.userModal.userState = '';
            this.userModal.orgCode = '';
            this.userModal.show = true;
        },
        onInactiveUser(id) {
            this.mainTable.tableLoading = true;
            api.userInactive({ id: id }).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.getUserList();
                    this.showSuccessModal();
                }
            });
        },
        onModifyUser(id) {
            api.userInfo({ id: id }).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    Object.assign(this.userModal, res.data.userInfo);
                    if (this.userModal.userType == USER_TYPE_COLLECT) {
                        this.userModal.orgCode = this.userModal.orgCode.split(",");
                    }
                    this.userModal.roleId = this.userModal.roleId.split(",");
                    this.userModal.mode = 'modify';
                    this.userModal.show = true;
                }
            });
        },
        onSaveUser() {

            if (this.userModal.userType == USER_TYPE_COLLECT) {
                if(!this.userModal.orgCode){
                    return this.showWarningModal("请先选择集货站");
                }
                this.userModal.orgCode = this.userModal.orgCode.join(",");
            }
            if(!this.userModal.roleId){
                return this.showWarningModal("请先选择角色");
            }
            this.userModal.isLeaf = 1;
            this.userModal.isFlag = parseInt(this.userModal.isFlag);
            this.userModal.roleId = this.userModal.roleId.join(",");
            this.userModal.loading = true;
            api.userSave(this.userModal).then(res => {
                this.userModal.loading = false;
                if (res.code === 0) {
                    this.userModal.show = false;
                    this.getUserList();
                    this.showSuccessModal();
                }
            });
        },
        getUserList() {
            this.mainTable.tableLoading = true;
            this.mainTable.tableSearch.filters.enable = true;
            this.mainTable.tableSearch.filters.conditions.push({ name: 'SYS_User.Creater', type:'eq', value: this.getUserId() });
            api.userList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        getUserTypeList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersUserTypeList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.userTypes = res.data.records;
                    this.setSelectOptions(this.mainTable.searchForm, 'SYS_User.UserType', this.userTypes, 'ParaName', 'ParaCode');
                }
            });
        },
        getSupplierList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.supplierList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.supplierList = res.data.records.map(s=> {
                        return { key: s.Code, value: s.Name };
                    });
                }
            });
        },
        getJoinList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersJoinList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.joinList = res.data.records;
                }
            });
        },
        getCollectList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersCollectList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.collectList = res.data.records;
                }
            });
        },
        getDeliverList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersDelivList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.deliverList = res.data.records;
                }
            });
        },
        getProductManagerList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.productManagerList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.customerManagers = res.data.records.map(s=> {
                        return { key: s.Id, value: s.ManagerName };
                    });
                }
            });
        },
        onUserTypeChange(userType) {
            this.userModal.orgCode = '';

            if (userType == USER_TYPE_DELIVER) {
                if(this.deliverList) {
                    this.userModal.orgCode = this.deliverList[0].ParaCode;
                }
            }
        }

    }
};
</script>
<style>
</style>
