import { render, staticRenderFns } from "./manager-complaint.vue.js?vue&type=template&id=5fdf4c8d!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./manager-complaint.vue?vue&type=template&id=5fdf4c8d"
import script from "./manager-complaint.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./manager-complaint.vue?vue&type=script&lang=js"
export * from "./manager-complaint.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./manager-complaint.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports