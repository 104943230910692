import { render, staticRenderFns } from "./tag-and-product.vue.js?vue&type=template&id=9d978f72&scoped=true!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./tag-and-product.vue?vue&type=template&id=9d978f72&scoped=true"
import script from "./tag-and-product.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./tag-and-product.vue?vue&type=script&lang=js"
export * from "./tag-and-product.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./tag-and-product.vue?vue&type=script&lang=js"
import style0 from "./tag-and-product.vue.less?vue&type=style&index=0&id=9d978f72&prod&media=print&scoped=true&lang=less!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./tag-and-product.vue?vue&type=style&index=0&id=9d978f72&prod&media=print&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d978f72",
  null
  
)

export default component.exports