import { render, staticRenderFns } from "./sales-promotion-and-product.vue.js?vue&type=template&id=28a1deda&scoped=true!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./sales-promotion-and-product.vue?vue&type=template&id=28a1deda&scoped=true"
import script from "./sales-promotion-and-product.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./sales-promotion-and-product.vue?vue&type=script&lang=js"
export * from "./sales-promotion-and-product.vue.js?vue&type=script&lang=js!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./sales-promotion-and-product.vue?vue&type=script&lang=js"
import style0 from "./sales-promotion-and-product.vue.less?vue&type=style&index=0&id=28a1deda&prod&media=print&scoped=true&lang=less!=!../../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./sales-promotion-and-product.vue?vue&type=style&index=0&id=28a1deda&prod&media=print&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a1deda",
  null
  
)

export default component.exports