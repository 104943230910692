import getPostRequest from './base-api';

export default {
    getHomePageStat(data) {
        return getPostRequest(data, 'api/stat/homePageStat');
    },
	getShortcut(data) {
	    return getPostRequest(data, 'api/resource/shortcut');
	},
	shortcutSave(data) {
	    return getPostRequest(data, 'api/grant/shortcutSave');
	},
};