<template>
    <div>
        <div v-if="tableCaption" class="caption flex space-between">
            <div class="flex">
                <span>{{tableCaption}}</span>
                <div style="margin-left:20px;">
                    <slot name="headerBtn"></slot>
                </div>
            </div>
            <div v-if="showCollapse" @click="handleCollapse()">
                <span>{{!collapsed?'收起':'展开'}}</span>
                <Icon :type="!collapsed?'ios-arrow-up':'ios-arrow-down'" size="18" />
            </div>
        </div>
        <div v-show="!collapsed">
            <Table ref="paginationTable" :row-class-name="rowClassName" :columns="columns" :data="data" :loading="loading" :size="size" :border="true"
                :stripe="true" :highlight-row="highlight" :max-height="tableHeight" :row-key="rowKey"
                :show-summary="summaryFields.length > 0 || summaryLabelField != ''" :summary-method="handleSummary" @on-select="onSelect"
                @on-selection-change="onSelectionChange" @on-row-click="onRowClick" @on-row-dblclick="onRowDbClick" />
            <!-- <Table v-if="sumData"
                :columns="columns"
                :data="sumData"
                :size="size"
                :show-header="false" /> -->
            <Page v-if="showPage" :total="tableSearch.limit.conditions.total" :page-size="tableSearch.limit.conditions.pageLength"
                :current="tableSearch.limit.conditions.pageStart" :page-size-opts="pageSizeOptions" class="page" show-sizer size="small"
                placement="top" show-total @on-change="tablePageOnChange" @on-page-size-change="tablePageOnSizeChange" prev-text="上一页"
                next-text="下一页" />
        </div>
    </div>
</template>

<script>
import search from '@/libs/search';
import { number2ThousandsFormat } from '@/libs/logic.js';

export default {
    name: 'PaginationTable',
    props: {
        rowClassName: { type: Function, required: false },
        columns: { type: Array, required: true },
        data: { type: Array, required: true },
        sumData: { type: Array, required: false },
        totalRow: { type: Object, required: false },
        loading: { type: Boolean, required: true },
        highlight: { type: Boolean, default: false },
        rowKey: { type: String, default: '' },
        size: { type: String, default: 'small' },
        maxHeight: { type: Number, default: 0 },
        autoHeight: { type: Boolean, default: false },
        windowHeight: { type: Boolean, default: false },
        tableSearch: { type: Object, required: true },
        tableLoadEvent: { type: Function },
        pageSizeOptions: {
            type: Array,
            default: () => {
                return search.getSizeConfig();
            }
        },
        showPage: { type: Boolean, default: true },
        summaryFields: { type: Array, default: () => [] },
        summaryLabelField: { type: String, default: '' },
        tableCaption: { type: String, default: '' },
        showCollapse: { type: Boolean, default: false }
    },
    data() {
        return {
            search,
            collapsed: false,
            tableHeight: 500
        };
    },
    mounted() {
        if (this.autoHeight) {
            this.tableHeight = null;
        }
        else if (this.windowHeight) {
            let bf = this.summaryFields.length > 0 ? 80 : 45;
            this.$nextTick(() => {
                this.tableHeight = window.innerHeight - this.$refs.paginationTable.$el.offsetTop - bf;
            });
        }
        else if (this.maxHeight > 0) {
            this.tableHeight = this.maxHeight;
        }
    },
    methods: {
        selectAll(status){
            this.$refs.paginationTable.selectAll(status);
        },
        handleSummary({ columns, data }) {
            if (this.totalRow && this.summaryLabelField != '') {
                return this.handleSummaryBySumData({ columns, data });
            }
            const sums = {};
            columns.forEach((column, index) => {
                const key = column.key;
                if (this.summaryLabelField && key === this.summaryLabelField) {
                    sums[key] = {
                        key,
                        value: '合计'
                    };
                    return;
                }

                if (this.summaryFields.includes(key)) {
                    const values = data.map(item => Number(item[key]));
                    const v = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        }
                        else {
                            return prev;
                        }
                    }, 0);
                    let vStr = v;
                    if (column.isCurrencyField) {
                        vStr = number2ThousandsFormat(vStr, 3, '￥');
                    }
                    sums[key] = {
                        key,
                        value: vStr
                    };
                }
                else {
                    sums[key] = {
                        key,
                        value: ''
                    };
                }
            });

            return sums;
        },
        handleSummaryBySumData({ columns, data }) {
            const sums = {};
            const totalRow = this.totalRow;
            const totalRowKeys = Object.keys(totalRow);
            columns.forEach((column, index) => {
                const key = column.key;
                if (this.summaryLabelField && key === this.summaryLabelField) {
                    sums[key] = {
                        key,
                        value: '合计'
                    };
                    return;
                }
                if (totalRowKeys.includes(key)) {
                    sums[key] = {
                        key,
                        value: totalRow[key]
                    };
                }
                else {
                    sums[key] = {
                        key,
                        value: ''
                    };
                }
            });
            return sums;
        },
        handleCollapse() {
            this.collapsed = !this.collapsed;
        },
        tablePageOnChange(pageIndex) {
            search.globalPageOnChange(this.tableSearch, pageIndex);
            this.tableLoadEvent();
        },
        tablePageOnSizeChange(pageSize) {
            search.globalSizeOnChange(this.tableSearch, pageSize);
            this.tableLoadEvent();
        },
        onSelect(selection, row) {
            this.$emit('on-select', selection, row);
        },
        onSelectionChange(selection) {
            this.$emit('on-selection-change', selection);
        },
        onRowClick(row, index) {
            this.$emit('on-row-click', row, index);
        },
        onRowDbClick(row, index) {
            this.$emit('on-row-dblclick', row, index);
        }
    }
};

</script>

<style scoped>
.page {
    padding: 5px 0;
    text-align: center;
    border-bottom: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    margin-bottom: 5px;
}

.caption {
    line-height: 30px;
    padding: 0 10px;
    background-color: #f8f8f9;
    color: #196d6d;
    border-left: 1px solid #e8eaec;
    border-right: 1px solid #e8eaec;
}
</style>
