<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getArticleList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4" />
        </div>
        <Row :gutter="16">
            <Col span="12">
                <pagination-table
                    :columns="mainTable.tableColumns"
                    :data="mainTable.tableData"
                    :loading="mainTable.tableLoading"
                    :table-search="mainTable.tableSearch"
                    :table-load-event="getArticleList"
                    :highlight="true"
                    @on-row-click="onArticleSelect" />
            </Col>
            <Col span="12">
                <Form ref="articleModalForm" :label-width="100" style="margin-top: 15px;">
                    <Row>
                        <Col span="18">
                        <FormItem label="标题：" required>
                            <Input v-model="articleModal.title"></Input>
                        </FormItem>
                        </Col>
                        <Col span="6" style="position: relative;">
                            <Button type="info" @click="onSaveArticle" style="position: absolute; right: 70px;">{{btnTitle}}</Button>
                            <Button type="error" @click="onReset" style="position: absolute; right: 0;">重置</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="24">
                        <FormItem label="分类：" required>
                            <RadioGroup v-model="articleModal.articleType" >
                                <Radio v-for="(item, index) in articleTypes" :label="item.ParaCode" :key="index">
                                    <span>{{item.ParaName}}</span>
                                </Radio>
                            </RadioGroup>
                        </FormItem>
                        </Col>
                    </Row>
                </Form>
                <div  style="margin-right: 100px;">
                    <vue-ueditor-wrap v-model="articleModal.content" :config="editorConfig" @before-init="addCustomButtom"></vue-ueditor-wrap>
                </div>
            </Col>
        </Row>
        <Upload action="https://up-z2.qiniup.com/"
                :data="uploadParams"
                :format="['jpg','jpeg','png']"
                :on-format-error ="handleFormatError"
                :on-success="handleUploadSuccess"
                :before-upload="beforeUpload"
                :show-upload-list="showUploadList"
        >
            <button id="uploadButton" style="display: none;"></button>
        </Upload>
    </div>

</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import { setSelectOptions } from '@/libs/selectOption';
import VueUeditorWrap from 'vue-ueditor-wrap';
import axios from "axios";
export default {
    name: 'ArticleList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction,
        VueUeditorWrap
    },
    data() {
        return {
            editorConfig: {
                UEDITOR_HOME_URL: "/UEditor/",
                serverUrl: '//ueditor.zhenghaochuan.com/cos'
            },
            articleModal: {
                show: false,
                loading: false,
                title: '',
                content: '',
                articleType: '',
                author: '',
                source: 'web',
                mode: 'add'
            },
            btnTitle: '新增',
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '标题', key: "Title", align: 'center' },
                    { title: '类型', key: "ArticleType", align: 'center',
                        render: (h, params) => {
                            let text = '';
                            let type = this.articleTypes.find(item => parseInt(item.ParaCode) === params.row.ArticleType);
                            if(type){
                                text = type.ParaName;
                            }
                            return h('div', [
                                h('span', {}, text)
                            ]);
                        }
                    },
                    { title: '作者', key: "Author", align: 'center' },
                    { title: '来源', key: "Source", align: 'center' },
                    { title: '是否显示', key: "IsShow", align: 'center',
                        render:(h, params)=>{
                            let _this = this;
                            return h('i-switch', {
                                props:{
                                    value: params.row.IsShow,
                                    size: 'large',
                                    'true-value': 1,
                                    'false-value': 0
                                },
                                scopedSlots:{
                                    open:()=>h('span', '显示'),
                                    close:()=>h('span', '隐藏')
                                },
                                on: {
                                    'on-change': function (value) {
                                        _this.onShowOrHide({ id: params.row.Id, isShow: value });
                                    }
                                }
                            });
                        }
                    },
                    {
                        title: "操作",
                        align: "center",
                        width: 80,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showEdit: this.checkAccessArray('ArticleEdit'),
                                        showDelete: this.checkAccessArray('ArticleEdit')
                                    },
                                    on: {
                                        handleEdit: dataId => {
                                            this.onModifyArticle(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteArticle(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '标题', type: 'Input', value: '', name: 'Title', sql: 'like' },
                    { label: '作者', type: 'Input', value: '', name: 'Author', sql: 'like' },
                    { label: '类型', type: 'Select', value: '', name: 'ArticleType', sql: 'eq', options: [], clearable: true },
                    { label: '来源', type: 'Input', value: '', name: 'Source', sql: 'like' }
                ]
            },
            selectedArticle: null,
            articleTypes: [],
            uploadParams: {},
            showUploadList: false,
            editor: null
        };
    },
    computed: {

    },
    mounted() {
        this.getArticleTypeList();
        this.getArticleList();
    },
    methods: {
        setSelectOptions,
        onArticleSelect(row, index) {
            this.selectedArticle = row;
        },
        onDeleteArticle(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '确定删除吗？',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.articleDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getArticleList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onModifyArticle(id) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.articleModal.id = b.Id;
            this.articleModal.title = b.Title;
            this.articleModal.content = b.Content;
            this.articleModal.articleType = b.ArticleType + '';
            this.articleModal.mode = 'modify';
            this.btnTitle = '修改';
        },
        onSaveArticle() {

            if(!this.articleModal.title) {
                return this.showWarningModal('请先输入标题！');
            }

            if(!this.articleModal.articleType) {
                return this.showWarningModal('请选择类型！');
            }

            if(!this.articleModal.content) {
                return this.showWarningModal('内容不能为空！');
            }

            this.articleModal.loading = true;
            this.articleModal.articleType = parseInt(this.articleModal.articleType);
            api.articleSave(this.articleModal).then(res => {
                this.articleModal.loading = false;
                if (res.code === 0) {
                    this.articleModal.show = false;
                    this.getArticleList();
                    this.onReset();
                    this.showSuccessModal();
                }
            });
        },
        getArticleList() {
            this.mainTable.tableLoading = true;
            api.articleList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedArticle = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        onReset() {
            this.articleModal.articleType = '';
            this.articleModal.content = '';
            this.articleModal.title = '';
            this.articleModal.mode = 'add';
            this.btnTitle = '新增';
        },
        onShowOrHide(obj) {
            api.articleShowOrHide(obj).then(res => {
                if (res.code === 0) {
                    this.showSuccessModal();
                }
            });
        },
        getArticleTypeList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersArticleTypeList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.articleTypes = res.data.records;
                    this.setSelectOptions(this.mainTable.searchForm, 'ArticleType', this.articleTypes, 'ParaName', 'ParaCode');
                }
            });
        },
        // 添加自定义按钮
        addCustomButtom (editorId) {
            let _this = this;
          window.UE.registerUI(
            'image-upload-button',
            function (editor, uiName) {
              // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
              editor.registerCommand(uiName, {
                execCommand: function () {}
              });
              _this.editor = editor;

              // 创建一个 button
              const btn = new window.UE.ui.Button({
                // 按钮的名字
                name: uiName,
                // 提示
                title: '图片',
                // 需要添加的额外样式，可指定 icon 图标，图标路径参考常见问题 2
                cssRules:
                  "background-image: url('/static/UEditor/themes/default/images/icons.png') !important; background-position: -380px 0px;",
                // 点击时执行的命令
                onclick: function () {
                  // 这里可以不用执行命令，做你自己的操作也可
                  document.getElementById('uploadButton').click();
                }
              });

              // 当点到编辑内容上时，按钮要做的状态反射
              editor.addListener('selectionchange', function () {
                const state = editor.queryCommandState(uiName);
                if (state === -1) {
                  btn.setDisabled(true);
                  btn.setChecked(false);
                } else {
                  btn.setDisabled(false);
                  btn.setChecked(state);
                }
              });

              // 因为你是添加 button，所以需要返回这个 button
              return btn;
            },
            58 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */,
            editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
          );
        },
        handleFormatError(request, file) {
            this.$Notice.warning({
                title: '格式错误',
                desc: '请上传png、jpg、jpeg格式文件'
            });
        },
        async beforeUpload(request) {
            // **注意这里的参数必须通过属性赋值，如果直接修改整个uploadParams是无效的。**
            // 上传之前设置上传参数,
            let filename = Date.now() + Math.random().toString(36).slice(2) + ".png";
            await api.qiniuToken({}).then(res => {
                this.uploadParams.token = res.data.token;
                // 七牛response hash key的值自定义，后面如果需要浏览或者下载都需要这个地址，如果key不定义，那么七牛生成的key === hash的值
                this.uploadParams.key = filename;
            });
        },
        handleUploadSuccess(request, file, list) {
            let imgPath = this.imageDomain + file.response.key;
            if(this.editor){
                this.editor.execCommand('inserthtml', `<div><p><img src="${imgPath}" style="width: 100%;object-fit: fill"></p></div>`)
            }
        },
    }

};
</script></style>

