<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getMemberAddressList"
				:handleSearchWhenSelectChange="true" 
                :table-search="mainTable.tableSearch"/>

           <!-- <Button v-if="checkAccessArray('MemberAddressEdit')"
                size="small"
                @click="onAddMemberAddress">新增会员地址</Button> -->
        </div>
        <Row>
            <Col span="24">
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getMemberAddressList"
                :highlight="true"
                @on-row-click="onMemberAddressSelect" />
            </Col>
        </Row>

        <MemberAddressModal ref="memberAddressModal" />
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import MemberAddressModal from './address-model.vue';
export default {
    name: 'MemberAddressList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction,
		MemberAddressModal
    },
    data() {
        return {
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '会员编码', key: "MemCode", align: 'center' },
                    { title: '姓名', key: "MemName", align: 'center' },
                    { title: '联系人', key: "LinkMan", align: 'center' },
                    { title: '手机号', key: "Phone", align: 'center' },
                    { title: '省', key: "Province", align: 'center' },
                    { title: '市', key: "City", align: 'center' },
                    { title: '区', key: "Area", align: 'center' },
                    { title: '街道', key: "Street", align: 'center' },
                    { title: '详细地址', key: "DetailAddr", align: 'center' },
					{ title: '发货方式', key: "DeliveryWay", align: 'center' },
					{
					    title: '是否默认地址', key: "IsDefault", align: 'center', 
					    render: (h, params) => {
					        let text = '';
					        let color = '';
					        switch(parseInt(params.row.IsDefault)) {
					            case 0: text = '否'; color = 'black'; break;
					            case 1: text = '是'; color = 'red'; break;
					            default: text = '未知'; color = 'red';
					        }
					        return h('div', [
					            h('span', {
					                style: {
					                    color: color
					                }
					            }, text)
					        ]);
					    }
					},
					{
					    title: '状态', key: "AddrState", align: 'center', 
					    render: (h, params) => {
					        let text = '';
					        let color = '';
					        switch(parseInt(params.row.AddrState)) {
					            case 0: text = '待审核'; color = 'red'; break;
					            case 1: text = '审核通过'; color = 'black'; break;
					            case 2: text = '审核不通过'; color = 'blue'; break;
					            default: text = '状态异常'; color = 'blue';
					        }
					        return h('div', [
					            h('span', {
					                style: {
					                    color: color
					                }
					            }, text)
					        ]);
					    }
					},
                    {
                        title: "操作",
                        align: "center",
                        width: 80,
						render: (h, params) => {
							let txt=params.row.AddrState!==0?'编辑':'审核';
							return h('div', [
								h('Button', {
									props: {
										size: 'small',
										shape: 'circle',
										statusField: 'Status',
										showEdit: this.checkAccessArray('MemberAddressEdit')
									},
									style: {
										margin: '2px'
									},
									on: {
										click: () => {
											this.onModifyMemberAddress(params.row.Id);
										}
									}
								}, txt)
							]);
						}
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
					{ label: '会员', type: 'Input', value: '', name: 'concat(MemCode,MemName) ', sql: 'like',clearable: true },
                    { label: '联系人', type: 'Input', value: '', name: 'LinkMan', sql: 'like' ,clearable: true},
                    { label: '手机号', type: 'Input', value: '', name: 'Phone', sql: 'like',clearable: true },
					{ label: '地址', type: 'Input', value: '', name: 'concat(Province,City,Area,Street,DetailAddr) ', sql: 'like' ,clearable: true},
                    {
                        label: '状态', type: 'Select', value: [], name: 'AddrState', sql: 'eq', options: [
                            { key: '待审核', value: '0' },
                            { key: '审核通过', value: '1' },
							{ key: '审核不通过', value: '2'}
                        ],clearable: true
                    },
                ]
            },
            selectedMemberAddress: null
        };
    },
    computed: {

    },
    mounted() {
        this.getMemberAddressList();
    },
    methods: {
        onMemberAddressSelect(row, index) {
            this.selectedMemberAddress = row;
        },
		getMemberAddressList() {
		    this.mainTable.tableLoading = true;
		    api.memberAddressList(this.mainTable.tableSearch).then(res => {
		        this.mainTable.tableLoading = false;
		        if (res.code === 0) {
		            this.selectedMemberAddress = null;
		            this.mainTable.tableData = res.data.records;
		            search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
		        }
		    });
		},
		//新增
        // onAddMemberAddress() {
        //     this.$refs.memberAddressModal.showModal();
        // },
		//编辑/修改
        onModifyMemberAddress(id,readOnly = false) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.$refs.memberAddressModal.showModal(b,readOnly);
        }
        
    }
};
</script>
<style>
</style>


