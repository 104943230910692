<template>
	<div class="">
		<Modal v-model="productModal.show" title="产品信息" :mask-closable="false" width="70">
			<Spin v-if="productModal.loading" size="large" fix></Spin>
			<Form ref="productModalForm" :label-width="120">
				<Row>
					<Col span="8">
					<FormItem label="编码:" required>
						<Input v-model="productModal.code" readonly></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="名称:" required>
						<Input v-model="productModal.name" readonly></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="等级:" required>
						<Input v-model="productModal.grade" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="8">
					<FormItem label="产品大类:" required>
						<Input v-model="productModal.mainCategory" readonly></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="产品小类:" required>
						<Input v-model="productModal.subCategory" readonly></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="颜色:" required>
						<Input v-model="productModal.color" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="8">
					<FormItem label="拼音码:">
						<Input v-model="productModal.pinYinCode" readonly></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="关键词:">
						<Input v-model="productModal.keyword" readonly></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="单位:">
						<Input v-model="productModal.unit" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="8">
					<FormItem label="规格:">
						<Input v-model="productModal.spec" readonly></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="包装数:">
						<Input v-model="productModal.packCount" readonly></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="长度:">
						<Input v-model="productModal.length" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="8">
					<FormItem label="叶片:">
						<Input v-model="productModal.blade" readonly></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="花苞:">
						<Input v-model="productModal.bud" readonly></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="开放度:">
						<Input v-model="productModal.openness" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="8">
					<FormItem label="茎杆:">
						<Input v-model="productModal.stalk" readonly></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="枝条弯曲度:">
						<Input v-model="productModal.bending" readonly></Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="病虫害:">
						<Input v-model="productModal.diseased" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="8">
					<FormItem label="重量:">
						<Input v-model.number="productModal.weight" type="number">
						<template #suffix>
							<span style="display: inline-block; line-height: 32px;">kg</span>
						</template>
						</Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="体积:">
						<Input v-model.number="productModal.volumn" type="number">
						<template #suffix>
							<span style="display: inline-block; line-height: 32px;">m³</span>
						</template>
						</Input>
					</FormItem>
					</Col>
					<Col span="8">
					<FormItem label="机械损伤:">
						<Input v-model="productModal.damage" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="8">
					<FormItem label="是否为新品:">
						<RadioGroup v-model="productModal.isNew">
							<Radio label="0" disabled>
								<span>非新品</span>
							</Radio>
							<Radio label="1" disabled>
								<span>新品</span>
							</Radio>
						</RadioGroup>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="24">
					<FormItem label="整扎标准:">
						<Input v-model="productModal.tieStandard" type="textarea" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="24">
					<FormItem label="备注:">
						<Input v-model="productModal.remark" type="textarea" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="24">
					<FormItem label="图片:">
						<template>
							<Row :gutter="16" style="margin-top: 10px;" v-if="productModal.picPath">
								<Col span="6">
								<img :src="productModal.picPath" fit="fill" width="150px" height="150px" />
								</Col>
							</Row>
						</template>
					</FormItem>
					</Col>
				</Row>
			</Form>

			<div slot="footer">
				<Button type="info" @click="onCloseProduct">关闭</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import api from '@/api/api';
	import search from '@/libs/search';
	export default {
		name: 'ProductApprovalModal',
		components: {

		},
		data() {
			return {
				imageDomain: this.imageDomain,
				productModal: {
					show: false,
					loading: false,
					mode: 'add',
					id: '',
					code: '',
					name: '',
					joinCode: '',
					joinName: '',
					brand: '',
					brandCode: '',
					dvAdd: '',
					mainCategory: '',
					subCategory: '',
					color: '',
					spec: '',
					grade: '',
					unit: '',
					packCount: '',
					pinYinCode: '',
					keyword: '',
					remark: '',
					picPath: '',
					paraShow: '',
					length: '',
					blade: '',
					bud: '',
					stalk: '',
					bending: '',
					diseased: '',
					openness: '',
					damage: '',
					duration: '',
					videoPath: '',
					tieStandard: '',
					weight: '',
					reviewText: '',
					approveState: 1,
					idea: ''

				},
			};
		},
		computed: {

		},
		mounted() {},
		methods: {

			async showModal(id) {

					//重新获取产品审核详情
					await api.productApprovalInfo({
						id: id
					}).then(res => {
                        this.initData(res.data.productApprovalInfo);
					});
				this.productModal.show = true;
			},
			initData(product) {
				this.productModal.id = product.id;
				this.productModal.code = product.code;
				this.productModal.name = product.name;
				this.productModal.joinCode = product.joinCode;
				this.productModal.joinName = product.joinName;
				this.productModal.brand = product.brand;
				this.productModal.brandCode = product.brandCode;
				this.productModal.dvAdd = product.dvAdd;
				this.productModal.mainCategory = product.mainCategory;
				this.productModal.subCategory = product.subCategory;
				this.productModal.color = product.color;
				this.productModal.spec = product.spec;
				this.productModal.grade = product.grade;
				this.productModal.unit = product.unit;
				this.productModal.packCount = product.packCount;
				this.productModal.pinYinCode = product.pinYinCode;
				this.productModal.keyword = product.keyword;
				this.productModal.remark = product.remark;
				this.productModal.reviewText = product.reviewText;
				this.productModal.picPath = this.imageDomain + product.picPath;
				this.productModal.paraShow = product.paraShow;
				this.productModal.length = product.length;
				this.productModal.blade = product.blade;
				this.productModal.bud = product.bud;
				this.productModal.stalk = product.stalk;
				this.productModal.bending = product.bending;
				this.productModal.diseased = product.diseased;
				this.productModal.openness = product.openness;
				this.productModal.damage = product.damage;
				this.productModal.isNew = product.isNew + '';
				this.productModal.duration = product.duration;
				this.productModal.videoPath = product.videoPath;
				this.productModal.tieStandard = product.tieStandard;
				this.productModal.weight = product.weight;
				this.productModal.productStandardCode = product.productStandardCode;
				this.productModal.approveState = product.approveState;
				this.productModal.state = product.state;
				this.productModal.volumn = product.volumn;
				this.productModal.mode = 'modify';
			},

			onCloseProduct() {
				this.productModal.show = false;
			},
		}
	};
</script>
<style>
</style>
