export default {
    closeAllConditions(search) {
        search.filters.enable = false;
        search.order.enable = false;
        search.limit.enable = false;
        search.excelExport.enable = false;
    },
    getSizeConfig() {
        return [10, 20, 50, 100];
    },
    getSearchParams(opt = null) {
        let condition = {
            filters: {
                enable: false,
                join: 'and',
                conditions: []
            },
            order: {
                enable: false,
                conditions: []
            },
            limit: {
                enable: true,
                conditions: {
                    pageLength: 10,
                    pageStart: 1,
                    total: 0
                }
            },
            excelExport: {
                enable: false,
                columns: []
            }
        };

        if (opt && opt.hasOwnProperty('closeAllConditions') && opt.closeAllConditions) {
            this.closeAllConditions(condition);
        }

        if (opt && opt.hasOwnProperty('pageSize') && opt.pageSize) {
            this.globalSizeOnChange(condition, opt.pageSize);
        }

        return condition;
    },
    globalPageOnChange(search, pageIndex) {
        search.limit.conditions.pageStart = pageIndex;
    },
    globalResetSearch(search) {
        search.limit.conditions.pageStart = 1;
    },
    globalSetFilterRecordCount(search, count) {
        search.limit.conditions.total = count;
    },
    globalSetFilterPageLength(search, pageLength) {
        search.limit.conditions.pageLength = pageLength;
    },
    globalRemoveFilterConditionKey(search, conditionName) {
        let index = search.filters.conditions.findIndex(e => {
            return e.name === conditionName;
        });
        if (index !== -1) {
            search.filters.conditions.splice(index, 1);
        }
    },
    globalAddFilterConditionKey(search, conditionName, op, value) {
        this.globalRemoveFilterConditionKey(search, conditionName);
        search.filters.enable = true;
        search.filters.conditions.push({
            name: conditionName,
            type: op,
            value: value
        });
    },
    globalSizeOnChange(search, pageSize) {
        // if (pageSize !== '所有') {
            search.limit.enable = true;
            search.limit.conditions.pageStart = 1;
            search.limit.conditions.pageLength = pageSize;
        // }
        // else {
        //     search.limit.enable = false;
        // }
    },
    globalSetFilterWithSearchForm(search, form) {
        search.filters.enable = form.length > 0;
        let conditions = form.map(v => {
            let val = typeof (v.value) === 'string' ? v.value : JSON.stringify(v.value);

            return { name: v.name, type: v.sql, value: val };
        });
       
        //去除空值的条件
        conditions = conditions.filter(a => (a.value === '' || a.value === '[]') ? false : true);

        search.filters.conditions = [...conditions];
        search.limit.conditions.pageStart = 1;
    }
};
