<template>
	<div>
		<div class="flex searchButtonContainer">
		    <search-card :form="mainTable.searchForm"
		        :table-load-event="getEvaluateList"
		        :table-search="mainTable.tableSearch"
		        :colSpan="4"
		        :searchBtnColSpan="4" />
		</div>
		<Row>
		    <Col span="24">
		    <pagination-table
		        :columns="mainTable.tableColumns"
		        :data="mainTable.tableData"
		        :loading="mainTable.tableLoading"
		        :table-search="mainTable.tableSearch"
		        :table-load-event="getEvaluateList"
		        :highlight="true"></pagination-table>
		    </Col>
		</Row>
	</div>
</template>

<script>
	import api from '@/api/api';
	import search from '@/libs/search';
	import { Modal } from 'view-design';
	let EvaluateRateList ={
		1 :'一星',
		2 :'二星',
		3 :'三星',
		4 :'四星',
		5 :'五星',
	}
	export default {
		data() {
			return{
				mainTable:{
					tableColumns:[
						{
						    title: '会员', key: '', align: 'center',
						    render: (h, params) => {
						        return h('div', [
						            h('span', { style: 'color:#2b85e4;margin-right:4px;' }, params.row.MemberCode) ,
						            h('span', {}, params.row.MemberName)
						        ]);
						    }
						},
						{ title: '品名', key: "ProductName", align: 'center',
						render: (h, params) => {
						    return h('div', [
						        h('span', { style: 'color:#2b85e4;margin-right:4px;' }, params.row.ProductCode),
						        h('span', {}, params.row.ProductName)
						    ]);
						}
						},
						{ title: '品牌', key: "Brand", align: 'center' },
						{ title: '加盟项目', key: "JoinName", align: 'center' },
						{ title: '等级', key: "Grade", align: 'center' },
						{ title: '星级', key: "EvaluateRate", align: 'center' ,
							render: (h, params) => {
								let text = EvaluateRateList[params.row.EvaluateRate];
								return h('div', [
									h('span', {}, text)
								]);
							}
						},
						{ title: '图片', key: "EvaluateImg",  align: 'center',
						    render: (h, params) => {
								let imgArr = []
								let dom = null
								const imageSrc = params.row.EvaluateImg.split(',').map(p=> this.imageDomain + p );
						        dom =  h('div',[
								imageSrc.forEach(url => {
								  imgArr.push(h('img',{
									  attrs:{
										src:url
									  },
									  style:{
										with:'30px',
										height:'30px',
										margin:'2px'
									  },
									  on: {
									      click: () => {
									          Modal.info({
									              title: '',
									              closable: true,
									              okText: '关闭',
									              render: h => {
									                  return h("img", {
									                      attrs: {
									                          src: url,
									                          style: "width: 350px;height: 350px;"
									                      }
									                  });
									              }
									          });
									      }
									  }
								  }));
								}),
								h('span',{
				
								  },imgArr)
							  ])
							  return dom
						    }
						},
						{ title: '评论描述', key: "EvaluateContent", align: 'center' },
						{ title: '单价', key: "ProductPrice", align: 'center' },
						{ title: '数量', key: "OrderCount", align: 'center' },
						{ title: '销售时间', key: "OrderTime", align: 'center' ,width:120},
						{ title: '评价时间', key: "EvaluateTime", align: 'center' ,width:120},
						{
							title: "操作",
							align: "center",
							width: 80,
							render: (h, params) => {
								return h('div', [
										h('Button', {
											props: {
												size: 'small',
												shape: 'circle'
											},
											style:{
												margin:'2px',
												display: (this.checkAccessArray('evaluateModify'))? 'inline' : 'none'
											},
										    on: {
										        click: () => {
													this.evaluateModify(params.row.Id)
										        }
										    }
										}, '删除'),
									]
								);
							}
						}
						
					],
					tableLoading: false,
					tableData:[],
					tableSearch: search.getSearchParams(),
					searchForm: [
					    { label: '日期', type: 'Daterange', value: '', name: 'EvaluateTime', sql: 'dateBetween' ,labelWidth: 50,fieldWidth: 300},
					    { label: '品牌', type: 'Input', value: '', name: 't2.Brand', sql: 'like' },
					    { label: '加盟项目', type: 'Input', value: '', name: 'JoinName', sql: 'like' },
						{ label: '星级', type: 'Select', value: [], name: 'EvaluateRate', sql: 'in', 
							options: [{ key: '一星', value: '1' }, { key: '二星', value: '2' }, { key: '三星', value: '3' }, { key: '四星', value: '4' }, { key: '五星', value: '5' }], 
							clearable: true ,
						}
					]
				}
			}
		},
		mounted() {
			this.getEvaluateList()
		},
		methods :{
			getEvaluateList(){
				this.mainTable.tableLoading = true;
				api.orderEvaluateAdminList(this.mainTable.tableSearch).then(res => {
				    this.mainTable.tableLoading = false;
				    if (res.code === 0) {
				        this.mainTable.tableData = res.data.records;
						console.log(this.mainTable.tableData[0].EvaluateImg.split(',').map(p=> this.imageDomain + p ));
						
				        search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
				    }
				});
			},
			evaluateModify(id){
				this.$Modal.confirm({
				    title: '确认',
				    content: '确认删除该评价?',
				    onOk: () => {
				        this.mainTable.tableLoading = true;
				        api.orderEvaluateDeleteById({ id: id }).then(res => {
				            this.mainTable.tableLoading = false;
				            if (res.code === 0) {
				                this.getEvaluateList();
				                this.showSuccessModal();
				            }
				        });
				    }
				});
			}
		}
	}
</script>

<style>
</style>