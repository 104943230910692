<template>
	<div class="nav-contianer">
		<div class="nav-tips">
			<div class="nav-desc">
				<img :src="Ban" alt="">
				<p>快捷导航</p>
			</div>
			<div class="nav-desc" style="margin-left: 14px;margin-bottom: 20px;">
				<p style="font-size: 14px;color: #2B5AC5; " @click="onNavItemModal">设置快捷导航</p>
			</div>
		</div>
		<div class="nav-list">
			<div class="nav-item" v-for="(item ,index) in list" :key="item.Id" @click="routerTo(item.Link)">
				<img :src="item.Icon === '' ? Nav1 : `${axios.fileServerUrl}/${item.Icon}`" alt="">
				<p>{{item.Name}}</p>
			</div>
		</div>
		
		<!-- 弹窗, 新增 / 修改 -->
		<NavItemModal ref="navItemModalRef" />
	</div>
</template>

<script>
	import axios from '@/libs/api.request';
	import Ban from '@/assets/images/ban.png'
	import Nav1 from '@/assets/images/nav1.png'
	import NavItemModal from './nav-item-modal.vue'
	export default {
		props:{
			list:{
				type: Array,
				default :[]
			}
		},
		components:{
			NavItemModal
		},
		data(){
			return {
				Ban,
				Nav1,
				axios,
			}
		},
		methods:{
			routerTo(link){
				this.$router.push(link);
			},
			onNavItemModal(){
				this.$refs.navItemModalRef.showModal();
			}
		}
	}
</script>

<style lang="less" scoped>
	.nav-contianer{
		width: 100%;
		height: auto;
		background-color: #f0f2f5;
		border-radius: 10px;
		display: flex;
	}
	.nav-tips{
		cursor: pointer;
		width: 128px;
		height: 100px;
		padding-top: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.nav-desc{
			display: flex;
			img {
				width: 13px;
				height: 26px;
			}
			p{
				width: 88px;
				margin-top: -2px;
				vertical-align:middle;
				margin-left: 16px;
				font-size: 22px;
				font-weight: 500;
				color: #555555;
			}
			
		}
		
	}
	.nav-list{
		margin-left: 30px;
		width: calc(100% +40px);
		padding-top: 10px;
		display: flex;
		flex-wrap: wrap;
		// justify-content: space-between;
	}
	.nav-item{
		cursor: pointer;
		margin-right: 40px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		img{
			width: 65px;
			height: 65px;
		}
		
		p{
			margin-top: 4px;
			width: 65px;
			font-size: 13px;
			font-weight: 500;
			color: #666666;
		}
	}
</style>