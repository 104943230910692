<template>
	<div class="">
		<Modal v-model="productModal.show" title="产品信息" :mask-closable="false" width="70">
			<Spin v-if="productModal.loading" size="large" fix></Spin>
			<Form ref="productModalForm" :label-width="120">
				<Row>
					<Col span="6">
					<FormItem label="产品经理:" required>
						<Select v-model="productModal.joinCode" v-if="!isMySelfJoin && !productModal.readOnly" style="width:100%"
							@on-change="onJoinChange">
							<Option v-for="item in joinList" :value="item.ParaCode" :key="item.ParaCode">
								{{ item.ParaName }}</Option>
						</Select>
						<Input v-else v-model="productModal.joinName" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="品牌:" required>
						<Select v-model="productModal.brandCode" v-if="!productModal.readOnly" filterable style="width:100%">
							<Option v-for="item in brandList" :value="item.code" :key="item.code">{{ item.name }}
							</Option>
						</Select>
						<Input v-else v-model="productModal.brand" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="产品标准:">
						<Select v-model="productModal.productStandardCode" filterable clearable style="width:100%"
							@on-change="onStandardChange" :remote-method="remoteSearchStandard"
							:loading="remoteSearchLoading" :disabled="productModal.readOnly == true">
							<Option v-for="item in standardList" :value="item.Code" :key="item.Id">{{ item.Code }}
								{{ item.Grade }} {{ item.Name }}</Option>
						</Select>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="编码:" required>
						<Input v-model="productModal.code" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="6">
					<FormItem label="名称:" required>
						<Input v-model="productModal.name" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="等级:" required>
						<Input v-model="productModal.grade" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="产品大类:" required>
                        <Select v-model="productModal.mainCategory" filterable clearable style="width:100%"
                        	@on-change="onMainCategoryChange" :disabled="productModal.readOnly == true">
                            <Option v-for="item in mainCateList" :value="item.Name" :key="item.Id">{{ item.Name }}</Option>
                        </Select>
						<!-- <Input v-model="productModal.mainCategory" readonly></Input> -->
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="产品小类:" required>
						<Input v-model="productModal.subCategory" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="6">
					<FormItem label="颜色:" required>
						<Input v-model="productModal.color" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="拼音码:">
						<Input v-model="productModal.pinYinCode" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="关键词:">
						<Input v-model="productModal.keyword" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="单位:">
						<Input v-model="productModal.unit" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="6">
					<FormItem label="规格:">
						<Input v-model="productModal.spec" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="包装数:">
						<Input v-model="productModal.packCount" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="长度:">
						<Input v-model="productModal.length" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="叶片:">
						<Input v-model="productModal.blade" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="6">
					<FormItem label="花苞:">
						<Input v-model="productModal.bud" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="开放度:">
						<Input v-model="productModal.openness" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="茎杆:">
						<Input v-model="productModal.stalk" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="枝条弯曲度:">
						<Input v-model="productModal.bending" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="6">
					<FormItem label="病虫害:">
						<Input v-model="productModal.diseased" readonly></Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="重量:">
						<Input v-model.number="productModal.weight" type="number">
						<template #suffix>
							<span style="display: inline-block; line-height: 32px;">kg</span>
						</template>
						</Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="体积:">
						<Input v-model.number="productModal.volumn" type="number">
						<template #suffix>
							<span style="display: inline-block; line-height: 32px;">m³</span>
						</template>
						</Input>
					</FormItem>
					</Col>
					<Col span="6">
					<FormItem label="机械损伤:">
						<Input v-model="productModal.damage" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="24">
					<FormItem label="整扎标准:">
						<Input v-model="productModal.tieStandard" type="textarea" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="24">
					<FormItem label="备注:">
						<Input v-model="productModal.remark" type="textarea" readonly></Input>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="6">
					<FormItem label="是否为新品:">
						<RadioGroup v-model="productModal.isNew">
							<Radio label="0" disabled>
								<span>非新品</span>
							</Radio>
							<Radio label="1" disabled>
								<span>新品</span>
							</Radio>
						</RadioGroup>
					</FormItem>
					</Col>
					<Col span="6" v-if="productModal.isNew == '1'">
					<FormItem label="新品持续时间:">
						<DatePicker type="date" v-model="productModal.duration"></DatePicker>
					</FormItem>
					</Col>
					<Col span="6" v-if="productModal.isNew == '1'">
					<FormItem>
						<span style="margin-left: -90px; color: blue;">(默认三个月)</span>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="24">
					<FormItem label="产品标签:">
						<CheckboxGroup v-model="selectTags">
							<Checkbox v-for="item in productTags" :key="item.Id" :label="item.Id" border>{{item.Name}}
							</Checkbox>
						</CheckboxGroup>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="24">
					<FormItem label="图片:">
						<template>
							<Upload action="https://up-z2.qiniup.com/" :data="uploadParams"
								:format="['jpg','jpeg','png']" :on-format-error="handleFormatError"
								:on-success="handleUploadSuccess" :before-upload="beforeUpload"
								:show-upload-list="showUploadList"
								v-if="productModal.readOnly == false && images.length <5">
								<Button icon="ios-cloud-upload-outline">上传图片</Button>
							</Upload>

							<Row :gutter="16" style="margin-top: 10px;">
								<Col span="5" v-for="(item, index) in images" :key="index">
								<div class="img-card">
									<img :src="item.src" fit="fill" width="150px" height="150px"
										@click="setPrimaryImg(item.src)" />
									<Icon type="md-trash" class="icon-delete" @click="deleteImage(index)"
										v-if="productModal.readOnly == false" />
									<div class="primary-img" v-if="item.src == productModal.picPath">封面</div>
								</div>
								</Col>
							</Row>
						</template>
					</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span="24">
					<FormItem label="视频:">
						<template>
							<Upload action="https://up-z2.qiniup.com/" :data="uploadParams" :format="['mp4']"
								accept=".mp4" :on-format-error="handleFormatError"
								:on-success="handleUploadVideoSuccess" :before-upload="beforeVideoUpload"
								:show-upload-list="showUploadList" v-if="productModal.readOnly == false">
								<Button icon="ios-cloud-upload-outline">上传视频</Button>
							</Upload>

							<Row v-if="productModal.videoPath">
								<Col span="24">
								<video width="320" height="240" controls>
									<source :src="productModal.videoPath" type="video/mp4">
								</video>
								</Col>
							</Row>
						</template>
					</FormItem>
					</Col>
				</Row>
				<Row v-if="productModal.mode === 'modify' && evaluates.length > 0">
					<Col span="24">
					<FormItem label="产品评价:">
						<Tag color="primary" v-if="!isDeleteEvaluate" v-for="item in evaluates" :value="item.Id"
							:key="item.Id">{{ item.EvaluateContent }}</Tag>
						<Tag color="primary" v-else type="border" closable @on-close="onDeleteProductEvaluate(item.Id)"
							v-for="item in evaluates" :value="item.Id" :key="item.Id">{{ item.EvaluateContent }}</Tag>
					</FormItem>
					</Col>
				</Row>

			</Form>

			<div slot="footer">
				<Button type="info" @click="onSaveProduct" v-if="productModal.readOnly == false">保存</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import api from '@/api/api';
	import search from '@/libs/search';
	import axiosOri from 'axios';
    import { getMainCateOptions} from '@/libs/selectOption';
	export default {
		name: 'ProductModal',
		components: {

		},
		data() {
			return {
				productModal: {
					show: false,
					loading: false,
					readOnly: false,
					mode: 'add',
					id: '',
					code: '',
					name: '',
					joinCode: '',
					joinName: '',
					brand: '',
					brandCode: '',
					dvAdd: '',
					mainCategory: '',
					subCategory: '',
					color: '',
					spec: '',
					grade: '',
					unit: '',
					packCount: '',
					pinYinCode: '',
					keyword: '',
					remark: '',
					picPath: '',
					paraShow: '',
					length: '',
					blade: '',
					bud: '',
					stalk: '',
					bending: '',
					diseased: '',
					openness: '',
					damage: '',
					duration: '',
					videoPath: '',
					tieStandard: '',
					weight: 0,
					volumn: 0,
					tags: ''
				},
				unitList: [],
				joinList: [],
				brandList: [],
				mainCateList: [],
				subCateList: [],
				subCateFilterList: [],
				productGrades: [],
				images: [],
				uploadParams: {},
				showUploadList: false,
				imageDomain: this.imageDomain,
				standardList: [],
				productTags: [],
				selectTags: [],
				remoteSearchLoading: false,
				isMySelfJoin: true,
				evaluates: [],
				isDeleteEvaluate: false,
				currProductStandardCode: ''
			};
		},
		computed: {

		},
		mounted() {
			this.getJoinList();
			this.getProductTagList();
		},
		methods: {
			setPrimaryImg(imgPath) {
				if (this.productModal.readOnly) {
					return;
				}
				this.productModal.picPath = imgPath;
			},
			// 删除图片
			deleteImage(index) {
				this.$Modal.confirm({
					title: '确认',
					content: '是否删除图片？',
					onOk: () => {
						if (this.images[index].id) {
							api.attachmentDelete({
								id: this.images[index].id
							}).then(res => {
								if (res.code === 0) {
									this.showSuccessModal();
									this.images.splice(index, 1);
								}
							});
						} else {
							this.images.splice(index, 1);
						}
					}
				});
			},
			handleFormatError(request, file) {
				this.$Notice.warning({
					title: '格式错误',
					desc: '请上传png、jpg、jpeg格式文件'
				});
			},
			async beforeUpload(request) {
				// **注意这里的参数必须通过属性赋值，如果直接修改整个uploadParams是无效的。**
				// 上传之前设置上传参数,
				let filename = Date.now() + Math.random().toString(36).slice(2) + ".png";
				await api.qiniuToken({}).then(res => {
					this.uploadParams.token = res.data.token;
					// 七牛response hash key的值自定义，后面如果需要浏览或者下载都需要这个地址，如果key不定义，那么七牛生成的key === hash的值
					this.uploadParams.key = filename;
				});
			},
			handleUploadSuccess(request, file, list) {
				let imgPath = this.imageDomain + file.response.key;
				this.images.push({
					id: '',
					src: imgPath,
					size: file.size
				});
			},
			async beforeVideoUpload(request) {
				// **注意这里的参数必须通过属性赋值，如果直接修改整个uploadParams是无效的。**
				// 上传之前设置上传参数,
				let suffix = request.name.substring(request.name.lastIndexOf("."));
				let filename = Date.now() + Math.random().toString(36).slice(2) + suffix;
				await api.qiniuToken({}).then(res => {
					this.uploadParams.token = res.data.token;
					// 七牛response hash key的值自定义，后面如果需要浏览或者下载都需要这个地址，如果key不定义，那么七牛生成的key === hash的值
					this.uploadParams.key = filename;
				});
			},
			handleUploadVideoSuccess(request, file, list) {
				this.productModal.videoPath = this.imageDomain + file.response.key;
			},
			async showModal(product, readOnly = false) {
				this.images = [];
				if (product) {
					//重新获取产品详情
					await api.productInfo({
						id: product.Id
					}).then(res => {
						product = res.data.productInfo;
					});
				}
				this.getProductImages(product);
				this.init(product, readOnly);
			},
			init(product, readOnly) {
				let that = this;
				let tempSearch = search.getSearchParams({
					closeAllConditions: true
				});
				that.productModal.loading = true;

				let brandParam = {};
				if (!product) {
					brandParam = this.joinList.length > 1 ? {
						joinCode: this.joinList[0].ParaCode
					} : {};
                    this.productModal.joinCode = this.joinList[0].ParaCode;
				} else {
					brandParam = this.joinList.length > 1 ? {
						joinCode: product.joinCode
					} : {};
				}

				axiosOri.all([
					api.brandListByUser(brandParam),
					api.parametersUnitList(tempSearch),
					api.productCategoryList(tempSearch),
					api.parametersGradeList(tempSearch)
				]).
				then(axiosOri.spread(function(brand, unit, category, grade) {
					that.productModal.loading = false;
					if (brand.code == 0 && unit.code == 0 && category.code == 0 && grade.code == 0) {
						if (!product) {
							that.brandList = that.joinList.length > 1 ? [] : brand.data.records;
						} else {
							that.brandList = brand.data.records;
						}

						that.unitList = unit.data.records;
						that.productGrades = grade.data.records;

						that.mainCateList = category.data.records.filter(c => c.ParentId == null);
						that.subCateList = category.data.records.filter(c => c.ParentId !== null);

						that.initData(product, readOnly);
					}
				}));
			},
			initData(product, readOnly) {
				if (product) {
					this.productModal.readOnly = readOnly;
					this.productModal.id = product.id;
					this.productModal.code = product.code;
					this.productModal.name = product.name;
					this.productModal.joinCode = product.joinCode;
					this.productModal.joinName = product.joinName;
					this.productModal.brand = product.brand;
					this.productModal.brandCode = product.brandCode;
					this.productModal.dvAdd = product.dvAdd;
					this.productModal.mainCategory = product.mainCategory;
					this.productModal.subCategory = product.subCategory;
					this.productModal.color = product.color;
					this.productModal.spec = product.spec;
					this.productModal.grade = product.grade;
					this.productModal.unit = product.unit;
					this.productModal.packCount = product.packCount;
					this.productModal.pinYinCode = product.pinYinCode;
					this.productModal.keyword = product.keyword;
					this.productModal.remark = product.remark;
					this.productModal.reviewText = product.reviewText;
					this.productModal.picPath = this.imageDomain + product.picPath;
					this.productModal.paraShow = product.paraShow;
					this.productModal.length = product.length;
					this.productModal.blade = product.blade;
					this.productModal.bud = product.bud;
					this.productModal.stalk = product.stalk;
					this.productModal.bending = product.bending;
					this.productModal.diseased = product.diseased;
					this.productModal.openness = product.openness;
					this.productModal.damage = product.damage;
					this.productModal.isNew = product.isNew + '';
					this.productModal.duration = product.duration;
					this.productModal.videoPath = product.videoPath;
					this.productModal.tieStandard = product.tieStandard;
					this.productModal.weight = product.weight;
					this.productModal.state = product.state;
					this.productModal.productStandardCode = product.productStandardCode;
					this.productModal.volumn = product.volumn;
					this.currProductStandardCode = product.productStandardCode;
					if (product.tags) {
						this.productModal.tags = product.tags;
						this.selectTags = this.productModal.tags.split(',');
					}

					this.echoSelect(product);
					this.checkMySelfJoin();
					this.getProductEvaluateList();
					this.productModal.mode = 'modify';
				} else {
					this.productModal.mode = 'add';
					this.productModal.id = '';
					this.productModal.code = '系统自动分配';
					this.productModal.name = '';
					this.productModal.brand = '';
					this.productModal.brandCode = '';
					this.productModal.dvAdd = '';
					this.productModal.mainCategory = '';
					this.productModal.subCategory = '';
					this.productModal.color = '';
					this.productModal.spec = '';
					this.productModal.grade = '';
					this.productModal.unit = '';
					this.productModal.packCount = '';
					this.productModal.pinYinCode = '';
					this.productModal.keyword = '';
					this.productModal.remark = '';
					this.productModal.picPath = '';
					this.productModal.paraShow = '';
					this.productModal.length = '';
					this.productModal.blade = '';
					this.productModal.bud = '';
					this.productModal.stalk = '';
					this.productModal.bending = '';
					this.productModal.diseased = '';
					this.productModal.openness = '';
					this.productModal.damage = '';
					this.productModal.isNew = '1';
					this.productModal.duration = '';
					this.productModal.videoPath = '';
					this.productModal.tieStandard = '';
					this.productModal.productStandardCode = '';
					this.productModal.weight = 0;
					this.productModal.volumn = 0;
					this.productModal.tags = '';
					this.selectTags = [];
					this.productModal.readOnly = false;
                    this.checkMySelfJoin();
				}
				this.productModal.show = true;
			},
			onMainCateChange(e) {
				let mainId = this.mainCateList.find(item => item.Name === e).Id;
				this.subCateFilterList = this.subCateList.filter(v => {
					return v.ParentId === mainId;
				});
				this.productModal.subCategory = '';
			},
			echoSelect(product) {
				let mainId = this.mainCateList.find(item => item.Name === product.mainCategory).Id;
				this.subCateFilterList = this.subCateList.filter(v => {
					return v.ParentId === mainId;
				});
			},
			onSaveProduct() {

				this.productModal.isNew = parseInt(this.productModal.isNew);
				if (this.productModal.picPath) {
					this.productModal.picPath = this.productModal.picPath.replaceAll(this.imageDomain, '');

				}
				if (this.images.length == 0) {
					this.productModal.picPath = '';
				} else if (this.images.length == 1) {
					this.productModal.picPath = this.images[0].src.replaceAll(this.imageDomain, '');
					this.productModal.productImages = [];
					this.images.forEach(item => {
						if (!item.id) {
							this.productModal.productImages.push({
								path: item.src.replaceAll(this.imageDomain, ''),
								size: item.size
							});
						}
					});
				} else {
					this.productModal.productImages = [];
					this.images.forEach(item => {
						if (!item.id) {
							this.productModal.productImages.push({
								path: item.src.replaceAll(this.imageDomain, ''),
								size: item.size
							});
						}
					});
					if (!this.productModal.picPath) {
						this.productModal.picPath = this.images[0].src.replaceAll(this.imageDomain, '');
					}
				}

                if (this.productModal.brandCode) {
                    this.productModal.brand = this.brandList.find(item => item.code === this.productModal.brandCode)
                        .name;
                }
                if (this.productModal.joinCode) {
                    this.productModal.joinName = this.joinList.find(item => item.ParaCode === this.productModal
                        .joinCode).ParaName;
                }


				if (!this.productModal.duration) {
					let now = new Date();
					now = now.setMonth(now.getMonth() + 3);
					let deadline = new Date(now);
					let year = deadline.getFullYear() + '-';
					let month = (deadline.getMonth() + 1 < 10 ? '0' + (deadline.getMonth() + 1) : deadline.getMonth() +
						1) + '-';
					let day = (deadline.getDate() < 10 ? '0' + (deadline.getDate()) : deadline.getDate());
					this.productModal.duration = year + month + day;
				} else {
					let deadline = new Date(this.productModal.duration);
					let year = deadline.getFullYear() + '-';
					let month = (deadline.getMonth() + 1 < 10 ? '0' + (deadline.getMonth() + 1) : deadline.getMonth() +
						1) + '-';
					let day = (deadline.getDate() < 10 ? '0' + (deadline.getDate()) : deadline.getDate());
					this.productModal.duration = year + month + day;
				}

				if (!this.productModal.weight) {
					this.productModal.weight = 0;
				}

				if (!this.productModal.volumn) {
					this.productModal.volumn = 0;
				}

				this.productModal.tags = this.selectTags.join(',');
				this.productModal.loading = true;
				api.productSave(this.productModal).then(res => {
					this.productModal.loading = false;
					if (res.code === 0) {
						this.productModal.show = false;
						this.$parent.getProductList();
						this.showSuccessModal();
					}
				});
			},
			getProductImages(product) {
				if (!product) {
					return;
				}

				let tempSearch = search.getSearchParams();
				tempSearch.limit.enable = false;
				tempSearch.filters.enable = true;
				tempSearch.filters.conditions = [{
					name: 'BusinessId',
					type: 'eq',
					value: product.id
				}];
				api.attachmentList(tempSearch).then(res => {
					if (res.code === 0) {
						res.data.records.forEach(item => {
							this.images.push({
								id: item.Id,
								src: this.imageDomain + item.Path,
								size: item.FileLength
							});
						});
					}
				});
			},
			getJoinList() {
				let tempSearch = search.getSearchParams({
					closeAllConditions: true
				});
				api.parametersJoinByUserList(tempSearch).then(res => {
					if (res.code === 0) {
						this.joinList = res.data.records;
						if (this.joinList.length == 1) {
							this.productModal.joinCode = this.joinList[0].ParaCode;
							this.productModal.joinName = this.joinList[0].ParaName;
						}
					}
				});
			},
			getProductTagList() {
				let tempSearch = search.getSearchParams({
					closeAllConditions: true
				});
				api.productTagList(tempSearch).then(res => {
					if (res.code === 0) {
						this.productTags = res.data.records;
					}
				});
			},
            onMainCategoryChange(e){
                let tempSearch = search.getSearchParams({
                	closeAllConditions: true
                });
                tempSearch.filters.enable = true;
                tempSearch.filters.conditions = [{
                	name: 'MainCategory',
                	type: 'eq',
                	value: e
                }];
                api.productStandardList(tempSearch).then(res => {
                	if (res.code === 0) {
                		this.standardList = res.data.records;
                	} else {
                		this.standardList = [];
                	}
                });
            },
			onStandardChange(e) {
				const selectStandard = this.standardList.find(item => item.Code === e);

				if (!selectStandard && this.productModal.mode === 'add') {
					this.productModal.name = '';
					this.productModal.dvAdd = '';
					this.productModal.mainCategory = '';
					this.productModal.subCategory = '';
					this.productModal.color = '';
					this.productModal.spec = '';
					this.productModal.grade = '';
					this.productModal.unit = '';
					this.productModal.packCount = '';
					this.productModal.pinYinCode = '';
					this.productModal.keyword = '';
					this.productModal.remark = '';
					this.productModal.picPath = '';
					this.productModal.paraShow = '';
					this.productModal.length = '';
					this.productModal.blade = '';
					this.productModal.bud = '';
					this.productModal.stalk = '';
					this.productModal.bending = '';
					this.productModal.diseased = '';
					this.productModal.openness = '';
					this.productModal.damage = '';
					this.productModal.isNew = '1';
					this.productModal.duration = '';
					this.productModal.videoPath = '';
					this.productModal.tieStandard = '';
					this.productModal.productStandardCode = '';
					this.productModal.weight = 0;
					this.productModal.volumn = 0;
					this.images = [];
					this.productModal.tags = '';
					this.selectTags = [];
					return;
				}

				if (e === undefined && this.standardList.length === 0 && this.productModal.mode === 'modify') {
					this.productModal.productStandardCode = this.currProductStandardCode;
					return;
				}

				this.productModal.name = selectStandard.Name;
				this.productModal.mainCategory = selectStandard.MainCategory;
				this.productModal.subCategory = selectStandard.SubCategory;
				this.productModal.color = selectStandard.Color;
				this.productModal.spec = selectStandard.Spec;
				this.productModal.grade = selectStandard.Grade;
				this.productModal.unit = selectStandard.Unit;
				this.productModal.packCount = selectStandard.PackCount;
				this.productModal.pinYinCode = selectStandard.PinYinCode;
				this.productModal.keyword = selectStandard.Keyword;
				this.productModal.remark = selectStandard.Remark;
				this.productModal.paraShow = selectStandard.ParaShow;
				this.productModal.length = selectStandard.Length;
				this.productModal.blade = selectStandard.Blade;
				this.productModal.bud = selectStandard.Bud;
				this.productModal.stalk = selectStandard.Stalk;
				this.productModal.bending = selectStandard.Bending;
				this.productModal.diseased = selectStandard.Diseased;
				this.productModal.openness = selectStandard.Openness;
				this.productModal.damage = selectStandard.Damage;
				this.productModal.tieStandard = selectStandard.TieStandard;
				this.productModal.weight = selectStandard.Weight || 0;
				this.productModal.volumn = selectStandard.Volumn || 0;
				this.productModal.isNew = selectStandard.IsNew + '';
				this.productModal.videoPath = '';
				if (selectStandard.PicPath) {
					this.productModal.picPath = selectStandard.PicPath;
					this.images = [];
					this.images.push({
						id: '',
						src: this.imageDomain + selectStandard.PicPath,
						size: 0
					});
				}

			},
			remoteSearchStandard(keyword) {
				if (keyword !== '') {
					this.remoteSearchLoading = true;
					let tempSearch = search.getSearchParams({
						closeAllConditions: true
					});
					tempSearch.filters.enable = true;
					tempSearch.filters.conditions = [{
						name: 'concat(Code,Name)',
						type: 'like',
						value: keyword
					}];
                    if(this.productModal.mainCategory){
                        tempSearch.filters.conditions.push({
                            name: 'MainCategory',
                            type: 'eq',
                            value: this.productModal.mainCategory
                        })
                    }
					api.productStandardList(tempSearch).then(res => {
						this.remoteSearchLoading = false;
						if (res.code === 0) {
							this.standardList = res.data.records;
						} else {
							this.standardList = [];
						}
					});
				} else {
					this.standardList = [];
				}
			},
			onJoinChange(joinCode) {
                this.productModal.joinCode = joinCode;
				api.brandListByUser({
					joinCode: joinCode
				}).then(res => {
					this.remoteSearchLoading = false;
					if (res.code === 0) {
						this.brandList = res.data.records;
					}
				});
			},
			checkMySelfJoin() {
                if(this.productModal.joinCode){
                    let isCurrentJoinCode = this.getUserOrgCode() == this.productModal.joinCode;
                    let isCurrentDepartment = this.getUserDepartmentName() == this.productModal.joinName;
                    this.isMySelfJoin = isCurrentJoinCode || isCurrentDepartment;
                }else{
                    this.isMySelfJoin = false;
                }
			},
			getProductEvaluateList() {
				this.isDeleteEvaluate = this.checkAccessArray('ProductEvaluateDel');
				this.evaluates = [];
				api.orderEvaluateListByProduct({
					productCode: this.productModal.code
				}).then(res => {
					if (res.code === 0) {
						this.evaluates = res.data.records;
					}
				});
			},
			onDeleteProductEvaluate(id) {
				this.$Modal.confirm({
					title: '确认',
					content: '真的要删除这条评价吗？',
					onOk: () => {
						api.orderEvaluateDeleteById({
							id: id
						}).then(res => {
							if (res.code === 0) {
								this.getProductEvaluateList();
							}
						});
					}
				});

			}


		}
	};
</script>
<style>
	.icon-delete {
		font-size: 30px;
		position: absolute;
		top: 2px;
		right: 2px;
		background-color: rgba(255, 255, 255, 0.6);
		border-radius: 50%;
	}

	.img-card {
		position: relative;
		width: 150px;
		height: 150px;
		margin-top: 10px;
		overflow: hidden;
	}

	.primary-img {
		color: #fff;
		height: 30px;
		width: 100px;
		position: absolute;
		top: 5px;
		left: -30px;
		text-align: center;
		line-height: 30px;
		transform: rotate(-45deg);
		background-color: #0c60ee;
	}
</style>
