<template>
	<div class="data-contianer">
		<div class="data-list">
			<div class="data-item" v-for="(item ,index) in list" :key="index" @click="routerTo(index)">
				<p>{{item.title}}</p>
				<div class="line"></div>
				<div class="data-main">
					<img :src="item.img" alt="">
					<div class="data-main-body">
						<p class="tips">{{item.tips}}</p>
						<Tooltip placement="top" :content="item.count.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')">
							<p class="count">{{item.count.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}}</p>
						</Tooltip>
					</div>
				</div>
				<div class="right">
					<Icon type="ios-arrow-forward" size="24" color="#fff" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			list:{
				type:Array,
				default :[]
			}
		},
		data() {
			return {}
		},
		methods:{
			routerTo(i){
				switch(i){
					case 0:
						this.$router.push('/basic/member-list');
						break;
					case 1:
						this.$router.push('/sys/action-list');
						break;
					default:
						return {}	
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.data-list{
		display: flex;
		width: 100%;
		.data-item{
			cursor: pointer;
			margin-right:20px;
			width: 50%;
			height: 210px;
			box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);
			border-radius: 10px;
			padding: 15px 20px 20px 20px;
			
			p {
				font-size: 22px;
				font-weight: bold;
				color: #FFFFFF;
			}
			
			.line{
				margin-top: 20px;
				margin-bottom: 24px;
				width: 96%;
				height: 1px;
				border: 1px solid #FFFFFF;
				opacity: 0.5;
			}
		}
		.data-item:nth-child(1){
			background: linear-gradient(to right, #57ABF8, #b9d8f8);
		}
		.data-item:nth-child(2){
			background: linear-gradient(to right, #FE785D, #ffd7cf);
		}
		.data-item:last-child{
			margin-right:0px;
		}
		.data-main{
			margin-left: 14px;
			display: flex;
			align-items: center;
			img{
				width: 70px;
				height: 70px;
				margin-right:32px ;
			}
			.data-main-body{
				width: 100%;
				height: 108px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				
				.tips{
					font-size: 20px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
				}
				.count{
					width: 166px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 30px;
					color: #FFFFFF;
				}
				
			}
			
		}
		
		.right{
			display: flex;
			justify-content: flex-end;
			margin-top: -42px;
		}
	}
	
</style>
