export default {
    /* 合法uri */
    validateURL(textval) {
        const urlregex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!$&'\*\+,;=.]+$/;
        return urlregex.test(textval);
    },
    /* 小写字母 */
    validateLowerCase(str) {
        const reg = /^[a-z]+$/;
        return reg.test(str);
    },
    /* 大写字母 */
    validateUpperCase(str) {
        const reg = /^[A-Z]+$/;
        return reg.test(str);
    },
    /* 大小写字母 */
    validateAlphabets(str) {
        const reg = /^[A-Za-z]+$/;
        return reg.test(str);
    },
    /* 市场售价 */
    validatePrice(str) {
        const reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
        return reg.test(str);
    },
    /* 库存预警值 匹配非负整数（正整数 + 0） */
    validatestockWarn(str) {
        const reg = /^(0|[1-9][0-9]*)$/;
        return reg.test(str);
    },
    /* 比价网站 只验证京东和苏宁网站 */
    validateCompareWebsite(str) {
        const reg = /^((https\:\/\/[0-9a-zA-Z\_]+\.|http\:\/\/[0-9a-zA-Z\_]+\.|https\:\/\/|http\:\/\/)|([0-9a-zA-Z\_]+\.){0,1})(jd|suning)\.(com$|com\/[\S]*)/i;
        return reg.test(str);
    },
    /* 固定电话 */
    validateTelephone(str) {
        const reg = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
        return reg.test(str);
    },
    /* 手机号码 */
    validatePhoneNumber(str) {
        const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        return reg.test(str);
    },
    /* 手机号码和固定电话 */
    validatePhTelNumber(str) {
        const reg = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
        return reg.test(str);
    },
    /* 电子邮箱 */
    validateEmail(str) {
        const reg = /^[A-Za-z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        return reg.test(str);
    },
    /* 邮编 */
    validateZipCode(str) {
        const reg = /^[1-9][0-9]{5}$/;
        return reg.test(str);
    },
    /* 身份证 */
    validateIDCard(str) {
        const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        return reg.test(str);
    },
    /* 银行卡号 15位或者16位或者19位 */
    validateBank(str) {
        const reg = /^([1-9]{1})(\d{14}|\d{18}|\d{15})$/;
        return reg.test(str);
    },
    /* 纳税人识别码 */
    validateTaxpayer(str) {
        const reg = /^([1-9]{1})(\d{14}|\d{18}|\d{15})$/;
        return reg.test(str);
    },
    /* 匹配全空格 */
    validateAllBlank(str) {
        const reg = /^\s+$/gi;
        return reg.test(str);
    },
	 /* 税号 */
	 validatetaxNum(str) {
	     const reg = /^[a-zA-Z0-9]{15}$|^[a-zA-Z0-9]{17}$|^[a-zA-Z0-9]{18}$|^[a-zA-Z0-9]{20}$/;
	     return reg.test(str);
	 },
	 /* 银行卡号 12位到23位 */
	 validateBankAccount(str) {
	     const reg = /^([1-9]{1})(\d{11}|\d{12}|\d{13}|\d{14}|\d{15}|\d{16}|\d{17}|\d{18}|\d{19}|\d{20}|\d{21})$/
	     return reg.test(str);
	 },
};
