<template>
    <div style="display:flex;">
        <Tooltip v-if="showInfo"
            transfer
            :content="infoText"
            style="margin-left:6px;">
            <Button type="info"
                :icon="infoIcon"
                size="small"
                ghost
                @click="onClickInfo"></Button>
        </Tooltip>
        <Tooltip v-if="showEdit"
            transfer
            :content="editText"
            style="margin-left:6px;">
            <Button type="primary"
                :icon="editIcon"
                size="small"
                ghost
                @click="onClickEdit"></Button>
        </Tooltip>
        <Tooltip v-if="showActive"
            transfer
            :content="statusField === 1? activeText[0]:activeText[1]"
            style="margin-left:6px;">
            <Button type="info"
                :icon="statusField === 1 ? 'md-eye-off' : 'md-eye'"
                size="small"
                ghost
                @click="onClickActive"></Button>
        </Tooltip>
        <Tooltip v-if="showDelete"
            transfer
            :content="deleteText"
            style="margin-left:6px;">
            <Button type="error"
                icon="md-close"
                size="small"
                ghost
                @click="onClickDelete"></Button>
        </Tooltip>
        <Tooltip v-if="showAudit"
            transfer
            :content="auditText"
            style="margin-left:6px;">
            <Button type="warning"
                icon="md-checkmark"
                size="small"
                ghost
                @click="onClickAudit"></Button>
        </Tooltip>
        <Tooltip v-if="showCustom"
            transfer
            :content="customText"
            style="margin-left:6px;">
            <Button type="success"
                :icon="customIcon"
                size="small"
                ghost
                @click="onClickCustom"></Button>
        </Tooltip>
		<Tooltip v-if="showCustom1"
		    transfer
		    :content="customText1"
		    style="margin-left:6px;">
		    <Button type="success"
		        :icon="customIcon1"
		        size="small"
		        ghost
		        @click="onClickCustom1"></Button>
		</Tooltip>
        <Tooltip v-if="showCustom2"
            transfer
            :content="customText2"
            style="margin-left:6px;">
            <Button type="success"
                :icon="customIcon2"
                size="small"
                :style="{backgroundColor: customBackgroundColor2}"
                ghost
                @click="onClickCustom2"></Button>
        </Tooltip>
        <Tooltip v-if="showCustom3"
            transfer
            :content="customText3"
            style="margin-left:6px;">
            <Button type="success"
                :icon="customIcon3"
                size="small"
                ghost
                @click="onClickCustom3"></Button>
        </Tooltip>

        <Modal v-model="auditModal.show"
            title="审批"
            :mask-closable="false">
            <Form ref="auditModalForm"
                :label-width="80">
                <Row>
                    <Col span="24">
                    <FormItem label="审批结果:"
                        required>
                        <RadioGroup v-model="auditModal.auditStatus">
                            <Radio label="通过">
                                <span>通过审批</span>
                            </Radio>
                            <Radio label="驳回">
                                <span>驳回审批</span>
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    </Col>
                    <Col span="24">
                    <FormItem label="审批意见:">
                        <Input v-model="auditModal.auditRemark"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row v-if="needAuditVerify">
                    <Col span="24">
                    <FormItem label="验证方式:">
                        <RadioGroup v-model="auditModal.verifyType">
                            <Radio label="PW">
                                <span>审批密码验证</span>
                            </Radio>
                            <Radio label="SMS">
                                <span>短信验证</span>
                            </Radio>
                        </RadioGroup>
                        <Button v-if="auditModal.verifyType === 'SMS'"
                            type="info"
                            size="small"
                            :loading="btnLoading"
                            @click="onSendAuditSms">发送验证码</Button>
                    </FormItem>
                    </Col>
                    <Col span="24">
                    <FormItem label="验证信息:">
                        <Input v-model="auditModal.verifyValue"
                            placeholder="输入审批密码或短信验证码"></Input>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onHandleAudit">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
export default {
    name: 'BaseTableFunction',
    props: {
        dataId: { type: String, required: true },
        statusField: { type: String, default: '' },
        showInfo: { type: Boolean, default: false },
        infoText: { Type: String, default: '查看' },
        infoIcon: { Type: String, default: 'md-information' },
        showEdit: { type: Boolean, default: false },
        editText: { Type: String, default: '编辑' },
        editIcon: { Type: String, default: 'md-create' },
        showActive: { type: Boolean, default: false },
        activeText: { type: Array, default: () => ['冻结', '激活'] },
        showDelete: { type: Boolean, default: false },
        deleteText: { Type: String, default: '删除' },
        showAudit: { type: Boolean, default: false },
        auditText: { Type: String, default: '审批' },
        showCustom: { type: Boolean, default: false },
        customText: { Type: String, default: '自定义' },
        customIcon: { Type: String, default: 'md-color-wand' },
        showCustom1: { type: Boolean, default: false },
        customText1: { Type: String, default: '自定义1' },
        customIcon1: { Type: String, default: 'md-color-wand' },
        showCustom2: { type: Boolean, default: false },
        customText2: { Type: String, default: '自定义2' },
        customIcon2: { Type: String, default: 'md-flame' },
        customBackgroundColor2: { Type: String, default: '#ffffff' },
        showCustom3: { type: Boolean, default: false },
        customText3: { Type: String, default: '自定义3' },
        customIcon3: { Type: String, default: 'md-flash' },
        needAuditVerify: { type: Boolean, default: false }
    },
    data() {
        return {
            auditModal: {
                show: false,
                dataId: '',
                auditStatus: '通过',
                auditRemark: '',
                verifyType: 'PW',
                verifyValue: ''
            },
            btnLoading: false
        };
    },
    methods: {
        onClickInfo(e) {
            this.$emit('handleInfo', this.dataId);
            e.stopPropagation();
        },
        onClickEdit(e) {
            this.$emit('handleEdit', this.dataId);
            e.stopPropagation();
        },
        onClickActive(e) {
            this.$emit('handleActive', this.dataId);
            e.stopPropagation();
        },
        onClickDelete(e) {
            this.$emit('handleDelete', this.dataId);
            e.stopPropagation();
        },
        onClickAudit(e) {
            this.auditModal.show = true;
            e.stopPropagation();
        },
        onHandleAudit() {
            this.auditModal.show = false;
            this.$emit('handleAudit', this.dataId, this.auditModal.auditStatus, this.auditModal.auditRemark, this.auditModal.verifyType, this.auditModal.verifyValue);
        },
        onClickCustom(e) {
            this.$emit('handleCustom', this.dataId);
            e.stopPropagation();
        },
        onClickCustom1(e) {
		    this.$emit('handleCustom1', this.dataId);
		    e.stopPropagation();
        },
        onClickCustom2(e) {
            this.$emit('handleCustom2', this.dataId);
            e.stopPropagation();
        },
        onClickCustom3(e) {
            this.$emit('handleCustom3', this.dataId);
            e.stopPropagation();
        },
        onSendAuditSms() {
            this.btnLoading = true;
            api.sendAuditCode({}).then(res => {
                this.btnLoading = false;
                if (res.code === 0) {
                    this.showSuccessModal("验证码已发送");
                }
            });
        }
    }
};
</script>

<style scoped>
</style>
