import { render, staticRenderFns } from "./reset-user-password-modal2.vue.js?vue&type=template&id=4ee1f2df!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./reset-user-password-modal2.vue?vue&type=template&id=4ee1f2df"
import script from "./reset-user-password-modal2.vue.js?vue&type=script&lang=js!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./reset-user-password-modal2.vue?vue&type=script&lang=js"
export * from "./reset-user-password-modal2.vue.js?vue&type=script&lang=js!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./reset-user-password-modal2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports