import cityData from "@/libs/city.data";

export const isNumeric = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

const isDateTimeEmpty = function (timeString) {
    return !timeString;
};

export const formatTimeString = function (timeString) {
    if (isDateTimeEmpty(timeString)) {
        return '';
    }

    // "2019-01-18T18:30:15.236"
    let parts = timeString.split('T');
    if (parts.length !== 2) {
        return '-';
    }

    let hms = parts[1];
    if (hms.split('.').length === 2) {
        hms = hms.split('.')[0];
    }

    return `${hms}`;
};

export const formatDateTimeString = function (timeString) {
    if (isDateTimeEmpty(timeString)) {
        return '';
    }

    // "2019-01-18T18:30:15.236"
    let parts = timeString.split('T');
    if (parts.length !== 2) {
        return '-';
    }

    let ymd = parts[0];
    let hms = parts[1];
    if (hms.split('.').length === 2) {
        hms = hms.split('.')[0];
    }

    return `${ymd} ${hms}`;

    // if (isDateTimeEmpty(timeString)) {
    //     return '';
    // }

    // let dateObj = new Date(timeString);

    // let month = '' + (dateObj.getMonth() + 1);
    // let day = '' + dateObj.getDate();
    // let year = '' + dateObj.getFullYear();

    // let hours = '' + dateObj.getHours();
    // let minutes = '' + dateObj.getMinutes();
    // let seconds = '' + dateObj.getSeconds();

    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;
    // if (hours.length < 2) hours = '0' + hours;
    // if (minutes.length < 2) minutes = '0' + minutes;
    // if (seconds.length < 2) seconds = '0' + seconds;

    // return [year, month, day].join('-') + ' ' + [hours, minutes, seconds].join(':');
};

export const formatDateString = function (timeString) {
    if (isDateTimeEmpty(timeString)) {
        return '';
    }

    // "2019-01-18T18:30:15.236"
    let parts = timeString.split('T');
    if (parts.length !== 2) {
        return '-';
    }

    let ymd = parts[0];
    let hms = parts[1];
    if (hms.split('.').length === 2) {
        hms = hms.split('.')[0];
    }

    return `${ymd}`;

    // let dateObj = new Date(timeString);

    // let month = '' + (dateObj.getMonth() + 1);
    // let day = '' + dateObj.getDate();
    // let year = '' + dateObj.getFullYear();

    // if (month.length < 2) month = '0' + month;
    // if (day.length < 2) day = '0' + day;

    // return [year, month, day].join('-');
};

export const getTodayDateString = function () {
    let now = new Date();
    let month = '' + (now.getMonth() + 1);
    let day = '' + now.getDate();
    let year = '' + now.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
};

export const getNextYearTodayDateString = function () {
    let now = new Date();
    let month = '' + (now.getMonth() + 1);
    let day = '' + now.getDate();
    let year = now.getFullYear() + 1;
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
};

export const getThisMonthStart = function () {
    let now = new Date();
    let month = '' + (now.getMonth() + 1);
    let day = '01';
    let year = '' + now.getFullYear();
    if (month.length < 2) month = '0' + month;
    return [year, month, day].join('-');
};

export const getThisMonthEnd = function () {
    let now = new Date();
    let monthStart = `${now.getFullYear()}-${('0' + (now.getMonth() + 1)).substring(1, 2)}-01`;

    let temp = new Date(monthStart);
    temp = temp.setMonth(temp.getMonth() + 1); //加一个月
    temp = new Date(temp);
    temp = temp.setDate(temp.getDate() - 1); //减一天
    temp = new Date(temp);
    let month = '' + (temp.getMonth() + 1);
    let day = '' + temp.getDate();
    let year = '' + temp.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
};

export const cutTooLengthString = function (str) {
    return str.length > 15 ? str.substring(0, 15) + '...' : str;
};

export const uuid = function () {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export const repeater = function (str, n) {
    return Array(n).fill(str).join('');
};

/**
 * 数字转千分位字符串
 * @param {*} num 数字
 * @param {*} fractional 小数位数
 * @param {*} currencyPrefix 货币前缀
 */
export const number2ThousandsFormat = function (num, fractional, currencyPrefix) {
    //判断是否有小数点
    if (!num && num !== 0) {
        return '';
    }
    let s = num.toString().indexOf(".");
    if (s === -1) { //是整数
        return currencyPrefix + (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + "." + repeater('0', fractional);
    }
    else { //是小数
        let arr = num.toString().split(".");
        if (arr.length > 1 && arr[1].length < fractional) {
            return currencyPrefix + (arr[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + "." + arr[1] + repeater('0', fractional - arr[1].length);
        }
        else {
            return currencyPrefix + (arr[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + "." + arr[1].substr(0, fractional);
        }
    }
};

/**
 * 数字保留固定位数小数（采用 toFixed 方法）
 * @param {*} number
 * @param {*} fractional
 */
export const numberToFixed = function (number, fractional) {
    return parseFloat(parseFloat(number).toFixed(fractional));
};

/**
 * 千分位字符串转数字
 * @param {*} str 字符串
 * @param {*} currencyPrefix 货币前缀
 */
export const thousandsFormat2Number = function (str, currencyPrefix) {
    str += '';
    str = str.replace(/[,]/g, '').replace(currencyPrefix, '');
    if (isNumeric(str)) {
        return parseFloat(str);
    }
    return NaN;
};

/**
 * 数字转百分比格式
 * @param {*} num 值域从[0-100]的数字
 */
export const number2PercentFormat = function (num) {
    return num + '%';
};

/**
 * 百分比格式转数字，返回[0-100]的数字
 * @param {*} str
 */
export const percentFormat2Number = function (str) {
    str = str.replace('%', '');
    if (isNumeric(str)) {
        return parseFloat(str);
    }
    return NaN;
};

/**
 * 数组去重
 * @param {*} array
 */
export const arrayUnique = function (array) {
    let res = [];
    for (let i = 0, len = array.length; i < len; i++) {
        let current = array[i];
        if (res.indexOf(current) === -1) {
            res.push(current);
        }
    }
    return res;
};

export const objectArrayUnique = function (array, key) {
    let arr = array;
    let result = [];
    arr.forEach(function (v, i, arr) {
        let bool = result.findIndex(e => {
            return e[key] === v[key];
        });
        if (bool === -1) {
            result.push(v);
        }
    });
    return result;
};

export const formatDateRange = function (dateA) {
    let a = dateA[0];
    if (a.toString().indexOf('T') === -1) return dateA; //已格式化
    let aJson = new Date(a).toJSON();
    let aNewDate = new Date(+new Date(aJson) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');

    let b = dateA[1];
    let bJson = new Date(b).toJSON();
    let bNewDate = new Date(+new Date(bJson) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');

    dateA[0] = aNewDate;
    dateA[1] = bNewDate;

    return dateA;
};

export const initRegionValues = function (province, city, dist) {
    let regionValues = [];
    let p = cityData.find(e => {
        return e.label === province;
    });
    if (p) {
        regionValues.push(p.value);
        let c = p.children.find(e => {
            return e.label === city;
        });
        if (c) {
            regionValues.push(c.value);
            let d = c.children.find(e => {
                return e.label === dist;
            });
            if (d) {
                regionValues.push(d.value);
            }
        }
    }
    return regionValues;
};

/**
 * @description: Array转树形结构（会改变原数组数据）
 * @param {父ID} pid
 * @param {源数据} arr
 * @return {树形数组}
 */
export const recursionGenerateTree = function (pid, arr) {
    let array = [];
    arr.forEach(item => {
        if (item.parentId === pid) {
            item.children = recursionGenerateTree(item.id, arr); // 接收子节点
            array.push(item);
        };
    });
    return array; // 返回查找到的节点
};

//乘法函数，用来得到精确的乘法结果
//说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
//numberMul(arg1,arg2)
//返回值：arg1乘以arg2的精确结果
export const numberMul = function (arg1, arg2, precision = 2) {
    if (!arg1 || !arg2) return 0;
    let m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    }
    catch (e) { }
    try {
        m += s2.split(".")[1].length;
    }
    catch (e) { }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m).toFixed(precision);
};

//加函数，用来得到精确的加法结果
//numberAdd(arg1,arg2)
export const numberAdd = function (num1, num2, precision = 2) {
    num1 == undefined ? num1 = 0 : '';
    num2 == undefined ? num2 = 0 : '';
    let sq1, sq2, m;
    try {
        sq1 = num1.toString().split(".")[1].length;
    }
    catch (e) {
        sq1 = 0;
    }
    try {
        sq2 = num2.toString().split(".")[1].length;
    }
    catch (e) {
        sq2 = 0;
    }
    m = Math.pow(10, Math.max(sq1, sq2));
    return ((numberMul(num1, m) + numberMul(num2, m)) / m).toFixed(precision);
};
