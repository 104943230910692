import { render, staticRenderFns } from "./AppAsideMenu.vue.js?vue&type=template&id=8456d786!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./AppAsideMenu.vue?vue&type=template&id=8456d786"
import script from "./AppAsideMenu.vue.js?vue&type=script&lang=js!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./AppAsideMenu.vue?vue&type=script&lang=js"
export * from "./AppAsideMenu.vue.js?vue&type=script&lang=js!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./AppAsideMenu.vue?vue&type=script&lang=js"
import style0 from "./AppAsideMenu.vue.less?vue&type=style&index=0&id=8456d786&prod&lang=less!=!../../../node_modules/vue-loader/lib/index.js??vue-loader-options!./AppAsideMenu.vue?vue&type=style&index=0&id=8456d786&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports