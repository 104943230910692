<template>
    <div style="padding:10px;">
        <div class="tips">
        	<h6>{{message}}{{title}}</h6>
			<span>{{today}}</span>
        </div>
		<NavItem :list="shortcutList"/>
		<div class="data-show" v-if="nickName === '系统管理员'">
			<DataCountItem style="width: 54%;" :list='memberList'/>
			<SaleCountItem style="width: 46%;" :list="orderList"/>
		</div>
		<div class="chart" v-if="nickName === '系统管理员'">
			<div class="chart-contianer">
				<div class="search">
					<p class="title">销售额统计</p>
					<div>
						<RadioGroup v-model="groupBy"
						    type="button" size="small"
						    @on-change="onGroupByChange">
						    <Radio :label="0"> <span>本月</span> </Radio>
						    <Radio :label="1"> <span>上月</span> </Radio>
							<Radio :label="2"> <span>近半年</span> </Radio>
						    <Radio :label="3"> <span>近一年</span> </Radio>
						</RadioGroup>
					</div>
				</div>
				
				<!-- line-chart -->
				<div id="lineChart" class="line-chart" style="width: 100%;height: 250px;" ></div>
			</div>
		</div>
		
		
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import NavItem from './components/nav-item.vue'
import DataCountItem from './components/data-count-item.vue'
import SaleCountItem from './components/sale-count-item.vue'
import * as echarts from 'echarts';
import User from '@/assets/images/user.png'
import Access from '@/assets/images/access.png'
import Sale from '@/assets/images/sale.png'
import Order from '@/assets/images/order.png'	
export default {
    name: 'Home',
    components: {
		NavItem,
		DataCountItem,
		SaleCountItem
    },
    data() {
        return {
            title:'，欢迎登陆缤纷鲜花后台系统！',
			memberList:[
				{
					title:'用户统计',
					img:User,
					tips:'总用户数',
					count:0
				},
				{
					title:'访问统计',
					img:Access,
					tips:'今日访问数',
					count:0
				}
			],
			orderList:[
				{
					title:'今日销售额',
					count:0,
					img:Sale
				},
				{
					title:'今日订单数',
					count:0,
					img:Order
				}
			],
			currentMonthStat:{
				dateList : [],
				orderAmountList:[]
			},
			lastMonthStat:{
				dateList : [],
				orderAmountList:[]
			},
			halfYearSaleStat:{
				dateList : [],
				orderAmountList:[]
			},
			yearSaleStat:{
				dateList : [],
				orderAmountList:[]
			},
			groupBy: 0 ,
			shortcutList:[],
			nickName:''
        };
    },
	computed: {
	    today() {
	        let date = new Date()
	        let year = date.getFullYear()
	        let month = date.getMonth() + 1
	        month = month < 10 ? '0' + month : month
	        let day = date.getDate();
	        day = day < 10 ? '0' + day : day;
	        return `${year}年${month}月${day}日`;
	    },
		message(){
			let date = new Date()
			let hours = date.getHours()
			if (hours >= 6 && hours <= 10) return `早上好`;
			if (hours >= 10 && hours <= 14) return `中午好`;
			if (hours >= 14 && hours <= 18) return `下午好`;
			if (hours >= 18 && hours <= 24) return `晚上好`;
			if (hours >= 0 && hours <= 6) return `凌晨好`;
		}
	},
    async mounted() {
		await this.userNickName()
		await this.getHomePageStat()
		if(this.nickName === '系统管理员'){
			await this.getMonthChart()
		}
		await this.getShortcut()
    },
    methods: {
		onGroupByChange(){
			switch(this.groupBy){
				case 0:
					this.getMonthChart()
					break;
				case 1:
					this.getLastMonthChart()
					break;
				case 2:
					this.getHalfYearChart()
					break;
				case 3:
					this.getAYearChart()
					break;
				default:
					return {}
			}
		},
		async getHomePageStat(){
			const {data:data} = await api.getHomePageStat()
			this.memberList[0].count = data.memberCount
			this.memberList[1].count = data.memberVisitCount
			this.orderList[0].count = data.orderAmount
			this.orderList[1].count = data.orderCount
			
			let currentMonthStat = []
			let lastMonthStat = []
			let halfYearSaleStat = []
			let yearSaleStat = []
			currentMonthStat = data.currentMonthStat
			lastMonthStat = data.lastMonthStat
			halfYearSaleStat = data.halfYearSaleStat
			yearSaleStat = data.yearSaleStat
			
			currentMonthStat.forEach(item =>{
				this.currentMonthStat.dateList.push(item.date)
				this.currentMonthStat.orderAmountList.push(item.orderAmount)
			})
			
			lastMonthStat.forEach(item =>{
				this.lastMonthStat.dateList.push(item.date)
				this.lastMonthStat.orderAmountList.push(item.orderAmount)
			})
			
			halfYearSaleStat.forEach(item =>{
				this.halfYearSaleStat.dateList.push(item.month)
				this.halfYearSaleStat.orderAmountList.push(item.orderAmount)
			})
			
			yearSaleStat.forEach(item =>{
				this.yearSaleStat.dateList.push(item.month)
				this.yearSaleStat.orderAmountList.push(item.orderAmount)
			})
		},
		async getShortcut(){
			const {data:data} = await api.getShortcut()
			this.shortcutList = data.shortcutList
		},
		userNickName(){
			this.nickName = this.getUserNickName()
		},
		getMonthChart(){
			var chartDom = document.getElementById("lineChart");
			chartDom.setAttribute('_echarts_instance_', '');
			var myChart = echarts.init(chartDom);
			var option;
			option = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
					  type: 'cross',
					  label: {
						backgroundColor: '#6a7985'
					  }
					}
				},
			  xAxis: {
				type: 'category',
				data: this.currentMonthStat.dateList
			  },
			  yAxis: {
				type: 'value'
			  },
			  series: [
				{
				  name: '销售额',
				  smooth: true,
				  type: 'line',
				  data: this.currentMonthStat.orderAmountList
				}
			  ]
			};
			
			option && myChart.setOption(option);
		},
		getLastMonthChart(){
			var chartDom = document.getElementById("lineChart");
			chartDom.setAttribute('_echarts_instance_', '');
			var myChart = echarts.init(chartDom);
			var option;
			option = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
					  type: 'cross',
					  label: {
						backgroundColor: '#6a7985'
					  }
					}
				},
			  xAxis: {
				type: 'category',
				data: this.lastMonthStat.dateList
			  },
			  yAxis: {
				type: 'value'
			  },
			  series: [
				{
				  name: '销售额',
				  smooth: true,
				  type: 'line',
				  data: this.lastMonthStat.orderAmountList
				}
			  ]
			};
			
			option && myChart.setOption(option);
		},
		getHalfYearChart(){
			var chartDom = document.getElementById("lineChart");
			chartDom.setAttribute('_echarts_instance_', '');
			var myChart = echarts.init(chartDom);
			var option;
			option = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
					  type: 'cross',
					  label: {
						backgroundColor: '#6a7985'
					  }
					}
				},
			  xAxis: {
				type: 'category',
				data: this.halfYearSaleStat.dateList
			  },
			  yAxis: {
				type: 'value'
			  },
			  series: [
				{
				  name: '销售额',
				  smooth: true,
				  type: 'line',
				  data: this.halfYearSaleStat.orderAmountList
				}
			  ]
			};
			
			option && myChart.setOption(option);
		},
		getAYearChart(){
			var chartDom = document.getElementById("lineChart");
			chartDom.setAttribute('_echarts_instance_', '');
			var myChart = echarts.init(chartDom);
			var option;
			option = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
					  type: 'cross',
					  label: {
						backgroundColor: '#6a7985'
					  }
					}
				},
			  xAxis: {
				type: 'category',
				data: this.yearSaleStat.dateList
			  },
			  yAxis: {
				type: 'value'
			  },
			  series: [
				{
				  name: '销售额',
				  smooth: true,
				  type: 'line',
				  data:this.yearSaleStat.orderAmountList
				}
			  ]
			};
			
			option && myChart.setOption(option);
		}
    }
};
</script>

<style scoped lang="less">
	.tips{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		cursor: pointer;
	}
	.tips h6{
		font-size: 30px;
		color: #2B5AC5;
	}
	.tips span{
		font-size: 19px;
		color: #2B5AC5;
	}
	.data-show{
		width: 100%;
		margin-top: 20px;
		display: flex;
	}
	.chart{
		margin-top: 56px;
	}
	.chart-contianer{
		width: 100%;
		height: 352px;
		background: #FFFFFF;
		box-shadow: 0px 5px 30px 0px rgba(22,115,255,0.1);
		
		.search{
			padding: 29px 41px 0 31px;
			display: flex;
			justify-content: space-between;
			.title{
				font-size: 28px;
				color: #232D50;
			}
		}
	}
</style>
