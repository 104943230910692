<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getProductColorList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4" />
            <Button v-if="checkAccessArray('ProductColorEdit')"
                size="small"
                @click="onAddProductColor">新增产品颜色</Button>
        </div>
        <Row>
            <Col span="24">
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getProductColorList"
                :highlight="true"
                @on-row-click="onProductColorSelect" />
            </Col>
        </Row>
        <Modal v-model="productColorModal.show"
            title="产品颜色信息"
            :mask-closable="false">
            <Spin v-if="productColorModal.loading"
                size="large"
                fix></Spin>
            <Form ref="productColorModalForm"
                :label-width="100">
                <Row>
                    <Col span="24">
                    <FormItem label="颜色分类:"required>
                        <RadioGroup v-model="productColorModal.colorType">
                            <Radio label="单色">
                                <span>单色</span>
                            </Radio>
                            <Radio label="混色">
                                <span>混色</span>
                            </Radio>
                            <Radio label="复色">
                                <span>复色</span>
                            </Radio>
                            <Radio label="特别色">
                                <span>特别色</span>
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    </Col>
                    <Col span="24">
                    <FormItem label="颜色名称:"
                        required>
                        <Input v-model="productColorModal.color"></Input>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="产品颜色:">
                        <Input v-model="productColorModal.productColor"></Input>
                    </FormItem>
                    </Col>
                    <Col span="24">
                    <FormItem label="序号:">
                        <Input v-model="productColorModal.sort"></Input>
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveProductColor">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
export default {
    name: 'ProductColorList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            productColorModal: {
                show: false,
                loading: false,
                mode: 'add',
                colorType: '',
                color: '',
                productColor: '',
                sort: 1
            },
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '序号', key: "Sort", align: 'center' },
                    { title: '颜色类型', key: "ColorType", align: 'center' },
                    { title: '颜色名称', key: "Color", align: 'center' },
                    { title: '产品颜色', key: "ProductColor", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 80,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'unitStatus',
                                        showEdit: this.checkAccessArray('ProductColorEdit'),
                                        showDelete: this.checkAccessArray('ProductColorEdit')
                                    },
                                    on: {
                                        handleEdit: dataId => {
                                            this.onModifyProductColor(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteProductColor(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '颜色名称', type: 'Input', value: '', name: 'Color', sql: 'like' }
                ]
            },
            selectedProductColor: null
        };
    },
    computed: {

    },
    mounted() {
        this.getProductColorList();
    },
    methods: {
        onProductColorSelect(row, index) {
            this.selectedProductColor = row;
        },
        onAddProductColor() {
            this.productColorModal.mode = 'add';
            this.productColorModal.colorType = '单色';
            this.productColorModal.color = '';
            this.productColorModal.productColor = '';
            this.productColorModal.sort = '1';
            this.productColorModal.show = true;
        },
        onDeleteProductColor(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '颜色删除后，属于该颜色的相关信息将一同被删除。',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.productColorDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getProductColorList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onModifyProductColor(id) {
            let u = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.productColorModal.id = id;
            this.productColorModal.colorType = u.ColorType;
            this.productColorModal.color = u.Color;
            this.productColorModal.productColor = u.ProductColor;
            this.productColorModal.sort = u.Sort;

            this.productColorModal.mode = 'modify';
            this.productColorModal.show = true;
        },
        onSaveProductColor() {
            this.productColorModal.loading = true;
            api.productColorSave(this.productColorModal).then(res => {
                this.productColorModal.loading = false;
                if (res.code === 0) {
                    this.productColorModal.show = false;
                    this.getProductColorList();
                    this.showSuccessModal();
                }
            });
        },
        getProductColorList() {
            this.mainTable.tableLoading = true;
            api.productColorList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedProductColor = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        }
    }
};
</script>
<style>
</style>
