<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card ref="searchCard"
                :form="mainTable.searchForm"
                :table-load-event="getProductApprovalList"
                :table-search="mainTable.tableSearch"
                :labelWidth="60"
                :defaultFieldWidth="150"
                :defaultSelectFieldWidth="200"
                @changeSelectContent="changeSelectContent" />
        </div>

        <div>
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getProductApprovalList"
                :highlight="true"
                :showCollapse="true"
                @on-row-click="onProductSelect" />
        </div>

        <Modal v-model="approvalModal.show" title="新品审核" :mask-closable="false">
            <Spin v-if="approvalModal.loading" size="large" fix></Spin>
            <Form ref="approvalModalForm"
                :label-width="80">
                <Row>
                    <Col span="24">
                    <FormItem label="申请人:">
                        {{approvalModal.applier}} ({{approvalModal.userName}})
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="审核意见:">
                        <RadioGroup v-model="approvalModal.approveState" >
                            <Radio label="2" >
                                <span>通过</span>
                            </Radio>
                            <Radio label="3" >
                                <span>不通过</span>
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                    <FormItem label="审核备注:">
                        <Input v-model="approvalModal.approveRemark" type="textarea" :rows="3" placeholder="请输入审核备注" />
                    </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button type="info" @click="onSaveApproval">保存</Button>
            </div>
        </Modal>

        <ProductApprovalModal ref="productApprovalModal" />
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import { setSelectOptions } from '@/libs/selectOption';
import BaseTableFunction from '@/components/base-table-function.vue';
import ProductApprovalModal from './approval-modal.vue';
import { Modal } from 'view-design';
export default {
    name: 'ProductApprovalList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction,
        ProductApprovalModal
    },
    data() {
        return {
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '图片', key: "PicPath", width: 40, align: 'center',
                        render: (h, params) => {
                            const imageSrc = this.imageDomain + params.row.PicPath;
                            return h('img', {
                                style: {
                                    width: '20px',
                                    height: '20px'
                                },
                                attrs: {
                                    src: imageSrc
                                },
                                on: {
                                    click: () => {
                                        Modal.info({
                                            title: '',
                                            closable: true,
                                            okText: '关闭',
                                            render: h => {
                                                return h("img", {
                                                    attrs: {
                                                        src: imageSrc,
                                                        style: "margin-top:20px;width: 350px;height: 350px;"
                                                    }
                                                });
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    },
                    { title: '编号', key: "Code", width: 80, align: 'center', excel: true },
                    { title: '名称', key: "Name", tooltip: true, width: 100, align: 'left', excel: true },
                    { title: '大类', key: "MainCategory", width: 60, align: 'center', excel: true },
                    { title: '小类', key: "SubCategory", tooltip: true, width: 80, align: 'center', excel: true },
                    { title: '等级', key: "Grade", tooltip: true, width: 60, align: 'center', excel: true },
                    { title: '包装', key: "PackCount", tooltip: true, width: 60, align: 'center', excel: true },
                    { title: '花色', key: "Color", width: 60, align: 'center', excel: true },
                    { title: '单位', key: "Unit", width: 40, align: 'center', excel: true },
                    { title: '创建日期', key: "CreateTime", tooltip: true, width: 120, align: 'left', excel: true },
                    { title: '申请人', key: "Applier",  width: 60, align: 'left', excel: true },
                    { title: '审核日期', key: "ApproveTime", tooltip: true, width: 120, align: 'left', excel: true },
                    {
                        title: '状态', key: "ApproveState", align: 'center', width: 80,
                        render: (h, params) => {
                            let text = '';
                            let color = '';
                            switch(parseInt(params.row.ApproveState)) {
                                case 1: text = '待审核'; color = 'red'; break;
                                case 2: text = '审核通过'; color = 'black'; break;
                                default: text = '审核不通过'; color = 'red';
                            }
                            return h('div', [
                                h('span', {
                                    style: {
                                        color: color
                                    }
                                }, text)
                            ]);
                        }
                    },
                    {
                        title: "操作",
                        align: "center",
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'RecordStatus',
                                        showInfo: true,
                                        showCustom: this.checkAccessArray('ProductReview') && (params.row.ApproveState === 1),
                                        customText: '审核产品'
                                    },
                                    on: {
                                        handleInfo: dataId => {
                                            this.onReviewProduct(dataId);
                                        },
                                        handleCustom: dataId => {
                                            this.onApproval(dataId, params.row);
                                        }
                                    }
                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '编码/名称', type: 'Input', value: '', name: 'concat(T_ProductApproval.Code, T_ProductApproval.Name)', sql: 'like', labelWidth: 72 },
                    { label: '等级', type: 'Select', value: '', name: 'Grade', sql: 'like', options: [], clearable: true, fieldWidth: 140 },
                    { label: '大类', type: 'Select', value: '', name: 'MainCategory', sql: 'like', options: [], clearable: true },
                    { label: '小类', type: 'Select', value: '', name: 'SubCategory', sql: 'like', options: [], clearable: true },
                    {
                        label: '状态', type: 'Select', value: '', name: 'ApproveState', sql: 'eq', options: [
                            { key: '待审核', value: '1' },
                            { key: '通过', value: '2' },
                            { key: '不通过', value: '3' }
                        ],
                        clearable: true,
                        fieldWidth: 150
                    }
                ]
            },
            selectedProduct: null,
            productGrades:[],
            mainCateList: [],
            searchSubCateFilterList: [],
            approvalModal: {
                show: false,
                loading: false,
                approveState: '',
                id: '',
                approveRemark: '',
                userName: '',
                applier: ''
            }
        };
    },
    computed: {

    },
    mounted() {
        this.getProductApprovalList();
        this.productMainCategoryList();
        this.getGradeList();
    },
    methods: {
        setSelectOptions,
        onProductSelect(row, index) {
            this.selectedProduct = row;
        },
        onReviewProduct(id) {
            this.$refs.productApprovalModal.showModal(id);
        },
        getProductApprovalList() {
            this.mainTable.tableLoading = true;
            api.productApprovalList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedProduct = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        getGradeList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.parametersGradeList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.productGrades = res.data.records;
                    this.setSelectOptions(this.mainTable.searchForm, 'Grade', this.productGrades, 'ParaName', 'ParaName');
                }
            });
        },
        productMainCategoryList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.productMainCategoryList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.mainCateList = res.data.records;
                    this.setSelectOptions(this.mainTable.searchForm, 'MainCategory', this.mainCateList, 'Name', 'Name');
                }
            });
        },
        async productSubCategoryList(parentId) {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            tempSearch.filters.enable = true;
            tempSearch.filters.conditions = [{ name: 'ParentId', type:'eq', value: parentId }];
            const { data } = await api.productCategoryList(tempSearch);
            return data.records;
        },
        async changeSelectContent(e) {

            if(e.name === 'MainCategory') {
                let mainCate = this.mainCateList.find(item => item.Name === e.value);
                this.searchSubCateFilterList = [];
                if(mainCate){
                    this.searchSubCateFilterList = await this.productSubCategoryList(mainCate.Id);
                }
                if(this.searchSubCateFilterList.length == 0) {
                    let subCate = this.mainTable.searchForm.find(item => item.name === 'SubCategory');
                    subCate.value = undefined;
                }
                this.setSelectOptions(this.mainTable.searchForm, 'SubCategory', this.searchSubCateFilterList, 'Name', 'Name');
            }
            search.globalSetFilterWithSearchForm(this.mainTable.tableSearch, this.$refs.searchCard.filterForm());
            this.getProductStandardList();
        },
        onApproval(id, row) {
            this.approvalModal.id = id;
            this.approvalModal.approveState = '';
            this.approvalModal.show = true;
            this.approvalModal.userName = row.UserName;
            this.approvalModal.applier = row.Applier;
        },
        onSaveApproval() {

            if (!this.approvalModal.approveState) {
                return this.showWarningModal('请先选择审核意见！');
            }
            this.approvalModal.approveState = parseInt(this.approvalModal.approveState);
            this.approvalModal.loading = true;
            api.productReview(this.approvalModal).then(res => {
                this.approvalModal.loading = false;
                if (res.code === 0) {
                    this.approvalModal.show = false;
                    this.getProductApprovalList();
                    this.showSuccessModal();
                }
            });
        }
    }
};
</script>
<style>
</style>
