<template>
    <div class="">
        <div class="flex searchButtonContainer">
			<DatePicker type="date" :editable="false" size="small" v-model="mainTable.saleDate"
			    :start-date="new Date(today)" @on-change="onChangeSaleDate(mainTable.saleDate)">
			</DatePicker>
			<search-card ref="searchCard" :form="mainTable.searchForm" :table-load-event="getBoxFaceList"
			    :table-search="mainTable.tableSearch" />
			<Button size="small" @click="onSaveBoxFace">保存</Button>
			<Button size="small" @click="onPrint">打印</Button>
			<Button size="small" @click="onExport">导出</Button>
			<Button size="small" @click="onBoxFaceCreate" type="primary">加箱面</Button>
        </div>
        <Row>
            <Col span="24">
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getBoxFaceList"
                :highlight="true"/>
            </Col>
        </Row>
		<box-info-print-modal ref="boxInfoRef"></box-info-print-modal>

		<Modal v-model="boxFaceCreateModal.show" title="加箱面" :mask-closable="false" width="50" @on-cancel="onCancel">
		    <Spin v-if="boxFaceCreateModal.loading" size="large" fix></Spin>
		    <Form ref="boxFaceCreateModalForm" :model="boxFaceCreateModal" :label-width="100">
		        <Row>
		            <Col span="24">
		            <FormItem label="会员号:" prop="memberCode">
		                <Input v-model="boxFaceCreateModal.memberCode" @on-blur="getMemberInfo" placeholder="请输入会员号" clearable></Input>
		            </FormItem>
		            </Col>
					<Col span="24">
					<FormItem label="会员名称:" prop="memberName">
					    <Input v-model="boxFaceCreateModal.memberName"></Input>
					</FormItem>
					</Col>
		           <Col span="24">
		           <FormItem label="收货地址:" prop="boxFace">
		               <Input v-model="boxFaceCreateModal.boxFace" type="textarea"></Input>
		           </FormItem>
		           </Col>
				   <Col span="24">
				   <FormItem label="物流方案:" prop="logistics">
				   	<Select v-model="boxFaceCreateModal.logistics" filterable @on-change="selectedLogistics">
				   		<Option v-for="item in logisticsList" :value="item.DeliveryWay" :label="item.DeliveryWay"
				   			:key="item.DeliveryWay">
				   			{{ item.DeliveryWay }}
				   		</Option>
				   	</Select>
				   </FormItem>
				   </Col>
		        </Row>
		    </Form>
		    <div slot="footer">
		        <Button type="info"
		            @click="onSaveBoxFaceCreate">提交</Button>
		    </div>
		</Modal>

    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import { changeDate } from '@/libs/changeTime';
import boxInfoPrintModal from './box-info-modal.vue';
export default {
    name: 'BoxFaceList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction,
		boxInfoPrintModal
    },
    data() {
        return {
			boxFaceCreateModal:{
				show: false,
				loading: false,
				memberCode:'',
				memberName:'',
				boxFace:'',
				logistics:'',
				city:'',
				phone:'',
				linkMan:''
			},
			logisticsList:[],
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
					{ title: '发货日期', key: 'SaleDate', align: 'center',width:80, excel: true,
					    render:(h, params)=>{
					        return h('span', {}, params.row.SaleDate.substr(0, 10));
					    }
					},
					{
					    title: '会员号', key: 'MemberCode',align: 'center',width:60, excel: true,
					    render: (h, params) => {
					        return h('div', [
					            h('span', { style: 'color:#2b85e4' }, params.row.MemberCode)
					        ]);
					    }
					},
                    { title: '会员名称', key: "MemberName", align: 'center',width:80, excel: true },
                    { title: '订单号', key: "OrderNo", align: 'center',width:90, excel: true },
					{title: '城市', key: 'City',align: 'center',width:80, excel: true,
					    render: (h, params) => {
					        return h('Input', {
					            props: {
					                value: params.row.City,
					            },
					            on: {
					                input: newVal => {
					                    this.mainTable.tableDataForUpdate[params.index].City = newVal;
					                }
					            }
					        });
					    }
					},
					{
					    title: '箱面', key: 'BoxFace', align: 'center', excel: true,
					    render: (h, params) => {
					        return h('Input', {
					            props: {
									type:'textarea',
									autosize:true,
					                value: params.row.BoxFace,
					            },
					            on: {
					                input: newVal => {
					                    this.mainTable.tableDataForUpdate[params.index].BoxFace = newVal;
					                }
					            }
					        });
					    }
					},
					{
					    title: '物流方案', key: 'DeliveryWay',align: 'center', excel: true,
					    render: (h, params) => {
					        return h('Select', {
					            props: {
					                transfer: true,
					                value: params.row.DeliveryWay
					            },
					            on: {
					                'on-change': newVal => {
					                    this.mainTable.tableDataForUpdate[params.index].DeliveryWay = newVal;
					                }
					            },
					        },
					            this.mainTable.DeliveryWayList.map(function (i) {
					                return h('Option', {
					                    props: {
					                        value: i.ParaName
					                    }
					                }, i.ParaName);
					            })
					        );
					    }
					},
					{
					    title: '物流公司', key: 'DeliveryCompany', align: 'center', excel: true,
					    render: (h, params) => {
					        return h('Input', {
					            props: {
					                value: params.row.DeliveryCompany,
					            },
					            on: {
					                input: newVal => {
					                    this.mainTable.tableDataForUpdate[params.index].DeliveryCompany = newVal;
					                }
					            }
					        });
					    }
					},
					{
					    title: '备注', key: 'Remark', align: 'center', excel: true,
					    render: (h, params) => {
					        return h('Input', {
					            props: {
									type:'textarea',
									autosize:true,
					                value: params.row.Remark,
					            },
					            on: {
					                input: newVal => {
					                    this.mainTable.tableDataForUpdate[params.index].Remark = newVal;
					                }
					            }
					        });
					    }
					},
                ],
                tableData: [],
				tableDataForUpdate: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                  { label: '订单类型', type: 'Select', value: '', sql: 'nneq', name: 'OrderType', options: [{key:'午场',value: 'neq,4'},{key:'晚场',value: 'eq,4'},]},
					        { label: '关键字', type: 'Input', value: '', name: 'concat(MemberCode,MemberName,OrderNo,DeliveryWay,DeliveryCompany,DetailAddr)', sql: 'like' , fieldWidth: '350',clearable: true }
                ],
				DeliveryWayList:[],
				saleDate: '',
            },
			saleDate: '',
        };
    },
    computed: {
		today() {
		    let date = new Date()
		    let year = date.getFullYear()
		    let month = date.getMonth() + 1
		    month = month < 10 ? '0' + month : month
		    let day = date.getDate();
		    day = day < 10 ? '0' + day : day;
		    return `${year}-${month}-${day}`;
		}
    },
    mounted() {
		this.mainTable.saleDate = this.today;
        this.getBoxFaceList();
		this.getDeliveryWayList();
    },
    methods: {
		onChangeSaleDate(saleDate) {
		    if (!saleDate) {
		        saleDate = this.today;
		    }
		    this.mainTable.saleDate = changeDate(saleDate);
		    this.mainTable.tableSearch.filters.conditions = [];
		    this.getBoxFaceList();
		},
		//获取物流方案
		getDeliveryWayList() {
			api.parametersLogisticsTypeList(search.getSearchParams()).then(res => {
				if (res.code === 0) {
					 this.mainTable.DeliveryWayList = res.data.records;
				}
			});
		},
		//获取列表
        getBoxFaceList() {
			this.mainTable.tableLoading = true;
			this.mainTable.tableSearch.filters.enable = true;
			this.mainTable.tableSearch.filters.conditions.push({
			    name: 'SaleDate',
			    type: 'eq',
			    value: changeDate(this.mainTable.saleDate)
			});
            api.boxFaceList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.mainTable.tableData = res.data.records;
					this.mainTable.tableDataForUpdate = JSON.parse(JSON.stringify(res.data.records));
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
		//保存
		onSaveBoxFace() {
		    let saveColumns = this.mainTable.tableDataForUpdate
		        .map(col => {
		            return {
		                id: col.Id,
		                city: col.City,
		                deliveryCompany: col.DeliveryCompany,
		                deliveryWay: col.DeliveryWay,
		                boxFace: col.BoxFace,
		                remark: col.Remark,
		            };
		        });
		    this.loading = true;
		    api.boxFaceSave(saveColumns).then(res => {
		        this.loading = false;
		        if (res.code === 0) {
		            this.showSuccessModal();
		            this.getBoxFaceList();
		        }
		    });

		},
		//打印
		onPrint() {
			const SaleDate=changeDate(this.mainTable.saleDate);
			this.$refs.boxInfoRef.showModal('箱面信息打印',SaleDate);
		},
		//导出
		onExport() {
		    this.mainTable.tableSearch.excelExport.enable = true;
		    this.mainTable.tableSearch.excelExport.columns = this.mainTable.tableColumns.filter(col => col.excel).map(col => {
		            return {
		                Key: col.key,
		                Title: col.title
		            };
		        });
		    this.mainTable.tableLoading = true;
		    api.boxFaceList(this.mainTable.tableSearch).then(res => {
		        this.mainTable.tableSearch.excelExport.enable = false;
		        if (res.code === 0) {
		            let url = api.excelServerUrl + res.data.fileName;
		            this.$downLoadFile(url);
		        }
		        this.mainTable.tableLoading = false;
		    });
		},
		//选择物流方案
		selectedLogistics(e){
			this.boxFaceCreateModal.logistics=e;
		},
		//获取物流方案
		getLogisticsList() {
			if(this.boxFaceCreateModal.city){
				let tempSearch = {
					filters: {
						enable: true,
						join: "and",
						conditions: [{
							name: 'Destination',
							type: 'eq',
							value: this.boxFaceCreateModal.city
						}]
					}
				}
				api.freightConfigCityList(tempSearch).then(res => {
					if (res.code === 0) {
						this.logisticsList = res.data.records;
					}
				});
			}
		},
		//加箱面
		onBoxFaceCreate(){
			this.boxFaceCreateModal.memberCode='';
			this.boxFaceCreateModal.memberName='';
			this.boxFaceCreateModal.boxFace='';
			this.boxFaceCreateModal.logistics='';
			this.boxFaceCreateModal.show = true;
		},
		//根据会员号获取会员名称、默认地址和物流方案
		getMemberInfo(){
			if(this.boxFaceCreateModal.memberCode){
				this.getMemberDefaultAddress();
			}
		},
		async getMemberDefaultAddress() {
			const {data : data} = await api.getMemberDefaultAddress({memberCode:this.boxFaceCreateModal.memberCode});
			if(data.filterAmount>0){
				let res = data.records[0];
				this.boxFaceCreateModal.memberCode=res.MemCode;
				this.boxFaceCreateModal.memberName=res.MemName;
				this.boxFaceCreateModal.boxFace = res.DetailAddr+' '+res.LinkMan+' '+res.Phone;
				this.boxFaceCreateModal.logistics=res.DeliveryWay;
				this.boxFaceCreateModal.city=res.City;
				this.getLogisticsList();
			}else{
				this.showWarningModal('该会员号还未添加默认地址!');
			}

		},
		//提交加箱面
		onSaveBoxFaceCreate(){
			this.boxFaceCreateModal.loading = true;
			if(!this.boxFaceCreateModal.memberCode){
				this.showWarningModal('会员号不能为空!');
				this.boxFaceCreateModal.loading = false;
				return;
			}
			if(!this.boxFaceCreateModal.memberName){
				this.showWarningModal('会员名称不能为空!');
				this.boxFaceCreateModal.loading = false;
				return;
			}
			if(!this.boxFaceCreateModal.boxFace){
				this.showWarningModal('会员地址不能为空!');
				this.boxFaceCreateModal.loading = false;
				return;
			}
			if(!this.boxFaceCreateModal.logistics){
				this.showWarningModal('物流方式不能为空!');
				this.boxFaceCreateModal.loading = false;
				return;
			}
			let dataForm={
				memberCode:this.boxFaceCreateModal.memberCode,
				boxFace:this.boxFaceCreateModal.boxFace,
				deliveryWay:this.boxFaceCreateModal.logistics,
				deliveryCompany :'',
				city:this.boxFaceCreateModal.city,
				remark:this.boxFaceCreateModal.remark
			}
			api.boxFaceCreate(dataForm).then(res => {
				this.boxFaceCreateModal.loading = false;
				if (res.code === 0) {
					this.boxFaceCreateModal.show = false;
					this.getBoxFaceList();
					this.showSuccessModal();
				}
			});
		},
		// //关闭弹窗
		onCancel(){
			this.$refs.boxFaceCreateModalForm.resetFields();
			this.boxFaceCreateModal.show = false;
		}
    }
};
</script>
<style>
</style>


