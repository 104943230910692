import axios from '@/libs/api.request';
import xpack from '../libs/xpack';

export function getXpackPostRequest(data, url, method = 'post') {
    return xpack.request({
        url, 
        data,
        method
    });
}

export default function getPostRequest(data, url, method = 'post') {
    return axios.request({
        url: url,
        data: data,
        method: method
    });
}