<template>
    <Header class="header-wrapper">
        <div @click="isCollapseChange">
            <!-- <i class="el-icon-s-unfold" v-show="collapse"></i>
			<i class="el-icon-s-fold" v-show="!collapse"></i> -->
            <svg :class="{'is-active':collapse}" class="hamburger" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="64" height="64">
                <path
                    d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
            </svg>
        </div>

        <!-- 面包屑 -->
        <Breadcrumb class="custom-bread-crumb"></Breadcrumb>

        <!-- 下拉菜单 -->

        <div class="fixed-right">
            <Menu mode="horizontal" theme="light" @on-select="onMenuChange">

                <Submenu name="list">
                    <template #title>
                        <span class="loginUser">当前用户:{{getUserNickName()}}</span>
                    </template>
                    <MenuItem name="home" to="/home">首页</MenuItem>
                    <MenuItem name="user-login">重新登录</MenuItem>
                    <MenuItem name="user-info" to="/sys/user-info">个人信息</MenuItem>
                    <MenuItem name="change-password" to="/sys/change-password">修改密码</MenuItem>

                </Submenu>
            </Menu>
        </div>
    </Header>
</template>

<script>
import Breadcrumb from "./Breadcrumb";
export default {
    name: "AppHeader",
    components: {
        Breadcrumb
    },
    props:{
        isCollapse:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            collapse: this.isCollapse
        };
    },
    methods:{
        isCollapseChange(){
            this.collapse = !this.collapse;
            this.$emit('on-coll-change', this.collapse);
        },
        onMenuChange(name){
            if(name==='user-login'){
                this.setLoginInfo(null);
                this.$store.dispatch("delAllViews").finally(res=>{
                    this.$router.push({
                        name: 'UserLogin'
                    });
                });
            }
        }
    }
};
</script>

<style lang="less">
.header-wrapper {
    display: flex;
    padding: 0 20px;
    height: 60px;
    &.ivu-layout-header {
        height: 60px;
        line-height: 60px;
        background: #fff;
    }
    .hamburger {
        vertical-align: middle;
        width: 20px;
        height: 20px;
    }

    .hamburger.is-active {
        transform: rotate(180deg);
    }
    .custom-bread-crumb {
        padding: 0 10px;
        flex: 1;
    }
    .fixed-right {
        justify-content: right;
    }
}
</style>
