<template>
	<div class="sale-contianer">
		<div class="sale-list">
			<div class="sale-item" v-for="(item ,index) in list" :key="index">
				<div class="sale-item-main">
					<p class="title">{{item.title}}</p>
					<Tooltip placement="top" :content="item.count.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')">
						<p class="count">{{item.count.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')}}</p>
					</Tooltip>
				</div>
				<div class="sale-images">
					<img :src="item.img" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Sale from '@/assets/images/sale.png'
	import Order from '@/assets/images/order.png'	
	export default {
		props:{
			list:{
				type:Array,
				default :[]
			}
		},
		data() {
			return {}
		},
	}
</script>

<style lang="less" scoped>
	.sale-list{
		width: 100%;
		display: flex;
		// margin-left: 10px;
		.sale-item{
			cursor: pointer;
			margin-left: 20px;
			padding: 40px 28px 21px 23px;
			width: 50%;
			height: 210px;
			border-radius: 10px;
			display: flex;
			justify-content: space-between;
			.sale-item-main{
				height: 82px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.title{
					font-size: 26px;
					font-weight: 400;
					color: #FFFFFF;
				}
				.count{
					width: 150px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 26px;
					font-weight: bold;
					color: #FFFFFF;
					line-height: 48px;
				}
			}
			
			.sale-images{
				margin-top: 53px;
				img{
					width: 80px;
					height: 80px;
				}
			}
		}
		
		.sale-item:nth-child(1){
			background: linear-gradient(to right, #6B7CF8, #c0c3f3);
		}
		.sale-item:nth-child(2){
			background: linear-gradient(to right, #A764EE, #dac2ee);
		}
	}
</style>