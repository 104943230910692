<template>
    <div class="">
        <div class="flex searchButtonContainer">
            <search-card :form="mainTable.searchForm"
                :table-load-event="getDepartmentList"
                :table-search="mainTable.tableSearch"
                :colSpan="4"
                :searchBtnColSpan="4" />

            <Button v-if="checkAccessArray('DepartmentEdit')"
                size="small"
                @click="onAddDepartment">新增部门</Button>
        </div>
        <Row>
            <Col span="24">
            <pagination-table
                :columns="mainTable.tableColumns"
                :data="mainTable.tableData"
                :loading="mainTable.tableLoading"
                :table-search="mainTable.tableSearch"
                :table-load-event="getDepartmentList"
                :highlight="true"
                @on-row-click="onDepartmentSelect" />
            </Col>
        </Row>

        <Modal v-model="departmentModal.show"
            title="部门信息"
            :mask-closable="false">
            <Spin v-if="departmentModal.loading"
                size="large"
                fix></Spin>
            <Form ref="departmentModalForm"
                :label-width="100">
                <Row>
					<Col span="12">
					<FormItem label="部门编码:" required>
					    <Input v-model="departmentModal.deptCode"></Input>
					</FormItem>
					</Col>
                    <Col span="12">
                    <FormItem label="部门名称:" required>
                        <Input v-model="departmentModal.deptName"></Input>
                    </FormItem>
                    </Col>
					
                </Row>
                <Row>
					<Col span="12">
					<FormItem label="部门类型:" required>
						<Select v-model="departmentModal.deptType" filterable>
							<Option v-for="item in deptTypeList" :value="item.ParaCode" :label="item.ParaName"
								:key="item.ParaCode">
								{{ item.ParaName }}
							</Option>
						</Select>
					</FormItem>
					</Col>
                    <Col span="12">
                    <FormItem label="是否启用:" required>
						<RadioGroup v-model="departmentModal.isFlag">
							<Radio :label="1">是</Radio>
							<Radio :label="0">否</Radio>
						</RadioGroup>
                    </FormItem>
                    </Col>
                </Row>
				<Row>
				    <Col span="24">
				    <FormItem label="服务省份:">
						<Select v-model="departmentModal.selectProvinceList" filterable multiple>
							<Option v-for="item in provinceList" :label="item.Province" :value="item.Province"
								:key="item.Province">
								{{ item.Province }}
							</Option>
						</Select>
				    </FormItem>
				    </Col>
				</Row>
				<Row>
					<Col span="24">
					<FormItem label="部门描述:">
					    <Input type="textarea" v-model="departmentModal.deptDec"></Input>
					</FormItem>
					</Col>
				</Row>
            </Form>
            <div slot="footer">
                <Button type="info"
                    @click="onSaveDepartment">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
export default {
    name: 'DepartmentList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            departmentModal: {
                show: false,
                loading: false,
                deptName: '',
                deptCode: '',
                deptDec: '',
                isFlag: 0,
                deptType: '',
				province:'',
				selectProvinceList:[],
                mode: 'add',
				id:''
            },
			deptTypeList: [],
			provinceList:[],
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
					{ title: '部门编码', key: "DeptCode", align: 'center' },
                    { title: '部门名称', key: "DeptName", align: 'center' },
					{ title: '部门类型', key: "DeptTypeName", align: 'center'},
					{ title: '服务省份', key: "Province", align: 'center' },
					{ title: '是否启用', key: "IsFlag", align: 'center' ,
						render: (h, params) => {
						    let text = '';
						    let color = '';
						    switch(parseInt(params.row.IsFlag)) {
						        case 0: text = '否'; color = 'black'; break;
						        case 1: text = '是'; color = 'red'; break;
						        default: text = '未知'; color = 'red';
						    }
						    return h('div', [
						        h('span', {
						            style: {
						                color: color
						            }
						        }, text)
						    ]);
						}
					},
                    { title: '部门描述', key: "DeptDec", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 80,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showEdit: this.checkAccessArray('DepartmentEdit'),
                                        showDelete: this.checkAccessArray('DepartmentEdit')
                                    },
                                    on: {
                                        handleEdit: dataId => {
                                            this.onModifyDepartment(dataId);
                                        },
                                        handleDelete: dataId => {
                                            this.onDeleteDepartment(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: [
                    { label: '部门名称', type: 'Input', value: '', name: 'DeptName', sql: 'like' },
                    { label: '部门编码', type: 'Input', value: '', name: 'DeptCode', sql: 'like' },
                    { label: '服务省份', type: 'Input', value: '', name: 'Province', sql: 'like' },
                ]
            },
            selectedDepartment: null
        };
    },
    computed: {

    },
    mounted() {
        this.getDepartmentList();
		this.getDeptTypeList();
		this.getProvinceList();
    },
    methods: {
		//获取部门类型列表
		getDeptTypeList() {
			let tempSearch = {
				filters: {
					enable: true,
					join: 'and',
					conditions: [{
						name: 'ParaType',
						type: 'eq',
						value: '200'
					}]
				}
			}
			api.parametersList(tempSearch).then(res => {
				if (res.code === 0) {
					this.deptTypeList = res.data.records;
				}
			});
		},
		//获取省份
		getProvinceList() {
			api.districtProvinceList({}).then(res => {
				if (res.code === 0) {
					this.provinceList = res.data.records;
				}
			});
		},
        onDepartmentSelect(row, index) {
            this.selectedDepartment = row;
        },
        onAddDepartment() {
            this.departmentModal.mode = 'add';
            this.departmentModal.id = '';
            this.departmentModal.deptName = '',
            this.departmentModal.deptCode = '',
            this.departmentModal.deptDec = '',
            this.departmentModal.isFlag =0,
            this.departmentModal.deptType = '',
			this.departmentModal.selectProvinceList=[],
            this.departmentModal.show = true;
        },
        onDeleteDepartment(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '确定删除吗？',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.departmentDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getDepartmentList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },
        onModifyDepartment(id) {
            let b = this.mainTable.tableData.find(e => {
                return e.Id === id;
            });
            this.departmentModal.id = b.Id;
            this.departmentModal.deptName = b.DeptName;
            this.departmentModal.deptCode = b.DeptCode;
            this.departmentModal.deptDec = b.DeptDec;
            this.departmentModal.isFlag = b.IsFlag;
            this.departmentModal.deptType = b.DeptType;
			this.departmentModal.selectProvinceList = b.Province ? b.Province.split(',') : [];
            this.departmentModal.mode = 'modify';
            this.departmentModal.show = true;
        },
        onSaveDepartment() {
			if(!this.departmentModal.deptCode){
				this.showWarningModal('部门编码不能为空!');
				this.departmentModal.loading = false;
				return;
			}
			if(!this.departmentModal.deptName){
				this.showWarningModal('部门名称不能为空!');
				this.departmentModal.loading = false;
				return;
			}
			if(!this.departmentModal.deptType){
				this.showWarningModal('部门类型不能为空!');
				this.departmentModal.loading = false;
				return;
			}
			let selectProvinceList = this.departmentModal.selectProvinceList;
			let selectProvince = selectProvinceList.join(',');
			let dataForm={
				mode:this.departmentModal.mode,
				id:this.departmentModal.id,
				deptName:this.departmentModal.deptName,
				deptCode:this.departmentModal.deptCode,
				deptDec:this.departmentModal.deptDec,
				isFlag:this.departmentModal.isFlag,
				deptType:this.departmentModal.deptType,
				province:selectProvince
			}
            this.departmentModal.loading = true;
            api.departmentSave(dataForm).then(res => {
                this.departmentModal.loading = false;
                if (res.code === 0) {
                    this.departmentModal.show = false;
                    this.getDepartmentList();
                    this.showSuccessModal();
                }
            });
        },
        getDepartmentList() {
            this.mainTable.tableLoading = true;
            api.departmentList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedDepartment = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        }
    }
};
</script>
<style>
</style>


