<template>
    <div class="">
        <Modal v-model="show" title="产品关联促销活动" :mask-closable="false" width="60" cancel-text="关闭">
            <Spin v-if="loading" size="large" fix></Spin>

            <Form ref="productAndSupplierModalForm"
                :label-width="100">
                <Row>
                    <Col span="8">
                    <FormItem label="促销活动:" required>
                        <Select v-model="salesPromotionId" filterable style="width:100%">
                            <Option v-for="item in salesPromotionList"
                                :value="item.key"
                                :key="item.key">{{ item.value }}</Option>
                        </Select>
                    </FormItem>
                    </Col>
                    <Col span="8">
                    <FormItem label="">
                        <Button type="primary" @click="onSalesPromotionAndProduct">关联活动</Button>
                    </FormItem>
                    </Col>
                </Row>
            </Form>

            <Row>
                <Col span="24">
                <pagination-table
                    :columns="mainTable.tableColumns"
                    :data="mainTable.tableData"
                    :loading="mainTable.tableLoading"
                    :table-search="mainTable.tableSearch"
                    :table-load-event="getSalesPromotionAndProductList"
                    :highlight="true"
                    @on-row-click="onSalesPromotionAndProductSelect" />
                </Col>
            </Row>

        </Modal>
    </div>
</template>

<script>
import api from '@/api/api';
import search from '@/libs/search';
import PaginationTable from '@/components/pagination-table.vue';
import SearchCard from '@/components/searchCard.vue';
import BaseTableFunction from '@/components/base-table-function.vue';
import { USER_TYPE_SUPPLY, USER_TYPE_JOIN } from '@/view/common/userType';
export default {
    name: 'SalesPromotionAndProductList',
    components: {
        PaginationTable,
        SearchCard,
        BaseTableFunction
    },
    data() {
        return {
            USER_TYPE_SUPPLY,
            show: false,
            supCode: '',
            salesPromotionId: '',
            loading: false,
            mainTable: {
                tableColumns: [
                    { type: 'index', width: 40, align: 'center' },
                    { title: '产品编码', key: "ProductCode", align: 'center' },
                    { title: '产品名称', key: "ProductName", align: 'center' },
                    { title: '促销活动', key: "SalesPromotionName", align: 'center' },
                    { title: '活动开始时间', key: "StartTime", align: 'center' },
                    { title: '活动结束时间', key: "EndTime", align: 'center' },
                    {
                        title: "操作",
                        align: "center",
                        width: 40,
                        render: (h, params) => {
                            return h(BaseTableFunction,
                                {
                                    props: {
                                        dataId: params.row.Id,
                                        statusField: 'Status',
                                        showDelete: this.checkAccessArray('SalesPromotionAndProductEdit')
                                    },
                                    on: {
                                        handleDelete: dataId => {
                                            this.onDeleteSalesPromotionAndProduct(dataId);
                                        }
                                    }

                                }
                            );
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                tableSearch: search.getSearchParams(),
                searchForm: []
            },
            selectedSalesPromotionAndProduct: null,
            productId: '',
            productCode: '',
            salesPromotionList: [],
            isSupplier: false
        };
    },
    computed: {

    },
    mounted() {
        this.checkUserType();
    },
    methods: {
        showModal(product) {
            this.show = true;
            this.productId = product.Id;
            this.productCode = product.Code;
            this.getSalesPromotionList();
            this.getSalesPromotionAndProductList();
        },
        onSalesPromotionAndProductSelect(row, index) {
            this.selectedSalesPromotionAndProduct = row;
        },

        onDeleteSalesPromotionAndProduct(id) {
            this.$Modal.confirm({
                title: '确认',
                content: '确定删除吗？',
                onOk: () => {
                    this.mainTable.tableLoading = true;
                    api.salesPromotionAndProductDelete({ id: id }).then(res => {
                        this.mainTable.tableLoading = false;
                        if (res.code === 0) {
                            this.getSalesPromotionAndProductList();
                            this.showSuccessModal();
                        }
                    });
                }
            });
        },

        onSalesPromotionAndProduct() {

            if(!this.salesPromotionId){
                return this.showWarningModal('请选择促销活动！');
            }

            let params = {
                productId: this.productId,
                productCode: this.productCode,
                supCode: '',
                supName: '',
                salesPromotionId: this.salesPromotionId
            };

            this.loading = true;
            api.salesPromotionAndProductSave(params).then(res => {
                this.loading = false;
                if (res.code === 0) {
                    if (this.isSupplier) {
                        this.salesPromotionId = '';
                    }
                    else{
                        this.salesPromotionId = '';
                        this.supCode = '';
                    }
                    this.getSalesPromotionAndProductList();
                    this.showSuccessModal(res.message);
                }
            });
        },
        getSalesPromotionAndProductList() {
			this.mainTable.tableSearch.filters.conditions=[];
            this.mainTable.tableLoading = true;
            this.mainTable.tableSearch.filters.enable = true;
            this.mainTable.tableSearch.filters.conditions.push({ name: 'ProductId', type:'eq', value: this.productId },{ name: 'IsProductOrBrand', type:'eq', value: '0' });
            api.salesPromotionAndProductList(this.mainTable.tableSearch).then(res => {
                this.mainTable.tableLoading = false;
                if (res.code === 0) {
                    this.selectedProductAndSupplier = null;
                    this.mainTable.tableData = res.data.records;
                    search.globalSetFilterRecordCount(this.mainTable.tableSearch, res.data.filterAmount);
                }
            });
        },
        getSalesPromotionList() {
            let tempSearch = search.getSearchParams({ closeAllConditions: true });
            api.salesPromotionActiveList(tempSearch).then(res => {
                if (res.code === 0) {
                    this.salesPromotionList = res.data.records.map(s=> {
                        return { key: s.Id, value: s.Name };
                    });
                }
            });
        },
        checkUserType() {
            this.isSupplier = this.getUserType() == this.USER_TYPE_SUPPLY;
        }

    }
};
</script>
<style media="print" scoped lang="less">

/deep/ .ivu-modal-footer > .ivu-btn-primary {
    display: none;
}
</style>

